import React, { Component } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import '../css/tips.sass'

export default class Tips extends Component {
    constructor(props) {
        super(props)
        //console.log(props, )
        this.state = {
            tipCtx: props.ctx //ctx,type
        }
    }

    componentWillReceiveProps(props){
        this.setState({
            tipCtx: props.ctx
        })
    }

    render() {
        const { tipCtx } = this.state
        return (
            <p className={tipCtx['type'] + ' tips'}>
                {tipCtx['ctx']}
            </p>
        )
    }
}