/**
 * Created by qs on 2021/3/16.
 */

export default [
    // {
    //     alpha_grouper_id: 'xs1442s',
    //     alpha_grouper_avatar: '',
    //     alpha_grouper_nickname: '测试数据',
    //     userMobile: 18855111710,
    //     openTime: '2021-03-16 15:00',
    //     openNum: 230,
    //     arrived_dates: ["12月07日", "12月07日", "12月07日", "12月07日", "12月07日"],
    //     end_day: 1,
    //     end_time: '16:00',
    //     sale_amount: 3506,
    //     buyer_num: 656,
    //     alpha_groups: [
    //         {
    //             id: "rxwqs",
    //             status: 1,//已成团，已发货
    //             arrived_date: '12月07日',
    //             arrived_day: '星期一',
    //             sale_amount: 2020,
    //             order_num: 3335,
    //             end_date: '2021-04-08 13:00'
    //         },
    //         {
    //             id: "erxwqs",
    //             status: 3,//未成团，进行中
    //             arrived_date: '12月27日',
    //             arrived_day: '星期一',
    //             sale_amount: 2020,
    //             order_num: 3335,
    //             end_date: '2021-04-05 11:22'
    //         },
    //         {
    //             id: "rrrrxwqs",
    //             status: 4,//未成团，已结束
    //             arrived_date: '2021-03-16',
    //             arrived_day: '星期一',
    //             sale_amount: 2020,
    //             order_num: 3335,
    //             end_date: '2020-04-05 11:22'
    //         }
    //     ]
    // },
    // {
    //     alpha_grouper_id: 'xs12s',
    //     alpha_grouper_avatar: '',
    //     alpha_grouper_nickname: '测试数据',
    //     userMobile: 18855111710,
    //     openTime: '2021-03-16 15:00',
    //     openNum: 230,
    //     arrived_dates: ["12月07日", "12月07日", "12月07日", "12月07日", "12月07日"],
    //     end_day: 1,
    //     end_time: '16:00',
    //     sale_amount: 3506,
    //     buyer_num: 656,
    //     alpha_groups: [
    //         {
    //             id: "rxwqs",
    //             status: 1,//已成团，已发货
    //             arrived_date: '12月07日',
    //             arrived_day: '星期一',
    //             sale_amount: 2020,
    //             order_num: 3335,
    //             end_date: '2021-04-08 13:00'
    //         },
    //         {
    //             id: "rxwqsqq",
    //             status: 2,//已成团，未发货
    //             arrived_date: '12月17日',
    //             arrived_day: '星期一',
    //             sale_amount: 2020,
    //             order_num: 3335,
    //             end_date: '2021-04-08 12:44'
    //         },
    //         {
    //             id: "erxwqs",
    //             status: 3,//未成团，进行中
    //             arrived_date: '12月27日',
    //             arrived_day: '星期一',
    //             sale_amount: 2020,
    //             order_num: 3335,
    //             end_date: '2021-04-05 11:22'
    //         },
    //         {
    //             id: "rrrrxwqs",
    //             status: 4,//未成团，已结束
    //             arrived_date: '2021-03-16',
    //             arrived_day: '星期一',
    //             sale_amount: 2020,
    //             order_num: 3335,
    //             end_date: '2020-04-05 11:22'
    //         }
    //     ]
    // }
]