import React, { Component } from "react";
import { Table } from 'antd';
import { withRouter as router } from 'react-router-dom'
import '../css/poll.sass'

class Poll extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jumpSrc: props['location']['state'],
            domHeight: 0,
            hide: false
        }
    }
    componentDidMount(props) {
        let dom = document.getElementById('mainIframe');
        this.setState({
            domHeight: dom['offsetParent']['clientHeight']
        })
    }

    printHandle = () => {
        //console.log(this.refs)
        window.print() //调用浏览器的打印预览
    }

    // 点击打印按钮  预览打印信息
    handleClick = () => {
        // 根据id获取 局部打印信息
        //const el = document.getElementById('content');
        //const iframe = document.getElementById('mainIframe');
        //console.log(iframe)
        //const iframe = document.createElement('IFRAME');
        //let doc = null;
        // iframe.setAttribute('id', 'printTest');
        // 根据渲染需要 增加具体的样式解决方式---- visibility: hidden：在调用iframe时在父页面出现打印内容
        //iframe.setAttribute('style', 'textAlign:center; visibility: hidden');
        //document.body.appendChild(iframe);
        //doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        //doc.write('<LINK rel="stylesheet" type="text/less" href="WarehouseOrderPrinting/pintStyle.less">');
        //doc.write('<div>' + el.innerHTML + '</div>');
        //doc.close();
        // 获取iframe的焦点，从iframe开始打印
        //iframe.contentWindow.focus();
        //iframe.contentWindow.print();
        // 打印完之后 关闭小窗
        //setTimeout(() => {
            //document.body.removeChild(iframe);
            //this.cancelSpulist();
        //}, 500);
    }

    render() {
        const { jumpSrc, domHeight, hide } = this.state;
        return (
            <div className='allprint'>
                <div className='poll flex'>
                    <div className='left' ref='printArea'>
                        <div className='content' id='content' style={{ height: domHeight }}>
                            <iframe border={0} id="mainIframe" name="mainIframe" src={jumpSrc} style={{ width: '100%', border: 0, zIndex: '999', minHeight: '100%' }}>
                            </iframe>
                        </div>
                    </div>
                    <div className='right print-hide'>
                        <p onClick={() => { this.printHandle() }} className='printBtn'>打印</p>
                    </div>
                </div>

            </div>
        )
    }
}

export default router(Poll)