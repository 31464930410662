import React from 'react'
import { withRouter as router } from 'react-router-dom'
import Base from '../../../../components/base'
import config from '../../../../configs/upload'
import ClassificationListRequest from '../../api/Classification/classificationListRequest'
import EditStandardGoodsRequest from '../../api/StandardGoods/EditStandardGoodsRequest'
import DetailStandardGoodsRequest from '../../api/StandardGoods/DetailStandardGoodsRequest'
import SpecificationListRequest from '../../api/SpecificationMangagemen/SpecificationListRequest'
import AddSpecificationValueRequest from '../../api/SpecificationMangagemen/AddSpecificationValueRequest'
import SpecsInfoRequest from '../../api/SpecificationMangagemen/SpecsInfoRequest'
import { Anchor, Divider, Input, Select, Modal, Radio, Switch, InputNumber, Button, Table, message, Spin } from 'antd';


import Tips from '../../../../components/tips/tips'
import UploadImage from '../../components/upLoadList'

import _, { find, map } from 'lodash'
import { PlusOutlined } from '@ant-design/icons';
import Sku from '../../../../components/sku'

import Editor from '../../../../components/editor'
import BigImgBar from '../../../../components/editor/bigImgBar'
import BigImgView from '../../../../components/editor/bigImgView'
import SmallImgBar from '../../../../components/editor/smallImgBar'
import SmallImgView from '../../../../components/editor/smallImgView'
import TextBar from '../../../../components/editor/textBar'
import TextView from '../../../../components/editor/textView'
import ComponentType from '../../../../components/editor/ComponentType'

import '../../css/newGoods.sass'
const { Link } = Anchor;
const { TextArea } = Input;
class EditGoods extends Base {
  constructor(props) {
    super(props)
    //console.log(props, '---1--props')
    this.state = {
      member_type: '',
      memberType: [],
      goodsName: '',
      readioValue: 1,
      frozenFoodValue: 'freezing',
      goodsCode: '',
      goodssalePrice: '',
      goodscostPrice: '',
      maxSaleNum: '',
      textArea: '',
      specification: false,
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      merchantId: '',
      goodsId: props.history.location.state.data.id,
      openSwitch: false,
      allDetail: {},//商品详情
      specsVal: false,
      specsList: [],//规格
      spec_pre: {},//规格名
      specsSonList: [],//规格值
      addSpecVal: [],//增加规格值
      selectSpec: [],//选择的规格值
      allSpecList: [],//所有选中规格值
      goods_image: '',//主图
      skuList: [],
      goodsContent: [],
      loadEditor: false,
      spinning: true,//加载中
      canUpGoods: true,//可以提交
      entities: [],
      hasEntities: false,
      main_img_flag: 0 //主图序号
    }
  }
  async componentDidMount() {

    let props = await this.props.location.state
    //console.log(this.props)
    if (props && props.data && props.data.id) {
      await this.setState({
        //merchantId: props.data.merchant_id, 
        goodsId: props.data.id
      })
      await this.getType()
      await this.getUrlSpec()
    } else {
      message.error('抱歉出错了！')

      setTimeout(() => {
        window.history.go(-1)
      }, 1000)

    }

  }

  componentWillReceiveProps(props) {
    //console.log(props, '-----2--------props---------')
  }

  getType = async () => {
    //await this.state.merchantId
    let res = await DetailStandardGoodsRequest.send({
      goods_id: this.state.goodsId,
      //merchant_id: this.state.merchantId 
    })
    let content = res.data['goods_content'];
    for (let i in content) {
      let keys = Object.keys(content[i]);
    }
    if (res.code == 200) {
      this.setState({
        allDetail: res['data']
      })
    }
    if (res.data['entities']) {
      let specsVal = true
      let skuList = res.data['entities']
      let isNullkeys = Object.values(res.data['entities'][0]['spec']);
      if (isNullkeys[0] == '' || !isNullkeys[0]) {
        specsVal = false;
        skuList = []
      }
      this.setState({
        specsVal,
        skuList
      })
    }
    if (res.data['goods_content']) {
      let content = res.data['goods_content']
      this.setState({
        goodsContent: [...content]
      })
    }
    this.setState({
      loadEditor: true
    })
    let { data } = await ClassificationListRequest.send({
      //merchant_id: this.state.merchantId 
    })
    let fileList = [];
    if (res && res.status == true && res.data) {
      let r = res.data

      let goods_image = r['goods_image']

      //console.log(r['image_list'])
      r['image_list'].map((v, i) => {
        if (v.indexOf('{') > - 1) {
          v = JSON.parse(v)['url']
        }

        if (v == goods_image) {
          this.setState({
            main_img_flag: i
          })
        }

        fileList.push(v)
      })
      let goodsName = r.goods_name;
      let member_type = r.category_ids[0];
      let textArea = r.goods_content;
      let goodsId = r.id;
      let goodsCode = r.goods_code;
      let goodscostPrice = r.market_price;
      //let merchantId = r.merchant_id;
      let goodssalePrice = r.sell_price;
      let maxSaleNum = r.stock_num;
      let frozenFoodValue = r.storage_type;
      this.setState({ goodsCode, goodsName, member_type, textArea, goodsId, fileList, goodscostPrice, goodssalePrice, maxSaleNum, frozenFoodValue })

    }
    this.setState({ memberType: data, spinning: false, goods_image: res['data']['goods_image'] })
    await this.state.allDetail
    await this.initData()//从初始化数据

  }

  initData = async () => {//初始化数据
    // 
    let specs = await this.getUrlSpec()
    //详情
    //列表
    //组装数据

    const demoSku = [//实例
      {
        cost_price: 10,
        sell_price: 20,
        entity_code: "2",
        stock_num: 30,
        values: [],
        spec_values: [
          {
            rowSpan: '1',
            specId: "k25y62",
            specName: "wwwwww",
            specValue: {
              id: "OGvxQX",
              value: "aaa"
            }
          }
        ]
      }
    ]

    let { allDetail } = await this.state;//详情

    let entities = allDetail['entities'];
    entities = entities ? entities : [];

    //console.log(allDetail,)

    if (entities && entities.length) {//有规格无值
      let isNullkeys = Object.values(allDetail['entities'][0]['spec']);
      if (isNullkeys[0] == '' || !isNullkeys[0]) {
        entities = []
      }
    }



    //组装
    let newEntities = entities.map((v, i) => {
      return {
        market_price: v['market_price'],
        sell_price: v['sell_price'],
        stock_num: v['stock_num'],
        entity_code: v['entity_code'],
        spec_values: map(v['spec'], (specVId, specId) => {
          let spec = find(specs, (spec) => {
            return specId === spec.id;
          });

          if (spec) {
            let specValue = find(spec['values'], (v) => {
              return v.id === specVId;
            });
            if (specValue) {
              return {
                specId: specId,
                specName: spec['name'],
                specValue: {
                  id: specVId,
                  value: specValue['value']
                }
              };
            }
          }
          return null;
        })
      };
    })

    await this.setState({ entities: [...newEntities] });

    console.log(newEntities)

    if (newEntities.length > 0) {
      this.setState({ hasEntities: true });
    } else {
      this.setState({ hasEntities: false });
    }

  }



  // 开启规格
  SwitchonChange = (checked) => {
    this.setState({ specification: checked })
  }

  getBase64 = (file) => {
    // console.log(file,'getBase64')
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  beforeUpload = (e) => {

    let type = e.type.includes('image');

    return new Promise((resolve, reject) => {
      if (!type) {
        message.error('格式不正确！')
        reject(false)
      } else if (e.size > 2000000) {
        message.error('图片尺寸应小于2MB')
        reject(false)
      } else {
        message.loading('上传图片中...', 1)
        resolve(e)
      }
    })
  }


  scrollToBottom() {
    if (this.messagesEnd) {
      const scrollHeight = this.messagesEnd.scrollHeight;//里面div的实际高度  2000px
      const height = this.messagesEnd.clientHeight;  //网页可见高度  200px
      const maxScrollTop = scrollHeight - height;
      this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。

      //console.log('scrollHeight', scrollHeight)
    }
  }


  handleChange = async ({ file }) => {
    await file
    const { fileList } = this.state;
    //let hide
    //return
    //  {url, is_main}
    if (file['response'] && file['response']['status']) {
      let data = file['response']['data']['url'];
      let list = fileList;
      list.push(data);

      if (list.length == 1) {
        this.setState({
          goods_image: list[0]
        })
      }
      this.setState({ fileList: list });
      message.success('上传成功！', 1);
    } else if (file['status'] && file['status'] == 'error') {
      //console.log(file,)
      message.error('上传失败！', 1);
    }

  };

  delUploadImg = (index) => {//删除上传图片
    let { fileList, main_img_flag } = this.state;
    if (index == main_img_flag) {
      return message.error('不能删除主图')
    }
    if (index < main_img_flag) {
      main_img_flag -= 1;
    }
    fileList.splice(index, 1)
    this.setState({
      fileList,
      main_img_flag
    })
  }

  setMainImg = (index) => {//设为主图
    const { fileList, goods_image } = this.state;
    let main_img = fileList[index];
    this.setState({ fileList, goods_image: main_img, main_img_flag: index })
  }

  fontImg = (index) => {
    let { fileList } = this.state

    if (index != 0) {
      fileList[index] = fileList.splice(index - 1, 1, fileList[index])[0];
    } else {
      fileList.push(fileList.shift());
    }

    this.setState({
      fileList
    })
  }

  backImg = (index) => {
    let { fileList } = this.state
    if (index != fileList.length - 1) {
      fileList[index] = fileList.splice(index + 1, 1, fileList[index])[0];
    } else {
      fileList.unshift(fileList.splice(index, 1)[0]);
    }
    this.setState({
      fileList
    })
  }


  openSpecs = async (e) => {//开启规格
    this.setState({
      specsVal: e
    })
    if (e) {
      this.setState({
        goodssalePrice: 0,
        goodscostPrice: 0,
        maxSaleNum: 0
      })
    }
  }

  getUrlSpec = async () => {//获取接口规格值
    let param = {
      page: -1
    }
    //const hide = message.loading('获取规格值中')
    let res = await SpecificationListRequest.send(param);
    let list = [];
    if (res.status) {
      let allSpec = res.data.data;
      list = allSpec.map((v, i) => {
        return {
          id: v['id'],
          name: v['spec_name'],
          values: v['spec_value'].map((sv) => {
            return {
              value: sv['spec_value'],
              id: sv['id']
            }
          })
        }
      });
      this.setState({ specsList: [...list] });
    }
    //hide();
    return list;
  }

  tabchange = (e) => {//spec table
    // console.log(e, 'ggggggggggg')
  }

  tabList = () => {
    //console.log(this.state.selectSpec)
  }

  specsInfo = async () => {//规格详情
    let param = {
      spec_id: this.state.spec_pre['id'],
      //merchant_id: this.state.merchantId
    }
    let res = await SpecsInfoRequest.send(param);
    return res;
  }

  arrCon = (arr1, arr2) => {//数组合成
    arr1.map((v, index) => {
      if (typeof (v) == 'string') {
        arr1[index] = JSON.parse(v)
      }
    })
    arr2.map((v, index) => {
      if (typeof (v) == 'string') {
        arr2[index] = JSON.parse(v)
      }
    })
    let lastArr = arr1.concat(arr2)
    this.setState({
      allSpecList: lastArr
    })
    return lastArr;
  }

  selectSpecsSonName = (data) => {//选择规格值
    const newarr = [...data];
    let { selectSpec, spec_pre } = this.state;

    newarr.map((v, i) => {
      newarr[i] = JSON.parse(v);
      let keys = spec_pre['id']
      newarr[i]['spec'] = {}
      newarr[i]['spec'][keys] = newarr[i]['id']
      selectSpec.map((k, j) => {
        if (k['id'] == newarr[i]['id']) {
          newarr[i]['entity_code'] = selectSpec[j]['entity_code'] || 0
          newarr[i]['market_price'] = selectSpec[j]['market_price'] || 0
          newarr[i]['sell_price'] = selectSpec[j]['sell_price'] || 0
          newarr[i]['stock_num'] = selectSpec[j]['stock_num'] || 0
        }
      })
    })

    this.setState({
      selectSpec: [...newarr]
    })
  }

  addSpecValAction = () => {//增加规格值

    const { addSpecVal, selectSpec } = this.state
    addSpecVal.map((v, index) => {
      if (!v['name'] || !v['name'].trim().length) {
        addSpecVal.splice(index, 1)
      }
    })
    addSpecVal.push({ name: '', id: new Date().getTime() })
    this.setState({
      addSpecVal
    })
  }

  delAddSpecs = (item, index) => {//删除规格值
    const { selectSpec, addSpecVal } = this.state;
    addSpecVal.splice(index, 1)
    this.setState({
      addSpecVal
    })
    this.arrCon([...selectSpec], [...addSpecVal])
  }

  addSpecBlur = (e, index) => {//键入值
    let { addSpecVal } = this.state;
    addSpecVal[index]['name'] = e.target.value;
    addSpecVal[index]['spec_value'] = e.target.value;
    this.setState({
      addSpecVal
    })


    // 非空判断
    addSpecVal.map(async (v, index) => {
      let canup = true
      if (!v || !v['name'].trim().length || v == '') {
        return canup = false
      }

      this.setState({
        spinning: true
      })
      //获取规格值

      let getSpec = await this.specsInfo();
      let getSpecList = getSpec['data']['spec_value'];

      getSpecList.map(list => {
        if (v['name'] == list['spec_value']) {
          addSpecVal.splice(index, 1)
          this.setState({
            addSpecVal
          })
          message.error('有相同规格值')
          return canup = false
        }
      })

      if (canup) {
        let param = {
          spec_id: this.state.spec_pre.id,
          spec_value: [v['name']],
          //merchant_id: this.state.merchantId
        }
        let res = await AddSpecificationValueRequest.send(param)
        if (res && res.code == 200) {
          message.success(res.message)
          const { selectSpec, addSpecVal } = this.state;
          this.arrCon([...selectSpec], [...addSpecVal])
        } else {
          message.error(res.message)
        }
        this.setState({
          spinning: false
        })
      }
    })
  }

  changeSwitch = (e) => {//点击switch
    const { openSwitch } = this.state.openSwitch;
    this.setState({
      openSwitch: !this.state.openSwitch
    })

  }


  replace = () => {
    this.props.history.go(-1)
  }
  save = async () => {

    let { canUpGoods, maxSaleNum, goodssalePrice, goodsId } = await this.state;

    if (!canUpGoods) {
      return message.error('请稍后再试！')
    }


    let edom = this.refs.edom;//编辑商品 component
    //console.log(JSON.stringify(edom))
    let edomCont = edom['state']['components'];
    let editorData = [];
    edomCont.map((v, i) => {
      let data = {};
      data[v['type']] = v['data'];
      editorData.push(data);
    })

    let SkuList = this.refs.SkuList ? this.refs.SkuList.state['specEntities'] : [];

    SkuList.map((v, i) => {
      v['spec'] = {};
      let spec_values = v['spec_values'];
      spec_values.map(j => {
        let specValue = j['specValue']
        v['spec'][j['specId']] = specValue['id']
      })
    })

    let fiellist = this.state.fileList;
    let lastline = {};
    let lent = this.state.fileList.length - 1;


    if (fiellist[lent].response) {
      lastline = JSON.stringify({
        uid: fiellist[lent]['uid'],
        name: fiellist[lent]['name'],
        status: fiellist[lent]['status'],
        url: fiellist[lent]['response']['data']['url']
      })
      fiellist.pop();
      fiellist.push(lastline);
    }
    let line = fiellist;
    line.map((res, i) => {
      if (typeof (res) == "object") {
        line[i] = JSON.stringify(res)
      }
    })
    let arr = []
    let arr1 = arr.concat(this.state.member_type)
    let params = {
      goods_type: 1,
      goods_id: goodsId,
      goods_name: this.state.goodsName,
      //merchant_id: this.state.merchantId,
      category_ids: arr1,
      image_list: line,
      goods_code: this.state.goodsCode,
      market_price: this.state.goodscostPrice,
      storage_type: this.state.frozenFoodValue,
      goods_image: this.state.goods_image
    }

    if (maxSaleNum) {
      if (isNaN(maxSaleNum)) {
        maxSaleNum = 0
      }
      params['stock_num'] = maxSaleNum
    }

    if (goodssalePrice) {
      if (isNaN(goodssalePrice)) {
        goodssalePrice = 0
      }
      params['sell_price'] = goodssalePrice
    }



    if (SkuList) {
      params['entities'] = [...SkuList]
    }
    if (edomCont.length) {
      params['goods_content'] = editorData;
    }
    if (!params['goods_name']) {
      return message.error('请填写商品名称')
    }
    if (!params['goods_image']) {
      return message.error('请上传商品图片')
    }
    if (!SkuList && !params['sell_price']) {
      return message.error('请填写商品售价')
    }
    this.setState({
      canUpGoods: false
    })
    const hide = message.loading('提交中', 1)
    let res = await EditStandardGoodsRequest.send(params)
    if (res.status == true) {
      message.success(res.message)
      this.replace();
    } else {
      message.error(res.message)
    }
    this.setState({
      canUpGoods: true
    })

    hide()
  }





  render() {

    const { previewVisible, previewImage, fileList, previewTitle, skuList, goodsContent, loadEditor, spinning, main_img_flag } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );


    const EditorBar = [
      { title: '大图', barTemplate: BigImgBar, template: BigImgView, type: ComponentType.BIG_IMG },
      { title: '小图', barTemplate: SmallImgBar, template: SmallImgView, type: ComponentType.SMALL_IMG, data: { count: 3 } },
      { title: '文字', barTemplate: TextBar, template: TextView, type: ComponentType.TEXT }
    ]

    const uploadProps = {
      action: `${config.apiUrl}/admin/upload/upload-image`,
      listType: "picture-card",
      name: 'file',
      accept: '.png,.jpg,.jpeg,.gif',
      showUploadList:false,
      onPreview: this.handlePreview,
      onChange: this.handleChange,
      beforeUpload: this.beforeUpload
    }

    const { entities } = this.state;//entitiesentitiesentities
    //console.log(entities)

    return (
      <div className='Newgoods'>
        {/* 发布商品 */}


        <div className='conton'>
          {/* 加载 */}

          {spinning ? (
            <div className='flex' style={{ textAlign: 'center', justifyContent: 'center' }}><Spin spinning={spinning} /> 商品加载中，请稍后</div>
          ) : null}

          {!spinning ? (
            <>
              <h3 className='goods-title'>商品库/商品编辑</h3>
              <Divider style={{ borderWidth: 2, borderColor: '#707070' }} />

              <div className='creat-conton'>
                {/* <Anchor style={{ position: 'fixed', right: '20%', top: '25%' }}>
                  <Link href="#components-anchor-demo-basic" title="基本信息" />
                  <Link href="#components-anchor-demo-static" title="价格/规格" />
                  <Link href="#components-anchor-demo-desc" title="商品描述" />
                </Anchor> */}
                <div className='mainUpGoods'>
                  <h3 style={{ fontSize: '26px', fontWeight: 700 }} id='components-anchor-demo-basic'>
                    <span style={{ height: "35px", width: '3px', background: '#366FBB', display: 'inline-block', lineHeight: '35px', verticalAlign: 'middle' }} ></span>基本信息
                  </h3>
                  <ul className='openSpecs'>
                    <li className='needMargin'>
                      <label>商品名称：</label>
                      <Input placeholder="请输入商品名称，最多20字"
                        maxLength={20}
                        className='needInput'
                        value={this.state.goodsName}
                        onChange={(e) => { this.setState({ goodsName: e.target.value }) }} />
                      {!this.state.goodsName ? (
                        <Tips ctx={{ ctx: '*请输入商品名称', type: 'error' }} />
                      ) : null}
                    </li>
                    <li className='needMargin'>
                      <label>商品分类：</label>
                      <Select
                        className='needSelect'
                        style={{ borderRadius: '30px' }}
                        value={this.state.member_type}
                        showSearch
                        onChange={(e) => {
                          this.setState({ member_type: e })
                        }}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          this.state.memberType.map(item => (
                            <Select.Option key={item.id} lable={item.cate_name} value={item.id}>
                              {item.cate_name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </li>
                    <li className='needMargin'>
                      <div className='flex' style={{ alignItems: 'flex-start' }}>
                        <label>商品图片：</label>

                        <div style={{ marginLeft: '15px' }}>
                          <UploadImage uploadProps={uploadProps} files={fileList} deleteImg={(index) => {
                            this.delUploadImg(index)
                          }

                          } setMainImg={(index) => {
                            this.setMainImg(index)
                          }} main_img={main_img_flag}
                            fontImg={(index) => { this.fontImg(index) }}
                            backImg={(index) => { this.backImg(index) }}
                          />
                        </div>
                      </div>

                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                      <p style={{ fontSize: '12px', paddingLeft: '115px' }}>建议尺寸：800x800，单张大小不超过2M，最多可上传10张</p>
                      {!fileList.length ? (
                        <Tips ctx={{ ctx: '*请选择商品图片', type: 'error' }} />
                      ) : null}
                    </li>
                    <li className='needMargin'>
                      <label>是否冻品：</label>
                      <Radio.Group onChange={(e) => { this.setState({ frozenFoodValue: e.target.value }) }} value={this.state.frozenFoodValue}>
                        <Radio value={'freezing'}>冻品</Radio>
                        <Radio value={'cold'}>鲜品</Radio>
                        <Radio value={'normal'}>常温品</Radio>
                        <Radio value={'heat'}>其他</Radio>
                      </Radio.Group>
                    </li>
                  </ul>
                </div>

                <div className='mainUpGoods'>
                  <h3 style={{ fontSize: '26px', fontWeight: 700 }} id='components-anchor-demo-static'>
                    <span style={{ height: "35px", width: '3px', background: '#366FBB', display: 'inline-block', lineHeight: '35px', verticalAlign: 'middle' }} ></span>价格/规格</h3>
                  <ul className='openSpecs'>

                    <li className='needMargin'>
                      <label>开启规格：</label><Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={false}
                        checked={this.state.specsVal} onChange={this.openSpecs} onClick={this.changeSwitch} />

                      {this.state.specsVal && this.state.specsList.length > 0 ? (
                        <Sku ref='SkuList' specData={this.state.specsList} entities={entities} changeEntities={(entities) => {
                          this.setState({ entities: entities });
                        }} />
                      ) : null}

                    </li>
                    {!this.state.specsVal ? (
                      <>
                        <li className='needMargin'>
                          <label>商品编码：</label><Input placeholder='请输入商品编码' className='needInput' value={this.state.goodsCode} onChange={(e) => { this.setState({ goodsCode: e.target.value }) }} />
                        </li>
                        <li className='needMargin'>
                          <label>商品售价：</label><InputNumber className='needInput' value={this.state.goodssalePrice} onChange={(e) => { this.setState({ goodssalePrice: e }) }} />元
                        </li>
                        <li className='needMargin'>
                          <label>商品划线价：</label><InputNumber className='needInput' value={this.state.goodscostPrice} onChange={(e) => { this.setState({ goodscostPrice: e }) }} />元
                        </li>
                        <li className='needMargin'>
                          <label>最大可售数量：</label><Input placeholder='不填则为无限大' className='needInput' value={this.state.maxSaleNum} onChange={(e) => { this.setState({ maxSaleNum: e.target.value }) }} />
                        </li>
                      </>
                    ) : null}
                  </ul>

                </div>
                <div className='mainUpGoods'>
                  <h3 style={{ fontSize: '26px', fontWeight: 700 }} id='components-anchor-demo-desc'>
                    <span style={{ height: "35px", width: '3px', background: '#366FBB', display: 'inline-block', lineHeight: '35px', verticalAlign: 'middle' }} ></span>商品描述</h3>
                  <div className='needMargin'>
                    {/* 商品描述： */}
                    {/* <TextArea placeholder="请输入商品描述"
                              value={this.state.textArea}
                              onChange={(e) => { this.setState({ textArea: e.target.value }) }}
                              style={{ width: '300px', height: "120px", borderRadius: '5px' }}
                              showCount maxLength={50} /> */}

                    <div className='goods_Editor' id='goods_Editor' ref={(el) => { this.messagesEnd = el; }}>
                      {loadEditor ? (
                        <Editor goodsContent={goodsContent} updateEditor={(e) => { }} ref='edom' bars={EditorBar} scrollBottom={() => { this.scrollToBottom() }} />
                      ) : null}
                    </div>

                  </div>
                </div>
              </div>
              {!spinning ? (
                <div className='conton' style={{ boxShadow: 'none' }}>
                  <div style={{ textAlign: 'center' }}>
                    <Button className='save' onClick={() => { this.replace() }}>返回</Button>
                    <Button className='save' onClick={() => { this.save() }}>保存</Button>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    )
  }
}
export default router(EditGoods)