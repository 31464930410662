import { Request } from '@zoranwong/acc-engine.js';
//let merchant_id = window.localStorage.getItem('merchant_id')
export default class AddClassificationRequest extends Request {
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    cate_name: ''
  };
  _method = 'POST';
  _uri = '/goods/shop-goods/category/cate-add';
  constructor(params) {
    super(params);
    this._data['cate_name'] = params.cate_name;
  }
  rules() {
    return {
      
    };
  }

  messages() {
    return {
      
    };
  }
}
