import { Request } from '@zoranwong/acc-engine.js';
export default class confirmCommuneRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
       
    };
    _method = 'POST';
    _uri = '/goods/group-buy/group-template/commune/group-buying-confirm';
    constructor(params) {
        super(params);
        this._data = {
            ...params
        }
    }
    rules() {

    }

    messages() {
    }
}
