import { Card } from "@alifd/next";
import React, { useState } from "react";
import SkuTableHeader from './skuTableHeader';
import SkuTableItem from "./skuTableItem";
import { find, each, extend, map } from 'lodash';

const SkuTable = ({
    entities, specs, change = () => {
    }
}) => {
    //console.log('-------------- sk table ----', entities);
    const style = { width: '100%' };
    const [stateEntities, setStateEntities] = useState([]);
    for (const entity of entities) {
        let obj = find(stateEntities, (e) => {
            
            return entity['id'] === e['id']
        });
        if (obj) {
            let values = entity['spec_values'];
            extend(entity, obj);
            entity['spec_values'] = values;
        }
    }
    return (
        <Card {...{
            style: style,
            title: '规格参数',
        }} contentHeight="auto">
            <div className="custom-content">
                <div className="next-table">
                    <table className='groupSku'>
                        <SkuTableHeader specs={specs} />
                        <tbody>
                        {entities.map((entity, index) => {
                            let vals = [];
                            each(specs, (spec) => {
                                let val = find(entity.values, (v) => {
                                    return (v.specId === spec.id);
                                })
                                if (val)
                                    vals.push(val);
                            });
                            entity.values = vals;
                            return <SkuTableItem getEntity={e=>{}} entity={entity} index={index} change={(entity, index) => {
                                entities[index] = entity;
                                setStateEntities([...entities]);
                                change(entities);
                            }} key={'sku_entity_'+index}/>
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    );
}

export default SkuTable;