import {ServiceProvider} from "@zoranwong/acc-engine.js";
import React from 'react';
import ReactDOM from 'react-dom';
import App from '../App';
// import {Router} from "react-router-dom";

export default class ReactDOMServiceProvider extends ServiceProvider {
    boot () {
        this.render()
    }
    render () {
        ReactDOM.render(
            // <React.StrictMode>
                <App application = {global.app}></App>,
            // </React.StrictMode>,
            document.getElementById('root')
        );
    }
}