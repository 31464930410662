export default [
    {
        title: "首页",
        route: "/home",
        exact: true,
        className: "nav-home",
    },
    {
        title: "团长",
        route: "/commander/list",
        exact: true,
        className: "nav-commander",
    },
    {
        title: "商品",
        route: "/goods/standardgoods",
        exact: true,
        className: "nav-goods",
    },
    {
        title: "团购",
        route: "/groups/groupslist",
        exact: true,
        className: "nav-groups",
    },
    {
        title: "订单",
        route: "/orders",
        exact: true,
        className: "nav-orders",
    },
    // {
    //     title: "物流",
    //     route: "/logistics/routes",
    //     exact: true,
    //     className: "nav-logistics",
    // },
    {
        title: "财务",
        route: "/finance",
        exact: true,
        className: "nav-finance",
    },
    // {
    //     title: "数据",
    //     route: "/datum",
    //     exact: true,
    //     className: "nav-datum",
    // },
];