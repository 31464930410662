import {map} from 'lodash';
const SKUTool = {
    skuGenerator (spec, result = []) {
        let temp = [];
        if (spec.values.length > 0) {
            if (result.length > 0) {
                result.forEach((sku) => {
                    spec.values.forEach(function (value) {
                        let speValue = {
                            specId: spec.id,
                            specName: spec.name,
                            specValue: value,
                            rowSpan: spec.rowSpan ? spec.rowSpan : 1,
                        };
                        temp.push(sku.concat(speValue));
                    });
                });
            } else {
                spec.values.forEach(function (value) {
                    let speValue = {
                        specId: spec.id,
                        specName: spec.name,
                        specValue: value,
                        rowSpan: spec.rowSpan ? spec.rowSpan : 1,
                    };
                    temp.push([speValue]);
                });
            }
        }
        return result = temp;
    },
    skuEntitiesGenerator (specs) {
        let skuResult = [];
        let entities = [];
        for (let spec of specs) {
            skuResult = this.skuGenerator(spec, skuResult);
        }

        for (const el of skuResult) {
            let entity = {
                sell_price: 0,
                stock_num: 0,
                market_price: 0,
                spec_values: el
            };
            entities.push(entity);
        }
        return entities;
    },
    spanRowsGenerator (specs) {
        let reSpecs = specs.reverse();
        reSpecs.reduce((rowSpan, spec) => {
            spec.rowSpan = rowSpan;
            return spec.values.length * rowSpan;
        }, 1);
        return reSpecs.reverse();
    },
    rebuildSpecs (entities) {
        let specs = {};
        for (let entity of entities) {
            let values = entity['spec_values'];
            for(let v of values) {
                let spec = specs[v.specId];
                if(!spec){
                    spec = {
                        id: v.specId,
                        name: v.specName,
                        values: {}
                    }
                }

                let vs = spec.values;
                if(!vs[v.specValue['id']]){
                    spec.values[v.specValue['id']] = {
                        id: v.specValue['id'],
                        value: v.specValue['value']
                    }
                }
                specs[v.specId] = spec;
            }
        }
        return map(specs, (spec) => {
            spec.values = map(spec.values, (value) => {
                return value;
            });
            spec.selectedValues = map(spec.values, (value) => {
                return {id: value.id, value: value.value};
            });
            return spec;
        });
    }
};

export {SKUTool};