import { Request } from '@zoranwong/acc-engine.js';
export default class RegisterRequst extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        customer_id: null,
        name: null,
        avatar: null,
        mobile: null,
        captcha: null,
        business_photos: null,
        id_card_photos: null
    };
    _method = 'POST';
    _uri = '/member/supplier/supplier-add';
    constructor(params) {
        super(params);
        this._data['customer_id'] = params['customer_id']
        this._data['name'] = params['name']
        this._data['avatar'] = params['avatar']
        this._data['mobile'] = params['mobile']
        this._data['captcha'] = params['captcha']
        this._data['business_photos'] = params['business_photos']
        this._data['id_card_photos'] = params['id_card_photos']
    }
    rules() {
        return {

        };
    }

    messages() {
        return {

        };
    }
}