import { Request } from '@zoranwong/acc-engine.js';
export default class GroupDraftListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {

    };
    _method = 'GET';
    _uri = '/goods/group-buy/group-template/group-template-list2';
    constructor(params) {
        super(params);
        this._data = {
            ...params
        }

    }
    rules() {

    }

    messages() {
    }
}
