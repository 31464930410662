import { withRouter as router } from "react-router-dom";
import Base from "../../components/base";
import { NavLink } from 'react-router-dom';
import React from "react";
//import reduxTest from '../../reduxTest';
//import { Provider } from 'react-redux';
//import store from '../../store.js';
import { addToCart, updateCart, deleteFromCart } from '../../actions/cart-actions';
import { addToPro } from '../../actions/products-actions';

import calendar from "../../components/calendar";

import Notice from './js/notice'
import './css/set.sass';

class Set extends Base {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        calendar.data().write('2021-06-14')
        //console.log(calendar.data())
    }

    render() {
        //console.log(this.props);
        return (
            <div className='system'>
                <div className='top_tips'>
                    <p onClick={() => { }}>说明：1.此消息会发送到客户微信的服务通知中</p>
                    <p style={{ paddingLeft: '3em' }}>2.用户可自主订阅所需消息，每条消息可单独订阅或退订，如果用户没有订阅则后台开关开启，也无法接受该消息，该次订阅为一次性订阅模板消息，</p>
                    <p style={{ paddingLeft: '3em' }}>一次触发最多可订阅3条消息</p>
                </div>

                <Notice></Notice>

            </div>
        );
    }
}

export default router(Set);