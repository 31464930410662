import { Request } from '@zoranwong/acc-engine.js';
export default class GroupListCtrlRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null,
        is_agree: null,
        remark: null,
        id: null,
    };
    _method = 'POST';
    _uri = '/goods/group-buy/group-template/audit-alpha-group/update-alpha-group';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['is_agree'] = params['is_agree'];
        this._data['id'] = params['id'];
        if(params['remark']){
            this._data['remark'] = params['remark'];
        }
    }
    rules() {
        
    }

    messages() {
    }
}
