import Layout from '../components/layout';
import StandardDoods from '../pages/goods/standardGoods/standardGoods'
import SpeciflcationMangagemen from '../pages/goods/specificationMangagemen/specificationMangagemen'
import SortManagemen from '../pages/goods/sortManagemen/sortManagemen'
import NewGoods from '../pages/goods/standardGoods/newGoods/newGoods'
import EditGoods from '../pages/goods/standardGoods/newGoods/editGoods'
import TProducts from '../pages/goods/templateProducts/templateProducts'
import goodsIcon from '../assets/goodsIcon.png'
import TemplateIcon from '../assets/TemplateIcon.png'
import specsIcon from '../assets/specsIcon.png'
const sider = [
    {
        name: '标准商品',
        path: '/goods/standardgoods',
        icon: goodsIcon
    },
    {
        name: '分类管理',
        path: '/goods/sortmanagemen',
        icon: TemplateIcon
    },
    {
        name: '规格管理',
        path: '/goods/speciflcationmangagemen',
        icon: specsIcon
    },
    // {
    //     name: '模板商品',
    //     path: '/goods/templateProducts'
    // }
];
const goods = {
    path: "/goods",
    component: Layout,
    sider: sider,
    routes: [
        {
            path: "/goods",
            name: 'goodsIndex',
            exact: true,
            component: StandardDoods
        },
        {
            path: "/goods/standardgoods",
            name: 'standardDoods',
            exact: true,
            component: StandardDoods
        },
        {
            path: "/goods/sortmanagemen",
            name: 'sortManagemen',
            exact: true,
            component: SortManagemen
        },
        {
            path: "/goods/speciflcationmangagemen",
            name: 'speciflcationMangagemen',
            exact: true,
            component: SpeciflcationMangagemen
        },
        {
            path: "/goods/newgoods",
            name: 'newGoods',
            exact: true,
            component: NewGoods
        },
         {
            path: "/goods/editgoods",
            name: 'editGoods',
            exact: true,
            component: EditGoods
        },
        {
            path: "/goods/templateProducts",
            name: 'templateProducts',
            exact: true,
            component: TProducts
        }
    ]
};

export default goods;