import {matchRoutes} from 'react-router-config';
// import {useHistory} from 'react-router-dom';

export default class Router {
    routes = null;
    history = null;
    constructor (routes) {
        this.routes = routes;
    }

    currentRoute () {
        // let history = useHistory();
        let branch = matchRoutes(this.routes, this.history);
        //console.log(branch,'555')
    }
}