import React from 'react'
import {
    Row,
    Col,
    Input,
    Button,
    Table,
    Popconfirm,
    Modal,
    message,
    Cascader,
    Upload,
    pagination,
    Carousel
} from 'antd';
import Progress from '../components/progress'
import BeginStep from "../components/beginStep";
import MainStay from "../components/mainStay";
import NextStep from "../components/nextStep";
import Material from "../components/material";
import Enterprise from "../components/enterprise";
import Examine from "../components/examine";
import ExamineSuccess from '../components/examine_success';
import Sign from '../components/sign';
import ComplateView from '../components/complate';

import '../css/merchant.sass'

export default class Apply extends React.Component {
    constructor(props) {
        //console.log(props,)
        super(props)
        this.state = {
            step: props.step,
            complate: props.complate,
            state: props.state,
            canNext: {
                state: true,
                msg: ''
            },
            identity: 'personal',//身份
            prepare: false,//准备去下一步
            result: false,
            res: props.res
        }
    }

    componentDidMount() {
        //初始位置
        let { step } = this.state
        this.handleStep(step)
        //console.log('----------------',)
    }

    componentWillReceiveProps(props) {
        //console.log(props,)
        this.setState({
            step: props.step,
            complate: props.complate,
            state: props.state,
            res: props.res
        })

        this.handleStep(props.step)
    }

    //处理步骤
    handleStep = (step) => {
        this.setState({ step });
        //this.refs.Carousel.goTo(step);//去某一张
    }

    goOrback = (e) => {
        let { step } = this.state;
        step = step + e;
        this.setState({ step });
        //this.refs.Carousel.goTo(step);//去某一张
    }

    render() {

        const contentStyle = {
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center'
        };

        function onChange(e) {
            //console.log(e,)

        }

        let { step, complate, canNext, identity, prepare, state, result, res } = this.state


        return (
            <div className='merchant'>

                {
                    step < 5 && step > 0 ? (
                        <Progress progressParam={{ step, complate }} />
                    ) : null
                }

                <div style={{ padding: '20px 40px' }}>
                    {/* <Carousel ref='Carousel' afterChange={onChange} dots={false} adaptiveHeight={true}>
                        <div>
                            <BeginStep />
                        </div>
                        <div>
                            <MainStay changeIdentity={e => { this.setState({ identity: e }) }} canNext={e => { this.setState({ canNext: e }) }} />
                        </div>
                        <div>
                            <Material goOrback={(e) => { this.goOrback(e) }} />
                        </div>
                        <div>
                            <Examine />
                        </div>
                        <div>
                            <ExamineSuccess state={state} />
                        </div>
                        <div>
                            <Sign />
                        </div>
                        <div>
                            <ComplateView />
                        </div>
                    </Carousel> */}
                    {step === 0 ? (<BeginStep />) : null}
                    {step === 1 ? (<MainStay changeIdentity={e => { this.setState({ identity: e }) }} canNext={e => { this.setState({ canNext: e }) }} />) : null}
                    {step === 2 ? (<Material goOrback={(e) => { this.goOrback(e) }} />) : null}
                    {step === 3 ? (<Examine state={state} />) : null}
                    {step === 4 ? (<ExamineSuccess state={state} />) : null}
                    {step === 5 ? (<Sign />) : null}
                    {step === 6 ? (<ComplateView res={res} changeAgree={(e, state) => { this.setState({ step: e, state }) }} />) : null}
                </div>

                <NextStep
                    canNext={canNext}
                    goOrback={(e) => { this.goOrback(e) }}
                    step={e => { this.handleStep(e) }}
                    progressParam={{ step, complate, state }}
                />

            </div>
        )
    }
}
