import { Request } from '@zoranwong/acc-engine.js';
export default class GrouperUpdateRequest extends Request {//后台 供应商给分组添加团长
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null,
        supplier_group_id: '',//分组id
        name: '',
        remark: ''
    };
    _method = 'POST';
    _uri = '/member/supplier/admin/channel/supplier-group-update';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['name'] = params['name'];
        this._data['supplier_group_id'] = params['supplier_group_id'];
        this._data['remark'] = params['remark'];
    }
    rules() {
    }

    messages() {
    }
}
