import React, { Component } from "react";
import { Table } from 'antd';
import { withRouter as router } from 'react-router-dom'
import '../css/poll.sass'

class Poll extends Component {
    constructor(props) {
        super(props)

        this.state = {
            jumpSrc: props['location']['state'],
            domHeight: 0,
            hide: false
        }
        //console.log(props)
    }

    componentDidMount(props) {
        let dom = document.getElementById('mainIframe');
        this.setState({
            domHeight: dom['offsetParent']['clientHeight']
        })
        //console.log(dom['offsetParent']['clientHeight'], 'ggggggggggg',dom.contentWindow.document)
    }

    printHandle = (jumpSrc) => {
        //console.log(this.refs)
        //window.print() //调用浏览器的打印预览
        window.open(jumpSrc +'&type=pdf')
    }

    render() {
        const { jumpSrc, domHeight, hide } = this.state
        const columns = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '年龄',
                dataIndex: 'age',
                key: 'age',
            },
            {
                title: '住址',
                dataIndex: 'address',
                key: 'address',
            },
        ];

        const dataSource = [
            {
                key: '1',
                name: '胡彦斌',
                age: 32,
                address: '西湖区湖底公园1号',
            },
            {
                key: '2',
                name: '胡彦祖',
                age: 42,
                address: '西湖区湖底公园1号',
            },
        ];


        return (
            // <iframe border={0} id="mainIframe" name="mainIframe" src={jumpSrc} style={{ width: '100%', border: 0, zIndex: '999', minHeight: '100%' }}>

            // </iframe>
            <div className='allprint'>
                <div className='poll flex'>
                    <div className='left' ref='printArea'>
                        <div className='content' id='content' style={{height: domHeight}}>
                            {/* <div className='flex tableHeader'>
                                <p>截单时间：2021-3-13</p>
                                <p>
                                    导出时间：2021-3-13 12:56:20
                                </p>
                            </div>

                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                            >

                            </Table>

                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                            >

                            </Table> */}

                            <iframe border={0} id="mainIframe" name="mainIframe" src={jumpSrc} style={{ width: '100%', border: 0, zIndex: '999', minHeight: '100%' }}>

                            </iframe>

                        </div>
                    </div>
                    <div className='center'></div>
                    <div className='right print-hide'>
                        <p onClick={() => { this.printHandle(jumpSrc) }} className='printBtn'>打印</p>
                    </div>
                </div>

            </div>
        )
    }
}

export default router(Poll)