import { Request } from '@zoranwong/acc-engine.js';
export default class SignRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        user_id: '',
        materials: {}
    };
    _method = 'GET';
    _uri = '/payment-service/incoming-api/get-sign-url';
    constructor(params) {
        super(params);
        this._data['user_id'] = params.user_id;
    }
    rules() {

    }

    messages() {

    }
}
