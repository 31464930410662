import React from "react";
import {
    Button,
    Image
} from 'antd';
import '../css/complateView.sass'
import store from '../../../store';
export default class ComplateView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            res: props.res,
            merchantDataImg: ''
        }

        //console.log(props, 'ComplateView')
    }

    componentDidMount() {
        // let state = store.getState();
        // console.log(state, 'mmmmmmmmmmmm')
        this.everInfo();
    }

    changeAgree() {
        this.props.changeAgree(0, 0)
    }

    everInfo = async () => {
        let merchantData = await window.localStorage.getItem('merchantData');
        this.setState({
            merchantDataImg: merchantData
        })
    }

    render() {
        let { res, merchantDataImg } = this.state
        return (
            <div className='complateView'>
                {
                    res && res.merchant_shortname ? (
                        <h2 className='welcome'>
                            您好，{res.merchant_shortname}
                        </h2>
                    ) : null
                }
                <h2 className='welcome'>
                    您的商户已完成签约！
                </h2>
                {
                    res && res.merchant_type ? (
                        <p className='type'>商户类型：{res.merchant_type} </p>
                    ) : null
                }

                {
                    merchantDataImg ? (
                        <div style={{ position: 'relative' }}>
                            <p className='everPost themeColor' style={{ padding: '10px 0' }}>您提交的商户资料，仅保存于本地，退出或使用其他浏览器则消失，点击查看图片后，右击可保存</p>
                            <Image style={{ width: 200 }} src={merchantDataImg} />
                        </div>
                    ) : null
                }

                {/* <Button onClick={() => { this.changeAgree() }}>修改签约</Button> */}
            </div>
        )
    }
}