import React from 'react'
import { Table, Pagination } from 'antd';
import qs from '../../../../utils/qs.js';

import AddNewWay from './AddNewWay.js';
import EditorMap from './EditorMap.js';

import '../../css/mapBox.sass';
import Delpng from '../../../../assets/notice/del.png'
import EyeHidepng from '../../../../assets/notice/eye-hide.png'
import EyeShowpng from '../../../../assets/notice/eye-show.png'
import EditorPng from '../../../../assets/notice/editor.png'
import CloseListpng from '../../../../assets/closeList.png'
import OpenListpng from '../../../../assets/openList.png'


//left
const left_data = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
        color: '#E59518',
        eye: false,
        point: [
            { lng: 117.253042, lat: 31.85119 },
            // { lng: 117.263042, lat: 31.86119 },
            // { lng: 117.264042, lat: 31.87119 }
        ],
        imgUrl: 'https://img0.baidu.com/it/u=278704511,764019469&fm=26&fmt=auto&gp=0.jpg'
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
        color: '#3EE2BB',
        eye: false,
        point: [
            { lng: 117.265042, lat: 31.86119 },
            // { lng: 117.269042, lat: 31.87119 }
        ],
        imgUrl: 'https://img2.baidu.com/it/u=41560978,3926713348&fm=26&fmt=auto&gp=0.jpg'
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
        color: '#FFE167',
        eye: false,
        imgUrl: 'https://img1.baidu.com/it/u=19682248,599767905&fm=26&fmt=auto&gp=0.jpg'
    },
    {
        key: '4',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
        color: '#FF6A6A',
        eye: false,
    },
    {
        key: '5',
        name: 'Joe Black',
        age: 17755111710,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
        color: '#3E9BE2',
        eye: false,
    }
];

export default class RouteList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultSelectedRowKeys: [],
            leftPage: 1,
            leftTotal: 30,
            addnewwayShow: false,
            randomColor: '',
            editorMapShow: false,
            closeList: false,
            left_data,
            delCommand: {
                show: false,
                way: 0
            },
            unPlan: false,
            routeInfo: {}
        }

    }

    onSelectChange = (e) => {

        //console.log(e,)

    }

    leftPageChange = (e) => {
        this.setState({
            leftPage: e
        })
    }

    seePoint = (data) => {//切换查看

        let { left_data } = this.state;
        this.props.clearOverlays(true)

        left_data.find((param) => {

            if (param.key == data.key) {
                param.eye = !param.eye
            } else {
                //param.eye = false
            }
        })

        // 查看*****************************
        this.props.MapData(left_data)

        this.setState({
            left_data
        })

    }

    delRoute = (data) => {//删除路线

        let { delCommand, left_data } = this.state;

        delCommand = {
            show: true,
            way: data.key
        }

        this.setState({
            delCommand
        })

    }


    hideDelCommand = () => {

        let { delCommand, left_data, unPlan } = this.state;

        delCommand['show'] = false;
        this.setState({
            delCommand,
            unPlan: false
        })

    }

    delRouteCommand = (id) => {//确定删除
        let { delCommand, left_data } = this.state;

        left_data = left_data.filter(item => item['key'] !== id)

        this.setState({
            left_data
        })
        this.hideDelCommand()
    }

    editorRoute = (data) => {//编辑路线

        this.setState({
            routeInfo: data,
            addnewwayShow: true,
            randomColor: null
        })

    }


    render() {

        const that = this

        let { defaultSelectedRowKeys,
            leftPage, leftTotal,
            addnewwayShow, randomColor,
            editorMapShow, closeList,
            left_data, delCommand, unPlan, routeInfo } = this.state;

        const left_columns = [
            {
                title: '线路编号',
                width: 80,
                render(record, index) {
                    //console.log(record, index)

                    return (
                        <div className='identifier' style={{ background: record.color }}>
                            {record.key}
                        </div>
                    )

                },
            },
            {
                title: '线路名称',
                width: 120,
                dataIndex: 'age',
                key: 'age',
            },
            {
                title: '配送人电话',
                width: 100,
                dataIndex: 'age',
                key: 'age',
            },
            {
                title: '配送人姓名',
                width: 100,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '线路操作',
                render: (record) => {
                    return (
                        <ul className='flex contrlRules'>
                            <li onClick={() => { this.delRoute(record) }}><img src={Delpng} alt='' /></li>
                            <li onClick={() => { this.seePoint(record) }}>
                                {record.eye ? (
                                    <img src={EyeShowpng} alt='' />
                                ) : (
                                    <img src={EyeHidepng} alt='' />
                                )}
                            </li>
                            <li onClick={() => { this.editorRoute(record) }}><img src={EditorPng} alt='' /></li>
                            <li>详情</li>
                        </ul>
                    )
                }
            }
        ]

        //right
        const data_right = [
            {
                key: '1',
                name: 'John Brown',
                age: 32,
                address: 'New York No. 1 Lake Park',
                tags: ['nice', 'developer'],
                color: '#E59518'
            },
            {
                key: '2',
                name: 'Jim Green',
                age: 42,
                address: 'London No. 1 Lake Park',
                tags: ['loser'],
                color: '#3EE2BB'
            }
        ]

        const columns_right = [
            {
                title: '线路编号',
                width: 80,
                render(record, index) {
                    //console.log(record, index)

                    return (
                        <div className='identifier' style={{ background: record.color }}>
                            {record.key}
                        </div>
                    )

                },
            },
            {
                title: '团长名称',
                width: 80,
                dataIndex: 'age',
                key: 'age',
            },
            {
                title: '地址',
                width: 120,
                dataIndex: 'age',
                key: 'age',
            },
            {
                title: '配送单数',
                width: 80,
                dataIndex: 'age',
                key: 'age',
            },
            {
                title: '金额',
                width: 80,
                dataIndex: 'age',
                key: 'age',
            }
        ]

        let rowSelection = {
            //selectedRowKeys: defaultSelectedRowKeys,
            defaultSelectedRowKeys,
            onChange: this.onSelectChange
        };

        return (
            <div className={closeList ? 'RouteList bottom' : 'RouteList'}>

                {
                    addnewwayShow ? (
                        <AddNewWay
                            routeInfo={routeInfo}
                            randomColor={randomColor}
                            hideAdd={e => {
                                this.setState({
                                    addnewwayShow: !e.hideAdd,
                                    editorMapShow: e.showEditorMap
                                })
                            }} />
                    ) : null
                }

                {
                    editorMapShow ? (
                        <EditorMap hideEditor={e => { this.setState({ editorMapShow: !e }) }} />
                    ) : null
                }

                <div className='listBox flex'>
                    <div className='listLeft'>
                        <div className='contrl_top flex'>
                            <p className='title'>线路列表</p>
                            <ul className='flex contrl_btn'>
                                <li onClick={() => {
                                    this.setState({
                                        randomColor: qs.randomColor(),
                                        addnewwayShow: true,
                                        routeInfo: {}
                                    })
                                }}>新增</li>
                                <li>打印</li>
                                <li>导出</li>
                            </ul>
                        </div>
                        <div className='table_data'>
                            <Table
                                rowSelection={rowSelection}
                                columns={left_columns}
                                dataSource={left_data}
                                scroll={{
                                    y: 250
                                }}
                                pagination={false}
                            />
                        </div>
                        {/* <div className='page_change'>
                            <Pagination
                                onChange={(e) => { this.leftPageChange(e) }}
                                pageSize={20}
                                hideOnSinglePage={true}
                                showSizeChanger={false}
                                current={leftPage}
                                total={leftTotal} />
                        </div> */}
                    </div>

                    <div className='listRight'>
                        <div className='contrl_top flex'>
                            <div className='flex' style={{ justifyContent: 'space-between', width: 400 }}>
                                <p className='title'>线路：全部线路</p>
                                <p className='title'>地址：xx个</p>
                                <p className='title'>总件数：213件</p>
                            </div>
                            <div className='flex hideOrshow' onClick={() => { this.setState({ closeList: !closeList }) }}>
                                {
                                    closeList ? (
                                        <>
                                            <img src={OpenListpng} width={16} style={{ marginRight: 5 }} alt='' />
                                            展开
                                        </>
                                    ) : (
                                        <>
                                            <img src={CloseListpng} width={16} style={{ marginRight: 5 }} alt='' />
                                            收起
                                        </>
                                    )
                                }
                            </div>
                        </div>

                        <div className='table_data'>
                            <Table
                                columns={columns_right}
                                dataSource={data_right}
                                scroll={{
                                    y: 250
                                }}
                                pagination={false}
                            />
                        </div>
                        {/* <div className='page_change'>
                            <Pagination
                                onChange={(e) => { this.leftPageChange(e) }}
                                pageSize={20}
                                hideOnSinglePage={true}
                                showSizeChanger={false}
                                current={leftPage}
                                total={leftTotal} />
                        </div> */}
                    </div>

                </div>


                {/* 删除提示 */}
                {
                    delCommand['show'] ? (
                        <div className='mask'>
                            <div className='hide' onClick={() => { this.hideDelCommand() }}></div>
                            <div className='content'>
                                <p className='title'>提示</p>
                                <div className='ctx'>
                                    <p>确认删除 {delCommand['way']}号线路 吗？</p>
                                    <p>删除后线路中的自提点将变为未规划</p>
                                </div>

                                <ul className='flex contrlLine'>
                                    <li onClick={() => {
                                        this.hideDelCommand()

                                    }}>取消</li>
                                    <li onClick={() => { this.delRouteCommand(delCommand['way']) }}>确定</li>
                                </ul>
                            </div>
                        </div>
                    ) : null
                }

                {/* 未规划 */}
                {
                    unPlan ? (
                        <div className='mask'>
                            <div className='hide' onClick={() => { this.hideDelCommand() }}></div>
                            <div className='content'>
                                <p className='title'>提示</p>

                                <div className='ctx'>
                                    <p>配送中含有尚未规划线路的地址
                                        仍要打印吗？</p>
                                </div>

                                <ul className='flex contrlLine'>
                                    <li onClick={() => {
                                        this.hideDelCommand()

                                    }}>取消</li>
                                    <li onClick={() => { this.hideDelCommand() }}>确定</li>
                                </ul>
                            </div>
                        </div>
                    ) : null
                }

            </div>
        )
    }
}