import {Input} from "@alifd/next";
import React from "react";

const SkuTableItem = ({
                          entity, index, change = () => {
    }
                      }) => {
    let values = entity['spec_values'] || [];
    let rowSpan = entity.rowSpan;

    function changeEntity (value, key) {
        entity[key] = value;
        change(entity, index);
    }

    return (
        <tr key={'sku_table_tr_' + index}>
            {values.map((v, vIdx) => {
                return (<SpecValuesRender value={v} rowSpan={rowSpan} valueIndex={vIdx}
                                          rowIndex={index} key = {'sku_td_'+index+'_'+vIdx}/>)
            })}

            <td>
                <Input placeholder="请输入商品条形码" value={entity['entity_code']} onChange={(value) => {
                    changeEntity(value, 'entity_code');
                }}/>
            </td>
            <td>
                <Input placeholder="请输入商品售价" value={entity['sell_price']} onChange={(value) => {
                    changeEntity(value, 'sell_price');
                }}/>
            </td>
            <td>
                <Input placeholder="请输入商品成本价" value={entity['market_price']} onChange={(value) => {
                    changeEntity(value, 'market_price');
                }}/>
            </td>
            <td>
                <Input placeholder="请输入最大可售数量" value={entity['stock_num']} onChange={(value) => {
                    changeEntity(value, 'stock_num');
                }}/>
            </td>
        </tr>
    );
}

function SpecValuesRender ({value, valueIndex, rowIndex}) {
    if(!value) return
    let rowSpan = value.rowSpan;
    if (rowIndex % rowSpan === 0)
        return (<td rowSpan={rowSpan}>{value['specValue']['value']}</td>);
    else
        return null;
}

export default SkuTableItem;