import React, { Component } from "react";
import qs from '../../../../utils/qs'
import { Input, Calendar, message } from 'antd'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import locale from 'antd/es/date-picker/locale/zh_CN';
import GroupListRequest from './api/groupListRequest'
import './css/index.sass'
//import 'moment/locale/zh-cn';
import emitter from "../../../../ev"
import list from './api/listMock'

//import store from '../../../../store';
import { addToBorn } from '../../../../actions/born-actions';


export default class SearchList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            demoList: [],
            nickname: [],
            writeName: '',
            page: 1,
            allpage: 5,
            canload: true,
            templateId: props.templateId || '',
            searchParam: {},
            showLastPage: true,//是否展示最后一页
            batchDates: props['sendList'],//设置可以显示的日期
            dateFlag: new Date(props['sendList'][0]['arrival_date']).getDate(),
            searchDay: props['sendList'][0]['arrival_date']//初始day
        }
        //console.log(props,'batchDatesbatchDatesbatchDates',this.state)
    }

    componentDidMount = async () => {
        this.watchBottom()//更新下一页
        const { searchDay, searchParam, batchDates } = this.state
        searchParam['arrived_date'] = searchDay;
        this.setState({
            searchParam
        })
        await this.standarlists(searchParam)
        emitter.emit('batch_id', batchDates[0]['batch_id'])
    }

    componentWillReceiveProps() {
        //this.giveList([])
        //console.log('pp')
    }

    componentWillUnmount() {
        emitter.removeAllListeners();
    }


    shouldComponentUpdate() {
        return true
    }

    searchList = () => {//搜索条件
        let sql = {
            //search: `goods_name::${this.state.searchInput};goods_type::${this.state.goods_type};sell_price::${this.state.result_price}`
        }

        this.standarlists(sql)
    }

    watchBottom = () => {//更新下一页

        emitter.addListener('isBottom', async (data) => {
            let { canload, showLastPage } = this.state
            //console.log('bbbbbbbbbbbbbbkkkkkkkkkkkkkkkkk')
            if (!data || !canload) return
            let { page, allpage } = this.state
            if (page < allpage) {
                page += 1
                await this.setState({
                    page
                })
                //console.log('bbbbbbbbbbbbbbkkkkkkkkkkkkkkkkk')
                this.searchList(this.state.searchParam)
            } else {
                if (showLastPage) {
                    message.error('已经是最后一页了')
                    this.setState({
                        showLastPage: false
                    })
                }
            }
        });

        emitter.addListener('alpha_groups', async (data) => {
            if (data) {
                this.searchList(this.state.searchParam)
            }
        })
    }

    standarlists = async (param) => {
        let { templateId, page, searchDay } = this.state;
        if (!templateId) return
        let params = {
            search: param['search'],
            arrived_date: param['arrived_date'] || searchDay,
            page: page,
            template_id: templateId
        }
        emitter.emit('isBottom', false)
        let { canload } = this.state;
        if (!canload) {
            return message.error('请稍后')
        }
        this.setState({
            canload: false
        })
        const hide = message.loading('加载数据中')
        let res = await GroupListRequest.send(params)
        if (res.code == 200) {
            message.success(res.message)
            if (!res.data.data || !res.data.data.length) {
                this.giveList(list)
            } else {
                this.setState({
                    allpage: res.data.last_page
                })
                this.giveList(res.data.data)
            }
        } else {
            //this.giveList(list)
            message.error(res.message)
        }
        this.setState({
            canload: true
        })
        hide();
    }

    giveList = (data) => {//更新数据

        // let unsubscribe = store.subscribe(() => {
        //     console.log(store.getState())
        //     let { bornListReducer } = store.getState();
        //     this.props.sendDomelist([...bornListReducer['cart']])
        // }
        // );

        // data.map(v => {
        //     store.dispatch(addToBorn(v));
        // })
        // this.props.canUpdate(true);

        // unsubscribe();
        emitter.emit('changeSiblingsData', data);
    }

    searchAll = () => {//搜索全部

        //清空 search  
        let { searchParam } = this.state

        searchParam['search'] = '';
        searchParam['arrived_date'] = '';
        this.setState({
            searchParam,
            writeName: ''
        })
        this.standarlists(searchParam)
    }

    sendList(props) {
        const { demoList } = this.state
        this.props.sendList(demoList)
    }

    writeNickname = (e) => {//输入昵称，手机号
        let testNmae = ['吴孟达', '周星驰', '刘德华', '黄渤', '王宝强', '周杰伦']
        let res = [];
        let { searchParam } = this.state

        for (let i in testNmae) {
            if (testNmae[i].indexOf(e.target.value) > -1 && e.target.value) {
                res.push(testNmae[i])
            }
        }
        searchParam['search'] = e.target.value;
        this.setState({
            writeName: e.target.value,
            nickName: res,
            searchParam
        })
    }

    searchNicknameOrTel = () => {
        let { searchParam } = this.state
        this.standarlists(searchParam)
    }

    setNickName = (v) => {
        let { searchParam } = this.state
        searchParam['search'] = v
        this.setState({
            writeName: v,
            nickName: [],
            searchParam
        })
    }

    render() {
        const that = this;

        const { searchParam, nickName, writeName, batchDates, dateFlag } = this.state;

        function onPanelChange(value, mode) {
            //console.log(value, mode);
        }

        const dateFullCellRender = (date) => {
            let dateFormat = date.format('YYYY-MM-DD');
            let day = date.date();
            if (batchDates.find(d => d['arrival_date'] === dateFormat)) {
                return (<div className={dateFlag == day ? 'active validDate ctrlbtn' : 'validDate ctrlbtn'} onClick={async () => {
                    //await this.giveList([])
                    //clear data
                    emitter.emit('changeSiblingsData', []);
                    searchParam['arrived_date'] = dateFormat
                    await that.setState({
                        searchParam,
                        dateFlag: day,
                        searchDay: searchParam['arrived_date']
                    })
                    //console.log(searchParam,'searchParamsearchParamsearchParam')
                    await that.standarlists(searchParam)
                    batchDates.find(v => {
                        if (v['arrival_date'] == dateFormat) {
                            emitter.emit('batch_id', v['batch_id'])
                        }
                    })
                }}>{day}</div>);
            } else {
                return (<div className='invalidDate ctrlbtn' disabled={true}>{day}</div>);
            }
        }

        function onSelect(e) {
            // console.log(e,'eeeeeeeeeeeeeeeeeeee')
            // let allDate = new Date(e)
            // let year = allDate.getFullYear();
            // let month = qs.transTen(allDate.getMonth() + 1);
            // let day = qs.transTen(allDate.getDate());
            // let lastDate = year+'-'+month+'-'+day;

            // searchParam['arrived_date'] = e

            // that.setState({
            //     searchParam
            // })

            // that.standarlists(searchParam)
        }

        return (
            <div className='searchList'>
                <div className='top flex'>
                    <label className=''>团长搜索：</label>
                    <div className='search flex' style={{ width: '184px', margin: '0' }}>
                        <Input value={writeName} style={{ width: '260px' }} placeholder='团长昵称手机号' onChange={this.writeNickname}></Input>
                        <div className='search_btn' onClick={() => { this.searchNicknameOrTel() }}>
                            <SearchOutlined style={{ color: '#666666' }} />
                        </div>
                        {nickName && nickName.length ? (
                            <ul className="searchRes">
                                {nickName.map(v => {
                                    return <li key={v} onClick={() => { this.setNickName(v) }}>{v}</li>
                                })}
                            </ul>
                        ) : null}
                    </div>
                    <p onClick={() => { this.searchAll() }} className='searchAll'>全部</p>
                </div>
                <div>
                    <p style={{ textAlign: 'center', fontSize: '12px', color: '#666666', padding: '0 0 10px 0' }}>选择到货时间</p>
                    {dateFlag ? (
                        <Calendar dateFullCellRender={dateFullCellRender} locale={locale} fullscreen={false} onPanelChange={onPanelChange} />
                    ) : null}
                </div>
            </div>

        )
    }
}