import { Request } from '@zoranwong/acc-engine.js'
//let merchant_id = window.localStorage.getItem('merchant_id')

export default class ListRequest extends Request {
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    page: 1,
    page_size: 20,
    with_batch: false,
    searchFields: null
  };
  _method = 'GET';
  _uri = '/goods/group-buy/group-template/group-template-list';
  constructor(params) {
    super(params);
    if(params['search']){
      this._data['search'] = params['search'] || '';//params.merchant_id;
    }
    
    this._data['page'] = params['page'] || 1;
    this._data['page_size'] = params['page_size'] || 20;
    this._data['with_batch'] = params['with_batch'] || false;
    this._data['searchFields'] = params['searchFields'] || '';
  }
  // get middleware(){
  //   return [...super.middleware, PasswordHashMiddleware];
  // }
  rules() {
    return {
    };
  }

  messages() {
    return {
    };
  }
}