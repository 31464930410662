import {Request} from '@zoranwong/acc-engine.js';
//let merchant_id = window.localStorage.getItem('merchant_id')

export default class DeleteClassificationRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
    cate_ids: [],
     //merchant_id: null
   };
   _method = 'DELETE';
   _uri = '/goods/shop-goods/category/cate-destroy';
  //  _responseClass = AddClassificationRequest;
  constructor(params) {
    super(params);
    //this._data['merchant_id'] = merchant_id;//params.merchant_id;
    this._data['cate_ids'] = params.cate_ids;
  }
   // get middleware(){
   //   return [...super.middleware, PasswordHashMiddleware];
   // }
   rules(){
     return {
        //  cate_name: 'size:20',
     };
   }

   messages(){
     return  {
        //  'cate_name.size': '分类名称最多为20字'    
    };
   }
}
