import { Request } from '@zoranwong/acc-engine.js';
export default class UserInfoRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: ''
    };
    _method = 'GET';
    _uri = '/member/supplier/supplier-info';
    constructor(params) {
        super(params);
        this._data['token'] = params.token;
    }
    rules() {

    }

    messages() {

    }
}
