import { Request } from '@zoranwong/acc-engine.js';
export default class DelCommanderRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null,
        ids: []
    };
    _method = 'DELETE';
    _uri = '/member/supplier/admin/channel/del-grouper-to-supplier-group';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['ids'] = params['ids'];
    }
    rules() {
    }

    messages() {
    }
}