import React from 'react'
import { withRouter as router } from "react-router-dom";
import Base from '../../../components/base'
import AddClassificationRequest from '../api/Classification/addClassificationRequest'
import EditClassificationRequest from '../api/Classification/editClassificationRequest'
import { Modal, Input, message, Button } from 'antd'

class TypeManagemen extends Base {
  constructor(props) {
    super(props)
    this.state = {
      typeName: "",
      type: 'create',
      //merchantId: 'RXZgrV',
      cateId: ''
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.record && nextProps.record.id) {
      this.setState({ type: 'edit', typeName: nextProps.record.cate_name, cateId: nextProps.record.id })
    } else {
      this.setState({ type: 'create', typeName: '' })
    }
  }
  handleCancel = () => {
    this.props.onCancel()
  };
  handleSubmit = async () => {
    const hide = message.loading('加载数据中', 1)
    let api = this.state.type === 'create' ? AddClassificationRequest : EditClassificationRequest;
    //let id = this.state.type === 'create' ? this.state.merchantId : this.props.record.merchant_id;
    let creatparam = {
      cate_name: this.state.typeName,
      //merchant_id: id
    }
    let editparam = {
      cate_name: this.state.typeName,
      //merchant_id: id,
      cate_id: this.state.cateId
    }
    let params = this.state.type === 'create' ? creatparam : editparam;
    if (!this.state.typeName) {
      message.error('请填写分组名称');
      return;
    }
    let res = await api.send(params);

    if (res.status) {
      message.success(res.message)
      this.props.reload(true)
    } else {
      message.error(res.message)
    }
  };

  render() {
    return (
      <div>
        <Modal
          centered
          title={this.state.type === 'create' ? '新增分类' : '编辑分类'}
          width={520}
          style={{ textAlign: 'center' }}
          visible={this.props.visible}
          onCancel={this.handleCancel}
          maskClosable={false}
          footer={
            <div style={{ textAlign: 'center', margin: '10px 0' }}>
              <Button
                style={{ marginRight: '40px' }}
                size="small"
                className='button-bac'
                onClick={this.handleCancel}
                type="default">取消</Button>
              <Button
                size="small"
                className='button-bac'
                onClick={this.handleSubmit}
                type="primary">保存</Button>
            </div>
          }
        >
          <ul style={{ margin: '30px 0' }}>
            <li>
              <span>分类名称：</span>
              <Input style={{ width: '350px', borderRadius: '4px' }}
                maxLength={20}
                value={this.state.typeName}
                placeholder="请输入分类名称，最多20字"
                onChange={(e) => { this.setState({ typeName: e.target.value }) }} />
            </li>
          </ul>

        </Modal>
      </div>
    )
  }
}
export default router(TypeManagemen)
