import React, { Component } from 'react';
import { message, Upload } from 'antd'
import config from '../../configs/upload'
import textUrl from '../../assets/text.png'

export default class TextBar extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    upText=()=>{//添加输入框
        this.props.addComponent('')
    }

    render() {
        return (
            <span className='editor_bar' onClick={this.upText}>
                <img width={20} src={textUrl} alt='' />
                <p style={{fontSize:'12px',color:'#000'}}>{this.props.title}</p>
            </span>
        )
    }
}