import Login from '../pages/login';
import Register from '../pages/login/register';
import Goods from "./Goods";
import Orders from "./Orders";
import Home from "./Home";
import Commander from "./Commander";
import Datum from "./Datum";
import Groups from "./Groups";
import Logistics from "./Logistics";
import Finance from "./Finance";
import Set from './Set';
import Page404 from '../pages/error/404';
import Poll from '../pages/orders/print/poll';
import Poll2 from '../pages/orders/print/poll2';
import Receipt from '../pages/orders/print/receipt';
import FunTest from './FunTest';

const routes = [
    {
        path: "/",
        exact: true,
        name: 'login',
        component: Login
    },
    {
        path: "/register",
        exact: true,
        name: 'register',
        component: Register
    },
    Goods,
    Orders,
    Home,
    Commander,
    Datum,
    Groups,
    Logistics,
    Finance,
    Set,
    FunTest,
    {
        path: '/poll',
        exact: true,
        name: 'poll',
        component: Poll
    },
    {
        path: '/receipt',
        exact: true,
        name: 'receipt',
        component: Receipt
    },
    {
        path: '/poll2',
        exact: true,
        name: 'poll2',
        component: Poll2
    },
    {
        path: "*",
        exact: true,
        name: '404',
        component: Page404
    }
];
export default routes;