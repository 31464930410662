import { Request } from '@zoranwong/acc-engine.js';
export default class SendGoodsRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        order_id: '',
        shipping_no:'',
        shipping_time: '',
        shipping_company: ''
    };
    _method = 'POST';
    _uri = '/order/group-order/merchant-order/shipping-order';
    constructor(params) {
        super(params);
        this._data['order_id'] = params['order_id'];
        this._data['shipping_no'] = params['shipping_no'];
        this._data['shipping_time'] = params['shipping_time'];
        this._data['shipping_company'] = params['shipping_company'];
    }
    rules() {

    }

    messages() {

    }
}
