import React from 'react'
import { withRouter as router } from 'react-router-dom'
import { Popconfirm, message, InputNumber, Tooltip, Table } from 'antd'
import Base from '../../../../components/base'
import GroupTemplateInfoRequest from '../../api/groupsList/groupTemplateInfoRequest'
import GroupTemplateGoodsListRequest from '../../api/groupsList/groupTemplateGoodsListRequest'
import GroupStockAdjustRequest from '../../api/groupsList/groupStockAdjustRequest';////调整团购模版库存
import '../../css/groupDetails.sass'
import 'moment/locale/zh-cn';
import qs from '../../../../utils/qs'
import BornList from '../../components/bornList';
import SearchList from '../../components/searchList';
import Output from '../components/Output'
import "react-datetime/css/react-datetime.css";
import emitter from "../../../../ev"
import { env } from "../../../../utils/functions";
import { createBrowserHistory } from "history";


const distype = [
    // { val: 'ORDER_ITEM_TOTAL_FULL_CUT', txt: '定向满减' },//
    // { val: 'ORDER_TOTAL_FULL_CUT', txt: '订单总额满减' },// 
    { val: 'PRE_ORDER_TOTAL_FULL_GIFT', txt: '每满XX元赠送' },// 
    { val: 'ORDER_TOTAL_FULL_GIFT', txt: '满赠' }// 
]

class GroupDetail extends Base {

    constructor(props) {
        super(props)
        this.state = {
            searchvalue: '',
            merchantId: '',
            groupId: "",
            data: [
                {
                    key: 1
                }
            ],
            goodslist: [],
            goodsinfo: {},
            goodbatch: [],
            edmo: [],
            delivery_type: '',
            templateId: props.location.state ? props.location.state.data.id : '',
            sendList: [],
            group_id: null,
            batch_id: null,
            canUpdate: false,
            demoList: [],
            promotions: [],//赠品：


        }
    }

    componentDidMount = async () => {
        let props = this.props.location.state
        if (props && props.data && props.data.id) {
            await this.setState({
                group_id: props.data.id
            })
            this.getData()

            emitter.addListener('batch_id', async (batch_id) => {
                //console.log('batch_id',batch_id,'batch_idbatch_idbatch_idbatch_idbatch_idbatch_idbatch_idbatch_idbatch_id')
                await this.setState(
                    {
                        batch_id: batch_id
                    }
                )
                this.getGoodsList(batch_id);
            })

        }
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
        //emitter.removeAllListeners()
    }


    getData = async () => {
        let { group_id } = this.state;
        const hide = message.loading('加载数据中')
        let params = {
            group_id: group_id
        };

        let resdata = await GroupTemplateInfoRequest.send(params);
        let arr2 = []
        if (resdata.data && resdata.data.batch) {
            arr2 = resdata.data.batch

            resdata.data['fontDate'] = qs.fontDay(resdata['data']['created_at'], 1)
            resdata.data['overDate'] = qs.fontDay(resdata['data']['end_datetime'], 2)

            if (!resdata.data.promotions) {
                resdata.data.promotions = []
            }

            this.setState({
                goodsinfo: resdata.data,
                goodbatch: [...arr2],
                edmo: resdata['data']['group_detail'],
                sendList: [...resdata.data.batch],
                promotions: [...resdata.data.promotions]
            })
        }

        hide();

    }


    getGoodsList = async () => {
        let { group_id, batch_id } = this.state
        let params = {
            group_id: group_id,
            group_batch_id: batch_id
        }

        let resgoods = await GroupTemplateGoodsListRequest.send(params)

        if (resgoods.status === false) return
        await this.setState({
            goodslist: []
        })
        const arr1 = (resgoods.data.data).reverse();
        window.localStorage.setItem('goodsNum', arr1.length)
        this.setState({
            goodslist: [...arr1]
        })
    }


    onSearch = (value) => {
        //console.log(value, 'searct')
    }
    selectTime = (data, dataString) => {
    }

    changeNum = async (v, item) => {//调整商品库存
        const { batch_id } = this.state
        const hide = message.loading('调整库存中')
        let param = {
            entity_id: v.id,
            group_id: this.props.location.state.data.id,
            stock_num: v['stock_num'],
            group_batch_id: batch_id
        }

        let res = await GroupStockAdjustRequest.send(param)

        if (res.status) {
            message.success(res.message)
        } else {
            message.error(res.message)
        }

        hide();
    }


    render() {
        const { goodsinfo, goodbatch, templateId, edmo, sendList, group_id, batch_id, canUpdate, demoList, promotions } = this.state
        let info = goodsinfo
        return (
            <div style={({ margin: '20px' })}>
                <ul className='detail'>
                    <li className='detail-flex' style={({ width: '360px', flex: 'unset', marginRight: '15px' })}>
                        <div>
                            <div className='detail-left'>
                                <div className='flex' style={({ justifyContent: 'space-between', marginBottom: '10px', flexWrap: "wrap" })}>
                                    <span title={info.group_name} className='title'>{info.group_name ? info.group_name : '加载中'}</span>
                                    {typeof goodsinfo['fontDate'] !== 'undefined' && goodsinfo['fontDate']['type'] === 'hour' ?
                                        (
                                            <p className='over-active'>{goodsinfo['fontDate']['hour']}小时前发布 {goodsinfo['overDate']['day']}天{goodsinfo['overDate']['hour']}小时{goodsinfo['overDate']['min']}分后结束</p>) : null}

                                    {typeof goodsinfo['fontDate'] !== 'undefined' && goodsinfo['fontDate']['type'] === 'day' ?
                                        (
                                            <p className='over-active'>{goodsinfo['fontDate']['day']}天前发布 {goodsinfo['overDate']['day']}天{goodsinfo['overDate']['hour']}小时{goodsinfo['overDate']['min']}分后结束</p>) : null}
                                </div>
                                <div className='flex'>

                                    <div className={goodsinfo.group_type == 1 ? 'pintuan group_type' : 'gonghuo group_type'}>
                                        {
                                            goodsinfo.group_type == 1 ? '拼团合约' : '供货合约'
                                        }
                                    </div>

                                    <span className='delivery'>{goodsinfo['delivery_type'] === 1 ? '快递发货' : '配送到自提点'}</span>

                                    {!goodsinfo['need_pay'] ? (<span className='delivery'>线下支付</span>) : null}

                                </div>

                                <div className='group_detail'>

                                    {
                                        edmo && edmo.length ? (
                                            <Output edmo={edmo}></Output>
                                        ) : null
                                    }

                                </div>

                                <div className='flex deliveryTime'>
                                    <p style={({ display: 'block', whiteSpace: 'nowrap' })}>配送时间：</p>
                                    <ul className='flex pstime'>
                                        {goodbatch.length ? (
                                            goodbatch.map(v => {
                                                return (
                                                    <li>{v['arrival_date']} {v['arrival_time']}</li>
                                                )
                                            })
                                        ) : null}
                                    </ul>
                                </div>

                                <div className='flex deliveryTime'>
                                    <p className='detail_title'>截单时间：</p>
                                    <p>到货日前{goodsinfo['end_day']}天，{goodsinfo['end_time']}</p>
                                </div>

                                <div className='flex deliveryTime'>
                                    <p className='detail_title'>{goodsinfo.group_type == 1 ? '成团条件：' : '订货条件：'}</p>
                                    <p>
                                        {goodsinfo['group_rule'] ? (<span> {goodsinfo['group_rule']['min_orders'] ? (<span>须满{goodsinfo['group_rule']['min_orders']}笔订单</span>) : null}  {goodsinfo['group_rule']['min_pay_amount'] ? (<span>团购总金额不小于{goodsinfo['group_rule']['min_pay_amount']}元</span>) : null} </span>) : '无'}</p>
                                </div>

                                <div className='flex deliveryTime'>
                                    <p className='detail_title'>分佣比例：</p>
                                    <p>{goodsinfo['commission_rate'] ? goodsinfo['commission_rate'] * 100 + '%' : 0}</p>
                                </div>

                                <div className='flex deliveryTime'>
                                    <p className='detail_title'>可见范围：</p>
                                    <div style={{ flex: 1 }}>
                                        {goodsinfo['visible_channel'] && goodsinfo['visible_channel'].length ? (
                                            <ul className='flex canseearea'>
                                                {
                                                    goodsinfo['visible_channel'].map(v => {
                                                        return (
                                                            <li key={v['id']}>{v['name']}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : (<p>全部可见</p>)}
                                    </div>
                                    {/* <div>
                                        <p>指定渠道可见</p>
                                        <ul className='cansee'>
                                            <li> 轻松快乐组</li>
                                            <li> 轻松快乐组</li>
                                        </ul>
                                    </div> */}
                                </div>

                                <div className='flex deliveryTime'>
                                    <p className='detail_title'>赠品：</p>
                                    {
                                        promotions && promotions.length ? (
                                            promotions.map((data, index) => {
                                                return <div className='table'>
                                                    {
                                                        data && data['rules'].length ? (

                                                            data['rules'].map((v, i) => {
                                                                return (
                                                                    <>
                                                                        <p style={{ margin: '5px 0px 3px' }}>
                                                                            优惠上限：
                                                                            {
                                                                                v['max_gift_num'] ? v['max_gift_num'] : '-'
                                                                            }
                                                                        </p>
                                                                        <table border="1" className='tableBox' style={{ 'borderCollapse': 'collapse' }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>优惠条件 ({data['name']})</th>
                                                                                    <th>赠品</th>
                                                                                    <th>赠品数量</th>

                                                                                </tr>
                                                                                <tr>
                                                                                    <td rowSpan={(v['gifts']).length + 1}>
                                                                                        金额：{v['amount']} 元
                                                                                        {/* <InputNumber disabled value={v['amount']} min={0} style={{ width: 50 }} /> */}
                                                                                    </td>
                                                                                </tr>
                                                                                {
                                                                                    v && v['gifts'].map((j, k) => {
                                                                                        return (
                                                                                            <tr key={j.goods_id + '-' + k}>
                                                                                                <td><Tooltip title={j['show_name'] ? j['show_name'] : j['goods_name']}> {j['show_name'] ? j['show_name'] : j['goods_name']}</Tooltip> </td>
                                                                                                <td><InputNumber disabled min={1} style={{ width: 50 }} value={j['gift_num']} /></td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </>
                                                                )
                                                            })

                                                        ) : null
                                                    }
                                                </div>

                                            })
                                        ) : (
                                            <p>无</p>
                                        )
                                    }
                                </div>

                                <div className='flex deliveryTime'>
                                    <p className='detail_title'>开团须知：</p> <p>{goodsinfo['group_notice'] || '无'}</p>
                                </div>

                                {/* <div className='flex deliveryTime'>
                                    <p>赠品：</p> <p>暂无</p>
                                </div>

                                <div className='flex deliveryTime'>
                                    <p>优惠券：</p> <p>暂无</p>
                                </div> */}
                            </div>

                            <div className='flex' style={({ marginTop: '20px', justifyContent: 'space-between' })}>
                                <div className='sale'>
                                    <h3>总销售额</h3>

                                    <span className='num'>{info['total_sale_amount']}</span>
                                </div>
                                <div className='sale'>
                                    <h3>总参团人数</h3>

                                    <span className='num'>{info['buyer_num']}</span>
                                </div>
                                <div className='sale'>
                                    <h3>总开团团长数</h3>

                                    <span className='num'>{info['alpha_group_count']}</span>
                                </div>
                            </div>

                        </div>
                    </li>
                    <li
                        className='detail-flex'
                        style={({ width: '450px', flex: 'unset', overflow: 'hidden' })}>
                        <BornList demoList={demoList} canUpdate={canUpdate}></BornList>
                    </li>

                    <li className='detail-flex' style={({ width: '350px', flex: 'unset', marginLeft: '15px' })}>
                        {
                            sendList && sendList.length ? (
                                <SearchList
                                    sendDomelist={(e) => { this.setState({ demoList: e }) }}
                                    templateId={templateId}
                                    sendList={sendList}
                                    canUpdate={(e) => { this.setState({ canUpdate: e }); }}></SearchList>
                            ) : null
                        }
                        <div>
                            <p className="shopListTitle">商品列表</p>
                            {
                                !this.state.goodslist ?
                                    <span style={({ fontSize: 26 })}>暂无数据</span> : this.state.goodslist.map(item => {
                                        return item['entities'].map(v => {
                                            return <div className='goodslist' key={v.id}>
                                                <p style={({ flex: '0 0 100px' })}>
                                                    <Tooltip title={item.goods_name}><span className='goodsname'>{(item.goods_name).slice(0, 10)}</span></Tooltip>
                                                    {/* <br /> */}
                                                    {
                                                        v['spec_name_json'] ? (
                                                            <Tooltip title={Object.keys(v['spec_name_json']) + ':' + Object.values(v['spec_name_json'])}>
                                                                <span style={({ fontSize: '12px' })} className='goodsname'>
                                                                    {Object.keys(v['spec_name_json']) + ':' + Object.values(v['spec_name_json'])}
                                                                </span>
                                                            </Tooltip>
                                                        ) : null
                                                    }
                                                </p>
                                                <p><span className='goodsprice'>￥{Number(v.sell_price).toFixed(2)}</span></p>
                                                <p className='flex'>

                                                    <span className='goodsnum'>剩余可售数量  <InputNumber onChange={(e) => {
                                                        v['stock_num'] = e
                                                    }} className='changeNumInput' defaultValue={v.stock_num} /></span>
                                                    <Popconfirm title="确定调整吗？" okText="确定" cancelText="取消" onConfirm={() => {
                                                        this.changeNum(v, item)
                                                    }}>
                                                        <span className='adjust'>调整</span>
                                                    </Popconfirm>
                                                </p>

                                            </div>
                                        })
                                    })
                            }
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}

export default router(GroupDetail)