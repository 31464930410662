import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import { SearchOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import {
    Row,
    Col,
    Input,
    Button,
    Table,
    Popconfirm,
    Modal,
    message,
    Radio
} from 'antd';
import '../css/selfPm.sass'
class SelfLiftingPointManagement extends Base {
    constructor(args) {
        super(args)

        const columns = [
            {
                title: '路线名称',
                dataIndex: 'name'
            },
            {
                title: '包含的自提点',
                dataIndex: 'address',
            },
            {
                title: '配送人员',
                dataIndex: 'send_people',
            },
            {
                title: '联系电话',
                dataIndex: 'tel',
            },
            {
                title: '备注',
                dataIndex: 'remark',
            },
            {
                title: '操作',
                render: (dataSource, i) =>

                    <div className='flex contrlTxt'>
                        <p onClick={() => this.editor(i)}>编辑</p>

                        <Popconfirm
                            title="您确定要删除吗？"
                            onConfirm={() => this.del_list(dataSource)}
                            onCancel={this.del_cancel}
                            okText="确定"
                            cancelText="取消"
                        >
                            <p>删除</p>
                        </Popconfirm>

                    </div>
            }
        ];
        const dataSource = [
            {
                key: '1',
                name: 'John Brown',
                age: 32,
                address: 'New York No. 1 Lake Park',
                tel: 1245645212,
                remark: 'fffffff',
                send_people: '小李'
            },
            {
                key: '2',
                name: 'Jim Green',
                age: 42,
                address: 'London No. 1 Lake Park',
                tel: 1245645212,
                remark: 'fffffff',
                send_people: '小李'
            },
            {
                key: '3',
                name: 'Joe Black',
                age: 32,
                address: 'Sidney No. 1 Lake Park',
                tel: 1245645212,
                remark: 'fffffff',
                send_people: '小李'
            },
            {
                key: '4',
                name: 'Disabled User',
                age: 99,
                address: 'Sidney No. 1 Lake Park',
                tel: 1245645212,
                remark: 'fffffff',
                send_people: '小李'
            }
        ];

        const demoAdd = [
            { self_address: 'xxxxx' },
            { self_address: 'eeee' },
            { self_address: 'rrrr' }
        ]

        const addcolumns = [
            {
                title: '自提点地址',
                dataIndex: 'self_address',
                width: 304
            },
            {
                title: '选择',
                style: { textAlign: 'center' },
                render: (demoAdd) => {

                    return (
                        <Radio.Group onChange={this.address_onChange} value={this.state.value}>
                            {

                                <Radio value={demoAdd['self_address']}></Radio>

                                // demoAdd.map(v=>{
                                //     <Radio value={v['self_address']}></Radio>
                                // })
                            }
                        </Radio.Group>
                    )
                }
            }
        ]

        this.state = {
            columns: columns,
            dataSource: dataSource,
            selectedRowKeys: [],
            isModalVisible: false,
            modelTitle: '创建路线',
            addcolumns: addcolumns,
            demoAdd: demoAdd,
            value: '',
            del_title: '确定删除该自提点吗？',
            delVisible: false
        }

    }

    address_onChange = (e) => {
        //console.log(e)
        this.setState({
            value: e.target.value,
        });
    }

    del_cancel = () => {
        message.error('您已取消删除！');
    }

    del_list = (data) => {
        if (Array.isArray(data)) {
            //console.log(data, this.state.dataSource)

        } else {
            const screenArr = this.state.dataSource.filter((item) => {
                return !data['key'].includes(item.key)
            });

            this.setState({
                dataSource: screenArr
            })
        }
        message.success('删除成功！')
    }

    onSelectChange = (selectedRowKeys) => {
        //console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    }

    handleCancel = () => {
        this.setState({ isModalVisible: false })
    }

    handleOk = () => {
        this.setState({
            isModalVisible: false
        })
    }

    creatRoute = () => {
        this.setState({
            isModalVisible: true,
            modelTitle: '创建路线'
        })
    }

    editor = (i) => {
        this.setState({
            isModalVisible: true,
            modelTitle: '编辑路线'
        })
    }

    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange
        };

        return (
            <div className='logisticsRoute'>
                <div className='box top'>
                    <Row>
                        <Col span={18}>
                            <Row>
                                <Col className='flex'>
                                    <label>搜索自提点：</label><Input style={{ width: '320px' }} className='' onBlur={this.getSelf}
                                        placeholder='请输入活动名称/跟团号/手机号/姓名/微信' />
                                </Col>

                                <Col className='flex'>
                                    <Button className='searchBtn' type="primary" icon={< SearchOutlined />}>搜索 </Button>
                                    <div style={{ marginLeft: "20px", cursor: 'pointer' }} onClick={() => this.clearParam()} > 清空所有搜索条件 </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Button className='primaryBtn' type='primary' style={{ float: 'right' }} onClick={this.creatRoute}> + 创建路线</Button>
                        </Col>
                    </Row>
                </div>

                <div className="lists" >
                    <Table
                        rowSelection={rowSelection}
                        columns={this.state.columns}
                        style={{ textAlign: 'center' }}
                        dataSource={this.state.dataSource}
                        rowKey={record => record.key}
                        rowClassName={(record, index) => { return index % 2 ? 'active' : '' }}
                    />
                </div>


                {/* 弹窗 */}
                <Modal
                    title={this.state.modelTitle}
                    style={{ textAlign: 'center' }}
                    centered
                    className='editorBox'
                    visible={this.state.isModalVisible}
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    <div className='citySelect flex' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">详细地址：</label>
                        <Input style={{ flex: 1, borderRadius: '4px' }} placeholder='' value='宁西路23号'></Input>
                    </div>
                    <div className='citySelect flex' style={{ flexWrap: 'nowrap', alignItems: 'flex-start' }}>
                        <label htmlFor="">自提点路线：</label>
                        {!this.state.demoAdd.length ? (<Button className='add_address'>添加自提点</Button>) : (
                            <div className='' style={{ width: '370px' }}>
                                <div className='headLine' style={{width:'390px',marginLeft:'-10px'}}></div>
                                <Table
                                    columns={this.state.addcolumns}
                                    dataSource={this.state.demoAdd}
                                    rowKey={record => record.self_address}
                                ></Table>
                            </div>
                        )}
                    </div>
                    <div className='citySelect flex' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">配送人员：</label>
                        <Input placeholder='请输入配送人员' style={{ flex: 1, borderRadius: '4px' }} placeholder='' value=''></Input>
                    </div>
                    <div className='citySelect flex' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">联系方式：</label>
                        <Input placeholder='请输入配送人员联系方式' style={{ flex: 1, borderRadius: '4px' }} placeholder='' value=''></Input>
                    </div>
                    <div className='citySelect flex' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">备注：</label>
                        <Input placeholder='请输入备注' style={{ flex: 1, borderRadius: '4px' }} placeholder='' value=''></Input>
                    </div>

                    <div style={{ textAlign: 'center',paddingBottom:'10px' }}>
                        <Button
                            style={{ marginRight: '40px' }}
                            size="small"
                            className='button-bac cancel'
                            onClick={this.handleCancel}
                            type="default">取消</Button>
                        <Button
                            size="small"
                            className='button-bac'
                            onClick={this.handleOk}
                            type="primary">保存</Button>
                    </div>
                </Modal>

                {/* 删除 */}
                <Modal
                    title='提示'
                    style={{ textAlign: 'center' }}
                    className='editorBox'
                    centered
                    visible={this.state.delVisible}
                    onCancel={this.handleCancel}
                    footer={
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                size="small"
                                className='button-bac'
                                onClick={this.handleCancel}
                                type="default">取消</Button>
                            <Button
                                size="small"
                                className='button-bac'
                                onClick={this.del_route}
                                type="primary">确定</Button>
                        </div>
                    }
                >
                </Modal>

            </div>
        );
    }
}

export default router(SelfLiftingPointManagement);