import { Request } from '@zoranwong/acc-engine.js';
export default class GetGroupRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        page_size: 20
    };
    _method = 'GET';
    _uri = '/goods/supplier/get-group-template-channels';
    constructor(params) {
        super(params);
        this._data['page_size'] = params['page_size'] || 20
    }
    rules() {
        
    }

    messages() {
    }
}
