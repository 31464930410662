import Layout from '../components/layout';
import GroupsList from '../pages/groups/groupsList/groupsList'
import GroupsCoupon from '../pages/groups/discountCoupon/discountCoupon'
import NewGrooups from '../pages/groups/groupsList/newGroups/newGroups'
import GroupDetails from '../pages/groups/groupsList/groupDetails/groupDetails'
import GroupExamine from '../pages/groups/groupExamine'
import GroupIcon from '../assets/GroupIcon.png'
import couponIcon from '../assets/couponIcon.png'
import groupExamineIcon from '../assets/groupExamineIcon.png'
const sider = [
    {
        name: '团购列表',
        path: '/groups/groupslist',
        icon: GroupIcon
    },
    {
        name: '优惠券',
        path: '/groups/coupon',
        icon: couponIcon
    },
    {
        name: '待审核',
        path: '/groups/groupExamine',
        icon: groupExamineIcon
    }
];
const group = {
    path: "/groups",
    component: Layout,
    sider: sider,
    routes: [
        {
            path: "/groups",
            exact: true,
            name: 'groupsIndex',
            component: GroupsList
        }, {
            path: '/groups/groupslist',
            exact: true,
            name: 'groupsList',
            component: GroupsList
        }, {
            path: '/groups/coupon',
            exact: true,
            name: 'groupsCoupon',
            component: GroupsCoupon
        }, {
            path: '/groups/newgroups',
            exact: true,
            name: 'newGrooups',
            component: NewGrooups
        }, {
            path: '/groups/details',
            exact: true,
            name: 'details',
            component: GroupDetails
        },
        {
            path: '/groups/groupExamine',
            exact: true,
            name: 'groupExamine',
            component: GroupExamine
        }
    ]
};
export default group;