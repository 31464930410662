import React from 'react'
import { Input } from 'antd'

import '../../css/mapBox.sass';

export default class AddNewWay extends React.Component {
    constructor(props) {
        //console.log(props,)
        super(props)
        this.state = {
            randomColor: props.randomColor || props.routeInfo.color,
            routeInfo: props.routeInfo
        }
    }

    componentDidMount() {

    }

    render() {
        let { randomColor, routeInfo } = this.state;
        return (
            <div className='AddNewWay'>
                <div className='hide' onClick={() => { this.props.hideAdd(true) }}></div>
                <div className='content'>

                    <p className='title'>
                        {routeInfo.color ? '修改路线' : '新增线路'}
                    </p>

                    <ul style={{ marginTop: 30 }}>
                        <li className='line flex'>
                            <p className='lineTitle'>线路编号：</p>
                            <div className='identifier' style={{ background: randomColor, marginLeft: 0 }}>
                                {
                                    routeInfo.color ? routeInfo.key : 8
                                }
                            </div>
                        </li>
                        <li className='line flex'>
                            <p className='lineTitle'>线路名称：</p>
                            <Input placeholder='请输入线路名称' />
                        </li>
                        <li className='line flex'>
                            <p className='lineTitle'>配送人姓名：</p>
                            <Input placeholder='请输入配送人姓名' value={routeInfo['name']} />
                        </li>
                        <li className='line flex'>
                            <p className='lineTitle'>配送人电话：</p>
                            <Input placeholder='请输入配送人电话' value={routeInfo['age']} />
                        </li>
                    </ul>

                    <ul className='contrlLine flex'>
                        <li className='cancel' onClick={() => { this.props.hideAdd({ hideAdd: true, showEditorMap: false }) }}>取消</li>
                        {
                            routeInfo.color ? (
                                <li>确定</li>
                            ) : (
                                <li className='define'
                                    onClick={() => {
                                        this.props.hideAdd({ hideAdd: true, showEditorMap: true });

                                    }}>
                                    下一步
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        )
    }
}