import { Request } from '@zoranwong/acc-engine.js';
export default class StandardGoodsListRequest2 extends Request {
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    //merchant_id: null,

  };
  _method = 'GET';
  _uri = '/goods/shop-goods/goods/goods-list2';
  constructor(params) {
    super(params);
    //this._data['merchant_id'] = params.merchant_id;
    this._data['page_size'] = params['page_size'] || 500;
    if (params['search']) {
      this._data['search'] = params['search']
    }
  }
  rules() {
    return {

    };
  }

  messages() {
    return {

    };
  }
}
