import { Request } from '@zoranwong/acc-engine.js';
export default class DelConditionRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        summary_condition_id: null
    };
    _method = 'DELETE';
    _uri = '/order/summary/merchant/del-group-order-summary-condition';
    constructor(params) {
        super(params);
        this._data['summary_condition_id'] = params['summary_condition_id'];
    }
    rules() {

    }

    messages() {

    }
}