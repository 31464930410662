import { Request } from '@zoranwong/acc-engine.js'
//let merchant_id = window.localStorage.getItem('merchant_id')

export default class TakeOffRequest extends Request {
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    //merchant_id: null,
    group_id: null
  };
  _method = 'PUT';
  _uri = '/goods/group-buy/group-template/group-template-take-off';
  constructor(params) {
    super(params);
    //this._data['merchant_id'] = merchant_id;//params.merchant_id;
    this._data['group_id'] = params.group_id;
  }
  // get middleware(){
  //   return [...super.middleware, PasswordHashMiddleware];
  // }
  rules() {
    return {
    };
  }

  messages() {
    return {
    };
  }
}