import { env } from "../utils/functions";

export default class AddMerchantId {
    async handle(request, next) {
        let merchantId = await window.localStorage.getItem('merchant_id') ? window.localStorage.getItem('merchant_id') : env('REACT_APP_MERCHANT_ID');
        let token = await window.localStorage.getItem('token')
        let pathname = await window.location.pathname;

        if (pathname !== '/' && pathname !== '/home' && pathname !== '/register') {
            //console.log(pathname == '/home')
            if (!merchantId || !token || merchantId === 'undefined') {
                return setTimeout(() => {
                    window.location.href = '/?noToken=true';
                }, 1500)
            }
        }
        //console.log(window.location.pathname)
        if (merchantId) {
            request.data['merchant_id'] = merchantId;
        }
        request['_headers'] = {
            "Request-Source": "admin-web"//添加header头
        }
        request['_data'] = {
            ...request['_data']
        }
        // 发送请求前数据拦截处理
        let res = await next(request)
        // 请求返回后数据拦截处理
        return res
    }
}