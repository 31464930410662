import {Button, Card} from "@alifd/next";
import React, {useState} from "react";
import SpecSelector from "./specSelector";
import SpecValueSelector from "./specValueSelector";
import {map, last, find} from 'lodash';
import {SKUTool} from "./helper";

let selectedSpecs = [];
let skuChanged = null;
let setSelectedSpecs = null;

function specsChange () {
    let changedSpecList = [];
    for (const spec of selectedSpecs) {
        if (spec.selectedValues.length > 0) {
            changedSpecList.push({
                id: spec.id,
                name: spec.name,
                values: map(spec.selectedValues, (value) => {
                    return {
                        id: value.id,
                        value: value.value
                    }
                }),
            });
        }
    }
    changedSpecList = SKUTool.spanRowsGenerator(changedSpecList);
    if (skuChanged)
        skuChanged(changedSpecList);
}

function selectSpec (id, name, values, index) {
    if (find(selectedSpecs, (spec) => {
        return spec.id === id;
    })) {

        return;
    }
    if (selectedSpecs[index]) {
        let selectedSpec = selectedSpecs[index];
        selectedSpec.name = name;
        selectedSpec.values = values;
        selectedSpec.id = id;
        selectedSpec.selectedValues = [];
        setSelectedSpecs([...selectedSpecs]);
        specsChange();
    }
}

function addSpec (originalSpecList) {
    if(selectedSpecs.length>= originalSpecList.length){
        return;
    }
    let spec = last(selectedSpecs);
    if (!spec || spec.name && spec.values.length > 0) {
        selectedSpecs.push({
            id: null,
            name: null,
            values: [],
            selectedValues: []
        });
    }

    setSelectedSpecs([...selectedSpecs]);
}

function delSpec (index) {
    if (selectedSpecs[index]) {
        selectedSpecs.splice(index, 1);
        setSelectedSpecs([...selectedSpecs]);
        specsChange();
    }
}

function selectSpecValue (specIndex, value) {
    let selectedSpec = selectedSpecs[specIndex];
    if (selectedSpec) {
        let selectedValues = selectedSpec['selectedValues'];
        if (!find(selectedValues, (v) => v.id === value.id)) {
            selectedValues.push(value);
            setSelectedSpecs([...selectedSpecs]);
            specsChange();
        }
    }
}

function delSpecValue (specIndex, id) {
    let selectedSpec = selectedSpecs[specIndex];
    if (!id) {
        if (selectedSpec) {
            selectedSpec['selectedValues'] = [];
            setSelectedSpecs([...selectedSpecs]);
        }
    } else {
        if (selectedSpec) {
            let selectedValues = selectedSpec['selectedValues'];
            let index = -1;
            map(selectedValues, (v, i) => {
                if (v.id === id)
                    index = i;
            });
            if (index > -1) {
                selectedValues.splice(index, 1);
            }
        }
    }
    specsChange();
}

const SpecBuilderCardStyle = {width: '100%', marginTop: '20px',display:'none'};
const SpecBuilder = ({changeSku, originalSpecList = [],selectedSpecArray = []}) => {
    skuChanged = changeSku;
    const [selectedSpecList, setSelectedSpecList] = useState([...selectedSpecArray]);
    setSelectedSpecs = setSelectedSpecList;
    selectedSpecs = selectedSpecList;
    //console.log();
    if(selectedSpecArray.length > 0 && selectedSpecList.length === 0){
        // setTimeout(() => {
        //     setSelectedSpecList([...selectedSpecArray]);
        // }, 200);
        selectedSpecList.push(...selectedSpecArray);
    }
    return (
        <Card {...{
            style: SpecBuilderCardStyle,
            title: '商品规格设置',
            subTitle: '(请点击右边按钮新增规格)',
            extra: <Button onClick={() => {addSpec(originalSpecList)}} type="primary">添加规格</Button>,
        }} contentHeight="auto">
            <div className="card-placeholder">
                {selectedSpecList.map((spec, index) => {
                    let id = 'spec_' + spec.id;
                    return (
                        <div className="taglist" key={index} id={id}>
                            <SpecSelector
                                selectSpec={(id, name, values) => {
                                    selectSpec(id, name, values, index);
                                }}
                                selectedSpec={spec}
                                specList={originalSpecList}
                                selectedSpecs = {selectedSpecList}
                                delSpec={() => {
                                    delSpec(index)
                                }}
                            />
                            <SpecValueSelector
                                specValues={spec.values}
                                selectedValues={spec.selectedValues}
                                delSpecValue={(id) => {
                                    delSpecValue(index, id)
                                }}
                                selectSpecValue={(value) => {
                                    selectSpecValue(index, value);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </Card>
    );
};
export default SpecBuilder;