import React, { Component } from "react";
import { Checkbox, Button, message } from 'antd';
import OrderExportAddRequest from "../api/OrderExportAddRequest";
import DistributionRequest from "../api/DistributionRequest";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './printPro.sass';

export default class PrintPro extends Component {

    constructor(props) {
        //console.log(props,)
        super(props)
        this.state = {
            exportArr: {
                arr: Array.isArray(props.exportParam) ? props.exportParam : []
            },
            exportRules: { ...props.exportRules },
            printData: { ...props.printData },
            result_export_rules: [],
            goods_fields: [{ name: 'goods_name', val: '商品名称' }, { name: 'goods_code', val: '商品ID/编码' },
            { name: 'spec_value', val: '规格' }, { name: 'quantity', val: '数量' }, { name: 'item_total_amount', val: '商品金额' }, { name: 'entity_price', val: '商品成本价' }],
            checked_goods_fields: [],
            mailing_fields: [{ name: 'consignee_name', val: '自提点名称' }, { name: 'consignee_phone', val: '自提点电话' }, { name: 'address', val: '地址' }],
            checked_mailing_fields: [],
            order_fields: [{ name: 'alpha_batch_no', val: '跟团号' }, { name: 'order_id', val: '订单号' },
            { name: 'user_name', val: '用户昵称' }, { name: 'payment_time', val: '支付时间' },
            { name: 'delivery_type', val: '物流信息/配送类型' }, { name: 'alpha_name', val: '团长昵称' }, { name: 'alpha_phone', val: '团长联系方式' },
            { name: 'order_status', val: '订单状态' }, { name: 'remark', val: '备注信息' }, { name: 'total_amount', val: '订单/退款金额' },
            { name: 'refunded_at', val: '退款时间' }, { name: 'refunded_amount', val: '退款金额' }, { name: 'user_name', val: '用户昵称' },
            { name: 'user_mobile', val: '用户电话' }
            ],
            checked_order_fields: [],
            group_fields: [
                { name: 'group_name', val: '团购名称' }, { name: 'channel_name', val: '所属渠道' }, { name: 'commission_rate', val: '返佣比例' },
                { name: 'arrival_date', val: '到货时间' }, { name: 'end_datetime', val: '截单时间' }
            ],

            checked_group_fields: []
        }
    }

    componentDidMount() {
        this.defalut_checked()//默认选中
    }

    bornTable = async () => {//生成报表

        let { exportRules, exportArr, printData } = await this.state;
        let { exportType } = printData;
        if (exportArr.arr && exportArr.arr.length) {
            let arr = exportArr.arr;
            let orderIdArr = [];
            arr.map(v => {
                orderIdArr.push(v.id)
            })
            orderIdArr = orderIdArr.join(',')
            exportRules['search'] = `order_id::${orderIdArr}`;
            exportRules['searchFields'] = 'order_id::in';
        }

        let api = exportType === 'orders' ? OrderExportAddRequest : DistributionRequest;

        let res = await api.send(exportRules);

        if (res.code === 200) {
            let hide = message.loading('订单报表生成中', 1)

            setTimeout(() => {
                this.props.jumpUrl({ pathname: `/orders/orderReport` })
            }, 1000)
        }
    }

    grand = (arr, type) => {//循环加参
        let { exportRules } = this.state;
        exportRules[type] = {};
        //console.log(arr)
        for (let i in arr) {
            arr[i] = JSON.parse(arr[i])
            // console.log(arr[i],)

            exportRules[type] = {
                ...exportRules[type],
                [arr[i]['name']]: arr[i]['val']
            }
        }
    }


    defalut_checked = () => {//默认选中

        let { goods_fields, mailing_fields, order_fields, group_fields, printData } = this.state;

        if (printData.exportType !== 'orders') return


        //默认选中导出整合


        let result_export_rules = {}


        //商品信息：

        for (let i in goods_fields) {
            this.state.checked_goods_fields.push(
                JSON.stringify(goods_fields[i])
            )
            result_export_rules['goods_fields'] = { ...result_export_rules['goods_fields'], [goods_fields[i]['name']]: goods_fields[i]['val'] }
        }

        //收货信息：
        for (let i in mailing_fields) {
            this.state.checked_mailing_fields.push(
                JSON.stringify(mailing_fields[i])
            )
            result_export_rules['mailing_fields'] = { ...result_export_rules['mailing_fields'], [mailing_fields[i]['name']]: mailing_fields[i]['val'] }
        }


        //订单信息：

        for (let i in order_fields) {
            this.state.checked_order_fields.push(
                JSON.stringify(order_fields[i])
            )
            result_export_rules['order_fields'] = { ...result_export_rules['order_fields'], [order_fields[i]['name']]: order_fields[i]['val'] }
        }


        //团购信息：
        for (let i in group_fields) {
            this.state.checked_group_fields.push(
                JSON.stringify(group_fields[i])
            )
            result_export_rules['group_fields'] = { ...result_export_rules['group_fields'], [group_fields[i]['name']]: group_fields[i]['val'] }
        }


        //console.log(result_export_rules)


        this.setState({
            exportRules: { ...result_export_rules, ...this.state.exportRules }
        })


    }


    render() {
        let { exportArr, exportRules, printData,
            checked_goods_fields, checked_mailing_fields,
            checked_order_fields, checked_group_fields } = this.state;
        //console.log(exportArr,)

        return (
            <div className='printPro'>
                <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }} onClick={() => { this.props.showSet(false) }}></div>
                <div className='printCotent'>
                    <div className='title'>{printData.exportType === 'orders' ? '导出订单设置' : '导出配送单设置'}</div>
                    <div className='printCtx'>
                        <div className='case'>
                            <ExclamationCircleOutlined style={{ fontSize: 16, color: '#EB7B1B', marginRight: 5, cursor: 'pointer' }} />
                            每次最多可导出5000笔，两次导出的时间间隔为30秒
                        </div>
                        <div className='info'>
                            <div className='flex team_name'>
                                <p>团购名称：</p>
                                {exportArr && exportArr.arr && exportArr.arr.map(v => {
                                    return (
                                        <p>{v.name}、</p>
                                    )
                                })}
                            </div>

                            {
                                exportRules.searchTimeType === 'arrival_date' ? (
                                    <p>到货日期：{exportRules['searchTimeArea']}</p>
                                ) : (
                                    <p>截单日期：{exportRules['searchTimeArea']}</p>
                                )
                            }

                            <div className='detail'>
                                <p>用户：{exportRules['userMobile']}</p>
                                <p>团长：{exportRules['teamMobile']}</p>
                                <p>渠道：{exportRules['channel_name']}</p>
                                <p>订单状态：{exportRules['orderStateTxt']}</p>
                            </div>
                        </div>
                        <div className='checkBox'>
                            <Checkbox.Group
                                defaultValue={printData.exportType === 'orders' ? checked_group_fields : []}
                                style={{ width: '100%' }}
                                onChange={(arr) => {
                                    this.grand(arr, 'group_fields')
                                }}>
                                <div className='flex select'>
                                    <p className='selectTitle'>团购信息：</p>
                                    <ul>

                                        {
                                            checked_group_fields.map((item) => {
                                                return (
                                                    <li><Checkbox disabled={printData.exportType === 'orders' ? false : true} value={item}>{JSON.parse(item).val}</Checkbox></li>
                                                )
                                            })
                                        }

                                    </ul>
                                    {/* <Checkbox.Group options={options} defaultValue={['Pear']} onChange={e => onChange(e)} /> */}
                                </div>
                            </Checkbox.Group>


                            <Checkbox.Group
                                defaultValue={printData.exportType === 'orders' ? checked_order_fields : []}
                                style={{ width: '100%' }}
                                onChange={(arr) => {
                                    this.grand(arr, 'order_fields')
                                }}>

                                <div className='flex select'>
                                    <p className='selectTitle'>订单信息：</p>
                                    <ul>
                                        {
                                            checked_order_fields.map((item) => {
                                                return (
                                                    <li><Checkbox disabled={printData.exportType === 'orders' ? false : true} value={item}>{JSON.parse(item).val}</Checkbox></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                            </Checkbox.Group>


                            <Checkbox.Group
                                defaultValue={printData.exportType === 'orders' ? checked_goods_fields : []}
                                style={{ width: '100%' }}
                                onChange={(arr) => {
                                    this.grand(arr, 'goods_fields')
                                }}>

                                <div className='flex select'>
                                    <p className='selectTitle'>商品信息：</p>
                                    <ul>
                                        {
                                            checked_goods_fields.map((item) => {
                                                return (
                                                    <li><Checkbox disabled={printData.exportType === 'orders' ? false : true} value={item}>{JSON.parse(item).val}</Checkbox></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                            </Checkbox.Group>


                            <Checkbox.Group
                                defaultValue={printData.exportType === 'orders' ? checked_mailing_fields : []}
                                style={{ width: '100%' }}
                                onChange={(arr) => {
                                    this.grand(arr, 'mailing_fields')
                                }}>

                                <div className='flex select'>
                                    <p className='selectTitle'>收货信息：</p>
                                    <ul>

                                        {
                                            checked_mailing_fields.map((item) => {
                                                return (
                                                    <li><Checkbox disabled={printData.exportType === 'orders' ? false : true} value={item}>{JSON.parse(item).val}</Checkbox></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                            </Checkbox.Group>

                            {/* <ul style={{ margin: '20px 0', lineHeight: '25px' }}>
                                <li ref="aa"><Checkbox value="AA">合并下单人和收货人信息相通的订单</Checkbox></li>
                                <li ref="aa"><Checkbox value="BB">隐藏已取消的订单</Checkbox></li>
                                <li ref="aa"><Checkbox value="CC">导出拼团中的订单</Checkbox></li>
                            </ul> */}


                        </div>
                    </div>
                    <div className='contrlBtn flex'>
                        <Button onClick={() => { this.props.showSet(false) }}>关闭</Button>
                        <Button onClick={() => { this.bornTable() }}>生成报表</Button>
                    </div>
                </div>
            </div>
        )
    }

}