import React from 'react';
import { Upload, Icon } from 'antd';
import selectUrl from '../../../assets/select.png'
import './css/upFile.sass'


function Item({ files, src, index, deleteImg, setMainImg, main_img, fontImg, backImg }) {
    return (
        <div className='upFileList' key={index}>
            <img className={index == main_img ? 'selectImg active' : 'selectImg'} width={20} src={selectUrl} alt='' />
            <div className='contrl'>
                <div className='movePic'>
                    {index != 0 ? (<p onClick={() => { fontImg(index) }} className='moveBtn'>前</p>) : null}
                    {index != files.length - 1 ? (<p onClick={() => { backImg(index) }} className='moveBtn'>后</p>) : null}
                </div>
                <div className='center'>
                    <p className='btn mainPic' onClick={() => {
                        setMainImg(index)
                    }}>设为主图</p>
                    <p className='btn del' onClick={() => {
                        deleteImg(index)
                    }}>删除</p>
                </div>
            </div>
            <img className='imgList' src={src} id={main_img} alt='' />
        </div>
    )
}

function PreviewList({ files, deleteImg, setMainImg, main_img, fontImg, backImg }) {
    return (
        files.map((item, index) => {
            return <Item files={files} src={item} select={item} index={index} deleteImg={deleteImg} setMainImg={setMainImg} main_img={main_img} fontImg={fontImg} backImg={backImg} />
        })
    )
}

export default function UploadImage({ uploadProps, files, deleteImg, setMainImg, main_img, fontImg, backImg }) {
    return (
        <>
            <PreviewList files={files} deleteImg={deleteImg} setMainImg={setMainImg} main_img={main_img} fontImg={fontImg} backImg={backImg} />
            {files.length < 10 ? (
                <Upload {...uploadProps}>
                    <Icon type="plus" />
                    <div className="ant-upload-text">上传图片</div>
                </Upload>
            ) : null}
        </>
    )

}