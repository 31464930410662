import React from 'react'
import '../css/merchant.sass'
import { Button, message, Image } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import store from '../../../store';
import Tips from './tips';
import SignRequest from '../api/SignRequest';
import QRCode from 'qrcode';
import examinePng from '../../../assets/examine.png';
//import detail from '../../goods/detail';

export default class ExamineSuccess extends React.Component {
    constructor(props) {
        super(props)
        //console.log(props,)
        this.state = {
            state: props.state,
            merchant: {
                merchant_type: '',
                merchant_shortname: '',
                qrCodeImg: ''
            },
            qrCodeImg: '',
            apply_desc: '查询中',
            merchantDataImg: ''
        }
    }

    componentDidMount() {
        let state = store.getState();
        //console.log(state, 'mmmmmmmmmmmm')

        this.getStore()

    }

    componentWillReceiveProps(props) {
        this.getStore()
        this.setState({
            state: props.state,
        })

    }

    getStore = async () => {
        let detail = await store.getState();
        //console.log(detail,'hhhh')

        let { merchant } = detail ? detail : { ...this.state }

        merchant = merchant ? merchant : this.state.merchant
        //console.log(merchant,)

        let { apply_state, apply_desc } = merchant;

        await this.setState({
            merchant,
            apply_desc
        })

        if (apply_state < 204) return

        let local = await window.localStorage.getItem('userInfo')

        if (!local) return

        let param = {
            user_id: await JSON.parse(local)['customer_id']
        }

        let res = await SignRequest.send(param)

        //console.log(res, 'res----------')

        if (res.code === 200 && res.data.sign_url) {
            // With promises
            QRCode.toDataURL(res.data.sign_url)
                .then(url => {
                    //console.log(url, 'res.data.sign_url')
                    this.setState({
                        qrCodeImg: url
                    })
                })
                .catch(err => {
                    console.error(err)
                })
        }

        let merchantData = window.localStorage.getItem('merchantData');
        this.setState({
            merchantDataImg: merchantData
        })

    }

    render() {
        let { state, merchant, qrCodeImg, apply_desc, merchantDataImg } = this.state;
        return (
            <div className='examine'>
                <div className='examine_state flex'>

                    <Image src={examinePng} width={58} height={60} />

                    {
                        state === 204 || state === 205 ? (<div className='examine_tips'>
                            <p className='title'>{apply_desc}</p>
                            <p>您的申请通过审核，请完成最后的签约步骤；<br />
                                若想修改下方商户信息，也可以重新认证修改，成功签约后将无法修改</p>
                        </div>) : (<div className='examine_tips'>
                            <p className='title'>{apply_desc}</p>

                            <Tips ctx={{ ctx: merchant['apply_detail'], type: 'error' }} />

                            <p>您的申请未通过审核，您可以选择重新认证；<br /></p>

                        </div>)
                    }
                </div>

                <div>
                    <p>商户类型：<span className='themeColor'>{merchant.merchant_type}</span></p>
                    <p>商户简称：<span className='themeColor'>{merchant.merchant_shortname}</span></p>
                </div>

                {
                    state === 204 && qrCodeImg ? (
                        <div className='qrCode'>
                            <Tips ctx={{ ctx: '请使用管理员微信扫描下方二维码进行签约！', type: 'error' }} />
                            <Image width={150} height={150} src={qrCodeImg} />
                        </div>
                    ) : null
                }

                {
                    state === 205 && qrCodeImg ? (
                        <div className='qrCode'>
                            <Tips ctx={{ ctx: '请使用法人微信扫描下方二维码进行签约！', type: 'error' }} />
                            <Image width={150} height={150} src={qrCodeImg} />
                        </div>
                    ) : null
                }

                {/* {
                    merchantDataImg ? (
                        <div style={{ position: 'relative' }}>
                            <p className='everPost themeColor' style={{ padding: '10px 0' }}>您提交的商户资料，仅保存于本地，退出或使用其他浏览器则消失，点击查看图片后，右击可保存</p>
                            <Image style={{ width: 200 }} src={merchantDataImg} />
                        </div>
                    ) : null
                } */}
            </div>
        )
    }
}