import { Request } from '@zoranwong/acc-engine.js'
let merchant_id = window.localStorage.getItem('merchant_id')

export default class GroupStockAdjustRequest extends Request {//调整团购模版库存
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    entity_id: null,//产品实体ID
    group_id: null,//团模版ID
    //merchant_id: null,
    stock_num: null,//修改数据
    group_batch_id: null
  };
  _method = 'POST';
  _uri = '/goods/group-buy/group-template/group-stock-adjust';
  constructor(params) {
    super(params);
    this._data['entity_id'] = params.entity_id;//params.merchant_id;
    this._data['group_id'] = params.group_id;
    this._data['stock_num'] = params.stock_num;
    this._data['group_batch_id'] = params.group_batch_id;
  }
  // get middleware(){
  //   return [...super.middleware, PasswordHashMiddleware];
  // }
  rules() {
    return {
    };
  }

  messages() {
    return {
    };
  }
}