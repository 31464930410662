import { Request } from '@zoranwong/acc-engine.js';
export default class SupplierTagRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null,
        page: 1
    };
    _method = 'GET';
    _uri = '/member/supplier/admin/channel/supplier-tag-list';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
    }
    rules() {
    }

    messages() {
    }
}
