import {Request} from '@zoranwong/acc-engine.js'
let merchant_id = window.localStorage.getItem('merchant_id')
export default class DeletePurchaseRequest extends Request{
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
    //merchant_id: null,
    group_ids:[]

  };
  _method = 'DELETE';
  _uri = '/goods/group-buy/group-template/group-template-destroy';
  constructor(params) {
   super(params);
   //this._data['merchant_id'] = merchant_id;//params.merchant_id;
   this._data['group_ids'] = params.group_ids;
  }
  // get middleware(){
  //   return [...super.middleware, PasswordHashMiddleware];
  // }
  rules(){
    return {
    };
  }

  messages(){
    return  {   
   };
  }
}