import Layout from '../components/layout';
import HomeIndex from '../pages/home';
const sider = [];
const HomeRoute = {
    path: "/home",
    component: Layout,
    sider: sider,
    routes:[
        {
            path: "/home",
            exact: false,
            name: 'homeIndex',
            component: HomeIndex
        }
    ]
};

export default HomeRoute;