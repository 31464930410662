import React, { Component } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

export default class TextView extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        let { data } = this.props
        if (data) {
            this.writeText(null, data)
        }
    }

    shouldComponentUpdate(e) {
        return true
    }

    writeText = (e, old) => {
        //console.log(e, 'nn')
        let value = e ? e.target.value : old
        this.props.updateComponentData(value);

        //关键是先设置为auto，目的为了重设高度（如果字数减少）
        this.refs.myTA.style.height = 'auto';

        //如果高度不够，再重新设置
        if (this.refs.myTA.scrollHeight >= this.refs.myTA.offsetHeight) {
            this.refs.myTA.style.height = this.refs.myTA.scrollHeight + 'px'
        }
    }

    del = (e) => {
        this.props.delComponentData(e)
    }


    render() {

        return (
            <div className='text'>
                {/* <p contenteditable="true" className='upText'>{this.props.data}输入内容</p> */}
                <CloseCircleOutlined className='closeIcon' onClick={this.del} />
                {/* <div ref='writeTxt' style={this.props.data?{border: 0}:{border: '1px dashed #ff0000'}} id="textarea" contenteditable="true" data-text="输入内容...">{this.props.data}</div> */}
                <textarea
                    style={this.props.data ? { border: 0 } : {}}
                    className='upText_area'
                    placeholder='输入文字'
                    onChange={this.writeText}
                    ref="myTA"
                    value={this.props.data}>
                </textarea>
            </div>
        )
    }
}