import React from 'react';
import { Input, Button, Switch, Select, Checkbox, InputNumber, Spin, message, Table, Tooltip } from 'antd';
import '../../css/reduce.sass'
import { PlusOutlined } from '@ant-design/icons';
import SelectGoods from '../../../../components/selectGoods';
import _ from 'lodash'
const { Option } = Select;

const distype = [
    // { val: 'ORDER_ITEM_TOTAL_FULL_CUT', txt: '定向满减' },//
    // { val: 'ORDER_TOTAL_FULL_CUT', txt: '订单总额满减' },// 
    { val: 'PRE_ORDER_TOTAL_FULL_GIFT', txt: '每满XX元赠送' },// 
    { val: 'ORDER_TOTAL_FULL_GIFT', txt: '满赠' }// 
]


export default class Reduce extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open_reduce: false,
            showGoods: false,
            giftList: [],
            discountArr: [],
            discountGoodsList: [],//每个优惠列表
            start: 0,
            contrlGoodsList: [],//操作的商品
            useTable: [],//使用的useTable
            specsList: [], //规格列表
            spinning: false,
            goodstype: [],
            useIndex: 0,
            selectArr: [],
            promotions: [],////优惠规则数组
            newPromo: {},
            discountType: distype[0],//优惠类型
            disLimit: true,
            defaultTypeText: distype[0]['txt']
        }
    }

    componentDidMount = async (e) => {
        //let clas = await this.getClassList();
        //let specs = await this.getUrlSpec();
        //this.getData()//
        //console.log(e, 'promotionspromotionspromotionspromotions')
    }

    async componentDidUpdate(e) {

        //console.log(e, this.props)

    }

    async componentWillReceiveProps(e) {
        let { discountGoodsList, start } = await this.state;
        await this.setState({
            specsList: e['specsList'],
            selectArr: []
        })
        let props = this.props

        if (props.getPromotions.length > 0 && props.getPromotions[0]['rules']) {
            await window.localStorage.setItem('promotions', JSON.stringify(props.getPromotions))
            let newselect = []
            await props.getPromotions[0]['rules'].map(async (v, i) => {
                //console.log(v, i)
                discountGoodsList[i] = await JSON.parse(window.localStorage.getItem('goodsTable'));
                v['gifts'].map(v => {
                    v['disNum'] = v['gift_num']
                })
                newselect.push(
                    {
                        discountPrice: v['amount'],
                        arr: v['gifts']
                    }
                )
            })

            await this.setState({
                open_reduce: true,
                selectArr: newselect,
                start: newselect.length,
                discountGoodsList,
                disLimit: true
            })

            if (props.getPromotions[0]['type'] === "ORDER_TOTAL_FULL_GIFT") {
                await this.setState({
                    discountType: distype[1],
                    disLimit: false
                })
            }
        }
    }

    handleChange = async (data) => {
        //console.log(data)
        let { selectArr } = await this.state;
        await this.setState({
            discountType: JSON.parse(data)
        })
        this.watchArr(selectArr)
    }

    inputChange(data) {
        //console.log(data)
    }

    selectGifts = async (data) => {
        this.setState({
            showGoods: true,
            useTable: data,
            useIndex: data.index
        })
    }

    getSelect = (e) => {
        let { useIndex, selectArr } = this.state;
        e.data.map(v => {
            v['show_name'] = v['goods_name']
            if (v['entity']['spec_values']) {
                v['show_name'] += ": " + v['entity']['spec_values'][0]['specValue']['value'];
            }
        })
        if (!selectArr[useIndex]) {
            selectArr[useIndex] = {}
            selectArr[useIndex]['arr'] = []
        }
        selectArr[useIndex]['arr'] = (selectArr[useIndex]['arr']).concat(e.data)
        this.watchArr(selectArr)
    }

    delSelect = (delindex, index) => {
        let { selectArr } = this.state;
        selectArr[delindex]['arr'].splice(index, 1)
        this.setState({
            selectArr
        })
        this.watchArr(selectArr)
    }

    writeInput = (e, writeindex, i) => {
        let { selectArr } = this.state;
        selectArr[writeindex]['arr'][i]['disNum'] = e;
        this.setState({
            selectArr
        })
        this.watchArr(selectArr)
    }

    writeMax = (e, writeindex) => {
        let { selectArr } = this.state;
        selectArr[writeindex]['maxNum'] = e
        this.setState({
            selectArr
        })
        this.watchArr(selectArr)
    }

    checkbox = (data, index) => {
        //console.log(data, index)
        let { selectArr } = this.state;
        selectArr[index]['maxLimit'] = data.target.checked
        this.setState({
            selectArr
        })
        this.watchArr(selectArr)
    }

    discountPrice = async (e, writeindex) => {
        let { selectArr } = this.state;
        selectArr[writeindex]['discountPrice'] = e
        await this.setState({
            selectArr
        })
        this.watchArr(selectArr)
    }


    handleCancel = () => {

    }

    handleOk = () => {

    }

    addDiscountArr = async () => {
        let { discountGoodsList, start } = this.state;
        discountGoodsList[start] = await JSON.parse(window.localStorage.getItem('goodsTable'));
        this.setState({
            start: start + 1,
            discountGoodsList
        })
    }

    watchArr = async (data) => {
        //console.log(data, this.state)
        let test = [//基本数据结构
            {
                name: '',
                type: '',
                action_type: '',
                rules: [
                    {
                        amount: '',
                        max_gift_num: '',
                        gifts: [
                            {
                                goods_id: '',
                                entity_id: '',
                                goods_name: '',
                                spec_id_json: {},
                                spec_name_json: {},
                                gift_num: ''
                            }
                        ]
                    }
                ]
            }
        ]

        let { promotions, discountType } = this.state;//优惠规则数组

        let newPromo = {
            rules: []
        }

        if (discountType['val'] === 'ORDER_TOTAL_FULL_GIFT') {
            this.setState({
                disLimit: false
            })
        } else {
            this.setState({
                disLimit: true
            })
        }

        let props = this.props['getPromotions'];
        //console.log(props,)

        if (!data) return

        data.map((v, i) => {
            if (props.length) {
                newPromo['id'] = props[0]['id'];
                newPromo['group_id'] = props[0]['group_id'];
            }
            newPromo['name'] = discountType['txt'];
            newPromo['type'] = discountType['val'];
            newPromo['action_type'] = 'ORDER_CREATE';
            newPromo['rules'].concat([])
            newPromo['rules'][i] = {};
            newPromo['rules'][i]['amount'] = v['discountPrice'] || 0;
            newPromo['rules'][i]['gifts'] = []

            if (v['maxLimit'] && discountType['val'] !== 'ORDER_TOTAL_FULL_GIFT') {
                v['maxNum'] = v['maxNum'] || 1
                newPromo['rules'][i]['max_gift_num'] = v['maxNum'] || 1
            }

            v['arr'].map((j, k) => {
                newPromo['rules'][i]['gifts'][k] = {
                    goods_id: j['goods_id'],
                    entity_id: j['entity_id'],
                    goods_name: j['goods_name'],
                    gift_num: j['disNum'] || 1,
                    show_name: j['show_name'],
                    goods_img: j['goods_image'],
                    spec_id_json: {},
                    spec_name_json: {}
                }

                if (v['maxLimit'] && j['disNum'] > v['maxNum'] && discountType['val'] != 'ORDER_TOTAL_FULL_GIFT') {
                    newPromo['rules'][i]['gifts'][k]['gift_num'] = v['maxNum']
                }


                if (!j['entity']) return
                if (j['entity']['spec_values']) {

                    newPromo['rules'][i]['gifts'][k]['spec_id_json'][`${j['entity']['spec_values'][0]['specId']}`] =
                        j['entity']['spec_values'][0]['specValue']['id']

                    newPromo['rules'][i]['gifts'][k]['spec_name_json'][`${j['entity']['spec_values'][0]['specName']}`] =
                        j['entity']['spec_values'][0]['specValue']['value']
                }
            })

        })

        await newPromo.rules.map((v, i) => {
            if (!v['gifts'].length) {
                newPromo.rules.splice(i, 1)
            }
        })

        //console.log(newPromo, 'newPromonewPromonewPromonewPromo')

        promotions[0] = await newPromo

        if (!newPromo.rules.length) {
            newPromo = []
            promotions = []
        }

        await this.setState({
            promotions
        })

        this.props.promotions(promotions)

    }

    render() {
        let { selectArr, discountGoodsList, disLimit, discountType } = this.state;
        //console.log(selectArr, discountGoodsList, disLimit, discountType)

        const columns = {}

        const Discount = (data) => {
            //console.log(data)
            return (
                <div className='discount'>
                    <div className='contrl flex'>
                        <Button style={{ background: '#366fbb' }}
                            onClick={() => { this.selectGifts(data) }}
                            className='font12' size='small'
                            type="primary" icon={<PlusOutlined />}>
                            选择赠品{data.index + 1}
                        </Button>
                        {
                            selectArr[data.index] && selectArr.length && disLimit ? (
                                <div className='flex'>
                                    <Checkbox
                                        checked={selectArr[data.index] && selectArr[data.index]['maxLimit'] ? selectArr[data.index]['maxLimit'] : false}
                                        onChange={(e) => { this.checkbox(e, data.index) }}>优惠上限</Checkbox>
                                    <InputNumber
                                        value={selectArr[data.index] && selectArr[data.index]['maxNum'] ? selectArr[data.index]['maxNum'] : 1}
                                        onChange={(e) => { this.writeMax(e, data.index) }}
                                        style={{ width: '50px' }}
                                        width={50}
                                        size='small' min={1} /> 件
                                </div>
                            ) : null
                        }

                    </div>

                    <div className='table'>
                        {
                            selectArr[data.index] && selectArr.length ? (
                                <table border="1" className='tableBox' style={{ 'borderCollapse': 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <th>优惠条件 ({this.state.discountType['txt']})</th>
                                            <th>赠品</th>
                                            <th>赠品数量</th>
                                            <th>操作</th>
                                        </tr>
                                        <tr>
                                            <td rowSpan={(selectArr[data.index]['arr']).length + 1} style={{ whiteSpace: 'initial' }}>
                                                金额：
                                                <InputNumber
                                                    key={data.index}
                                                    value={selectArr[data.index] && selectArr[data.index]['discountPrice'] ? selectArr[data.index]['discountPrice'] : 0}
                                                    min={0}
                                                    // onChange={(e) => { this.discountPrice(e, data.index) }}
                                                    onBlur={(e) => { this.discountPrice(e.target.value, data.index) }}
                                                />
                                                元 </td>
                                        </tr>
                                        {
                                            selectArr[data.index] && selectArr[data.index]['arr'].map((v, i) => {
                                                return (
                                                    <tr key={v.id + '-' + i}>
                                                        <td><Tooltip title={v['show_name'] ? v['show_name'] : v['goods_name']}> {v['show_name'] ? v['show_name'] : v['goods_name']}</Tooltip> </td>
                                                        <td><InputNumber min={1} style={{ width: 50 }} value={v['disNum'] ? v['disNum'] : 1} onChange={(e) => { this.writeInput(e, data.index, i) }} /></td>
                                                        <td className='delBtn' onClick={() => {
                                                            this.delSelect(data.index, i)
                                                        }}>删除</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            ) : null
                        }
                    </div>
                </div>
            )
        }

        return (
            <div className='reduce'>
                {/* SelectGoods */}
                {
                    this.state.showGoods && this.state.useTable ? (
                        <SelectGoods specsList={this.state.specsList}
                            goodstype={this.state.goodstype}
                            useTable={this.state.useTable}
                            type='gift'
                            canconcat={(e) => { this.getSelect(e) }}
                            hideGooods={e => { this.setState({ showGoods: !e }) }} />
                    ) : null
                }

                <div style={{ margin: '10px 0' }}>
                    <span className="sty-left">满赠：</span>
                    <Switch
                        checked={this.state.open_reduce}
                        onChange={
                            async (e) => {
                                await this.setState({ open_reduce: e ? true : false });
                            }
                        }>
                    </Switch>
                </div>

                {
                    this.state.open_reduce ? (

                        <>
                            <div style={{ margin: '10px 0' }}>
                                <span className="sty-left">优惠方式：</span>
                                <Select defaultValue={'请选择'} style={{ width: 120 }} onChange={this.handleChange}>
                                    {
                                        distype.map(v => {
                                            return <Option value={JSON.stringify(v)}>{v.txt}</Option>
                                        })
                                    }
                                </Select>
                            </div>

                            <Button type='primary' style={{ 'background': '#366FBB' }} onClick={() => { this.addDiscountArr() }}>添加优惠条件</Button>

                            {
                                discountGoodsList && discountGoodsList.length ? (
                                    discountGoodsList.map((v, i) => {
                                        return (
                                            <Discount key={i} data={v} index={i} />
                                        )
                                    })
                                ) : null
                            }
                        </>

                    ) : null
                }

            </div>
        )
    }

}
