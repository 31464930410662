import React from 'react'
import {
    Row,
    Col,
    Input,
    DatePicker,
    Space,
    Select,
    Button,
    Tooltip,
    Table,
    Timeline,
    Modal,
    Pagination,
    message,
    List
} from 'antd';
import {
    SearchOutlined,
    PlusSquareOutlined
} from '@ant-design/icons';
import 'moment/locale/zh-cn';
import OrderListRequest from '../api/OrderListRequest';
import PurchaseListRequest from '../../home/api/purchaseListRequest';
import GetGroupRequest from '../../home/api/GetGroupRequest';
import GroupListRequest from '../../home/api/groupListRequest';
import ConditionListRequest from '../api/ConditionLiistRequest';
import DelConditionRequest from '../api/DelConditionRequest';
import getCommuneListRequest from '../../groups/api/getCommuneListRequest';
import qs from '../../../utils/qs';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './search.sass';
import PrintAuto from './printAuto';
import Print from '../com/print';
import PrintPro from './printPro';
import VirtualList from 'rc-virtual-list';

const {
    RangePicker
} = DatePicker;
const {
    Option
} = Select;

//console.log(qs.nextDate('2021-02-28', 1), 1)

export default class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            orderNum: '',
            visible_add: false,
            visible_Writeoff: false,
            visible_refund: false,
            visible: false,
            visible_refund_reason: false,
            managementId: '',
            setDatesFlag: 'all',
            orderFlag: '',
            dataSource: [],
            merchant_remark: '',
            loading: false,
            sqlStatus: '',
            sqlDate: '',
            sqlName: '',
            goods_name: '',
            total: 0,
            page: 1,
            printData: {},//打印
            sendGoodsVisible: false,
            sendParam: {},// 发货信息
            sendParamNumber: '',//快递单号
            order_id: '',//订单号
            shipping_company: '',//
            writeoffTxt: '',
            writeoffParam: {},
            remark_type: '',
            selectFlag: 0,
            mockPrintAuto: [],
            group_name: '',
            group_name_list: [],
            groupList: [],
            channel_page: 1,
            channel_last_page: 1,
            channel_total: 0,
            group_id: '',
            channel_id: '',
            channel_ids: [],
            searchPeople: '',
            searchTeamer: '',
            searchTimeType: 'arrival_date',
            searchTimeArea: '',
            printPro_show: false,
            tipsVisible: false,
            printType: 'print',
            search_json: {},
            exportParam: {},
            list_group_name: '',
            group_name_id: null,
            channel_arr: [],
            exportRules: {
                userMobile: '全部',
                teamMobile: '全部',
                channel_name: '全部渠道',
                circle_name: '全部圈子',
                searchTimeType: 'arrival_date',
                orderStateTxt: '全部',
                order_id: ''
            },
            channels: [],
            channel_show: false,//渠道
            commune_page: 1,
            commune_last_page: 1,
            communeList: [],
            commune_total: 0,
            circle_show: false,//圈子
        }
    }

    async componentDidMount() {
        //console.log(qs.getPrintDate())
        await this.getOrderList();//获取订单列表
        await this.getGroupList();
        await this.getConditionList();
        await this.search_circle();
    }


    componentWillReceiveProps(e) {
        let { exportArr } = e
        this.setState({
            exportParam: exportArr || []
        })
    }

    getConditionList = async () => {
        let res = await ConditionListRequest.send();
        //console.log(res,)
        if (res.code === 200 && res.data.length) {

            let arr = []
            res.data.map(v => {
                if (v['search_json']['group_ids']) {
                    arr.push(v['search_json']['group_ids'])
                }
            })
            let sql;
            if (arr.length) {
                sql = `id::${arr.join(',')};`
                this.getSaveGroupName(sql, res.data)
            }
        }
    }

    getTeamName = async (id = '') => {

        if (!id) return this.setState({ list_group_name: '' })

        let param = {}

        let sqlarr = [
            { name: 'id', val: id }
        ]

        let sql = qs.sqlSearch(sqlarr)//sql 

        if (sql) {
            param['search'] = sql['search'] || ''
        }

        let res = await PurchaseListRequest.send(param)

        if (!res.data || res.code !== 200) return

        let data = await res.data.data[0];

        if (!data) return

        this.setState({
            list_group_name: data['group_name'] ? data['group_name'] : ''
        })

    }

    getSaveGroupName = async (sql, arr) => {
        //let { groupList } = this.state;
        //console.log(arr, groupList)
        // let param = {
        //     search: sql,
        //     searchFields: 'id::in'
        // }
        //let res = await PurchaseListRequest.send(param);
        //console.log(res,)
        //let data = groupList

        // if (arr.length) {
        //     arr.map((v, i) => {
        //         let result = data.find(x => x.channel_id == v['search_json']['channel_ids'][0])
        //         console.log(result,groupList,v)
        //         if (result && Array.isArray(v['search_json']['group_ids']) && v['search_json']['group_ids'].length > 0) {
        //             //console.log(v)

        //             v['name'] = result['group_name']
        //             v['visible_channel'] = {
        //                 id: result.channel_id,
        //                 name: result.channel_name
        //             }
        //         } else {
        //             v['name'] = ''
        //             v['visible_channel'] = {
        //                 id: '',
        //                 name: '全部渠道'
        //             }
        //         }
        //     })
        // }

        //console.log(arr, )

        if (arr.length) {
            for (let i in arr) {
                if (!Array.isArray(arr[i]['channels'])) {
                    //console.log(, 'nananan')
                    arr[i]['channels'] = Object.values(arr[i]['channels'])
                }
            }
        }

        await this.setState({
            mockPrintAuto: arr,
            selectFlag: 0
        })

    }

    tab = async (index) => {//切换tab

        let { mockPrintAuto } = await this.state;

        await this.setState({
            exportParam: {},
            list_group_name: '',
            channel_id: ''
        })

        this.props.clearExport({})

        await this.setState({
            selectFlag: index,
            searchObj: index > 0 ? mockPrintAuto[index - 1] : {},
            search_json: index > 0 ? mockPrintAuto[index - 1]['search_json'] : {}
        })

        //console.log(this.state.search_json,)

        let mockParam = {};
        mockParam = mockPrintAuto[index - 1]

        if (mockParam && mockParam['search_json'] && mockParam['search_json']['group_ids']) {
            let id = mockParam['search_json']['group_ids'][0];
            await this.getTeamName(id)

            // await this.setState({
            //     channel_id: mockParam['search_json']['channel_ids'][0]
            // })
            //console.log(mockParam)
            if (mockParam['channels'].length) {
                await this.setState({
                    channel_id: mockParam['channels'][0]['id']
                })
            }

        }

        await this.getOrderList()
    }

    clearAll = async () => {//清空所有条件
        await this.setState({
            goods_name: '',
            sqlName: '',
            searchTeamer: '',
            searchPeople: ''
        })
    }

    addPrint = () => {//添加打单时间
        let { mockPrintAuto } = this.state;

        let arr = {
            id: null,
            name: '',
            visible_channel: '',
            search_json: {
                summary_time: '23:59',
                group_end_times: ['00:00', '23:59'],
                group_ids: [],
                channel_ids: []
            }
        }

        mockPrintAuto.push(arr);
        this.setState({
            mockPrintAuto
        })
    }

    getGroupName = async (group_name = '') => {

        if (!group_name) return this.setState({ group_name_list: [] })
        let sqlarr = [
            { name: 'group_name', val: group_name }
        ]
        let sql = qs.sqlSearch(sqlarr)//sql 
        let param = {
            searchFields: 'group_name::like',
            with_batch: 'false'
        }

        if (sql) {
            param['search'] = sql['search'] || ''
        }

        let res = await PurchaseListRequest.send(param)
        let newarr = []
        let arr = res.data.data;

        arr.map((v, i) => {
            newarr.push({
                group_name: v['group_name'],
                id: v['id'],
                end_datetime: v['end_datetime']
            })
        })

        this.setState({
            group_name_list: newarr
        })

    }

    selectTime = async (e) => {
        if (!e) return this.setState({ searchTimeArea: '' });
        let sortTime = e * 24 * 60 * 60 * 1000;
        let newsort = new Date().getTime();
        let newDate = qs.gettoday(new Date(newsort + sortTime))
        let nowDate = qs.gettoday()
        let resnewDate = newDate['year'] + '-' + newDate['month'] + '-' + newDate['day'];
        let resnowDate = nowDate['year'] + '-' + nowDate['month'] + '-' + nowDate['day'];
        await this.setState({
            searchTimeArea: resnewDate + '~' + resnowDate
        })
        //console.log(this.state.searchTimeArea)
    }

    selectDate = async (data, dateArr) => {//选择日期
        let { exportRules } = this.state;
        if (!dateArr[0]) {
            await this.setState({
                searchTimeArea: ''
            })
            exportRules['searchTimeArea'] = ''
        } else {
            dateArr[1] = qs.nextDate(dateArr[1], 1)
            //console.log(dateArr,)

            await this.setState({
                searchTimeArea: dateArr.join('~')
            })
            exportRules['searchTimeArea'] = dateArr.join('~')
        }
        this.setState({
            exportRules
        })
    }

    getGroupList = async () => {
        let { channel_page, channel_total, channel_last_page, groupList, channels } = this.state
        let param = {
            page: channel_page,
            page_size: 500
        }
        //let res = await GetGroupRequest.send(param)
        let res = await GroupListRequest.send(param)
        //console.log(res,)
        if (!res.data) return
        await this.setState({
            groupList: groupList.concat(res.data),
            channels: channels.concat(res.data)
        })
    }

    search_circle = async (e) => {//搜索 圈子

        if (e) {
            await this.setState({
                commune_page: e
            })
        }

        let { circle_name, commune_total, commune_page } = await this.state;
        let param = {
            page: commune_page,
            page_size: 30
        }
        let { customer_id } = JSON.parse(await window.localStorage.getItem('userInfo'))

        let sqlarr = [
            { name: 'member_user_id', val: customer_id },
            { name: 'name', val: circle_name }
        ]

        let sql = qs.sqlSearch(sqlarr)//sql

        //console.log(sql)

        param['search'] = sql['search']

        this.getCommuneList(param)

    }

    getCommuneList = async (param) => {//获取圈子列表

        let { communeList } = this.state

        let res = await getCommuneListRequest.send(param);

        if (res.code === 200) {

            let dataArr = res.data.data

            dataArr.map(v => {
                v['checked'] = false
            })

            this.setState({
                communeList: communeList.concat(dataArr),
                commune_total: res.data.total,
                commune_last_page: res.data.last_page
            })
        }

        //console.log(res, 'getCommuneListRequest')

    }

    searchList = (page) => {//搜索条件,渠道
        const {
            sqlStatus, sqlDate,
            sqlName, goods_name,
            remark_type, group_name,
            order_id, group_id,
            searchPeople, searchTeamer,
            searchTimeType, searchTimeArea,
            group_name_id, channel_arr, channel_id
        } = this.state;

        let sqlarr = [
            // { name: 'id', val: channel_arr.join(',') },
            { name: 'order_status', val: sqlStatus },
            { name: 'created_at', val: sqlDate },
            { name: 'alpha_group_name', val: sqlName },
            { name: 'goods_name', val: goods_name },
            { name: 'remark_type', val: remark_type },
            { name: 'order_id', val: order_id },
            { name: 'group_name', val: group_name }
        ]

        // if (!group_name_id) {
        //     sqlarr.push()
        // }

        if (searchPeople) {
            if (isNaN(searchPeople)) {
                sqlarr.push(
                    { name: 'user_name', val: searchPeople }
                )
            } else {
                sqlarr.push(
                    { name: 'user_mobile', val: searchPeople }
                )
            }
        }

        if (searchTimeArea) {
            sqlarr.push(
                { name: searchTimeType, val: searchTimeArea }
            )
        }

        if (searchTeamer) {
            if (isNaN(searchTeamer)) {
                sqlarr.push(
                    { name: 'alpha_name', val: searchTeamer }
                )
            } else {
                sqlarr.push(
                    { name: 'alpha_phone', val: searchTeamer }
                )
            }
        }

        let sql = qs.sqlSearch(sqlarr)//sql 

        if (!page) {
            page = this.state.page
        }

        this.getOrderList(sql, page)
    }


    setOrders = async (index) => {
        //const { sqlStatus, sqlDate, sqlName, goods_name, remark_type } = this.state;

        await this.setState({
            orderFlag: index,
            sqlStatus: index
        })

        if (index === 'remark') {
            await this.setState({
                remark_type: '1,2,3',
                sqlStatus: ''
            })
        } else {
            await this.setState({
                remark_type: ''
            })
        }

        // 用户备注
        const REMARK_TYPE_USER = 1;
        // 团长备注
        const REMARK_TYPE_ALPHA = 2;
        // 商家备注
        const REMARK_TYPE_MERCHANT = 3;

        this.searchList()///////////////////////////////

    }


    getOrderList = async (sql, page) => {//获取订单列表
        const hide = message.loading('数据加载中', 1)
        let { sqlStatus, sqlDate,
            sqlName, goods_name,
            remark_type, search_json, searchTimeType,
            channel_id, group_id, group_name_id,
            exportRules, channel_arr } = this.state;

        search_json = {
            ...search_json,
            channel_ids: [channel_id]
        }
        //console.log(search_json, channel_id, channel_arr)

        this.setState({ loading: true, search_json })

        let param = {
            page: page || 1,
            page_size: 30
        }

        if (sql) {
            param['search'] = sql['search'] || '';
            param['searchFields'] = `id::in;state::in`;

            exportRules['search'] = param['search'];
            exportRules['searchFields'] = `id::in;state::in`;

            if (searchTimeType) {
                param['searchFields'] = `id::in;state::in;${searchTimeType}::between`;
                exportRules['searchFields'] = `id::in;state::in;${searchTimeType}::between`;
            }

            this.setState({
                exportRules
            })
        }

        // if (group_name_id) {//名称
        //     search_json['group_ids'] = [];
        //     search_json['group_ids'].push(group_name_id)
        // }

        //console.log(param, search_json)

        if (search_json) {
            for (let i in search_json) {
                //console.log(i, search_json[i], !search_json[i][0])
                if (!search_json[i][0]) {
                    delete search_json[i]
                }
            }
            param['search_json'] = search_json;
        }



        let res = await OrderListRequest.send(param);
        //console.log(res, `-------------------------------`)
        if (!res || !res.status || res.code !== 200) return this.setState({ loading: false });
        let result = res['data']['data'];

        if (result && result.length) {
            result = result.filter(item => item['order_status'] !== 9)//过滤等待支付完成
            result = result.filter(item => item['order_status'] !== 21)//过滤支付失败
            result = result.filter(item => item['order_status'] !== 100)//过滤未支付取消
            result.forEach(v => {
                v['delivery_type_text'] = qs.logistics(v['delivery_type'])
                v['order_status_txt'] = qs.orderStatus(v['order_status'], v['delivery_type'])

                //综合 件数价格
                v['all_buy_num'] = 0;
                v['all_buy_price'] = 0;

                if (!v['group_buy']) {
                    v['group_buy'] =
                    {
                        alpha_avatar: "",
                        alpha_batch_id: "",
                        alpha_batch_no: "",
                        alpha_group_id: "",
                        alpha_group_name: "未知",
                        alpha_id: "",
                        alpha_name: "未知",
                        alpha_phone: "未知",
                        arrival_date: "未知",
                        arrival_time: "未知",
                        end_datetime: "未知",
                        group_batch_id: "",
                        group_id: "",
                    }
                }

                if (!v['order_items']) {
                    v['order_items'] = [{
                        buy_quantity: 1,
                        entity_img: "",
                        entity_price: "0.10",
                        goods_name: "",
                        id: "",
                        item_discount_amount: "",
                        item_payment_amount: "",
                        item_total_amount: "",
                        spec_name: [],
                        type: 0
                    }]
                }
                let items = v['order_items'];

                items.map(j => {
                    v['all_buy_num'] += j['buy_quantity'];
                    v['all_buy_price'] += Number(j['item_total_amount']);
                })
                if (!v['user']) {
                    v['user'] = {
                        avatar: "",
                        desc: null,
                        mobile: "未知",
                        name: "未知",
                        user_id: ""
                    }
                }
            })

        }

        this.setState({
            dataSource: result,
            total: res['data']['total'],
            loading: false,
            page: res['data']['current_page']
        })

        this.props.backData({
            dataSource: result,
            total: res['data']['total'],
            loading: false,
            page: res['data']['current_page'],
            sqlStatus, sqlDate, sqlName, goods_name, remark_type
        })

        hide()
        //console.log(this.state.dataSource)
    }

    printHandle = (type, exportType) => {//批量打印
        let { printData, end_date_print, exportParam, selectFlag } = this.state
        printData = {
            title: '选择时间',
            visible: type === 'print' ? true : false,
            type: type,//print打印，，export导出
            text: '选择打印的订单类型',
            exportType,
        }

        let errorTxt = '请勾选需要导出的订单';
        //console.log(exportParam,)

        //if (type === 'export' && !exportParam.length) return message.error(errorTxt);

        //api接口未完成、、
        if (type === 'export' && selectFlag === 0) {
            this.setState({//api接口未完成、、
                printPro_show: true
            })
        } else {
            printData.visible = true
        }

        this.setState({
            printData: printData
        })
        //console.log(this.props,)
    }

    printTicket = () => {//打印小票
        let { exportParam } = this.state
        //console.log(exportParam,)
        if (!exportParam.length) return message.error('请勾选需要打印的订单')
        this.props.jumpReceipt('receipt')
    }

    handleCancel = () => {
        this.setState({
            tipsVisible: false,
            channel_show: false,
            circle_show: false

        })
    }

    onSearch = (e) => {
        //console.log('onSearch', e)
    }

    delCondition = async (flag) => {
        let { mockPrintAuto } = this.state;
        let param = {
            summary_condition_id: mockPrintAuto[flag]['id']
        }
        let res = await DelConditionRequest.send(param);
        if (res.code === 200) {
            message.success('删除成功！')
            mockPrintAuto.splice(flag, 1)
            this.setState({ mockPrintAuto, selectFlag: 0 })
        } else {
            message.error('删除失败')
        }
    }

    onScrollChannel = (e) => {
        //console.log(e)
        // let { channel_page, channel_last_page } = this.state;
        // if (channel_page === channel_last_page) return;

        // this.setState({
        //     channel_page: channel_page + 1
        // })
        // this.getGroupList();
    }

    onScrollCommune = (e) => {
        //console.log(e)
        let { commune_page, commune_last_page } = this.state;
        if (commune_page === commune_last_page) return;

        this.setState({
            commune_page: commune_page + 1
        })
        this.search_circle();
    }

    render() {
        let {
            sqlName, goods_name,
            orderFlag, selectFlag,
            mockPrintAuto, group_name_list,
            groupList, printData,
            printPro_show, tipsVisible, printType,
            exportParam, search_json, list_group_name,
            group_name_id, exportRules, channels,
            channel_show,
            communeList, circle_show,
            channel_page, channel_last_page,
            commune_page, commune_last_page
        } = this.state;

        const ContainerHeight = 200;

        const TipsContent = function () {
            let tips
            if (printType === 'print') {
                tips = <div className='tipstxt'>还未到汇总时间！</div>
            } else {
                tips = <div className='tipstxt'>正在导出订单，请稍等2分钟再来打印！</div>
            }
            return tips;
        }

        return (
            <div className='search_all'>
                <div className='flex'>
                    <ul className='order_print'>
                        <li onClick={() => { this.tab(0) }} className={selectFlag === 0 ? 'active' : ''}>全部订单</li>
                        {
                            mockPrintAuto.length ? mockPrintAuto.map((data, index) => {
                                //console.log(data, )

                                return (
                                    <li
                                        key={index + 1}
                                        onClick={() => { this.tab(index + 1) }}
                                        className={selectFlag === index + 1 ? 'active' : ''}>
                                        每天{data['search_json']['summary_time']}打单
                                    </li>
                                )

                            }) : null
                        }

                        {/* 添加按钮 */}
                        <Tooltip title='该功能为在截单后（当天），把筛选范围内的所有团购的订单合并汇总打印或导出。方便给每个店铺（团长）配货。'>
                            <PlusSquareOutlined
                                style={{ color: '#366FBB', fontSize: 30, cursor: 'pointer', marginLeft: 10 }}
                                onClick={() => { this.addPrint() }}
                            />
                        </Tooltip>

                    </ul>

                </div>
                <div className={selectFlag === 0 ? 'active search' : 'search none'}>
                    <div className='search-left'>

                        <div className='flex'>
                            <div className='marginR40 flex'>
                                搜索用户：
                                <Input value={this.state.searchPeople}
                                    onChange={(e) => {
                                        exportRules['userMobile'] = e.target.value
                                        this.setState({ searchPeople: e.target.value, exportRules })
                                    }}
                                    placeholder='手机号，名称' />
                            </div>

                            <div className='marginR40 flex'>
                                搜索团长：
                                <Input
                                    value={this.state.searchTeamer}
                                    onChange={(e) => {
                                        exportRules['teamMobile'] = e.target.value
                                        this.setState({ searchTeamer: e.target.value, exportRules })
                                    }}
                                    placeholder='手机号，名称' />
                            </div>

                            <div className='marginR40 flex'>
                                选择日期：
                                <Select style={{ width: 70 }} defaultValue='到货'
                                    onChange={e => {
                                        exportRules['searchTimeType'] = e;
                                        this.setState({ searchTimeType: e, exportRules })
                                    }}>
                                    <Option value='arrival_date'>到货</Option>
                                    <Option value='end_datetime'>截单</Option>
                                    <Option value='after_sales_at'>售后</Option>
                                </Select>

                                <Select
                                    onChange={e => { this.selectTime(e) }}
                                    style={{ width: 100, margin: '0 10px' }}
                                    defaultValue='全部'>
                                    <Option key='' value=''>全部</Option>
                                    <Option key='0' value='0'>本日</Option>
                                    <Option key='-1' value='-1'>昨日</Option>
                                    <Option key='-7' value='-7'>本周</Option>
                                    <Option key='-14' value='-14'>近两周内</Option>
                                    <Option key='-30' value='-30'>近一个月内</Option>
                                </Select>

                                <RangePicker
                                    onChange={this.selectDate}
                                    style={
                                        {
                                            borderRadius: '4px',
                                            margin: 0,
                                            width: 200
                                        }
                                    }
                                    locale={
                                        locale
                                    }
                                />
                            </div>


                            {/* <div className='marginR40'>
                            订单查询： <Input value={sqlName} className='input-radius getOrderNum'
                                    onChange={(e) => {
                                        this.setState({ sqlName: e.target.value })
                                    }}
                                    placeholder='请输入活动名称/跟团号/手机号/姓名/微信' />
                            </div>
                            <div className='marginR40'>
                                商品名称： < Input value={goods_name} className='input-radius'
                                    onChange={(e) => {
                                        this.setState({ goods_name: e.target.value })
                                    }}
                                    placeholder='请输入商品名称' />
                            </div>

                            <div className='marginR40'>
                                订单状态：
                                <Select
                                    showSearch
                                    style={
                                        {
                                            width: 200,
                                            borderRadius: '4px'
                                        }
                                    }
                                    onChange={async (e) => {
                                        await this.setState({ sqlStatus: String(e) })
                                        this.searchList()
                                    }}
                                    placeholder="订单状态"
                                    optionFilterProp="children"
                                    filterOption={
                                        (input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option value=""> 全部 </Option>
                                    {
                                        qs.orderSMock().map(v => {
                                            return (
                                                <Option value={v['val']}> {v['name']} </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div> */}

                            <div className='flex' style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
                                订单状态：
                                <Select
                                    showSearch
                                    style={
                                        {
                                            width: 130,
                                            borderRadius: '4px'
                                        }
                                    }
                                    onChange={async (e) => {
                                        e = JSON.parse(e)
                                        exportRules['orderStateTxt'] = e.name;
                                        await this.setState({ sqlStatus: String(e.val), exportRules })
                                        this.searchList()
                                    }}
                                    placeholder="订单状态"
                                    optionFilterProp="children"
                                    filterOption={
                                        (input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option value=""> 全部 </Option>
                                    {
                                        qs.orderSMock().map(v => {
                                            return (
                                                <Option key={v['name']} value={JSON.stringify(v)}> {v['name']} </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className='flex' style={{ marginTop: '20px', justifyContent: 'space-between' }}>
                            <div className='marginR40 flex'>
                                合约名称：
                                <div className='likeSearch'>
                                    <Input
                                        allowClear={true}
                                        placeholder='查找合约名称'
                                        value={this.state.group_name}
                                        // onBlur={() => { this.setState({ group_name_list: [] }) }}
                                        onChange={e => {
                                            this.getGroupName(e.target.value);
                                            this.setState({ group_name: e.target.value, group_name_id: null, group_id: '' })
                                        }}></Input>
                                    {
                                        group_name_list.length ? (
                                            <ul className='search_sel'>
                                                {
                                                    group_name_list.map((v) => {
                                                        return <li
                                                            title={v['end_datetime']}
                                                            onClick={() => {
                                                                //console.log(v,)
                                                                this.setState({
                                                                    group_name: v['group_name'],
                                                                    group_name_list: [],
                                                                    group_name_id: v['id']
                                                                })
                                                            }}>
                                                            {v['group_name']} (截单时间：{v['end_datetime']})
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div className='marginR40 flex' onClick={() => { this.setState({ channel_show: true }) }}>
                                渠道名称：
                                {/* <Select
                                    style={{ width: 160 }}
                                    defaultValue='全部渠道'
                                    onChange={async (e) => {
                                        //console.log(e,);
                                        e = await JSON.parse(e);
                                        exportRules['channel_name'] = e.name;
                                        await this.setState({ channel_id: e['id'] })
                                        await this.searchList()
                                    }}>
                                    <Option value={'{"id":"","type":"NORMAL_CHANNEL","name":"全部渠道","group_templates":[]}'}>全部渠道</Option>
                                    {
                                        groupList.length ? (
                                            groupList.map(v => {
                                                return (
                                                    <Option value={JSON.stringify(v)}>{v['name']}</Option>
                                                )
                                            })
                                        ) : null
                                    }
                                </Select> */}
                                <div className='channel_name'>
                                    {exportRules['channel_name']}
                                </div>
                            </div>

                            <div className='marginR40 flex' onClick={() => { this.setState({ circle_show: true }) }}>
                                圈子名称：

                                <div className='channel_name'>
                                    {exportRules['circle_name']}
                                </div>
                            </div>

                            <div className='marginR40 flex'>
                                订单编号：
                                <Input
                                    placeholder='订单编号'
                                    value={this.state.order_id}
                                    onChange={e => {
                                        exportRules['order_id'] = e.target.value
                                        this.setState({ order_id: e.target.value, exportRules })
                                    }} />
                            </div>

                            {/* <div className='flex'>
                                选择日期：
                                <RangePicker
                                    onChange={this.selectDate}
                                    style={
                                        {
                                            borderRadius: '4px',
                                            margin: 0
                                        }
                                    }
                                    locale={
                                        locale
                                    }
                                />

                                <ul className='flex orderLi'>
                                    <li className={
                                        this.state.setDatesFlag === 'all' ? 'active' : ''
                                    }
                                        onClick={
                                            () => this.setDates('all')
                                        }> 全部
                                    </li>
                                    <li className={
                                        this.state.setDatesFlag === 0 ? 'active' : ''
                                    }
                                        onClick={
                                            () => this.setDates(0)
                                        }> 本日
                                    </li>
                                    <li className={
                                        this.state.setDatesFlag === 1 ? 'active' : ''
                                    }
                                        onClick={
                                            () => this.setDates(1)
                                        }> 昨日
                                    </li>
                                    <li className={
                                        this.state.setDatesFlag === 7 ? 'active' : ''
                                    }
                                        onClick={
                                            () => this.setDates(7)
                                        }> 本周
                                    </li>

                                </ul>
                            </div> */}


                            <div className='flex'>
                                <Button className='searchBtn'
                                    type="primary"
                                    onClick={() => this.searchList()}
                                    icon={< SearchOutlined />
                                    }>搜索 </Button>
                                <div className='clear'
                                    onClick={() => { this.clearAll() }}> 清空所有搜索条件
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    selectFlag > 0 ? (
                        <div className={'search'}>
                            <PrintAuto
                                channels={channels}
                                list_group_name={list_group_name}
                                updateList={e => { if (e) { this.getConditionList() } }}
                                groupList={groupList}
                                detail={mockPrintAuto[selectFlag - 1]}
                                selectFlag={selectFlag - 1}
                                del={() => { this.delCondition(selectFlag - 1) }}
                            />
                        </div>
                    ) : null
                }


                {/* all */}
                <div className='flex' style={{ justifyContent: 'space-between' }}>

                    <ul className="ruleTab flex">
                        <li
                            className={orderFlag === '' ? 'active' : ''}
                            onClick={() => this.setOrders('')}> 全部
                        </li>
                        <li className={orderFlag === 110 ? 'active' : ''}
                            onClick={() => this.setOrders(110)}> 待退款
                        </li>
                        <li className={orderFlag === 111 ? 'active' : ''}
                            onClick={() => this.setOrders(111)}> 已退款
                        </li>
                        <li className={orderFlag === 'remark' ? 'active' : ''}
                            onClick={() => this.setOrders('remark')}> 备注
                        </li>
                    </ul>


                    <ul className='contrl flex'>
                        {/* {
                            !selectFlag ? (
                                <li onClick={() => {
                                    this.printHandle('export')
                                }}>批量导出</li>
                            ) : null
                        }
                        {selectFlag > 0 ? (<>
                            <li onClick={() => {
                                this.printHandle('print')
                            }}>批量打印
                            </li>

                            <li onClick={() => {
                                this.printHandle('export')
                            }}>批量导出</li>
                        </>
                        ) : null} */}

                        {/* <li onClick={() => {
                            this.printTicket()
                        }}>打印小票
                        </li> */}

                        <li onClick={() => {
                            this.printHandle('print')
                        }}>批量打印
                        </li>

                        <li onClick={() => {
                            this.printHandle('export', 'orders')
                        }}>批量导出订单</li>

                        <li onClick={() => {
                            this.printHandle('export', 'distribution')
                        }}>批量导配送单</li>

                        {/* <li onClick={() => {
                            this.printHandle('export','distribution')
                        }}>批量导配送单</li> */}


                    </ul>

                    {/* 打印 */}
                    <Print
                        backTicket={e => { this.props.backTicket(e) }}
                        printSet={e => { this.setState({ printPro_show: e.showSet }) }}
                        printData={printData}
                        exportParam={exportParam}
                        searchJson={search_json}
                        jump={(path, param) => { this.props.jump(path, param) }}>
                    </Print>

                    {
                        printPro_show ? (
                            <PrintPro
                                jumpUrl={e => { this.props.jumpUrl(e) }}
                                exportParam={exportParam}
                                printData={printData}
                                exportRules={exportRules}
                                showSet={e => { this.setState({ printPro_show: e }) }} />
                        ) : null
                    }

                    <Modal
                        title='提示'
                        visible={tipsVisible}
                        onCancel={this.handleCancel}
                        centered
                        footer={false}
                        width={320}
                        style={{ padding: 0, textAlign: 'center' }}
                    >
                        <div className='tipsModel'>
                            {/* <div className='title'>提示</div> */}
                            <TipsContent />
                            <div className='contrlBtn'>
                                <Button>取消</Button>
                                <Button>确定</Button>
                            </div>
                        </div>
                    </Modal>


                    <Modal
                        title='选择渠道'
                        visible={channel_show}
                        onCancel={this.handleCancel}
                        centered
                        footer={false}
                        width={500}
                        style={{ padding: 0, textAlign: 'center' }}
                    >
                        <div className='tipsModel'>
                            <List>
                                <List.Item>
                                    <div onClick={async () => {
                                        this.handleCancel();
                                        exportRules['channel_name'] = '全部渠道';
                                        await this.setState({ channel_id: '', exportRules })
                                        await this.searchList()
                                    }}>
                                        全部渠道
                                    </div>
                                </List.Item>
                            </List>
                            <List>
                                <VirtualList
                                    className='channel_show'
                                    data={groupList}
                                    height={ContainerHeight}
                                    itemHeight={0}
                                    itemKey="id"
                                    onScroll={() => { this.onScrollChannel() }}
                                >
                                    {/* <Option value={'{"id":"","type":"NORMAL_CHANNEL","name":"全部渠道","group_templates":[]}'}></Option> */}

                                    {(item, index) => (
                                        <List.Item key={item.id}>
                                            <div onClick={async () => {
                                                this.handleCancel();
                                                exportRules['channel_name'] = item.name;
                                                exportRules['circle_name'] = '全部圈子';
                                                await this.setState({ channel_id: item['id'], exportRules })
                                                await this.searchList()
                                            }}>
                                                {item.name}
                                            </div>

                                        </List.Item>
                                    )}
                                </VirtualList>
                            </List>
                            {
                                channel_page === channel_last_page ? (
                                    <p>没有更多了</p>
                                ) : null
                            }
                        </div>
                    </Modal>


                    <Modal
                        title='选择圈子'
                        visible={circle_show}
                        onCancel={this.handleCancel}
                        centered
                        footer={false}
                        width={500}
                        style={{ padding: 0, textAlign: 'center' }}
                    >
                        <div className='tipsModel'>
                            <List>
                                <List.Item>
                                    <div onClick={async () => {
                                        this.handleCancel();
                                        exportRules['circle_name'] = '全部圈子';
                                        await this.setState({ channel_id: '', exportRules })
                                        await this.search_circle()
                                    }}>
                                        全部圈子
                                    </div>
                                </List.Item>
                            </List>
                            <List>
                                <VirtualList
                                    className='channel_show'
                                    data={communeList}
                                    height={ContainerHeight}
                                    itemHeight={0}
                                    itemKey="id"
                                    onScroll={() => { this.onScrollCommune() }}
                                >
                                    {/* <Option value={'{"id":"","type":"NORMAL_CHANNEL","name":"全部渠道","group_templates":[]}'}></Option> */}

                                    {(item, index) => (
                                        <List.Item key={item.id}>
                                            <div onClick={async () => {
                                                this.handleCancel();
                                                exportRules['circle_name'] = item.name;
                                                exportRules['channel_name'] = '全部渠道';
                                                await this.setState({ channel_id: '', exportRules })
                                                await this.searchList()
                                            }}>
                                                {item.name}
                                            </div>

                                        </List.Item>
                                    )}
                                </VirtualList>
                            </List>
                            {
                                commune_page === commune_last_page ? (
                                    <p>没有更多了</p>
                                ) : null
                            }
                        </div>
                    </Modal>


                </div>
            </div>
        )
    }
}