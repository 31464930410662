import React from 'react'
import { Table, Pagination } from 'antd';

import '../../css/mapBox.sass';
import mapEditorPng from '../../../../assets/mapEditor.png'

export default class EditorMap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div className='editorMap'>

                <div className='flex editorLine'>

                    <p className='title'>新增线路：鼠标点击地图，使头尾相连形成一个封闭的范围，该范围内的自提点将自动生成新线路</p>

                    <ul className='flex contrlLine'>
                        <li onClick={()=>{this.props.hideEditor(true)}}>取消</li>
                        <li>保存</li>
                    </ul>

                </div>

                <img className='mapEditorPng' src={mapEditorPng} width={310} alt='' />
                <p className='editorTips'>温馨提示：线路范围可随时修改，请放心框选~</p>

            </div>
        )
    }
}