import React from 'react'
import '../css/merchant.sass'
import { Button, message } from 'antd';
import store from '../../../store';
import { CheckOutlined } from '@ant-design/icons';

export default class NextStep extends React.Component {
    constructor(props) {
        //console.log(props,)
        super(props)
        this.state = {
            step: props.progressParam['step'],//进行的步骤
            complate: props.progressParam['complate'],//完成的步骤
            state: props.progressParam['state'],
            canNext: {},
            result: props.result
        }
    }

    nextStep = (data) => {
        this.props.step(data)
    }

    componentWillReceiveProps(e) {
        //console.log(e,)
        let { progressParam, canNext } = e;
        this.setState({
            step: progressParam.step,
            complate: progressParam.complate,
            state: progressParam.state,
            canNext
        })
    }

    getState = async () => {
        let res = await store.getState();
    }

    stepCtrl = (param) => {//步骤前进后退
        let { canNext } = this.state;
        //准备去下一步
        //this.props.prepare(true)
        if (!canNext['state'] && param > 0) return message.error(canNext['msg'])
        //let {step} = this.state
        this.props.goOrback(param)
    }

    checkState = async () => {//刷新
        await true
        window.location.reload()
    }

    render() {

        let { step, complate, result, state } = this.state;

        //console.log(this.state, '---------state----------')

        return (
            <div className='nextStep flex'>

                {
                    !step && (state === 202 || !state) ? (
                        <Button className='themebtn' onClick={() => { this.nextStep(1) }}>我要入驻</Button>
                    ) : null
                }

                {
                    step === 4 && state === 202 ? (
                        <Button className='themebtn' onClick={() => { this.nextStep(1) }}>我要入驻</Button>
                    ) : null
                }

                {
                    step > 0 && step < 2 ? (
                        <div className='stepCtrl flex'>
                            <Button className='themebtn prev' onClick={() => { this.stepCtrl(-1) }}>上一步</Button>
                            <Button className='themebtn' onClick={() => { this.stepCtrl(1) }}>下一步</Button>
                        </div>
                    ) : null
                }
                {
                    step == 3 ? (
                        <div className='stepCtrl flex'>
                            {/* <Button className='themebtn' onClick={() => { this.nextStep(0) }}>返回</Button> */}
                            <Button className='themebtn' onClick={() => { this.checkState() }}>刷新</Button>
                        </div>
                    ) : null
                }
                {
                    step == 4 && state === 204 ? (
                        <div className='stepCtrl flex' style={{ marginTop: 400 }}>
                            <Button className='themebtn prev' onClick={() => { this.stepCtrl(-3) }}>重新认证</Button>
                            {
                                result || state === 204 ? (<Button className='themebtn' onClick={() => { window.location.reload() }}>刷新</Button>) : null
                            }
                        </div>
                    ) : null
                }
                {
                    step == 4 && state === 205 ? (
                        <div className='stepCtrl flex' style={{ marginTop: 400 }}>
                            <Button className='themebtn prev' onClick={() => { this.stepCtrl(-3) }}>重新认证</Button>
                            {
                                result || state === 205 ? (<Button className='themebtn' onClick={() => { window.location.reload() }}>刷新</Button>) : null
                            }
                        </div>
                    ) : null
                }
                {
                    step == 5 ? (
                        <div className='stepCtrl flex'>
                            <Button className='themebtn' onClick={() => { }}>同意签署</Button>
                        </div>
                    ) : null
                }

            </div>
        )
    }
}