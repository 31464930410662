import { message } from 'antd';

const qs = {
    transTen(num) {//不满10转双位
        return num = num > 9 ? num : '0' + num
    },
    getDayNum(t1, t2) {
        let sort1 = new Date(t1).getTime();
        let sort2 = new Date(t2).getTime();

        let res = Math.abs(sort2 - sort1) / 60 / 60 / 24 / 1000;
        return res;
    },
    getDayNumInt(t1, t2) {
        let fontd = this.betweenDate(t1);
        let backd = this.betweenDate(t2)

        let sort1 = new Date(fontd).getTime();
        let sort2 = new Date(backd).getTime();

        let res = Math.abs(sort2 - sort1) / 60 / 60 / 24 / 1000;
        return res;
    },
    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return (false);
    },
    isNull(param = '', txt, callback) {
        let cansub = true;
        //console.log(param, txt)
        if (!param || param.length == 0) {
            message.error(txt)
            cansub = false
            if (callback) { callback() }
        }
        return cansub
    },
    timing() {//记时

    },
    delYear(sort) {
        let newt = new Date(sort);
        let month = newt.getMonth() + 1;
        let day = newt.getDate();
        return month + '月' + day + '日'
    },
    getDate(e) {
        if (e) {

        }
    },
    getPrintDate(endDay = 0) {
        let sort = endDay * 24 * 60 * 60 * 1000;
        let prinsort = (new Date().getTime()) + sort;
        let newDate = new Date(prinsort);
        let year = newDate.getFullYear();
        let month = this.transTen(newDate.getMonth() + 1);
        let day = this.transTen(newDate.getDate());
        return year + '-' + month + '-' + day
    },
    mustNum(data) {
        let res
        if (isNaN(res)) return 0
        return res
    },
    isMobile(data) {//手机号码?
        let reg = /^1[0-9]{10}$/; //验证规则
        return reg.test(data)
    },
    hideMobile(tel) {
        if (!tel || isNaN(tel) || tel.length !== 11) return tel;
        let reg = /(\d{3})\d{4}(\d{4})/;
        return tel.replace(reg, "$1****$2")
    },
    logistics(status) {//物流方式
        let res
        switch (status) {// 1:快递发货,2:顾客自提,3:团长配送
            case 1:
                res = '快递发货'
                break;
            case 2:
                res = '顾客自提'
                break;
            case 3:
                res = '团长配送'
                break;
            default:
                break;
        }
        return res;
    },
    orderStatus(status, delivery_type) {//订单状态
        let res
        switch (status) {// 0:未支付,9:待支付,20:已支付，21：支付失败,40：已发货，60:已收货，80：已完成，100：未支付取消，110：待退款，111：已退款
            case 0:
                res = '未支付'
                break;
            case 9:
                res = '待支付'
                break;
            case 20:
                res = '已支付，待发货'
                if (delivery_type == 2) {
                    res = '已支付，待自提'
                }
                break;
            case 21:
                res = '支付失败'//
                break;
            case 40:
                res = '已支付，待收货'
                if (delivery_type == 2) {
                    res = '已支付，待自提'
                }
                break;
            case 60:
                res = '已收货'
                if (delivery_type == 2) {
                    res = '已自提'
                }
                break;
            case 70:
                res = '退款失败'
                break;
            case 80:
                res = '已完成'
                break;
            case 100:
                res = '未支付取消'
                break;
            case 110:
                res = '待退款'
                break;
            case 111:
                res = '已退款'
                break;
            default:
                res = '未知'
                break;
        }
        return res;
    },

    betweenDate(data) {
        let newDate = new Date(data)
        let year = newDate.getFullYear();
        let month = this.transTen(newDate.getMonth() + 1);
        let day = this.transTen(newDate.getDate());
        return year + '-' + month + '-' + day;
    },
    fontDay(creatDate, type) {//发布时间//结束时间
        creatDate = creatDate.replace(/\-/g, "/");
        let nowDate = new Date().getTime();
        creatDate = new Date(creatDate).getTime();

        let reduce = Math.abs(creatDate - nowDate);
        let reduceDay = Math.floor(reduce / 1000 / 60 / 60 / 24);
        let reduceHour = Math.floor(reduce / 1000 / 60 / 60 % 24);
        let reduceMin = Math.floor(reduce / 1000 / 60 % 60);
        let fontreduceHour = 0;


        if (reduceDay < 1) {
            //reduceDay = reduceDay.toFixed(2)
            fontreduceHour = parseInt(reduceDay * 24)// fontreduceHour小时前发布
            if (type === 1) {
                return { type: 'hour', day: 0, hour: fontreduceHour, min: reduceMin }
            }
            if (type === 2 && nowDate > creatDate) {
                return { type: 'hour', day: 0, hour: 0, min: 0 }
            } else {

            }

            return { type: 'hour', day: reduceDay, hour: reduceHour, min: reduceMin }

        } else {
            if (type === 2 && nowDate > creatDate) {
                return { type: 'hour', day: 0, hour: 0, min: 0 }
            }
            reduceDay = parseInt(reduceDay)
            return { type: 'day', day: reduceDay, hour: reduceHour, min: reduceMin }
        }
    },
    ellipsis(txt, limit = 10, replace = '...') {
        if (txt && txt.length > limit) {
            txt = txt.slice(0, limit) + replace
        }
        return txt;
    },
    isIE() {//ie浏览器
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            return true
        } else {
            return false
        }
    },
    download(url) {//download/////////
        //console.log(url, '--------- dowload ----------' )
        const eleLink = document.createElement('a');
        eleLink.style.display = 'none';
        // eleLink.target = "_blank"
        eleLink.href = url;
        // eleLink.href = record;
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);

    },
    orderSMock() { //查询订单
        let searchList = [
            { val: 20, name: '已支付，待发货' },
            { val: 21, name: '支付失败' },
            { val: 40, name: '待自提/待收货' },
            { val: 60, name: '已收货' },
            { val: 70, name: '退款失败' },
            { val: 80, name: '已完成' },
            // {val: 100, name:'未支付取消'},
            { val: 110, name: '待退款' },
            { val: 111, name: '已退款' },
        ]
        return searchList;
    },

    sqlSearch(arr = []) {
        let sqlarr = '';
        arr.map(v => {
            if (v['name'] && v['val']) {
                sqlarr += `${v['name']}::${v['val']};`
            }
        })
        return { search: sqlarr };
    },

    toFixed(data, num) {
        return Number(data).toFixed(num);
    },

    yearDays(year = new Date().getFullYear()) {//xxxx年天数
        return new Date(year, 2, 0).getDate() === 29 ? 366 : 365;
    },

    monthDays(year, month) {//xx月天数
        return new Date(year, month, 0).getDate()
    },

    transDate(string) {//ex: '2021-06-24'
        let res = new Date(string);

        return {
            year: res.getFullYear(),
            month: res.getMonth() + 1,
            day: res.getDate()
        }
    },

    gettoday(t) {//得到现在
        let now = new Date();
        if (t) { now = new Date(t) }

        return {
            year: this.transTen(now.getFullYear()),
            month: this.transTen(now.getMonth() + 1),
            date: this.transTen(now.getDay()),
            day: this.transTen(now.getDate()),
            hour: this.transTen(now.getHours()),
            min: this.transTen(now.getMinutes()),
            seconds: this.transTen(now.getSeconds())
        }
    },

    transState(state) {
        let txt = '其他'
        switch (state) {
            case 0:
                txt = '未开始';
                break;
            case 101:
                txt = '进行中';
                break;
            case 102:
                txt = '进行中';
                break;
            case 201:
                txt = '已结束';
                break;
            case 202:
                txt = '已终止';
                break;
            default:
                txt = '其他';
                break;
        }

        return txt;
    },


    batchState(state) {//0929

        let txt = '其他'
        switch (state) {
            case 0:
                txt = '未开始';
                break;
            case 10:
                txt = '未结束';
                break;
            case 11:
                txt = '未结束，已成团';
                break;
            case 100:
                txt = '结束，已成团';
                break;
            case 101:
                txt = '结束，未成团';
                break;
            default:
                txt = '其他';
                break;
        }

        return txt;
    },

    transStateFix(state) {//0823修改
        let txt = '其他'
        switch (state) {
            case 0:
                txt = '未开始';
                break;
            case 101:
                txt = '进行中';
                break;
            case 102:
                txt = '进行中';
                break;
            case 201:
                txt = '已结束';
                break;
            case 202:
                txt = '已终止';
                break;
            default:
                txt = '其他';
                break;
        }

        return txt;
    },

    getAngle(x, y) {//已经知道两边求角度 已知道两点求顺时针角度
        var radian = Math.atan(y / x);//弧度
        var angle = Math.floor(180 / (Math.PI / radian));//弧度转角度
        if (x < 0) {//x小于0的时候加上180°，即实际角度
            angle = angle + 180;
        }
        return angle;
    },

    getdiffdate(stime, etime) {
        //初始化日期列表，数组
        var diffdate = new Array();
        var i = 0;
        //开始日期小于等于结束日期,并循环
        while (stime <= etime) {
            diffdate[i] = stime;

            //获取开始日期时间戳
            var stime_ts = new Date(stime).getTime();
            //增加一天时间戳后的日期
            var next_date = stime_ts + (24 * 60 * 60 * 1000);
            //拼接年月日，这里的月份会返回（0-11），所以要+1
            var next_dates_y = new Date(next_date).getFullYear() + '-';
            var next_dates_m = (new Date(next_date).getMonth() + 1 < 10) ? '0' + (new Date(next_date).getMonth() + 1) + '-' : (new Date(next_date).getMonth() + 1) + '-';
            var next_dates_d = (new Date(next_date).getDate() < 10) ? '0' + new Date(next_date).getDate() : new Date(next_date).getDate();
            stime = next_dates_y + next_dates_m + next_dates_d;
            //增加数组key
            i++;
        }
        return diffdate;
    },

    refund(state = 0) {//退款

        let resTxt = '不允许用户退款';
        switch (state) {
            case 0:
                resTxt = '不允许用户退款';
                break;
            case 100:
                resTxt = '未截单允许用户退款';
                break;
            default:
                resTxt = '不允许用户退款';
        }

        return resTxt

    },

    randomColor(arr = ['#ffffff'], callback) {//1,随机颜色//2,不重复//3,去除白色
        let randomTxt = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        //let randomTxt = ['f','f','f','f','f','f','f','f','f','f','f','f','f','f','f', '0'];
        let index0 = randomTxt[Math.floor(Math.random() * 16)];
        let index1 = randomTxt[Math.floor(Math.random() * 16)];
        let index2 = randomTxt[Math.floor(Math.random() * 16)];
        let index3 = randomTxt[Math.floor(Math.random() * 16)];
        let index4 = randomTxt[Math.floor(Math.random() * 16)];
        let index5 = randomTxt[Math.floor(Math.random() * 16)];

        let resColor = `#${index0 + index1 + index2 + index3 + index4 + index5}`;

        if (arr.includes(resColor)) {
            callback(arr)
            console.log('重复了',)
            return this.randomColor(arr = ['#ffffff'], callback)
        }

        return `#${index0 + index1 + index2 + index3 + index4 + index5}`

    },

    nextDate(old, dayNum = 1) {//往后dayNum天
        let g = new Date(old);
        let param = new Date(g.setDate(g.getDate() + dayNum));
        return `${param.getFullYear()}-${this.transTen(param.getMonth() + 1)}-${this.transTen(param.getDate())}`;
    },

    /**
* 数组元素交换位置
* @param {array} arr 数组
* @param {number} index1 添加项目的位置
* @param {number} index2 删除项目的位置
* index1和index2分别是两个数组的索引值，即是两个要交换元素位置的索引值，如1，5就是数组中下标为1和5的两个元素交换位置
*/
    swapArray(arr, index1, index2) {
        arr[index1] = arr.splice(index2, 1, arr[index1])[0];
        return arr;
    },

    //上移 将当前数组index索引与后面一个元素互换位置，向数组后面移动一位

    zIndexUp(arr, index, length) {
        if (index + 1 != length) {
            return this.swapArray(arr, index, index + 1);
        } else {
            alert('已经处于置顶，无法上移');
        }
    },

    //下移 将当前数组index索引与前面一个元素互换位置，向数组前面移动一位

    zIndexDown(arr, index, length) {
        if (index != 0) {
            return this.swapArray(arr, index, index - 1);
        } else {
            alert('已经处于置底，无法下移');
        }
    },

    //置顶，即将当前元素移到数组的最后一位

    zIndexTop(arr, index, length) {
        if (index + 1 != length) {

            //首先判断当前元素需要上移几个位置,置底移动到数组的第一位
            var moveNum = length - 1 - index;

            //循环出需要一个一个上移的次数
            for (var i = 0; i < moveNum; i++) {

                this.swapArray(arr, index, index + 1);

                index++;

            }
        } else {
            alert('已经处于置顶');
        }
    },

    //置底，即将当前元素移到数组的第一位

    zIndexBottom(arr, index, length) {

        let res = []

        if (index != 0) {

            //首先判断当前元素需要上移几个位置,置底移动到数组的第一位
            var moveNum = index - 0;

            //循环出需要一个一个上移的次数
            for (var i = 0; i < moveNum; i++) {

                res = this.swapArray(arr, index, index - 1);

                index--;

            }
        } else {
            alert('已经处于置底');
        }

        return res;

    },

    allBankList() {
        return [{
            id: 1,
            val: '工商银行'
        }, {
            id: 2,
            val: '交通银行'
        }, {
            id: 3,
            val: '招商银行'
        }, {
            id: 4,
            val: '民生银行'
        }, {
            id: 5,
            val: '中信银行'
        }, {
            id: 6,
            val: '浦发银行'
        }, {
            id: 7,
            val: '兴业银行'
        }, {
            id: 8,
            val: '光大银行'
        }, {
            id: 9,
            val: '广发银行'
        }, {
            id: 10,
            val: '平安银行'
        }, {
            id: 11,
            val: '北京银行'
        }, {
            id: 12,
            val: '华夏银行'
        }, {
            id: 13,
            val: '农业银行'
        }, {
            id: 14,
            val: '建设银行'
        }, {
            id: 15,
            val: '邮政储蓄银行'
        }, {
            id: 16,
            val: '中国银行'
        }, {
            id: 17,
            val: '宁波银行'
        }, {
            id: 0,
            val: '其他银行'
        }
        ]
    },

    apply_state(state) {

        let txt = {

        };
        switch (state) {
            case 200:
                txt = { n: '资料校验中', s: 3, complate: 2, state };
                break;
            case 201:
                txt = { n: '审核中', s: 3, complate: 2, state };
                break;
            case 202:
                txt = { n: '已驳回', s: 4, complate: 0, state };
                break;
            case 203:
                txt = { n: '已冻结', s: 4, complate: 0, state };
                break;
            case 204:
                txt = { n: '待签约', s: 4, complate: 3, state };
                break;
            case 205:
                txt = { n: '待账户验证', s: 4, complate: 3, state };
                break;
            case 206:
                txt = { n: '完成', s: 6, complate: 3, state };
                break;

            default:
                break;
        }

        return txt;
    },

    //地址栏参数
    hrefParam(name) {
        // 获取参数
        var url = window.location.search;
        // 正则筛选地址栏
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        // 匹配目标参数
        var result = url.substr(1).match(reg);
        //返回参数值
        return result ? decodeURIComponent(result[2]) : null;
    },

    //圈子状态
    circle_status(change_user_role, opt_statue) {
        let res;
        if (change_user_role === 'GB_MERCHANT_MANAGER' && opt_statue === 0) {
            res = '等待组织确认'
        } else if (change_user_role === 'COMMUNE_MANAGER' && opt_statue === 1) {
            res = '已确认，待发布'
        } else if (change_user_role === 'COMMUNE_MANAGER' && opt_statue === 2) {
            res = '组织已取消'
        } else if (change_user_role === 'COMMUNE_MANAGER' && opt_statue === 0) {
            res = '已修改，待确认'
        }

        return res;
    }

};
export default qs;