import React from "react";
import {
    withRouter as router
} from "react-router-dom";
import Base from "../../../components/base";
import {
    Table, Pagination, message
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import OrderExportListRequest from "../api/OrderExportListRequest";
import BaiduMapEx from "../../../components/baiduMap/BaiduMapEx";
import BaiduMapLine from "../../../components/baiduMap/BaiduMapLine";
import BaiduMapBookLushu from "../../../components/baiduMap/BaiduMapBookLushu";

import { Map, Polyline, Polygon } from 'react-bmap';
import { SketchPicker } from 'react-color';//选色
import '../css/orderReport.sass'

import qs from '../../../utils/qs'

class OrderReport extends Base {

    constructor(props) {
        super(props)
        this.state = {
            color: '#D0021B',
            show: false,
            page: 1,
            total: 0,
            list: []
        }
    }

    componentDidMount() {
        this.getList();
    }

    componentWillReceiveProps() {

    }

    getList = async () => { //getdata
        let { page } = this.state;
        let param = {
            page: page
        }
        const hide =  message.loading('加载数据中')
        let res = await OrderExportListRequest.send(param);
        if (res.code === 200) {
            this.setState({
                list: res.data.data,
                total: res.data.total
            })
        }
        hide()
    }


    render() {

        let { color, show, page, list, total } = this.state

        const dataSource = [
            {
                key: '1',
                name: '胡彦斌',
                age: 32,
                address: '西湖区湖底公园1号',
            },
            {
                key: '2',
                name: '胡彦祖',
                age: 42,
                address: '西湖区湖底公园1号',
            },
        ];

        const columns = [
            {
                title: '名称',
                width: 200,
                key: 'name',
                render: (res) => {
                    return (
                        <p>
                            {res.task_name}
                        </p>
                    )
                }
            },
            {
                title: '订单时间范围',
                render: (res) => {
                    return (
                        <p>
                            截单时间：
                            {res.begin_at} ~
                            {res.end_at}
                        </p>
                    )
                }
            },
            {
                title: '报表生成时间',
                render: (res) => {
                    return (
                        <p>
                            截单时间：
                            {res.begin_at} ~
                            {res.end_at}
                        </p>
                    )
                }
            },
            {
                title: '报表状态',
                width: 150,
                render: (res) => {
                    return (
                        <div>
                            {
                                res.is_end && res.result ? (
                                    <>
                                        <div>已生成，
                                            <span
                                                style={{ cursor: 'pointer', color: '#366FBB' }}
                                                onClick={async () => {
                                                    let urls = res.result.urls;
                                                    // let urls = [
                                                    //     "https://cili-app.oss-cn-beijing.aliyuncs.com/订单汇总/青松福年来/202108201528/供应商订单查询导出(1).xlsx",
                                                    //     "https://cili-app.oss-cn-beijing.aliyuncs.com/订单汇总/青松福年来/202108201528/供应商订单查询导出(2).xlsx",
                                                    //     "https://cili-app.oss-cn-beijing.aliyuncs.com/订单汇总/青松福年来/202108201528/供应商订单查询导出(3).xlsx",
                                                    //     "https://cili-app.oss-cn-beijing.aliyuncs.com/订单汇总/青松福年来/202108201528/供应商订单查询导出(4).xlsx",
                                                    //     "https://cili-app.oss-cn-beijing.aliyuncs.com/订单汇总/青松福年来/202108201528/供应商订单查询导出(5).xlsx",
                                                    //     "https://cili-app.oss-cn-beijing.aliyuncs.com/订单汇总/青松福年来/202108201528/供应商订单查询导出(6).xlsx"
                                                    // ]
                                                    // await qs.download(urls)
                                                    for (let i in urls) {
                                                        await (function name(url) {
                                                            return new Promise(function (resovle) {
                                                                setTimeout(function (params) {
                                                                    qs.download(url);
                                                                    return resovle(1);
                                                                }, 500 * i);
                                                            })
                                                        })(urls[i]);
                                                    }
                                                }}>
                                                点击下载
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <p>未生成，请稍后</p>
                                )
                            }
                        </div>
                    )
                }
            },
            {
                title: '操作',
                width: 100,
                render: (res) => {
                    return (
                        !res.is_end ? (
                            <p className='updateOrderList' onClick={()=>{this.getList()}}>
                                刷新
                            </p>
                        ) : null
                    )
                }
            }
        ];

        return (
            <>
                <div className='orderReport'>
                    <div className='toptips'>
                        <ExclamationCircleOutlined style={{ fontSize: 16, color: '#EB7B1B', marginRight: 5, cursor: 'pointer' }} />
                        系统将为您保留近30天内的导出数据，便于您随时导出下载
                        {/* 即将上线 */}
                    </div>

                    {/* <BaiduMapEx /> */}
                    {/* <BaiduMapLine param={{name:'lyp'}} /> */}
                    {/* <BaiduMapBookLushu /> */}

                    {/* <button onClick={() => { this.setState({ show: !show }) }}>选择</button> */}

                    {/* {
                        show ? (
                            <SketchPicker color={color} onChange={e => { console.log(e,); this.setState({ color: e.hex }); }} />

                        ) : null
                    } */}

                    {/* <div style={{ width: 10, height: 10, background: color }}></div> */}

                    <div className='overTable'>
                        <b>已生成订单报表</b>
                        （温馨提示：导出的订单报表中不包含已取消的订单）
                    </div>

                    <Table
                        dataSource={list}
                        columns={columns}
                        pagination={false}
                        scroll={
                            { y: 600 }
                        }
                    />

                    <div style={{ margin: 10, float: 'right' }}>
                        <Pagination
                            onChange={async (e) => { await this.setState({ page: e }); this.getList(e) }}
                            hideOnSinglePage={true}
                            current={page}
                            pageSize={15}
                            total={total} />
                    </div>
                </div>
            </>
        )
    }

}

export default router(OrderReport);