import Layout from '../components/layout';
import FunTest from '../pages/funTest';
import datumIcon from '../assets/datumIcon.png'
const sider = [
    {
        name: '方法测试',
        path: '/funTest',
        icon: datumIcon
    }
];
const funTest = {
    path: "/funTest",
    component: Layout,
    sider: sider,
    routes:[
        {
            path: "/funTest",
            exact: false,
            name: 'datusIndex',
            component: FunTest
        }
    ]
};
export default funTest;