import React, { Component } from "react";
import { map } from "lodash";
import qs from '../../../../utils/qs'

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detail: props.detail
        }
    }

    componentDidMount() {
        let { detail } = this.state
        //

    }

    render() {
        const { detail } = this.state//

        //console.log(detail)

        return (
            <div className='detail-header'>
                <div className='headerTop flex'>
                    <img className='avatar' src={detail['alpha_grouper_avatar']} alt='' />
                    <div className='userInfo'>
                        <p className='marginB10'>{detail['alpha_grouper_nickname']} <span style={{ width: '15px', display: 'inline-block' }}></span> {detail['userMobile']}</p>
                        <p className='marginB10'>{detail['alpha_group_batches'][0] ? detail['alpha_group_batches'][0]['created_at'] : ''}开团  开团{localStorage.getItem('goodsNum')}件商品 下单人数{detail['buyer_num']}人</p>
                        <div className='marginB10 flex' style={{ alignItems: 'flex-start' }}>
                            <p style={{ flex: '0 0 60px' }}>配送时间：</p>
                            {
                                detail['group_type'] === 1 ? (
                                    <ul className='flex pstime'>
                                        {
                                            map(detail['arrived_dates'], (v) => {
                                                return (<li>{v}</li>);
                                            })
                                        }
                                    </ul>
                                ) : (
                                    <ul className='flex pstime'>
                                        <li>
                                            {detail['purchase_info'][0]['arrival_date']}
                                        </li>
                                    </ul>
                                )
                            }

                        </div>
                        <p>截单时间： 到货日前{detail['end_day']}天，{detail['end_time']}</p>

                    </div>
                </div>
                <div className='flex headerBottom'>
                    {
                        detail['group_type'] === 1 ? (
                            <div className='showNum'>
                                <p>{detail['sale_amount']}</p>
                                销售额
                            </div>
                        ) : (
                            <div className='showNum'>
                                <p>{detail['purchase_info'][0]['purchase_amount']}</p>
                                销售额
                            </div>
                        )
                    }

                    <div className='showNum'>

                        {
                            detail['group_type'] === 1 ? (<p>{detail['order_num']}</p>) : (<p>{detail['purchase_info'][0]['order_num']}</p>)
                        }
                        订单数
                    </div>
                </div>
            </div>
        )
    }
}

