import { Request } from '@zoranwong/acc-engine.js';
export default class MerchantRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        user_id: '',
        materials: {}
    };
    _method = 'POST';
    _uri = '/payment-service/incoming-api/incoming-add';
    constructor(params) {
        super(params);
        this._data['user_id'] = params.user_id;
        this._data['materials'] = params.materials;
    }
    rules() {

    }

    messages() {

    }
}
