import React from "react";
import './DateArea.sass';

export default class DateArea extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            threeMonthDays: {},
            item: {},
            end: false
        }
    }

    async componentDidMount() {
        let { threeMonthDays, item, end } = await this.props;
        await this.setState({
            end
        })
        await this.transTime(item);
        await this.sustainDay(item);
        await this.beginPos(threeMonthDays, item)
        await this.setState({
            threeMonthDays,
            item
        })

    }

    async componentWillReceiveProps(props) {
        let { threeMonthDays, item, end } = await props;
        await this.setState({
            end,
            item: {}
        })
        await this.transTime(item);
        await this.sustainDay(item);
        await this.beginPos(threeMonthDays, item)
        await this.setState({
            threeMonthDays,
            item
        })

    }

    //时间转化
    transTime = async (item) => {
        if (!item) return

        await item.arrival_date.map((v, i) => {
            v['timeString'] = new Date(v.year + '-' + v.month + '-' + v.day).getTime();
        })

        //日期排序大-》小
        item.arrival_date.sort((a, b) => b.timeString - a.timeString)

        return item;
    }

    //持续的天数
    sustainDay = async (item) => {
        if (!item) return

        await item.arrival_date.map((v, i) => {
            //持续的天数
            item['timeReduce'] = (item.arrival_date[0]['timeString'] - item.arrival_date[item.arrival_date.length - 1]['timeString']) / 1000 / 60 / 60 / 24;
        })

        return item;
    }


    //初始位置

    beginPos = async (threeMonthDays, item) => {
        let { end } = this.state;
        if (!item) return

        //和上月一号比较
        let firstDayString = item.arrival_date[0]['timeString'];
        let leastDayString = item.arrival_date[item.arrival_date.length - 1]['timeString'];

        let lastMonthString = new Date(threeMonthDays.preYear + '-' + threeMonthDays.preMonth + '-' + 1).getTime();

        if (leastDayString - lastMonthString < 0) {
            item['beginArea'] = 0
            item['timeReduce'] = (lastMonthString - item.arrival_date[0]['timeString']) / 1000 / 60 / 60 / 24;

            if (firstDayString - lastMonthString < 0) {

                item['timeReduce'] = 0;

            }

        } else {
            item['beginArea'] = (leastDayString - lastMonthString) / 1000 / 60 / 60 / 24;

        }

        if (end) {
            item['beginArea'] -= item['end_day']
        }

        this.refs['dateArea'].style.left = (item['beginArea']) * 40 + 'px';
        this.refs['dateArea'].style.width = (item['timeReduce'] + 1) * 40 - 10 + 'px';


        // if (item['timeReduce'] === 0 && leastDayString - lastMonthString < 0) {
        //     this.refs['dateArea'].style.width = '0px';
        //     this.refs['dateArea'].style.border = 0
        // }

        return item;
    }


    //排序
    reorder = (a, b) => {
        return a - b;
    }





    //长度
    areaLength = () => {

    }



    //结束位置
    overPos = () => {

    }



    render() {
        let { item } = this.state
        return (
            <div className='dateArea' ref='dateArea'>

            </div>
        )
    }
}
