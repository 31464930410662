import { Request } from '@zoranwong/acc-engine.js';
export default class GroupListAuditRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null,
        type: 0,
        //search: '',
        page: 1
    };
    _method = 'GET';
    _uri = '/goods/group-buy/group-template/audit-alpha-group/audit-alpha-group-list';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['page'] = params['page']?params['page']:1;
        this._data['type'] = params['type'];
        if(params['search']){
            this._data['search'] = params['search'];
        }
    }
    rules() {
        
    }

    messages() {
    }
}
