import { Result, Button } from 'antd';
import { withRouter as router } from "react-router-dom";
import Base from "../../components/base";
import React from "react";

class Emptry extends Base {
  render() {
    return <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary">Back Home</Button>}
    />
  }
}

export default router(Emptry);