import React from 'react'
import { withRouter as router } from "react-router-dom";
import Base from '../../../components/base'
import { Row, Col, Input, Button, DatePicker, Table, Tooltip, Popover, Modal, message, Pagination, Popconfirm, Select, TimePicker, Radio, Space } from 'antd';

import PurchaseListRequest from '../api/groupsList/purchaseListRequest'
import DeletePurchaseRequest from '../api/groupsList/deletePurchaseRequesty'
import HandleOPenRequest from '../api/groupsList/handleOpenRequest'
import TakeOffRequest from '../api/groupsList/takeoffRequest'
import confirmCommuneRequest from '../api/confirmCommuneRequest';
import GetGroupRequest from '../../home/api/GetGroupRequest'
import GroupListRequest from '../../home/api/groupListRequest';
import GroupDraftListRequest from '../../home/api/groupDraftListRequest';
import TempUpdateRequest from '../api/groupsList/tempUpdateRequest';
import moment from 'moment';

import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { SearchOutlined, CloseOutlined, DeleteOutlined, RollbackOutlined, HighlightOutlined } from '@ant-design/icons';
import '../css/grouplist.sass'
import { env } from "../../../utils/functions";
import qs from '../../../utils/qs';
const { RangePicker } = DatePicker;
const {
  Option
} = Select;
class GroupsList extends Base {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      Visible: false,
      groupId: '',
      loading: true,
      group_name: '',
      goods_name: '',
      arrival_date: '',
      //end_order_date:'',
      end_date: '',
      modalTitle: '',//遮罩标题
      modalType: '',
      contrlModel: false,
      page: 1,
      total: 0,
      state: null,
      search_state: null,
      group_template_batch_state: null,
      channel_id: null,
      end_time: null,
      channels: [],
      teamState: [
        { name: '全部', val: '' },
        { name: '进行中', val: 101 },
        { name: '未开始', val: '0' },
        { name: '已结束', val: 201 },
        { name: '已终止', val: 202 }
      ],
      alphaBatchState: [
        { name: '全部', val: '' },
        { name: '未开始', val: '0' },
        { name: '进行中', val: 101 },
        { name: '已结束', val: 201 }
      ],
      refundParam: {
        show: false,
        param: {

        }
      },
      isDraft: false
    }
  }

  async componentDidMount() {
    await this.getList();
    await this.getGroup();
  }

  getGroup = async (sql) => {
    let params = {
      page: 1,
      page_size: 500
    }
    if (sql) {
      params['search'] = sql['search']
    }
    let res = await GroupListRequest.send(params)
    if (res.code === 200) {
      this.setState({
        channels: res.data
      })
    }
    //console.log(res, )
  }

  takeOff = async (data) => {//停止
    const groupId = data['id'];
    //console.log(groupId)
    let param = {
      group_id: groupId
    }

    let res = await TakeOffRequest.send(param);
    if (res.code === 200) {
      message.success(res.message)
      this.searchGoods()
    }
    //console.log(res,)
  }

  getList = async (sql, page) => {
    let { isDraft } = this.state;
    let params = {
      page: page
    }
    if (sql) {
      params['search'] = sql['search']
    }

    let res;
    if (isDraft) {
      params['is_self'] = 1
    }

    res = isDraft ? await GroupDraftListRequest.send(params) : await PurchaseListRequest.send(params)

    let arrdata = res.data.data || [];

    arrdata.map(v => {
      v['goods_name_limit'] = v['goods_name'].slice(0, 10);
      let last_change_record = v['last_change_record'];
      let { change_user_role, opt_status } = last_change_record
      if (change_user_role === 'COMMUNE_MANAGER' && !opt_status) {

        v['change_stop_btn'] = true

      }
      v['circle_status'] = qs.circle_status(change_user_role, opt_status)
      if(!v['state']){
        v['circle_status']=v['state_name']
      }
    })

    if (res.status) {
      this.setState({
        loading: false,
        data: arrdata,
        total: res['data']['total'],
        page: res['data']['current_page']
      })
    }

    this.setState({ loading: false })

  }

  searchGoods = (page) => {//搜索条件
    const { group_name, goods_name, arrival_date, end_date, state, group_template_batch_state, channel_id, end_time, isDraft } = this.state;

    let sqlarr = [
      { name: 'group_name', val: group_name },
      { name: 'goods_name', val: goods_name },
      { name: 'arrival_date', val: arrival_date },
      { name: 'end_date', val: end_date },
      { name: 'state', val: state },
      { name: 'group_template_batch_state', val: group_template_batch_state },
      { name: 'channel_id', val: channel_id },
      { name: 'end_time', val: end_time }
    ]

    let sql = qs.sqlSearch(sqlarr)//sql 

    if (!page) {
      page = this.state.page
    }

    this.setState({ loading: true })
    this.getList(sql, page)
  }

  clearAll = async () => {//清空所有条件
    await this.setState({
      group_name: '',
      goods_name: '',
      arrival_date: '',
      end_date: ''
    })

    this.searchGoods()

  }

  destory = async () => {
    let { state } = this.state;

    state = state === 202 ? '' : 202;

    await this.setState({
      state
    })

    this.searchGoods()
  }

  changeIsDraft = async () => {
    let { isDraft } = this.state;

    isDraft = isDraft === false ? true : false;

    await this.setState({
      isDraft
    })

    this.searchGoods()
  }

  publishGroups = () => {
    this.setState({ Visible: true })
  }

  management = (record) => {
    this.setState({ managementId: record.merchant_id, groupId: record.id })
  }


  confirm_commune = async (record, state) => {//同意、拒绝、圈子

    let { commune_id } = await record
    let { customer_id } = await JSON.parse(window.localStorage.getItem('userInfo'))

    let param = {
      commune_id,
      gb_id: record['id'],
      user_id: customer_id,
      state,
      reason: ''
    }

    let res = await confirmCommuneRequest.send(param);

    if (res.code === 200) {

      message.success(res.message)

      this.searchGoods()
    } else {
      message.error(res.message)
    }

    //console.log(res)


  }

  modification = (data) => {//修改
    let groupId = data.id;
    let type = data['group_type'];
    this.props.history.push({
      pathname: '/groups/newgroups', state: { id: groupId, type: 'change' }, query: {
        type
      }
    })
  }

  copy = (data) => {//复制
    let groupId = data['id'];
    let type = data['group_type'];
    this.props.history.push({
      pathname: '/groups/newgroups', state: { id: groupId, type: 'copy' }, query: {
        type
      }
    })
  }

  manually = () => {
    //console.log(this.state.managementId, '手动成团')
  }
  Delete = async () => {
    let arr = []
    let group_ids = arr.concat(this.state.groupId)
    let params = {
      group_ids
    }
    const hide = message.loading('加载数据中', 1)
    let res = await DeletePurchaseRequest.send(params)
    if (res.status === true) {
      message.success(res.message)
      this.getList()
    } else {
      message.error(res.message)
    }
    hide();
  }
  // 
  manualStart = async (record) => {//手动开始
    let param = {
      group_id: record.id
    }
    const hide = message.loading(' ', 1)
    let res = await HandleOPenRequest.send(param)
    if (res.code === 200) {
      message.success(res.message)
      this.getList()
    } else {
      message.error(res.message)
    }
    hide();
  }
  groupDetail = (record) => {
    // console.log(record,'团购详情')
    this.props.history.push({ pathname: `/groups/details`, state: { data: record } })
  }
  handleCancel = () => {
    let { refundParam } = this.state
    refundParam.show = false
    this.setState({ Visible: false, refundParam })
  }

  // 
  creatServes = () => {
    //console.log('创建服务')
  }

  copyGroup = () => {
    //console.log('复制拼团')
  }

  arrivaltime = (data, dataString) => {
    this.setState({
      arrival_date: dataString.join('~')
    })
  }

  endOrderTime=()=>{
    this.setState({
      //end_order_date: dataString.join('~')
    })
  }

  deadline = (data, dataString) => {
    this.setState({
      end_date: dataString.join('~')
    })
  }

  contrl = (type) => {//选择操作

    let modalTitle
    switch (type) {
      case 'handle':
        modalTitle = '手动开团'
        break;
      case 'delete':
        modalTitle = '删除团购'
        break;
      default:
        break;
    }
    this.setState({
      modalTitle,
      contrlModel: true
    })

  }

  creatGroups = (name, type) => {
    //if (name == 'gonghuo') { return message.error('暂未开放，敬请期待') }

    this.props.history.push({
      pathname: '/groups/newgroups', query: {
        name,
        type
      }
    })

  }

  refund = (e) => {
    this.setState({
      refundParam: {
        show: true,
        id: e.id,
        value: e.refund_limit ? e.refund_limit : 0
      }
    })

  }

  changeRefund = async () => {
    let { refundParam } = this.state;

    let param = {
      group_id: refundParam.id,
      refund_limit: refundParam.value
    }

    let res = await TempUpdateRequest.send(param);

    if (res.code === 200) {
      message.success('退款设置成功！')
      this.getList()
    } else {
      message.error('退款设置失败！')
    }

    this.handleCancel()
  }

  render() {
    let { channels, isDraft, state, modalTitle, contrlModel, page, total, teamState, alphaBatchState, refundParam, end_order_date } = this.state;

    const nostart = (
      <div className='team_contrl'>
        <p style={{ 'cursor': "pointer" }} onClick={this.modification}>修改</p>
        <p style={{ 'cursor': "pointer" }} onClick={this.copy}>复制</p>
        {/* <p style={{ 'cursor': "pointer" }} onClick={() => this.contrl('handle')}>手动成团</p> */}
        <Popconfirm
          title="您确定要停止团购吗？"
          onConfirm={this.takeOff}
          onCancel={this.cancel}
          okText="确定"
          cancelText="取消"
        >
          <p style={{ 'cursor': "pointer" }}>停止</p>
        </Popconfirm>

        {/* <Popconfirm
          title="您确定要删除团购吗？"
          onConfirm={this.Delete}
          onCancel={this.cancel}
          okText="确定"
          cancelText="取消"
        >
          <p style={{ 'cursor': "pointer" }}>删除</p>
        </Popconfirm> */}

      </div>
    )
    const endding = (
      <div className='team_contrl'>
        <p style={{ 'cursor': "pointer" }} onClick={this.copy}>复制</p>
        <Popconfirm
          title="您确定要停止团购吗？"
          onConfirm={this.takeOff}
          onCancel={this.cancel}
          okText="确定"
          cancelText="取消"
        >
          <p style={{ 'cursor': "pointer" }}>停止</p>
        </Popconfirm>
      </div>
    )

    const columns = [
      {
        title: '团购标题',
        width: 80,
        render: (text, record) =>
          <div style={{ 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap', display: 'block', boxSizing: 'border-box', textAlign: 'center' }}>
            <Tooltip title={record.group_name}><span onClick={() => { this.groupDetail(record) }} style={{ color: '#366fbb', cursor: 'pointer' }}>
              {qs.ellipsis(record.group_name)}</span>
            </Tooltip>
            <div className={record.group_type == 1 ? 'pintuan group_type' : 'gonghuo group_type'}>
              {
                record.group_type == 1 ? '拼团合约' : '供货合约'
              }
            </div>
          </div>
      },
      {
        title: '可见渠道',
        width: 80,
        render: (ctx) => {
          return (
            <div>
              {Array.isArray(ctx['visible_channel']) && ctx['visible_channel'].length && !ctx['commune_id'] ? (
                ctx['visible_channel'].map((data, index) => {
                  return (
                    <span>
                      {data['name']}
                      {(index < ctx['visible_channel'].length - 1) ? '，' : ''}
                    </span>
                  )
                })
              ) : ctx['commune_id'] ? (
                <p>
                  {ctx['commune_name']}(圈子)
                </p>
              ) : null}

            </div>
          )
        }
      },
      {
        title: '合约时间 / 状态',
        key: 'created_at',
        render: (ctx) => {
          return (
            <>
              <p>
                {ctx['created_at']} ~ {ctx['end_datetime']}
              </p>
              {
                ctx['commune_id'] && ctx['state'] < 200 ? (
                  <p>
                    {ctx['circle_status']}
                  </p>
                ) : (
                  <p>
                    {ctx['state_name']}
                  </p>
                )
              }

            </>
          )
        }
      },
      {
        title: '截团时刻',
        width: 80,
        render: (ctx) => {
          return (
            <>
              到货日期前 {ctx['end_day']} 天 {ctx['end_time']}
            </>
          )
        }
      },
      {
        title: '返佣比例',
        width: 80,
        render: (ctx) => {
          return (
            <>
              <span>{(ctx['commission_rate'] ? ctx['commission_rate'] : 0) * 100 + '%'}</span>
            </>
          )
        }
      },
      {
        title: '到货批次',
        width: 80,
        render: (ctx) => {
          return (
            <>
              {/* 到货日期前 {ctx['end_day']} 天 {ctx['end_time']} */}
              <table border="0" className='testTable'>
                <tbody>
                  <tr>
                    <th style={{ width: 80 }}></th>
                    <th style={{ width: 80 }}></th>
                    <th style={{ width: 90 }}></th>
                    <th style={{ width: 90 }}></th>
                    <th style={{ width: 80 }}></th>
                    <th style={{ width: 80 }}></th>
                  </tr>
                  {
                    ctx['batch'].map((v, i) => {

                      return (
                        <tr className={'group_list_table_tr'}>
                          <td style={{ width: 80 }}>
                            <span style={{ padding: '2px 10px', border: '1px solid #366fbb', borderRadius: 4, minWidth: 68, display: 'inline-block' }}>
                              {qs.gettoday(v['arrival_date'])['month']}月
                              {qs.gettoday(v['arrival_date'])['day']}日
                            </span>
                          </td>
                          <td style={{ width: 80 }}>{v['order_num']}</td>
                          <td style={{ width: 90 }}>{v['group_passed_count']}</td>
                          <td style={{ width: 90 }}>{v['group_failed_count']}</td>
                          {
                            !isDraft ? (
                              <td style={{ width: 80 }}>{qs.transState(v['state'])}</td>
                            ) : null
                          }
                          <td style={{ width: 80 }}>{v['sales_amount']}</td>
                        </tr>
                      )

                    })
                  }
                </tbody>
              </table>
            </>
          )
        }
      },
      {
        title: '订单数',
        width: 80,
        render: (ctx) => {
          return (
            <>
              {/* 到货日期前 {ctx['end_day']} 天 {ctx['end_time']} */}
            </>
          )
        }
      },
      {
        title: '已成团/单',
        width: 90,
        render: (ctx) => {
          return (
            <>
              {/* 到货日期前 {ctx['end_day']} 天 {ctx['end_time']} */}
            </>
          )
        }
      },
      {
        title: '未成团/单',
        width: 90,
        render: (ctx) => {
          return (
            <>
              {/* 到货日期前 {ctx['end_day']} 天 {ctx['end_time']} */}
            </>
          )
        }
      },
      {
        title: '批次状态',
        width: 80,
        render: (ctx) => {
          return (
            <>
              {/* 到货日期前 {ctx['end_day']} 天 {ctx['end_time']} */}
            </>
          )
        }
      },
      {
        title: '付款金额',
        width: 80,
        render: (ctx) => {
          return (
            <>
              {/* 到货日期前 {ctx['end_day']} 天 {ctx['end_time']} */}
              {
                isDraft ? (
                  ctx['state_name']
                ) : null
              }
            </>
          )
        }
      },
      {
        title: '总金额',
        width: 70,
        render: (ctx) => {
          return (
            <>
              <span>{ctx['sales_amount']}</span>
            </>
          )
        }
      },
      // {
      //   title: '团购商品',
      //   dataIndex: 'goods_name_limit',
      //   render: (text, record) => {
      //     //console.log(text,record)
      //     return (
      //       <Tooltip title={record['goods_name']}><p style={{ width: '170px', fontSize: '14px' }}>{qs.ellipsis(record['goods_name'])}</p></Tooltip>
      //     )
      //   }
      // },
      // {
      //   title: '团购类型',
      //   width: 150,
      //   dataIndex: 'group_type',
      //   render: (text, record) =>
      //     <div>
      //       {text === 1 ? '团购' : '服务'}
      //     </div>
      // },
      // {
      //   title: '开团数',
      //   dataIndex: 'number',
      // },
      // {
      //   title: '开团状态',
      //   dataIndex: 'state_name',
      // },
      {
        title: '操作',
        render: (text, record) =>
          <div className=''>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>

              <span style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px', marginRight: 5 }} onClick={() => this.copy(record)}>复制</span>


              {
                !record['commune_id'] ? (//非圈子

                  <>

                    {
                      record['state'] === 0 ? (

                        <span style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px', marginRight: 5 }} onClick={() => this.modification(record)}>修改</span>

                      ) : null
                    }


                    {
                      !record.state ? <span onClick={() => this.manualStart(record)} style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px' }}>手动开始</span> : ''
                    }
                  </>

                ) : null
              }



              {
                record['commune_id'] && record['change_stop_btn'] && state !== 202 ? (
                  <>
                    {
                      record['state'] === 0 ? (

                        <span style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px', marginRight: 5 }} onClick={() => this.modification(record)}>修改</span>

                      ) : null
                    }


                    <Popconfirm title="确认团购？" okText="确定" cancelText="取消" onConfirm={() => this.confirm_commune(record, 1)}>
                      <span style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px', marginRight: 5 }} >确认</span>
                    </Popconfirm>

                    {/* <Popconfirm title="拒绝团购？" okText="确定" cancelText="取消" onConfirm={() => this.confirm_commune(record, 2)}>
                      <span style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px', marginRight: 5 }} >拒绝</span>
                    </Popconfirm> */}


                  </>

                ) : null
              }


              {
                record['state'] < 200 ? (
                  <Popconfirm title="确定停止团购？" okText="确定" cancelText="取消" onConfirm={() => this.takeOff(record)}>
                    <span style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px', marginRight: 5 }}>停止</span>
                  </Popconfirm>
                ) : null
              }


            </div>

            {
              record['group_type'] === 1 ? (
                <p className='refund_btn' onClick={() => { this.refund(record) }}>{qs.refund(record['refund_limit'])}</p>
              ) : null
            }

          </div>
      },
    ]


    return (
      <div className={'groupList'}>
        {/* <Modal footer={null} visible={this.state.Visible} onCancel={this.handleCancel}>
          <Button type='primary' onClick={this.creatGroups} className='grouptype'>
            <h2>创建团购</h2>
            <span className='span-wrap'>该场景适用于社区团购或拼团等场景</span>
          </Button>
          <Button type='primary' onClick={this.creatServes} className='grouptype'>
              <h2>创建服务</h2>
              <span className='span-wrap'>服务适用于家政服务和老师排课等场景</span>
          </Button>
          <Button type='primary' onClick={this.copyGroup} className='grouptype'>
            <h2 className='span-wrap'>复制已有团购快速开团</h2>
          </Button>
        </Modal> */}

        {/* 退款 */}

        <Modal
          width={300}
          padding={0}
          color={'#666'}
          style={{ padding: '5px 0', width: 200, color: '#666' }}
          visible={refundParam.show}
          footer={false}
          onCancel={this.handleCancel}
          centered>
          <div className='Modeltitle refund'>团合约退款设置 </div>
          <div style={{ padding: 10 }}>
            <Radio.Group onChange={e => { refundParam.value = e.target.value; this.setState({ refundParam }) }} defaultValue={refundParam.value}>
              <Space direction="vertical">
                <Radio value={100}>未截单允许用户退款</Radio>
                <Radio value={0}>完全不允许用户退款</Radio>
              </Space>
            </Radio.Group>
          </div>

          <div className='flex refundContrl'>
            <Button size='small' onClick={() => { this.handleCancel() }}>取消</Button>
            <Button size='small' onClick={() => { this.changeRefund() }}>确定</Button>
          </div>
        </Modal>


        {/* 删除团 */}
        <Modal
          style={{ padding: '10px 0' }}
          visible={this.state.Visible}
          footer={false}
          onCancel={this.handleCancel}
          centered>
          <div className='Modeltitle'>
            请选择开团类型
            {/* <CloseOutlined onClick={this.handleCancel} /> */}
          </div>
          <ul className='flex open_menu'>
            <li onClick={() => { this.creatGroups('pintuan', 1) }}>
              <p className='title'>创建拼团合约</p>
              <p>
                统一供价、返佣，用户拼团付款，团长零库存
              </p>
            </li>


            {/* 100为供货合约，暂时隐掉改为1 */}
            <li onClick={() => { this.creatGroups('gonghuo', 100) }}>
              <p className='title'>创建供货合约</p>
              <p>
                统一出厂价，团长订货
                后，自行设置零售价格
                后开团售卖
              </p>
            </li>
            <li>
              <p style={{ marginTop: '20px' }} className='title'>复制已有团购快速开团</p>
            </li>
          </ul>
        </Modal>

        <Modal
          title={modalTitle}
          visible={contrlModel}
        >
        </Modal>

        {/* 团购列表 */}
        <div style={{ marginLeft: '20px' }}>

          <div className='search flex'>
            <div className='search-left' style={{ paddingBottom: '0' }}>

              <div className='needMargin inline'>
                团购标题：<Input value={this.state.group_name} onChange={(e) => {
                  this.setState({
                    group_name: e.target.value
                  })
                }} className='input-radius' placeholder='请输入团购/服务标题' />
              </div>

              <div className='needMargin inline'>
                团购状态：
                <Select style={{ width: 120 }} defaultValue='全部' onChange={(e) => { this.setState({ state: e }) }}>
                  {
                    teamState.map((v, i) => {
                      return <Option value={v['val']}>{v['name']}</Option>
                    })
                  }
                </Select>
              </div>


              {/* <div className='needMargin inline'>
                商品名称：<Input value={this.state.goods_name} onChange={(e) => {
                  this.setState({
                    goods_name: e.target.value
                  })
                }} className='input-radius' placeholder='请输入商品名称' />
              </div> */}
              <div className='needMargin inline'>
                到货时间：<RangePicker onChange={this.arrivaltime} style={{ borderRadius: '4px', width: 200 }} locale={locale} />
              </div>

              <div className='needMargin inline' style={{ cursor: 'pointer' }} onClick={() => { this.destory() }}>
                {
                  state !== 202 ? (<>
                    <DeleteOutlined /> 回收站</>
                  ) : (
                    <>
                      <RollbackOutlined /> 返回
                    </>
                  )
                }
              </div>

              <div className='needMargin inline' style={{ cursor: 'pointer' }} onClick={() => { this.changeIsDraft() }}>
                {
                  !isDraft ? (<>
                    <HighlightOutlined /> 草稿箱</>
                  ) : (
                    <>
                      <RollbackOutlined /> 返回
                    </>
                  )
                }
              </div>

              <div className='needMargin inline'>
                所属渠道：
                <Select
                  style={{ width: 160 }}
                  defaultValue='全部'
                  onChange={e => { this.setState({ channel_id: JSON.parse(e)['id'] }) }}>
                  <Option key={-1} value='{"id":"","name":"全部渠道","remark":"","tags":[],"created_at":"","updated_at":"","grouper_count":1}'>全部渠道</Option>
                  {
                    channels && channels.length && channels.map((v, i) => {
                      return (
                        <Option key={i} value={JSON.stringify(v)}>{v['name']}</Option>
                      )
                    })
                  }
                </Select>
              </div>

              <div className='needMargin inline'>
                批次状态：
                <Select style={{ width: 120 }} defaultValue='全部' onChange={e => { this.setState({ group_template_batch_state: e }) }}>
                  {
                    alphaBatchState.map((v, i) => {
                      return <Option value={v['val']}>{v['name']}</Option>
                    })
                  }
                </Select>
              </div>


              {/* <div className='needMargin inline'>
                截止时间：<RangePicker onChange={this.deadline} style={{ borderRadius: '4px' }} locale={locale} />
              </div> */}

              <div className='needMargin inline'>
                截团时刻：<TimePicker.RangePicker onChange={(e, d) => { this.setState({ end_time: d.join('~') }) }} locale={locale} style={{ width: 200 }} format={'HH:mm'} />
              </div>

              {/* <div className='needMargin inline'>
                截单时间：<RangePicker onChange={this.endOrderTime} style={{ borderRadius: '4px', width: 200 }} locale={locale} />
              </div> */}

              <div className='needMargin inline' style={{ marginRight: 0 }}>
                <Button onClick={() => { this.searchGoods() }} className='search-btn' type="primary">
                  <SearchOutlined style={{ fontSize: '15px', fontWeight: 'bold' }} />搜索
                </Button>
                <span onClick={this.clearAll} className='clear'>清空所有搜索条件</span>
              </div>

            </div>

            <Button type='primary' className='issue' onClick={this.publishGroups}>
              <i></i>
              发布团购
            </Button>

          </div>

          <div className='goods_list_show'>
            <Table
              scroll={
                {
                  scrollToFirstRowOnChange: false,
                  x: false,
                  y: 550
                }
              }
              className={'group_list_table'}
              pagination={false}
              loading={this.state.loading}
              // rowSelection={rowSelection} 
              columns={columns}
              dataSource={this.state.data}
              rowKey={record => record.id}
              rowClassName={(record, index) => {
                let className = '';
                if (index % 2) className = 'dark-row';
                return className;
              }}
            />

          </div>
        </div>

        <div className='page_style'>
          <Pagination
            pageSize={15}
            hideOnSinglePage={true}
            onChange={(e) => { this.searchGoods(e) }}
            defaultCurrent={1} showSizeChanger={false}
            current={page}
            total={total} />
        </div>


      </div>
    )
  }

}
export default router(GroupsList)