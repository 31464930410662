import React from 'react';
import arrivalPng from './images/arrival.png'

const BMap = window.BMap;
export default class BaiduMapEx extends React.Component {
    componentDidMount() {
        // 百度地图API功能
        var map = new BMap.Map("allmap");    // 创建Map实例
        map.centerAndZoom(new BMap.Point(116.404, 39.915), 14);  // 初始化地图,设置中心点坐标和地图级别
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        // 创建polyline对象
        var pois = [
            new BMap.Point(116.350658, 39.938285),
            new BMap.Point(116.386446, 39.939281),
            new BMap.Point(116.389034, 39.913828),
            new BMap.Point(116.442501, 39.914603)
        ];
        var polyline = new BMap.Polyline(pois, {
            enableEditing: false,//是否启用线编辑，默认为false
            enableClicking: true,//是否响应点击事件，默认为true
            strokeWeight: '8',//折线的宽度，以像素为单位
            strokeOpacity: 0.8,//折线的透明度，取值范围0 - 1
            strokeColor: "#18a45b" //折线颜色
        });
        map.addOverlay(polyline);          //增加折线

        // 插入一个自定义图片
        var pt = new BMap.Point(116.350658, 39.938285); // 图片所在的位置点
        var myIcon = new BMap.Icon(arrivalPng, new BMap.Size(24, 24));  // 70 80表示图片的宽高
        var marker2 = new BMap.Marker(pt, { icon: myIcon });  // 创建标注
        map.addOverlay(marker2);              // 将标注添加到地图中

        //加点
        for(let i in pois){
            map.addOverlay(new BMap.Marker(pois[i]))
        }

    }
    render() {
        return (
            <div id="allmap" style={{ width: '100%', height: '500px' }}></div>
        )
    }
}