import React from 'react'
import {
    Row,
    Col,
    Input,
    DatePicker,
    Space,
    Select,
    Button,
    Tooltip,
    Table,
    Timeline,
    Modal,
    Pagination,
    message,
    TimePicker,
    Popconfirm
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/zh_CN';
import PurchaseListRequest from '../../home/api/purchaseListRequest';
import GetGroupRequest from '../../home/api/GetGroupRequest';
import AddConditionRequest from '../api/AddConditionRequest';
import UpdateConditionRequest from '../api/UpdateConditionRequest';
import qs from '../../../utils/qs';
import moment from 'moment';
import './printAuto.sass';

const {
    Option
} = Select;

export default class PrintAuto extends React.Component {
    constructor(props) {
        super(props)
        //console.log(props,)
        this.state = {
            detail: props.detail,
            teamState: [
                { name: '全部', val: '' },
                { name: '进行中', val: 101 },
                { name: '未开始', val: '0' },
                { name: '已结束', val: 201 },
                { name: '已终止', val: 202 }
            ],
            group_name: props.detail['name'] ? props.detail['name'] : null,
            group_name_list: [],
            groupList: props.groupList,
            summary_time: props.detail['search_json']['summary_time'],
            group_end_times: props.detail['search_json']['group_end_times'],
            //group_ids: props.detail['search_json']['group_ids'] ? props.detail['search_json']['group_ids'] : [],
            channel_ids: '',
            id: null,
            summary_condition_id: props.detail['id'],
            defaultChannel: '全部',
            list_group_name: ''
        }
    }

    async componentDidMount(e) {

        let { detail, groupList } = this.state

        if (detail['visible_channel']) {
            this.setState({
                channel_ids: detail['visible_channel']['id'],
                defaultChannel: detail['visible_channel']['name'],
            })
        }
    }

    async componentWillReceiveProps(e) {

        let detail = e.detail
        //console.log(e, detail)
        await this.setState({
            detail: null,
            list_group_name: e.list_group_name
        })


        this.setState({
            detail,
            group_name: detail['name'] ? detail['name'] : null,
            //group_ids: detail['search_json']['group_ids'] ? detail['search_json']['group_ids'] : '',
            summary_condition_id: detail['id'],
            group_end_times: detail['search_json']['group_end_times'],
            summary_time: detail['search_json']['summary_time']
        })

        if (detail['visible_channel']) {
            this.setState({
                channel_ids: detail['visible_channel']['id'],
                defaultChannel: detail['visible_channel']['name'],
            })
        }

    }


    del = () => {
        this.props.del(true)
    }

    getGroupName = async (group_name = '') => {

        //console.log(group_name,)

        this.setState({
            group_name
        })

        if (!group_name) return this.setState({ group_name_list: [] })
        let sqlarr = [
            { name: 'group_name', val: group_name }
        ]
        let sql = qs.sqlSearch(sqlarr)//sql 
        let param = {
            searchFields: 'group_name::like',
            with_batch: 'false'
        }

        if (sql) {
            param['search'] = sql['search'] || ''
        }

        let res = await PurchaseListRequest.send(param)
        //console.log(res, 'vvvv')
        if (!Array.isArray(res.data.data) || res.code !== 200) return
        let newarr = []
        let arr = await res.data.data;

        await arr.map((v, i) => {
            newarr.push({
                group_name: v['group_name'],
                id: v['id'],
                end_datetime: v['end_datetime']
            })
        })

        //console.log(newarr, 'newarrnewarrnewarrnewarrnewarrnewarrnewarrnewarrnewarrnewarrnewarr')

        await this.setState({
            group_name_list: newarr
        })

    }


    addCondition = async () => {
        let { summary_time, group_end_times, group_ids, channel_ids, summary_condition_id, detail } = await this.state;

        const ex = {//实例结构
            "merchant_id": "WGqb2n",
            "search_json": {
                "summary_time": "10:30",
                "group_end_times": [
                    "5:00",
                    "10:00"
                ],
                "channel_ids": [
                    "WGqb2n"
                ],
                "group_ids": [
                    "WGqb2n"
                ]
            }
        }

        let hide = message.loading('保存中');

        let param = {
            search_json: {
                summary_time,
                group_end_times,
                group_ids: [group_ids],
                channel_ids: channel_ids ? [channel_ids] : []
            }
        }
        hide();

        //console.log(param, group_ids)

        //return

        let res
        if (summary_condition_id) {
            param['summary_condition_id'] = summary_condition_id
            // param['search_json']['summary_time'] = detail['search_json']['summary_time']
            res = await UpdateConditionRequest.send(param)
        } else {
            res = await AddConditionRequest.send(param);
        }

        if (res.code === 200) {
            message.success(res.message);
            //拉取列表
            this.props.updateList(true)
        } else {
            message.error(res.message)
        }


    }

    updateCondition = async () => {

        let hide = message.loading('修改中');

        let { summary_time, group_end_times, group_ids, channel_ids, summary_condition_id } = await this.state;
        //console.log(summary_time, group_ids)

        let param = {
            summary_condition_id,
            search_json: {
                summary_time,
                group_end_times,
                group_ids: [],
                channel_ids: [channel_ids]
            }
        }

        let res = await UpdateConditionRequest.send(param);

        //console.log(param,)

        hide();

        if (res.code === 200) {
            message.success('修改成功！')
        } else {
            message.error('修改失败')
        }

    }


    render() {
        let { detail, teamState, group_name_list, groupList, summary_time, list_group_name, group_name } = this.state;

        //console.log(detail, 'xxx', "detail&&detail['channels'].length && detail['channels'][0]?detail['channels'][0]['name']:'全部渠道'")

        function onSearch(val) {
            //console.log('search:', val);
        }
        //console.log(this.props, summary_time)

        function handleChange(e) {
            //console.log(e,)
        }
        return (
            <div className='search'>
                {
                    detail ? (
                        <div className='search-left'>

                            <div className='flex'>
                                <div className='marginR40 flex'>
                                    <p>汇总时刻：</p>
                                    <Tooltip title='每天汇总统计的时刻，最迟为生产或配货前20分钟且与截团时间为同一天，如选择前几日的订单，请单独筛选导出。'>
                                        <ExclamationCircleOutlined style={{ fontSize: 16, color: '#EB7B1B', marginRight: 5, cursor: 'pointer' }} />
                                    </Tooltip>

                                    {/* <DatePicker onChange={(e) => { console.log(e,) }} /> */}
                                    {qs.gettoday()['month']}月{qs.gettoday()['day']}日：

                                    <TimePicker
                                        onChange={(e, t) => { this.setState({ summary_time: t }) }}
                                        defaultValue={moment(detail['search_json']['summary_time'], 'HH:mm')}
                                        format={'HH:mm'}
                                        locale={locale} />
                                </div>

                                <div className='marginR40 flex'>
                                    汇总范围：
                                    <Tooltip title='该区间截团的所有团购批次，这个是一个汇总的范围'>
                                        <ExclamationCircleOutlined style={{ fontSize: 16, color: '#EB7B1B', marginRight: 5, cursor: 'pointer' }} />
                                    </Tooltip>
                                    <TimePicker.RangePicker
                                        onChange={(e, t) => { this.setState({ group_end_times: t }) }}
                                        defaultValue={[moment(detail['search_json']['group_end_times'][0], 'HH:mm'), moment(detail['search_json']['group_end_times'][1], 'HH:mm')]}
                                        locale={locale} style={{ width: 200 }} format={'HH:mm'} />
                                </div>

                                <p>批次状态：已截团</p>
                            </div>

                            <div className='flex' style={{ marginTop: '20px' }}>
                                <div className='marginR40 flex'>
                                    团购名称：
                                    <div className='likeSearch'>
                                        <Input
                                            allowClear
                                            defaultValue={list_group_name ? list_group_name : '输入团购名称'}
                                            placeholder={list_group_name ? list_group_name : '输入团购名称'}
                                            value={this.state.group_name}
                                            onChange={e => { this.getGroupName(e.target.value); this.setState({ group_name: e.target.value }) }}></Input>
                                        {
                                            group_name_list.length ? (
                                                <ul className='search_sel'>
                                                    {
                                                        group_name_list.map((v) => {
                                                            return (
                                                                <li
                                                                    title={v['end_datetime']}
                                                                    onClick={() => {
                                                                        this.setState({ group_ids: v['id'] });
                                                                        this.setState({ group_name: v['group_name'], group_name_list: [] })
                                                                    }}>{v['group_name']} (截单时间：{v['end_datetime']})</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            ) : null
                                        }
                                    </div>
                                </div>

                                <div className='marginR40 flex'>
                                    渠道名称：
                                    <Select
                                        style={{ width: 160 }}
                                        defaultValue={!detail ? '全部渠道' : detail['id'] && detail['channels'][0] ? detail['channels'][0]['name'] : '全部渠道'}
                                        onChange={async (e) => {
                                            //console.log(e, JSON.parse(e))
                                            //this.setState({ channel_ids: e })
                                            if (e) {
                                                await this.setState({ channel_ids: JSON.parse(e)['id'] })
                                            }
                                        }}>
                                        <Option key={-1} value='{"id":"","name":"全部渠道","remark":"","tags":[],"created_at":"","updated_at":"","grouper_count":1}'>全部渠道</Option>
                                        {
                                            groupList.length ? (
                                                groupList.map(v => {
                                                    //console.log(v, )
                                                    return (
                                                        <Option value={JSON.stringify(v)}>{v['name']}</Option>
                                                    )
                                                })
                                            ) : null
                                        }
                                    </Select>
                                </div>

                                <div className='flex savePrint'>
                                    <Button onClick={() => { this.addCondition() }}>保存</Button>
                                    <Popconfirm title="确定删除吗？" onConfirm={() => { this.del() }} okText="确定" cancelText="取消">
                                        <Button className='cancel'>删除</Button>
                                    </Popconfirm>
                                </div>
                            </div>

                        </div>
                    ) : null
                }
            </div>
        )
    }
}