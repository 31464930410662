import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { Modal, DatePicker, Radio, message } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';
import '../css/print.sass';
import qs from '../../../utils/qs'
import PrintRequest from '../api/PrintRequest';


const { RangePicker } = DatePicker;


export default class Print extends Component {
    constructor(props) {
        super(props)
        this.state = {
            printData: {},
            dayFlag: 0,
            load: false,
            end_date_print: qs.getPrintDate(),
            type_text: '',
            searchJson: {},
            exportParam: {}
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        let { searchJson, exportParam } = nextProps;
        this.setState({
            printData: nextProps['printData'],
            searchJson,
            exportParam
        })
    }

    shouldComponentUpdate(e) {
        return true;
    }

    hideVisible = () => {
        let { printData } = this.state
        printData['visible'] = false
        this.setState({
            printData
        })
    }

    tabDay = (day) => {//选择日期
        let { end_date_print } = this.state
        end_date_print = qs.getPrintDate(day);

        this.setState({
            dayFlag: day,
            end_date_print
        })
    }

    nextStep = (type) => {//类型,下一步
        let { printData } = this.state
        printData['type'] = type;//print打印，，export导出
        this.setState({
            printData,
            load: true
        })
    }

    comPrint = async () => {

        let { printData, end_date_print, type_text, searchJson, exportParam } = this.state;
        if (!type_text) { return message.error('请选择类型') };


        //新导出
        // if (printData['type'] === 'export') {//export
        //     this.hideVisible();
        //     this.hideDY();
        //     return this.props.printSet({ showSet: true })
        // }
        let exportArr = []
        if (exportParam.length) {
            exportParam.map(v => {
                exportArr.push(v.id)
            })
        }
        searchJson['group_ids'] = exportArr;
        let param = {
            end_date: end_date_print,
            search_json: searchJson,
            type: printData['type']
        }

        //如果是print_ticket打印小票
        if (type_text === 'print_ticket') {
            return this.props.backTicket({ param })
        }


        let hide = message.loading('请求中');
        //console.log(param)
        let res = await PrintRequest.send(param);
        if (res.code === 200 && res.data) {
            hide();
            //message.success(res.message)

            if (param['type'] === 'export') {
                hide = message.loading('导出中')
                if (res.data[type_text].length > 5) { qs.download(res.data[type_text]) } else {
                    message.error('没有导出记录')
                }
            }

            if (param['type'] === 'print') {
                const { type_text } = this.state

                let resUrl = res.data[type_text];
                if (resUrl.length < 5 || resUrl === '') {
                    this.hideDY();
                    this.hideVisible();
                    return message.error('暂无数据')
                }
                hide = message.loading('跳转中')
                if (window.location.protocol === 'https:') {//是https的网站 跳到 https
                    let url = res.data[type_text];
                    //console.log(url,'------')
                    resUrl = url.replace(/^http:/, window.location.protocol)
                    //console.log(resUrl,'-----',url)
                }
                this.props.jump('/poll', resUrl)
                //this.props.history.push({pathname:'/poll'})
            }
        } else {
            message.error(res.message)
        }
        hide()
        //测试
        // if (param['type'] == 'export') {
        //     qs.download('https://klsfood.oss-cn-beijing.aliyuncs.com/2021-04-10测试.xlsx')
        // } else {
        //     //this.props.jump('/poll2', 'http://localhost/java.pdf')
        //     //window.location.href = 'http://localhost/java.pdf'
        // }

    }

    hideDY = () => {//隐藏打印
        this.setState({
            load: false
        })
    }

    onChange = (e) => {
        //console.log(e)
        this.setState({
            type_text: e.target.value
        })
    }

    onChangeDate = (e) => {
        this.setState({
            dayFlag: -1,
            end_date_print: qs.betweenDate(e)
        })
    }

    render() {
        let { printData, dayFlag, load } = this.state;
        function disabledDate(current) {
            // Can not select days before today and today
            return current && current < moment().endOf('day');
        }
        return (
            <>
                {
                    printData ? (
                        <Modal
                            centered
                            footer={false}
                            title={printData.title}
                            visible={printData.visible}
                            onCancel={this.hideVisible}>

                            <div className='print printBox'>
                                <div className='flex' style={({ marginBottom: '10px' })}>
                                    <label className='tipsname'>
                                        选择截单时间：
                                    </label>
                                    <ul className='flex selectDay'>
                                        <li onClick={() => this.tabDay(0)} className={dayFlag === 0 ? 'active' : ''}>今天</li>
                                        <li onClick={() => this.tabDay(-1)} className={dayFlag === -1 ? 'active' : ''}>昨天</li>
                                        <li onClick={() => this.tabDay(-2)} className={dayFlag === -2 ? 'active' : ''}>前天</li>
                                    </ul>
                                </div>
                                <div className='flex'>
                                    <label className='tipsname'></label>
                                    <div>
                                        <label style={({ display: 'block', marginBottom: '5px' })} className='tipsname'>自定义筛选时间</label>
                                        {/* <RangePicker
                                            disabledDate={disabledDate}
                                            locale={locale} /> */}
                                        <DatePicker onChange={this.onChangeDate} locale={locale} />
                                    </div>
                                </div>
                                {

                                }
                                <div>
                                    <p className='printtips'>提示：此时汇总为截单时间后30min截止</p>
                                    <div onClick={() => this.nextStep(printData.type)} className='nextstep'>下一步</div>
                                </div>
                            </div>

                        </Modal>
                    ) : null
                }

                <Modal
                    centered
                    footer={false}
                    visible={printData.type === 'print' && load}
                    title='选择打印的订单类型'
                    onCancel={this.hideDY}
                >
                    <div className='print printBox'>
                        <Radio.Group onChange={this.onChange}>
                            {/* <Radio style={({ margin: '5px 0', fontSize: '14px' })} value={1}>
                                汇总单：统计多个团购同一截单时间的商品总数和明细
                        </Radio><br /> */}
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'merchant_summary_group_batch_print_page_url'}>
                                团长对商品单：根据团长维度汇总的商品单，用于跟物流对货
                            </Radio><br />
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'group_leader_summary_group_batch_print_page_url'}>
                                团长对用户商品单：用于团长和用户核对商品信息
                            </Radio>
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'print_ticket'}>
                                打印小票
                            </Radio>
                        </Radio.Group>

                        {/* <Radio.Group onChange={this.print_ticket}>
                            <Radio style={({ margin: '5px 0', fontSize: '14px' })} value={'print_ticket'}>
                                打印小票
                            </Radio>
                        </Radio.Group> */}

                        <div style={({ height: '20px' })} />

                        <div className='flex'>
                            <div onClick={() => { this.hideDY() }} className='nextstep'>取消</div>

                            <div onClick={() => { this.comPrint() }} className='nextstep'>
                                {/* <Link to={({ pathname: 'poll'})} target="_blank">确定</Link> */}
                                确定
                            </div>
                            {/* <Link></Link> */}
                        </div>
                    </div>
                </Modal>

                <Modal
                    centered
                    footer={false}
                    visible={printData.type === 'export' && load}
                    title='选择导出的订单类型'
                    onCancel={() => this.hideDY()}
                >
                    <div className='print printBox'>
                        <Radio.Group onChange={this.onChange}>
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'merchant_summary_groupers_export_file_url'}>
                                汇总单：根据团长分拣商品
                            </Radio>
                            <br />
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'grouper_summary_users_export_file_url'}>
                                用户商品单：团长用于查看用户购买的商品信息
                            </Radio>
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'grouper_summary_users_export_file_url2'}>
                                用户商品单2：团长用于查看用户购买的商品信息
                            </Radio>
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'user_pick_tms_export_file_url'}>
                                用于导入tms模板
                            </Radio>
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'order_summary_export_url'}>
                                商品汇总（含订货单）
                            </Radio>
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'tms_pickup_station_list'}>
                                tms物流自提点汇总
                            </Radio>
                            <Radio style={({ fontSize: '14px', display: 'block' })} value={'dispatch_order_export_file_url'}>
                                导出当前批次配送单
                            </Radio>
                        </Radio.Group>

                        <div style={({ height: '20px' })} />

                        <div className='flex'>
                            <div onClick={() => { this.hideDY() }} className='nextstep'>取消</div>

                            <div onClick={() => { this.comPrint() }} className='nextstep'>
                                {/* <Link to={({ pathname: 'poll'})} target="_blank">确定</Link> */}
                                确定
                            </div>
                            {/* <Link></Link> */}
                        </div>
                    </div>
                </Modal>
            </>
        )
    }

}