import React from 'react'
import { withRouter as router } from "react-router-dom";
import Base from '../../../components/base'
import {
   Row,
   Col,
   Input,
   Button,
   Table,
   Popconfirm,
   Modal,
   message,
   Cascader,
   Upload,
   DatePicker
} from 'antd';
import '../css/discountCoupon.sass'
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import discountUrl from '../../../assets/discount.png'
import discountUrl1 from '../../../assets/discount1.png'

class DiscountCoupon extends Base {
   constructor(props) {
      super(props)
      this.state = {
         discountFlag: 0
      }
   }
   onBeginChange = (e) => {//开始时间
      //console.log(e, '开始时间')
   }
   onOverChange = (e) => {//结束时间
      //console.log(new Date(e).getDate(), '结束时间')
   }
   setDiscount = (index) => {
      this.setState({
         discountFlag: index
      })
   }

   render() {
      const columns = [
         {
            title: '优惠券名称',
            dataIndex: 'dicount_name',
            key: 'name',
         },
         {
            title: '优惠券类型',
            dataIndex: 'discount_type',
            key: 'name',
         },
         {
            title: '有效期',
            dataIndex: 'discount_time',
            key: 'name',
         },
         {
            title: '发放总量',
            dataIndex: 'discount_num',
            key: 'name',
         },
         {
            title: '操作',
            render: () => {
               return (
                  <ul className='flex contrl'>
                     <li>数据查看</li>
                     <li>编辑发放量</li>
                     <li>复制</li>
                     <li>作废</li>
                  </ul>
               )
            }
         }
      ]
      return (
         <div className='discountCoupon'>
            {/* 搜索 */}
            <div className='box'>
               <div className='flex'>
                  <div className='flex writeSearch' style={{ marginRight: '20px' }}>
                     <label>优惠券名称：</label>
                     <Input style={{ width: '160px', borderRadius: '4px', height: '30px' }} placeholder='请输入优惠券名称'></Input>
                  </div>
                  <div className='flex writeSearch' style={{ marginRight: '20px' }}>
                     <label>有效期：</label>
                     <DatePicker style={{ width: '120px', borderRadius: '4px', height: '30px' }} onChange={this.onBeginChange} placeholder='开始日期' />
                     <label style={{ margin: '0 10px' }}>至</label>
                     <DatePicker style={{ width: '120px', borderRadius: '4px', height: '30px' }} onChange={this.onOverChange} placeholder='结束日期' />
                  </div>
                  <div className='flex writeSearch'>
                     <Button className='searchBtn' type="primary"> <SearchOutlined style={{fontSize:'16px',margin: 0}} /> 搜索 </Button>
                     <label style={{ marginLeft: '20px', cursor: 'pointer' }}>清空所有搜索条件</label>
                  </div>
               </div>
            </div>

            {/* 说明 */}
            <div className='explain'>
               <div className='flex'>
                  <div className='box' style={{ width: '400px' }}>
                     <div className='tips'>场景说明：供应商想直接给团长发优惠券时，可以创建权益优惠券</div>
                     <div className='flex addDiscount'>
                        <div className='flex'>
                           <img width={39} src={discountUrl} alt="" />
                           <div className='discountTxt'>
                              <p className='title'>权益优惠券</p>
                              <p>满199减100</p>
                           </div>
                        </div>
                        <PlusCircleOutlined style={{ fontSize: '32px', color: '#366FBB' }} />
                     </div>
                  </div>

                  <div className='box' style={{ width: '400px' }}>
                     <div className='tips'>场景说明：当供应商想创建优惠券，给团长点击领取时，可以创建领取优惠券</div>
                     <div className='flex addDiscount'>
                        <div className='flex'>
                           <img width={39} src={discountUrl1} alt="" />
                           <div className='discountTxt'>
                              <p className='title'>领取优惠券</p>
                              <p>满199打7折</p>
                           </div>
                        </div>
                        <PlusCircleOutlined style={{ fontSize: '32px', color: '#366FBB' }} />
                     </div>
                  </div>
               </div>
            </div>

            { /* 切换条件 */}
            <ul className="ruleTab flex">
               <li className={this.state.discountFlag == 0 ? 'active' : ''}
                  onClick={() => this.setDiscount(0)} > 权益优惠券 </li>
               <li className={this.state.discountFlag == 1 ? 'active' : ''}
                  onClick={() => this.setDiscount(1)} > 领取优惠券 </li>
            </ul>

            {/* 表格 */}
            <Table
               columns={columns}
            ></Table>

         </div>
      )
   }

}
export default router(DiscountCoupon)