import { Request } from '@zoranwong/acc-engine.js';
export default class commanderListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null,
        page: 1
    };
    _method = 'GET';
    _uri = '/member/supplier/admin/channel/supplier-has-groupers-list2';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        if(params['search']){
            this._data['search'] = params['search'];
        }
        this._data['page'] = params['page'];
        this._data['page_size'] = params['page_size'] || 15
    }
    rules() {
    }

    messages() {
    }
}
