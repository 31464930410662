import { Request } from '@zoranwong/acc-engine.js';
export default class ChangeGrouperRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        ids: null,
        supplier_group_id: '',
        token:'',
        grouper_id:''
    };
    _method = 'POST';
    _uri = '/member/supplier/admin/channel/change-grouper-to-supplier-group';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['ids'] = params['ids'];
        this._data['grouper_id'] = params['grouper_id'];
        this._data['supplier_group_id'] = params['supplier_group_id'];
    }
    rules() {
    }

    messages() {
    }
}
