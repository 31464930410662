import { Request } from '@zoranwong/acc-engine.js';
export default class SpecificationListRequest extends Request {
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    //merchant_id: null
  };
  _method = 'GET';
  _uri = '/goods/shop-goods/specification/spec-list';
  constructor(params) {
    super(params);

    this._data['page'] = params['page'] || 1;
    this._data['page_size'] = params['page_size'] || 500;

  }
  rules() {
    return {

    };
  }

  messages() {
    return {

    };
  }
}
