import React, { Component } from 'react';
import { message, Upload } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import config from '../../configs/upload'

export default class BigImgView extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    onMouseOver = (e) => {
        //console.log(e)

    }

    onMouseOut = (e) => {
        // console.log(e)
    }

    del = (e) => {
        this.props.delComponentData(e)
    }

    beforeUpload = (e) => {
        let type = e.type.includes('image');

        return new Promise((resolve, reject) => {
            if (!type) {
                message.error('格式不正确！')
                reject(false)
            } else if (e.size > 2000000) {
                message.error('图片尺寸应小于2MB')
                reject(false)
            } else {
                message.loading('上传图片中...', 1)
                resolve(e)
            }
        })
    }

    upBigImgCg = async (e) => {//
        // const hide = message.loading('上传图片中...', 1)
        // console.log(message, '==============================')
        let res = await e.file.response;
        if (res && res.code === 200) {
            // hide();
            message.success('上传成功！')
            //console.log(e, this.props.data, res)
            this.updateComponentData(res.data.url)
        }
    }

    updateComponentData(data) {
        this.props.updateComponentData([data])
    }

    render() {
        return (
            <div className='bigimg' onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
                <CloseCircleOutlined className='closeIcon' onClick={this.del} />
                <img src={this.props.data} alt='' />
                {
                    !this.props.data.length ? (
                        <Upload
                            // beforeUpload={this.beforeUpload}
                            class="small-image-upload"
                            progress={false}
                            name='file'
                            action={`${config.apiUrl}/admin/upload/upload-image`}
                            onChange={this.upBigImgCg}
                            accept='.png,.jpg,.jpeg,.gif'
                        >
                            <div style={{ textAlign: 'center' }}>
                                <div className='addimgicon'>
                                    <PlusOutlined />
                                </div>
                                <p>选择大图</p>
                            </div>
                        </Upload>
                    ) : null
                }
            </div>
        )
    }
}