import React from 'react'
import {
    Row,
    Col,
    Input,
    Button,
    Table,
    Popconfirm,
    Modal,
    message,
    Cascader,
    Upload,
    pagination,
    Carousel
} from 'antd';
import Progress from '../components/progress'
import BeginStep from "../components/beginStep";
import MainStay from "../components/mainStay";
import NextStep from "../components/nextStep";
import Material from "../components/material";
import Enterprise from "../components/enterprise";
import Examine from "../components/examine";
import ExamineSuccess from '../components/examine_success';
import Sign from '../components/sign';

import '../css/merchant.sass'

export default class Enter extends React.Component {
    constructor(props) {
        //console.log(props,)
        super(props)
        this.state = {
            step: props.step,
            complate: props.complate,
            state: props.state,
            canNext: {
                state: true,
                msg: ''
            },
            identity: 'personal',//身份
            result: props.result,//审核结果
        }
    }

    componentWillReceiveProps(props) {
        //console.log(props,)
        this.setState({
            step: props.step,
            complate: props.complate,
            state: props.state
        })
        this.handleStep(props.step)
    }

    componentDidMount() {
        //初始位置
        let { step } = this.state
        this.handleStep(step)
    }

    //处理步骤
    handleStep = (step) => {
        this.setState({ step });
        //this.refs.Carousel.goTo(step);//去某一张
    }

    goOrback = (e) => {
        let { step } = this.state;
        step = step + e;
        this.setState({ step });
        //this.refs.Carousel.goTo(step);//去某一张
    }

    render() {

        const contentStyle = {
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center'
        };

        function onChange(e) {
            //console.log(e,)

        }

        let { step, complate, canNext, identity, result } = this.state

        return (
            <div className='merchant'>

                {
                    step < 5 ? (
                        <Progress progressParam={{ step, complate }} />
                    ) : null
                }

                <div style={{ padding: '20px 40px' }}>
                    {/* <Carousel ref='Carousel' afterChange={onChange} dots={false} adaptiveHeight={true}>
                        <div>
                            <BeginStep />
                        </div>
                        <div>
                            <MainStay changeIdentity={e => { this.setState({ identity: e }) }} canNext={e => { this.setState({ canNext: e }) }} />
                        </div>
                        <div>
                            {
                                identity === 'personal' ? (<Material />) : (<Enterprise />)
                            }
                        </div>
                        <div></div>
                        <div>
                            <ExamineSuccess result={result} />
                        </div>
                        <div>
                            <Sign />
                        </div>
                    </Carousel> */}

                    {step === 4 ? (<ExamineSuccess result={result} />) : null}
                    {step === 5 ? (<Sign />) : null}

                </div>

                <NextStep
                    result={false}
                    canNext={canNext}
                    goOrback={(e) => { this.goOrback(e) }}
                    step={e => { this.handleStep(e) }}
                    progressParam={{ step, complate }}
                />

            </div>
        )
    }
}
