import { Request } from '@zoranwong/acc-engine.js';
export default class AddConditionRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        
    };
    _method = 'POST';
    _uri = '/order/summary/merchant/add-group-order-summary-condition';
    constructor(params) {
        super(params);
        this._data['search_json'] = params['search_json'];
    }
    rules() {

    }

    messages() {

    }
}