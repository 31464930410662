import {Request} from '@zoranwong/acc-engine.js';
export default class AddStandardGoodsRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
     goods_type:'',
     //merchant_id: null,
     goods_name:'',
     sell_price:'',
     stock_num:'',
     category_ids:'',
     image_list:'',
     goods_code:'',
     market_price:'',
     goods_content:'',
     storage_type:'',
     entities: [],
     goods_image: []

   };
   _method = 'POST';
   _uri = '/goods/shop-goods/goods/goods-add';
   constructor(params) {
     super(params);
     //console.log(params.goods_content,'params.goods_content')
     this._data['goods_type'] = params.goods_type;
     //this._data['merchant_id'] = params.merchant_id;
     this._data['goods_name'] = params.goods_name;
     this._data['sell_price'] = params.sell_price;
     this._data['stock_num'] = params.stock_num;
     this._data['category_ids'] = params.category_ids;
     this._data['image_list'] = params.image_list;
     this._data['goods_code'] = params.goods_code;
     this._data['market_price'] = params.market_price;
     this._data['goods_content'] = params.goods_content;
     this._data['storage_type'] = params.storage_type;
     this._data['entities'] = params.entities;
     this._data['goods_image'] = params.goods_image;
   }
   rules(){
     return {
        
     };
   }

   messages(){
     return  {
         
    };
   }
}
