import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import {
    Row,
    Col,
    Input,
    Button,
    Table,
    Popconfirm,
    Modal,
    message,
    Cascader,
    Upload,
    pagination,
    Carousel
} from 'antd';

import qs from '../../../utils/qs'
import _ from 'lodash'
import CheckRequest from "../api/CheckRequest";
import Apply from "./apply";//申请
import Enter from "./enter";//
import '../css/merchant.sass'
import store from "../../../store";
import locale from "antd";
import html2canvas from 'html2canvas';

class Merchant extends Base {
    constructor(props) {
        super(props)
        this.state = {
            status: false,
            step: 0,
            complate: 0,
            result: false,
            state: 0,
            res:{}
        }
    }

    componentDidMount() {
        this.getMerchantState();
    }

    componentWillReceiveProps(props) {
        //console.log(props, 'propspropspropsprops')
    }

    testCanvas = async () => {//canvas截屏
        // let element = this.refs['testCanvas'];
        let element = document.body
        //console.log(this.refs, element)
        // 调用函数，取到截图的二进制数据，对图片进行处理（保存本地、展示等）
        let imgBlobData = await this.convertToImage(element);
        //console.log(imgBlobData,)
    }

    // 生成快照
    convertToImage = (container, options = {}) => {
        // 设置放大倍数
        const scale = window.devicePixelRatio;

        // 传入节点原始宽高
        const width = container.offsetWidth;
        const height = container.offsetHeight;

        // html2canvas配置项
        const ops = {
            scale,
            width,
            height,
            useCORS: true,
            allowTaint: false,
            ...options
        };

        return html2canvas(container, ops).then(canvas => {
            // 返回图片的二进制数据
            return canvas.toDataURL("image/png");
        });
    }

    getMerchantState = async () => {
        let local = await window.localStorage.getItem('userInfo')

        if (!local) return

        let param = {
            user_id: await JSON.parse(local)['customer_id']
        }

        let res = await CheckRequest.send(param);

        //console.log(res, 'getMerchantStategetMerchantState')

        if (!res || res.code !== 200) {

            return this.setState({
                status: false,
                step: 0,
                complate: 0,
                state: 0,
                result: false
            })

        }

        //apply_state
        //200 资料校验中
        //201 审核中
        //202 已驳回
        //203 已冻结
        //204 待签约
        //205 待账户验证
        //206 完成
        //207 申请失败

        //202===207前端展示相同，

        let apply_state = res.data.apply_state;

        apply_state = apply_state > 206 ? 202 : apply_state

        if (!res.status || apply_state > 206 || apply_state < 200) { }

        await store.dispatch({ type: '', state: { ...store.getState(), merchant: res.data } })

        let propsTxt = await qs.apply_state(apply_state);

        let { s, complate, state } = propsTxt

        //console.log(propsTxt,)

        await this.setState({
            status: false,
            step: s,
            complate: complate,
            state: state,
            result: true,
            res: res.data
        })

        // await this.setState({
        //     status: false,
        //     step: 0,
        //     complate: 0,
        //     state: 0,
        //     result: false
        // })

        //console.log(res,)

    }

    render() {

        let { status, step, result, complate, state, res } = this.state;

        //console.log(step, status)

        return (
            <div className='merchant' ref='testCanvas'>

                {status ? (<Enter step={step} result={result} complate={complate} state={state} />) : (<Apply step={step} complate={complate} state={state} res={res} />)}

            </div>
        )
    }
}

export default router(Merchant);