import {Request} from '@zoranwong/acc-engine.js';
export default class SpecsInfoRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
     spec_id:'',
     //merchant_id: null

   };
   _method = 'GET';
   _uri = '/goods//shop-goods/specification/spec-info';
   constructor(params) {
     super(params);
     this._data['spec_id'] = params.spec_id;
     //this._data['merchant_id'] = params.merchant_id;
   }
   rules(){
     return {
        
     };
   }

   messages(){
     return  {
         
    };
   }
}
