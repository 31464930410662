import activity from './../../../assets/notice/activity.png';
import discount from './../../../assets/notice/discount.png';
import extraction from './../../../assets/notice/extraction.png';
import get from './../../../assets/notice/get.png';
import order from './../../../assets/notice/order.png';
import pay from './../../../assets/notice/pay.png';
import pteam from './../../../assets/notice/pteam.png';
import refued from './../../../assets/notice/refued.png';
import send from './../../../assets/notice/send.png';
import React, { Component } from 'react';
import '../css/set.sass';
import { NavLink } from 'react-router-dom';
import {
    Switch, message
} from 'antd';

export default class Notice extends Component {
    constructor(props) {
        super(props)

        this.state = {
            noticeList: [
                { name: 'activity', src: activity, val: false, txt: '活动即将开始' },
                { name: 'discount', src: discount, val: false, txt: '优惠券到账' },
                { name: 'extraction', src: extraction, val: false, txt: '订单自提' },
                { name: 'get', src: get, val: false, txt: '领取成功' },
                { name: 'order', src: order, val: false, txt: '订单自提' },
                { name: 'pay', src: pay, val: false, txt: '支付成功' },
                { name: 'pteam', src: pteam, val: false, txt: '拼团成功' },
                { name: 'refued', src: refued, val: false, txt: '退款' },
                { name: 'send', src: send, val: false, txt: '发货' }
            ]
        }
    }

    switchChange = (e, index) => {
        let { noticeList } = this.state;
        noticeList[index]['val'] = e;
        if (e) {
            message.success('开启' + noticeList[index]['txt'] + '通知成功')
        } else {
            message.success('关闭' + noticeList[index]['txt'] + '通知成功')
        }
        this.setState({
            noticeList
        })
    }


    render() {
        let { noticeList } = this.state
        return (
            <ul className="set_switch">
                {
                    noticeList.map((v, index) => {
                        return (
                            <li className='flex' key={index}>
                                <img src={v['src']} alt=''/>
                                <div>
                                    <p>{v['txt']}通知</p>
                                    <NavLink to={'/set'}>查看详情</NavLink>
                                </div>
                                <Switch checked={v['val']} onChange={(e) => this.switchChange(e, index)} />
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

}



