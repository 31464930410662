import { Request } from '@zoranwong/acc-engine.js';
export default class OrderListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {

    };
    _method = 'GET';
    _uri = '/order/group-order/merchant-order/order-list';
    constructor(params) {
        super(params);
        if(params['search']){
            this._data['search'] = params['search'];
            this._data['searchFields'] = params['searchFields'];
        }
        if(params['search_json']){
            this._data['search_json'] = params['search_json'];
        }

        if(params['orderBy']){
            //this._data['orderBy'] = params['orderBy'];
        }
        
        this._data['page'] = params['page'] || 1;
        this._data['page_size'] = params['page_size'] || 20
    }
    rules() {

    }

    messages() {

    }
}