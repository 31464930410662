import { Request } from '@zoranwong/acc-engine.js';
export default class AddRemarkRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        order_id: '',
        merchant_remark:''
    };
    _method = 'POST';
    _uri = '/order/group-order/merchant-order/add-merchant-remark';
    constructor(params) {
        super(params);
        this._data['order_id'] = params['order_id'];
        this._data['merchant_remark'] = params['merchant_remark'];
    }
    rules() {

    }

    messages() {

    }
}
