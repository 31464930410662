import { Request } from '@zoranwong/acc-engine.js';
export default class groupListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null
    };
    _method = 'GET';
    _uri = '/member/supplier/admin/channel/supplier-group-list';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['page'] = params['page']?params['page']:1;
        this._data['page_size'] = params['page_size']?params['page_size']:500;
    }
    rules() {
        
    }

    messages() {
    }
}
