import {
    Row,
    Col,
    Input,
    Button,
    Table,
    Popconfirm,
    Modal,
    message,
    Cascader,
    Upload,
    pagination
} from 'antd';
import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import { NavLink } from 'react-router-dom';
import React from "react";
import '../css/probably.sass'
import GetdrawRequest from '../api/GetdrawRequest'
import UserInfoRequest from '../api/userInfoRequest'
import qs from '../../../utils/qs'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

class Probably extends Base {
    constructor(args) {
        super(args)

        const columns = [
            {
                title: '提现时间',
                dataIndex: 'created_at'
            },
            {
                title: '提现金额（元）',
                dataIndex: 'amount'
            },
            {
                title: '提现状态',
                render: (dataSource) => {
                    return (
                        <div className={dataSource['stateClass']}>
                            {dataSource['state']}
                        </div>
                    )
                }
            }
        ]


        // dataSource.map(v=>{
        //     v['stateClass'] ='state'+v['state']
        //     v['state']=v['state']?'提现成功':'提现失败'
        // })

        this.state = {
            financeWay: 1,
            columns: columns,
            dataSource: [],
            with_draw_balance: '0.00'
        }

    }

    async componentDidMount() {
        let token = qs.getQueryVariable('token') || window.localStorage.getItem('token')
        await this.getdraw();
        await this.getUser(token);//用户
    }

    changeWay = async (index) => {
        await this.setState({
            financeWay: index
        })
        this.getdraw();
    }

    getUser = async (token) => {//获取信息
        let param = {
            token: token
        }
        let res = await UserInfoRequest.send(param)

        if (res['code'] == 200) {
            //console.log(res)
            this.setState({
                with_draw_balance: res.data.with_draw_balance
            })
        } else {
            message.error(res.message)
        }
    }

    getdraw = async () => {
        const { financeWay } = this.state;
        const hide = message.loading('加载数据中')
        let param = {
            type: financeWay
        }
        let res = await GetdrawRequest.send(param);

        if (res.code == 200) {
            // message.success(res.message);

            let resdata = res.data;

            if (resdata && resdata.length) {
                resdata.map(v => {
                    v['stateClass'] = 'state' + v['state'];
                    v['stateTxt'] = v['state'] ? '提现成功' : '提现失败'
                })
            }

            this.setState({
                dataSource: res.data
            })

        } else {
            message.error(res.message)
        }

        hide();
    }

    render() {
        let { with_draw_balance, dataSource, columns } = this.state
        return (
            <div className='probably'>
                <div className='box top'>
                    <div className='donot_support'>暂不支持提现，请前往小程序</div>
                    <p className='title'>银行可提现余额（元）</p>
                    <div className='flex'>
                        <p className='bold_font'>{with_draw_balance}</p>
                        {/* <a style={{ color: '#999999' }}>资产明细>></a> */}
                        <NavLink to={'/finance/detailed'}>资产明细>></NavLink>
                    </div>
                    <p className='tips'><ExclamationCircleOutlined style={{ color: '#EB7B1B' }} /> 资金到账时间预计有1分钟延迟，请耐心等待</p>
                </div>

                <div className='flex' style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div className='box left boxLeft'>
                        {/* <div className='contrlBtn flex'>
                            <div className={this.state.financeWay == 1 ? 'btns active' : 'btns'} onClick={() => this.changeWay(1)}>提现至微信零钱</div>
                            <div className={this.state.financeWay == 2 ? 'btns active' : 'btns'} onClick={() => this.changeWay(2)}>提现至银行卡</div>
                        </div> */}

                        {dataSource && dataSource.length ? (
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                rowKey={record => record.id}
                                pagination={false}
                            ></Table>
                        ) : <p>暂无数据</p>}

                    </div>
                    <div className='box right warnTips boxRight'>
                        <p className='title'>温馨提示</p>
                        <ul className='lists'>
                            <li>1) 微信零钱提现每天限额3000，实时到账。</li>
                        </ul>
                    </div>

                </div>
                {/* <Row justify="space-between">
                    <Col span={16}>

                    </Col>
                    <Col span={7}>

                    </Col>
                </Row> */}
            </div>
        )
    }
}

export default router(Probably);