import React, { Component } from 'react';
import './sku.scss';
import { find, extend, map } from 'lodash';
import SpecBuilder from "./specBuilder";
import { SKUTool } from "./helper";
import SkuTable from "./skuTable";

class Sku extends Component {
    constructor(props) {
        super(props);
        let { changeEntities } = props;
        this.state = {
            originSpecData: this.props.specData ? this.props.specData : [],
            selectedSpecs: [],
            specEntities: this.props.entities ? this.props.entities : [],
            changeEntities: changeEntities ? changeEntities : null
        };
        //console.log('----------------------------- sku -----------------------', this.props.specEntities)
    }

    componentDidMount() {
        if (this.state.originSpecData && this.state.originSpecData.length > 0 && this.state.specEntities.length > 0) {
            this.reBuildSpecs(this.state.specEntities);
        }
    }
    componentWillUnmount() {
        this.setState({
            selectedSpecs: [],
            specEntities: [],
        });
    }
    componentWillReceiveProps(nextProps) {
        //console.log('----------------------------------------- rerender before 0 -----------------', nextProps['entities']);
        this.reBuildSpecs(nextProps['entities']);
        this.setState({
            originSpecData: nextProps['specData'],
        }, () =>{
            //console.log('----------------------------------------- rerender before -----------------', nextProps['entities'], this.state.specEntities);
            
        });
    }

    reBuildSpecs(specEntities) {
        let specs = SKUTool.spanRowsGenerator(SKUTool.rebuildSpecs(specEntities));
        this.setState({ selectedSpecs: specs });
        let entities = SKUTool.skuEntitiesGenerator(specs);
        let newList = [];
        for (const entity of entities) {
            let e = find(specEntities, (e) => {
                return (JSON.stringify(map(e['spec_values'], (specValue) => {
                    return { id: specValue.specId, valueId: specValue.specValue.id };
                })) === JSON.stringify(map(entity['spec_values'], (specValue) => {
                    return { id: specValue.specId, valueId: specValue.specValue.id };
                })));
            });
            let temp = extend({}, entity, e);
            temp['spec_values'] = entity['spec_values'];
            let old = find(this.props.selectedEntities, function(old) {
                return old['id'] === temp['id'];
            });
            if(old){
                temp['checked'] = old['checked'];
            }
            newList.push(temp);
        }
        this.setState({ specEntities: newList });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    changeSelectedSpecs(list) {
        this.setState({ selectedSpecs: list });
        let entities = SKUTool.skuEntitiesGenerator(list);
        this.setState({ specEntities: entities });
    }

    render() {
       
        let { originSpecData, selectedSpecs, specEntities } = this.state;
        //console.log('----------------------------------------- rerender running -----------------', specEntities);
        return (
            <div className="specifications">
                <SpecBuilder changeSku={(specs) => {
                    this.changeSelectedSpecs(specs);
                }} originalSpecList={originSpecData}
                    selectedSpecArray={selectedSpecs} />
                <SkuTable specs={selectedSpecs} entities={specEntities} change={(entities) => {
                     this.setState({ specEntities: entities});
                    if (this.state.changeEntities) {
                        this.state.changeEntities(entities);
                    }                      
                }} />
            </div>
        );
    }
}

export default Sku;
