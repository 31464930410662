import { Request } from '@zoranwong/acc-engine.js';
export default class VerifyRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        order_ids: [],
        user_id:''
    };
    _method = 'POST';
    _uri = '/order/group-order/user-order/verify-order';
    constructor(params) {
        super(params);
        this._data['order_ids'] = params['order_ids'];
        this._data['user_id'] = params['user_id'];
    }
    rules() {

    }

    messages() {

    }
}
