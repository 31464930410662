import React from 'react';
import { withRouter as router } from "react-router-dom";
import qs from '../../utils/qs'
import Base from "../../components/base";

class FunTest extends Base {
    constructor(props) {
        super(props)
        this.state = {
            result: ''
        }
    }


    componentDidMount() {

        this.reload()

    }

    reload = () => {

        //色彩的随机选择、、去除白色
        let single = ['#ffffff'];
        let other = qs.randomColor(single, function (params) {

            //console.log(params,)

        })

        console.log("%c%s", `color: ${other}` ,other, 'success')

    }

    render() {
        let { result } = this.state
        return (
            <>
                <div>
                    {result}
                </div>
                <button onClick={() => {
                    this.reload()
                }}>重新执行</button>
            </>
        )
    }
}

export default router(FunTest);
