import React from "react";
import calendar from '../../utils/calendar'

export default class Nongli extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            detail: {}
        }
    }

    componentDidMount() {
        let props = this.props;
        this.getDetail(props)
    }

    componentWillReceiveProps(e) {
        // console.log(this.props, e)
    }

    getDetail = (e) => {
        let detail = calendar.solar2lunar(e.data.year, e.data.month, e.data.day)
        //console.log(detail, )
        this.setState({
            detail
        })
    }

    render() {
        let { detail } = this.state
        return (

            <div
                className={detail['festival'] || detail['festivalY'] ? 'nongli show' : 'nongli'}
                title={
                    detail['Animal'] + '\n' + detail['Term'] + '\n' +
                    detail['IMonthCn'] + detail['IDayCn'] + '\n' + detail.festival
                }
            >
                <div className='content'>
                    {/* <p>{detail['Animal']}</p> */}

                    <p>{detail['festival'] ? detail['festival'] : ''}</p>
                    <p>{detail['festivalY'] ? detail['festivalY'] : ''}</p>
                    <p>{detail['Term'] ? detail['Term'] : ''}</p>
                    <p>
                        {
                            !detail['festival'] && !detail['Term'] && !detail['festivalY'] ? '农历' : ''
                        }
                    </p>
                    <p>{detail['IMonthCn']}{detail['IDayCn']}</p>
                </div>

            </div>

        )
    }
}