import {Request} from '@zoranwong/acc-engine.js';
export default class DeleteSpecificationRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
     //merchant_id: null,
     spec_ids:[]

   };
   _method = 'DELETE';
   _uri = '/goods/shop-goods/specification/spec-destroy';
   constructor(params) {
     super(params);
     //this._data['merchant_id'] = params.merchant_id;
     this._data['spec_ids'] = params.spec_ids
   }
   rules(){
     return {
        
     };
   }

   messages(){
     return  {
         
    };
   }
}
