import {Request} from '@zoranwong/acc-engine.js';
export default class StandardGoodsListRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
     search: '',
     page: 1
   };
   _method = 'GET';
   _uri = '/goods/shop-goods/goods/goods-list';
   constructor(params) {
     super(params);
     this._data['search'] = params['search'] || '';
     this._data['page'] = params['page'] || 1;
     this._data['page_size'] = params['page_size'] || 15
   }
   rules(){
     return {
        
     };
   }

   messages(){
     return  {
         
    };
   }
}
