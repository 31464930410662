import React from 'react'
import '../css/merchant.sass'
import { Button, message, Image } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import store from '../../../store';
import examinePng from '../../../assets/examine.png';

export default class Examine extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            state: props.state,
            state_txt: '资料校验中',
            askNum: {
                incoming_id: '',
                applyment_id: ''
            },
            merchant: {}
        }
    }

    componentDidMount() {
        this.getStore()
        //console.log('nnnnnnnnnnnn', this.state, store.getState())
    }



    componentWillReceiveProps() {
        let state = store.getState();
        let merchant = state.merchant ? state.merchant : null;

        if (merchant) {
            this.setState({
                state_txt: merchant.apply_desc ? merchant.apply_desc : '资料校验中',
                askNum: {
                    applyment_id: merchant['applyment_id'],
                    incoming_id: merchant['incoming_id']
                }
            })
        }
        //console.log(state, 'nnnnnnnnnnnn', this.state)
        this.getStore()
    }


    async getStore() {
        let detail = await store.getState();

        let { merchant } = detail ? detail : { ...this.state }

        merchant = merchant ? merchant : this.state.merchant

        await this.setState({
            merchant,
            state_txt: merchant.apply_desc ? merchant.apply_desc : '资料校验中',
        })

        let { applyment_id, incoming_id } = merchant;

        this.setState({
            askNum: {
                applyment_id,
                incoming_id
            }
        })

    }

    render() {
        let { state_txt, askNum, merchant } = this.state;
        return (
            <div className='examine'>
                <div className='examine_state flex'>

                    <Image src={examinePng} width={58} height={60} />

                    <div className='examine_tips'>
                        <p className='title'>{state_txt}</p>

                        <p>您的申请已经成功提交，个体户10分钟后可重新查询结果，企业商户将于1~2个工作日内处理完毕，请耐心等待，<br />
                            相关信息我们会及时通知给你，如有疑问，请咨询磁力圈客服。</p>
                    </div>

                </div>
                <div>
                    {
                        askNum['incoming_id'] ? (
                            <p>业务申请编号：<span className='themeColor'>{askNum['incoming_id']}</span></p>
                        ) : (<p>业务申请编号：<span className='themeColor'>点击刷新按钮试试</span></p>)
                    }

                    {
                        askNum['applyment_id'] ? (
                            <p>微信支付申请单号：<span className='themeColor'>{askNum['applyment_id']}</span></p>
                        ) : null
                    }

                </div>
            </div>
        )
    }
}