import { withRouter as router } from "react-router-dom"
import Base from "../../../components/base"
import { Table, Button, Input, Row, Col, Popconfirm, message, Pagination } from 'antd'
import TypeManagemen from './typeManagemen'
import ClassificationListRequest from '../api/Classification/classificationListRequest'
import DeleteClassificationRequest from '../api/Classification/deleteClassificationRequest'
import { SearchOutlined } from '@ant-design/icons';
import '../css/sortManagemen.sass'
import React from "react";
import Application from "@zoranwong/acc-engine.js";
const app = Application.getInstance();
class SortManagemen extends Base {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
      data: [],
      typeId: '',
      visible: false,
      //merchantId: 'RXZgrV',
      loading: true,
      page: 1,
      total: 0,
    }

  }

  componentDidMount() {
    this.sortmanagemenList()//ASK
  }

  sortmanagemenList = async () => {
    let {page}=this.state
    let params = {
      //merchant_id: this.state.merchantId
      page
    }
    let res = await ClassificationListRequest.send(params)
    if (res.status) {
      this.setState({ data: res.data})
    }

    this.setState({
      loading: false
    })

  }

  // 添加分类
  addType = () => {
    this.setState({ visible: true, record: {} });
  }
  // 清空条件
  clear = () => {
    this.sortmanagemenList()
  }
  search = () => {

  }
  closeNew = () => {
    this.setState({ visible: false })
  };

  // 编辑
  edit = (record) => {
    //console.log(record)
    this.setState({ visible: true, record: record })
  }

  // 删除
  delete = (record) => {
    this.setState({ typeId: record })
  }

  //分页
  pageChange = async (e) => {
    await this.setState({
      page: e
    })

    //console.log(e)

  }

  confirm = async () => {

    const hide = message.loading('加载中', 1)
    let arr = []
    let arr1 = arr.concat(this.state.typeId.id)
    let params = {
      cate_ids: arr1,
      //merchant_id: this.state.typeId.merchant_id
    }
    let res = await DeleteClassificationRequest.send(params)
    if (res.status == true) {
      message.success(res.message)
      this.sortmanagemenList()
    } else {
      message.error(res.message)
    }

    hide()
  }

  cancel = (e) => {
    message.error('取消删除喽');
  }

  reload = () => {//重新加载
    this.closeNew()
    this.sortmanagemenList()
  }



  render() {
    let { page, total } = this.state;
    const columns = [
      {
        title: '分类名称',
        dataIndex: 'cate_name'
      },
      {
        title: '创建时间',
        dataIndex: 'created_at'
      },
      {
        title: "操作",
        render: (text, record) =>
          <div>
            <span onClick={() => this.edit(record)} style={{ 'color': '#909090', 'cursor': "pointer", marginRight: '5px' }}>编辑</span>
            <Popconfirm
              title="您确定要删除吗？"
              onConfirm={this.confirm}
              onCancel={this.cancel}
              okText="确定"
              cancelText="取消"
            >
              <span onClick={() => this.delete(record)} style={{ 'color': '#909090', 'cursor': "pointer" }}>删除</span>
            </Popconfirm>
          </div>
      }
    ]
    return (
      <div className='need-margin'>

        <TypeManagemen
          reload={this.reload}
          visible={this.state.visible}
          onCancel={this.closeNew}
          record={this.state.record}
        />
        <div className='search-tit'>

          <div className='flex' style={{ justifyContent: 'space-between' }}>

            <div className='flex'>
              <div style={{ marginRight: '40px' }}>分类搜索：<Input
                style={{ width: '200px', borderRadius: '4px' }}
                value={this.state.searchValue}
                placeholder='请输入分类名称'
                onChange={(e) => { this.setState({ searchValue: e.target.value }) }}

              /></div>

              <div>
                <Button onClick={this.search} className='button-bac' icon={<SearchOutlined />}>搜索</Button>
                <span className='clear' onClick={this.clear}>清空搜索条件</span>
              </div>
            </div>

            <div>
              <Button className='button-bac' onClick={this.addType}>添加分类</Button>
            </div>

          </div>
          {/* <Row>
            <Col span={19}>
              <Row>
                <Col span={10}>

                </Col>
                <Col span={6}>

                </Col>
              </Row>
            </Col>

            <Col span={5}>

            </Col>
          </Row> */}
        </div>

        <div className='table-data'>
          <Table
            columns={columns}
            scroll={
              {
                scrollToFirstRowOnChange: false,
                x: false,
                y: 600
              }
            }
            dataSource={this.state.data}
            rowKey={record => record.id}
            loading={this.state.loading}
            pagination={false}
            rowClassName={(record, index) => {
              let className = '';
              if (index % 2) className = 'dark-row';
              return className;
            }}
          />

        </div>

        {/* <div className={'page_style'}>
          <Pagination
            onChange={async (e) => {
              await this.setState({ page: e })
              
            }}
            hideOnSinglePage={true}
            defaultCurrent={1}
            showSizeChanger={false}
            pageSize={15}
            current={page}
            total={total} />
        </div> */}

      </div>
    );
  }
}

export default router(SortManagemen);