import { Request } from '@zoranwong/acc-engine.js';
export default class TProductsRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
  _data = {
    search: '',
    //merchant_id: null,

  };
  _method = 'GET';
  _uri = '/goods/group-buy/group-template/group-template-list';
  constructor(params) {
    super(params);
    this._data['search'] = params.search;
    //this._data['merchant_id'] = params.merchant_id;
  }
  rules() {
    return {

    };
  }

  messages() {
    return {

    };
  }
}
