import { Request } from '@zoranwong/acc-engine.js';
export default class GetdetailRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token:'',
        trade_type:'',
        is_in:'',
        created_at:'',
        amount:'',
        trade_no:'',
        page: 1
    };
    _method = 'GET';
    _uri = '/member/supplier/admin/finance/get-asset-detail-list';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['is_in'] = params['is_in']; //搜索参数：资金流向（0：支出，1：收入）
        this._data['trade_type'] = params['trade_type'];//WITH_DRAW：提现，RETURN_COMMISSION：返佣，ORDER_CASHBACK：交易收入，RECHARGE：充值，PUNISH：罚款，SERVICE_FEE：技术服务费，REFUND：退款
        this._data['created_at'] = params['created_at'];//搜索参数：日期选择
        this._data['amount'] = params['amount'];//搜索参数：金额
        this._data['trade_no'] = params['trade_no'];//搜索参数：订单号
        this._data['page'] = params['page'];
    }
    rules() {
    }

    messages() {
    }
}
