import {withRouter as router} from "react-router-dom";
import Base from "../../components/base";
import React from "react";

class Logistics extends Base {
    render () {
        //console.log(this.props);
        return (<div>物流---</div>);
    }
}

export default router(Logistics);