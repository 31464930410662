import { Request } from '@zoranwong/acc-engine.js';
export default class AddSpecificationRequest extends Request {
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    spec_name: '',
    //merchant_id: null,
    spec_value: []

  };
  _method = 'POST';
  _uri = '/goods/shop-goods/specification/spec-add';
  constructor(params) {
    super(params);
    let spec_name = params.spec_name.replaceAll('[', '【')
    spec_name = params.spec_name.replaceAll(']', '】');
    this._data['spec_name'] = spec_name;
    //this._data['merchant_id'] = params.merchant_id;
    this._data['spec_value'] = params.spec_value
  }
  rules() {
    return {

    };
  }

  messages() {
    return {

    };
  }
}
