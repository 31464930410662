import React, { Component } from 'react';
import ReactDOM from 'react-dom';
//import BigImgBar from './bigImgBar';
//import BigImgView from './bigImgView';
import ComponentType from './ComponentType';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import emitter from "../../ev"

import './css/editor.sass'
// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bars: props.bars,// {title: '名称', icon:'图标', barTemplate: 'bar模板', template: '显示模板'}
            components: [],// {template: '显示模板', data: '渲染数据', type: ''}
            goodsContent: []//传来的goodsContent
        };
    }

    componentDidMount() {

    }

    addGoodsContent(arr) {//返回编辑
        let { bars, components } = this.state
        for (let i in arr) {
            let idata = Object.keys(arr[i])
            for (let k in bars) {
                if (idata[0] === bars[k]['type']) {
                    components.push({
                        template: bars[k]['template'],
                        data: Object.values(arr[i])[0],
                        type: idata[0]
                    })
                }
            }
        }
        this.setState({ components });
        //console.log(this.state.components, )
    }

    componentWillReceiveProps = (e) => {
        //console.log(e, )
        emitter.emit('edmo', e)
        let { components } = this.state
        if (components.length) return
        if (e.goodsContent && e.goodsContent.length) {
            this.addGoodsContent(e.goodsContent)
        }
    }

    shouldComponentUpdate(e,i) {
        //console.log(e,i, )
        return true
    }

    addComponent(template, data, type) {
        let components = this.state.components;
        //console.log(components, 'componentscomponentscomponents', data)
        components.push({
            template: template,
            data: data,
            type: type
        });
        // console.log(' add component -----------------', components);
        this.setState({ components: components });
        this.props.scrollBottom(true)
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const components = reorder(
            this.state.components,
            result.source.index,
            result.destination.index
        );

        this.setState({
            components
        });

        //console.log(this.state.components)
    }

    updateComponentData = async (data, index) => {
        let components = this.state.components;
        //console.log(components, 'bbbbbb')
        if (components[index]['type'] === 'image_b') {
            components[index]['data'] = data[0];
        } else {
            components[index]['data'] = data;
        }

        this.setState({
            components: components
        });

        emitter.emit('edmo', components)

        this.props.updateEditor(components)
    }

    delComponentData = (event, index, i, type) => {
        let components = this.state.components;
        //console.log(type, event, index, i, 'delComponentData', components);
        if (type && type === 'small_img') {

            components[index]['data'].splice(i, 1)

        } else {
            let component = components.splice(index, 1)
        }


        //console.log(components, component)
        this.setState({
            components: [...components]
        })

        this.props.updateEditor(components)
    }

    render() {
        return (<div className="editor">
            <div className="components-view">

                {
                    !this.state.components.length ? (
                        <div style={{ height: '350px' }}></div>
                    ) : null
                }

                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                //provided.droppableProps应用的相同元素.
                                {...provided.droppableProps}
                                // 为了使 droppable 能够正常工作必须 绑定到最高可能的DOM节点中provided.innerRef.
                                ref={provided.innerRef}
                            >
                                {this.state.components.map((item, index) => {
                                    return (
                                        <Draggable droppableId="droppable" key={index} draggableId={'born' + index} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    id={'born' + index}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {React.createElement(item.template, {
                                                        data: item.data, key: index, updateComponentData: (data) => {
                                                            this.updateComponentData(data, index);
                                                        }, delComponentData: (event, i, type) => {
                                                            this.delComponentData(event, index, i, type);
                                                        }
                                                    })}
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}

                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {/* {
                    this.state.components.map((item, index) => {
                        const component = item.template;

                        //console.log(item)
                        return React.createElement(component, { data: item.data, key: index });
                    })
                } */}
            </div>
            <div className="opt-bar flex">
                {this.state.bars.map((element, index) => {
                    const component = element.barTemplate;
                    return React.createElement(component, {
                        title: element.title, key: index, addComponent: (data) => {
                            this.addComponent(element.template, data, element.type);
                        },
                        data: element.data
                    });

                })}
            </div>
        </div>);
    }
}