import React from 'react'
import '../css/progress.sass'
import { CheckOutlined } from '@ant-design/icons';

export default class Progress extends React.Component {
    constructor(props) {
        super(props)
        //console.log(props,)
        this.state = {
            step: props.progressParam['step'],//进行的步骤
            complate: props.progressParam['complate'],//完成的步骤
        }
    }

    componentDidMount = () => {

    }

    componentWillReceiveProps(e) {
        let { progressParam } = e;
        this.setState({
            step: progressParam.step,
            complate: progressParam.step - 1
        })

    }

    //处理步骤展示
    showStep = () => {

    }


    render() {
        let { step, complate } = this.state
        return (
            <ul className='progress flex'>
                <li className={step >= 1 ? 'flex afterLine active' : 'flex afterLine'}>
                    <p className='circular'>
                        {
                            complate < 1 ? 1 : (
                                <CheckOutlined />
                            )
                        }
                    </p>
                    <p className=''>选择入驻主体类型</p>
                </li>
                <li className={step >= 2 ? 'flex afterLine active' : 'flex afterLine'}>
                    <p className='circular'>
                        {
                            complate < 2 ? 2 : (
                                <CheckOutlined />
                            )
                        }
                    </p>
                    <p className=''>填写资料</p>
                </li>
                <li className={step >= 3 ? 'flex active' : 'flex'}>
                    <p className='circular'>
                        {
                            complate < 3 ? 3 : (
                                <CheckOutlined />
                            )
                        }
                    </p>
                    <p>审核结果</p>
                </li>
            </ul>
        )
    }
}