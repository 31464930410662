import Layout from '../components/layout';
import Set from '../pages/set';
const sider = [];
const SetRoute = {
    path: "/set",
    component: Layout,
    sider: sider,
    routes:[
        {
            path: "/set",
            exact: false,
            name: 'set',
            component: Set
        }
    ]
};

export default SetRoute;