import React from 'react'
import { Radio, Checkbox, Input, DatePicker, Upload, message, Button, Select, Cascader } from 'antd'

import moment from 'moment';
import '../css/merchant.sass'
import { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import config from '../../../configs/upload';
import qs from '../../../utils/qs'

import html2canvas from 'html2canvas';
import MerchantRequest from '../api/MerchantRequest'
import locale from 'antd/es/date-picker/locale/zh_CN';
import Tips from './tips';
import region from '../../../utils/region.json';
import store from '../../../store/index.js'
//import { format } from '_echarts@5.2.2@echarts/core';

const { RangePicker } = DatePicker;
const { Option } = Select;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export default class Material extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            next60: this.next60Day(),//后60天，身份证选用
            canNext: {},
            next: false,
            shopUrl: 'https://merchant-admin.cili-app.com/',//店铺地址
            noshopUrl: false,
            fontImg: '',//人像照
            nofontImg: false,
            backImg: '',//国徽照
            nobackImg: false,
            merchant_shortname: '',//简称
            nomerchant_shortname: false,
            realName: '',//
            norealname: false,
            mailbox: '',//邮箱
            nomailbox: false,
            mobile: '',//手机号
            nomobile: false,
            idnum: '',//身份证号
            noidnum: false,
            idislongVal: 1,//非长期身份证
            notlongdate: this.next60Day(),//初始非长期时间
            bank_address_code: [],//开户地
            nobank_address_code: false,
            Sub_branch: '',//开户行
            account_number: '',//银行账号
            noaccount_number: false,
            companyName: '', //商户/公司名称
            nocompanyName: false,
            manager: '',//经营者
            nomanager: false,//
            regnum: '',//注册号
            noregnum: false,
            business_license: '',//营业执照
            nobusiness_license: false,
            bank_account_type: '74',//账户类型
            nobank_account_type: false,
            need_account_info: false,//是否填写结算银行账户
            open_account_name: '',//开户名称
            noopen_account_name: false,//
            business_license_info: {},//营业执照/登记证书信息
            id_doc_type: 'IDENTIFICATION_TYPE_MAINLAND_IDCARD',//经营者/法人证件类型
            id_card_info: {},//经营者/法人身份证信息
            contact_info: {},//超级管理员信息
            sales_scene_info: {},//店铺信息
            account_info: {},//结算银行账户
            noSub_branch: false,
            bankList: qs.allBankList(),//支持的银行
            otherBank: false,//其他银行
            bank_name: '',//其他银行全称
            materialType: 4,//类型： 个体 、、2，企业
            contact_name: '',//超管姓名
            contact_id_card_number: '',//超管证件号
            mobile_phone: '',//超管手机号
            contact_email: '',//超管邮箱
            nocontact_name: false,
            nocontact_id_card_number: false,
            nomobile_phone: false,
            nocontact_email: false,
            whereStep: 4,
            localMerchant: {}

        }
    }

    componentDidMount() {
        // this.props.canNext({
        //     state: false,
        //     msg: '请将资料填写完整'
        // })
        (async () => {
            let { organization_type } = await store.getState();
            this.getLocalStore();
            console.log(organization_type, 'organization_type__')
            this.setState({
                materialType: organization_type
            })
        })()

        //this.next60Day();
    }

    componentWillReceiveProps(props) {
        //console.log(props,)


    }

    // Converts image to canvas; returns new canvas element
    convertImageToCanvas = async (dom) => {
        await dom;
        html2canvas(dom).then(function (canvas) {
            //将canvas转化成base64图片
            var tempSrc = canvas.toDataURL("image/png");
            if (tempSrc) {
                window.localStorage.setItem('merchantData', tempSrc)
            }
            return tempSrc;
        });
    }


    getLocalStore = async () => {
        let merchant = await window.localStorage.getItem('merchant');
        merchant = JSON.parse(merchant);
        let { materials } = merchant || {}
        if (materials) {

            let { fontImg,//人像照
                backImg,//国徽照
                merchant_shortname,//简称
                realName,//
                mailbox,//邮箱
                mobile,//手机号
                idnum,//身份证号
                idislongVal,//非长期身份证
                notlongdate,//初始非长期时间
                bank_address_code,//开户地
                Sub_branch,//开户行
                account_number,//银行账号
                companyName, //商户/公司名称
                manager,//经营者
                regnum,//注册号
                business_license,//营业执照
                bank_account_type,//账户类型
                need_account_info,//是否填写结算银行账户
                open_account_name,//开户名称
                business_license_info,//营业执照/登记证书信息
                id_doc_type,//经营者/法人证件类型
                id_card_info,//经营者/法人身份证信息
                contact_info,//超级管理员信息
                sales_scene_info,//店铺信息
                account_info,//结算银行账户
                bankList,//支持的银行
                otherBank,//其他银行
                bank_name,//其他银行全称
                materialType,//类型： 个体 、、2，企业
                contact_name,//超管姓名
                contact_id_card_number,//超管证件号
                mobile_phone,//超管手机号
                contact_email,//超管邮箱}
            }
                = this.state

            this.setState({
                fontImg: materials['id_card_info']['id_card_copy'],
                backImg: materials['id_card_info']['id_card_national'],
                idnum: materials['id_card_info']['id_card_number'],
                notlongdate: materials['id_card_info']['id_card_valid_time'],
                merchant_shortname: materials['merchant_shortname'],
                companyName: materials['business_license_info']['merchant_name'],
                manager: materials['business_license_info']['legal_person'],
                regnum: materials['business_license_info']['business_license_number'],
                business_license: materials['business_license_info']['business_license_copy'],
                realName: materials['id_card_info']['id_card_name'],
                mailbox: materials['contact_info']['contact_email'],
                contact_name: materials['contact_info']['contact_name'],
                contact_id_card_number: materials['contact_info']['contact_id_card_number'],
                mobile_phone: materials['contact_info']['mobile_phone'],
                contact_email: materials['contact_info']['contact_email'],
                account_number: materials['account_info']['account_number'],
                Sub_branch: materials['account_info']['account_bank'],
                open_account_name: materials['account_info']['account_name'],
                mobile: materials['contact_info']['mobile_phone'],
                bank_address_code: materials['account_info']['bank_address_code']


            })

        }

    }


    checkFrom = async (state = false) => {

        let { nobackImg, nofontImg, noidnum, nomailbox, nomobile,
            nomerchant_shortname, norealname, backImg, fontImg, idnum, mailbox, mobile,
            merchant_shortname, realName, companyName, nocompanyName, manager,
            nomanager, regnum, noregnum, business_license, nobusiness_license, account_number, noaccount_number,
            need_account_info, open_account_name, noopen_account_name, nocontact_name, contact_name, nocontact_id_card_number,
            contact_id_card_number, nomobile_phone, mobile_phone, nocontact_email, contact_email, materialType
        } = await this.state;

        //regnum 15/18位

        if (regnum.length == 15 || regnum.length == 18) {

        } else {
            regnum = ''
            this.setState({
                regnum
            })
        }

        //步骤 1
        //if (merchant_shortname && realName && mailbox && mobile && idnum) { this.setState({ whereStep: 2 }) }

        nobackImg = backImg ? false : true
        nofontImg = fontImg ? false : true
        noidnum = idnum ? false : true
        nomailbox = mailbox ? false : true
        nomobile = mobile ? false : true
        nomerchant_shortname = merchant_shortname ? false : true
        norealname = realName ? false : true
        nocompanyName = companyName ? false : true
        nomanager = manager ? false : true
        noregnum = regnum ? false : true
        nobusiness_license = business_license ? false : true
        noaccount_number = account_number ? false : true
        noopen_account_name = open_account_name ? false : true
        nocontact_name = contact_name ? false : true
        nocontact_id_card_number = contact_id_card_number ? false : true
        nomobile_phone = mobile_phone ? false : true
        nocontact_email = contact_email ? false : true

        await this.setState({
            nobackImg,
            nofontImg,
            noidnum,
            nomailbox,
            nomobile,
            nomerchant_shortname,
            norealname,
            nocompanyName,
            nomanager,
            noregnum,
            nobusiness_license,
            noaccount_number,
            noopen_account_name,
            nocontact_name,
            nocontact_id_card_number,
            nomobile_phone,
            nocontact_email
        })
        let checkArr = [nobackImg, nofontImg, noidnum,
            nomailbox, nomobile, nomerchant_shortname,
            norealname, nocompanyName, nomanager, noregnum,
            nobusiness_license, noaccount_number,
            noopen_account_name]

        if (materialType === '2') {//企业
            checkArr.concat([
                nocontact_name,
                nocontact_id_card_number,
                nomobile_phone,
                nocontact_email
            ])
        }
        if (checkArr.includes(true)) {
            this.setState({
                canNext: {
                    state: false,
                    msg: '请将资料填写完整'
                }
            })
        } else {
            this.setState({
                canNext: {
                    state: true,
                    msg: ''
                }
            })
        }
    }

    idislong = async (e) => {//长期身份证
        await e;
        this.setState({
            idislongVal: e.target.value
        })
        if (e.target.value === 2) {
            await this.setState({
                notlongdate: '长期'
            })
        }
    }

    jumpFixMobile = () => {//修改手机指引

        window.open("https://kf.qq.com/faq/200703rEFN3U200703zeaeQN.html");

    }

    notlongdateval = async (e) => {//非长期值

        //console.log(e, e[0].format('l'))
        await this.setState({
            notlongdate: e ? (e.format('L')).replace(/\//g, "-") : e
        })
        //console.log(this.state.notlongdate,)
    }

    stepCtrl = async (param) => {//步骤前进后退
        //await this.convertImageToCanvas(this.refs['material']);
        await this.checkFrom(true)
        let { canNext } = this.state;
        //console.log(canNext, param)
        //准备去下一步
        //////this.props.prepare(true)////
        if (!canNext['state'] && param > 0) return message.error(canNext['msg'])

        if (param < 0) return this.props.goOrback(param)

        //参数传递
        let {
            shopUrl,
            next,
            fontImg,
            nofontImg,
            backImg,
            nobackImg,
            merchant_shortname,
            nomerchant_shortname,
            realName,
            norealname,
            mailbox,
            nomailbox,
            mobile,
            nomobile,
            idnum,
            noidnum,
            notlongdate,
            Sub_branch,
            bank_address_code,
            account_number,
            bank_account_type,
            open_account_name,
            business_license_info,
            business_license,
            regnum,
            companyName,
            manager,
            id_doc_type,
            id_card_info,
            contact_info,
            sales_scene_info,
            account_info,
            bankList,
            otherBank,
            bank_name,
            materialType,
            contact_name,
            contact_id_card_number,
            mobile_phone,
            contact_email

        } = await this.state

        if (Array.isArray(bank_address_code)) {
            bank_address_code = bank_address_code.length > 1 ? bank_address_code[1] : []
        }


        business_license_info = {
            business_license_copy: business_license,
            business_license_number: regnum.toString(),
            merchant_name: companyName,
            legal_person: manager
        }

        id_card_info = {
            id_card_copy: fontImg,
            id_card_national: backImg,
            id_card_name: realName,
            id_card_number: idnum.toString(),
            id_card_valid_time: notlongdate.toString()
        }

        contact_info = {
            contact_type: '65',
            contact_name: realName,
            contact_id_card_number: idnum.toString(),
            mobile_phone: mobile.toString(),
            contact_email: mailbox.toString()
        }

        sales_scene_info = {
            store_name: companyName,
            store_url: shopUrl,

        }

        account_info = {
            bank_account_type: bank_account_type.toString(),
            account_name: open_account_name,
            bank_address_code: bank_address_code.toString(),
            account_number: account_number.toString(),
            account_bank: Sub_branch
        }


        //企业填写超级管理员
        if (materialType === '2') {
            contact_info = {
                contact_type: '66',
                contact_name,
                contact_id_card_number: contact_id_card_number.toString(),
                mobile_phone: mobile_phone.toString(),
                contact_email: contact_email.toString(),
            }

        }

        let merchantParam = {
            merchant_shortname,
            business_license_info,
            id_doc_type,
            id_card_info,
            contact_info,
            sales_scene_info
        }

        //如果是支行，非支持17家银行

        // if (!bankList.includes(Sub_branch)) {
        //     delete account_info.account_bank;
        //     account_info['bank_name'] = Sub_branch;
        // }
        if (otherBank) {
            account_info['bank_name'] = bank_name
            if (!bank_name) {
                return message.error('请将其他银行名称填写完整！', 1)
            }
        }

        //need_account_info 是否填写结算银行账户
        let need_account_info = await [bank_account_type, open_account_name, bank_address_code, account_number];
        //console.log(need_account_info, 'need_account_info1')
        need_account_info = need_account_info.find(x => x.length > 0)//有一处填写===>true

        await this.setState({
            need_account_info
        })

        merchantParam['need_account_info'] = need_account_info ? true : false

        let noneed_account_info = await [bank_account_type, open_account_name, bank_address_code, account_number];//没有填写完整

        noneed_account_info = noneed_account_info.find(x => !x.length)

        if (need_account_info) { merchantParam = { ...merchantParam, account_info } } else {
            if (merchantParam['account_info']) {
                delete merchantParam['account_info']
            }
        }

        if (need_account_info && noneed_account_info !== undefined) return message.error('请将结算账户填写完整')

        //await store.dispatch({ type: '', state: { ...store.getState(), ...merchantParam } })

        let { organization_type } = await store.getState();

        let materials = { organization_type, ...merchantParam };

        let user_id = await JSON.parse(window.localStorage.getItem('userInfo'))['customer_id']

        //console.log(materials,)

        let resParam = {
            user_id,
            materials
        }

        //console.log(resParam, 'resParamresParamresParamresParam')
        //本地保存 
        window.localStorage.setItem('merchant', JSON.stringify(resParam));
        message.loading('资料上传中...', 1)
        await this.convertImageToCanvas(this.refs['material']);

        //return;

        let res = await MerchantRequest.send(resParam);

        if (res && res.code === 200) {
            message.success('上传成功！')
            await store.dispatch({ type: '', state: { ...store.getState(), merchant: res.data } })
        } else return message.error(res.message)


        //console.log(res, '------------------------')

        this.props.goOrback(param)
    }

    //账户类型
    bank_account_type = async (e) => {
        await this.setState({
            bank_account_type: e,
            nobank_account_type: e ? false : true
        })
    }

    //赋值银行名称
    setBankName = (data) => {
        this.setState({
            Sub_branch: data.val,
            otherBank: !data.id ? true : false
        })
    }

    next60Day = () => {

        let now = qs.gettoday();
        let nowDay = `${now.year}-${now.month}-${now.day}`
        //console.log(now, nowDay)

        let next = qs.nextDate(nowDay, 60);
        console.log(next, )

        return next;

    }

    render() {
        const fontprops = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            accept: '.png,.jpg,.jpeg',
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: (file) => {
                //console.log(file, 'beforeUpload-------', file.size)
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('请上传小于2MB的图片！')
                    return false;
                }
                message.loading('图片上传中')
            },
            onChange: (info) => {
                console.log(info)
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success('图片上传成功！')
                    getBase64(info.file.originFileObj, async (fontImg) => {
                        await fontImg
                        await this.setState({
                            fontImg: info.file['response']['data']['url'],
                            nofontImg: false
                        })
                    }
                    );
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        const backprops = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            accept: '.png,.jpg,.jpeg',
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: (file) => {
                //console.log(file, 'beforeUpload-------', file.size)
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('请上传小于2MB的图片！')
                    return false;
                }
                message.loading('图片上传中')
            },
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success('图片上传成功！')
                    getBase64(info.file.originFileObj, async (backImg) => {
                        await backImg;
                        await this.setState({
                            backImg: info.file['response']['data']['url'],
                            nobackImg: false
                        })
                    }
                    );
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        const business_license_props = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            accept: '.png,.jpg,.jpeg',
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: (file) => {
                //console.log(file, 'beforeUpload-------', file.size)
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('请上传小于2MB的图片！')
                    return false;
                }
                message.loading('图片上传中')
            },
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success('图片上传成功！')
                    getBase64(info.file.originFileObj, async (business_license) => {
                        await business_license;
                        await this.setState({
                            business_license: info.file['response']['data']['url'],
                            nobusiness_license_props: false
                        })
                    }
                    );
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        function onChange(params) {

        }
        function handleChange(params) {

        }

        let {
            next,
            fontImg,
            nofontImg,
            backImg,
            nobackImg,
            merchant_shortname,
            nomerchant_shortname,
            realName,
            norealname,
            notlongdate,
            mailbox,
            nomailbox,
            mobile,
            nomobile,
            idnum,
            noidnum,
            idislongVal,
            Sub_branch,
            bank_address_code,
            account_number,
            noshopUrl,
            shopUrl,
            companyName,
            nocompanyName,
            manager,
            nomanager,
            regnum,
            noregnum,
            business_license,
            nobusiness_license,
            open_account_name,
            noaccount_number,
            need_account_info,
            noopen_account_name,
            bank_account_type,
            nobank_account_type,
            noSub_branch,
            next60,
            bankList,
            otherBank,
            materialType,
            contact_name,
            contact_id_card_number,
            mobile_phone,
            contact_email,
            nocontact_name,
            nocontact_id_card_number,
            nomobile_phone,
            nocontact_email,
            whereStep


        } = this.state

        const dateFormat = 'YYYY/MM/DD';

        return (
            <div className='material' ref='material'>
                <div className='beginStep'>

                    {
                        whereStep > 0 ? (
                            <>

                                <p className='title'>商户基本信息</p>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>商户简称：</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ nomerchant_shortname: merchant_shortname.length ? false : true }); }}
                                        onInput={async (e) => { await this.setState({ merchant_shortname: e.target.value }) }}
                                        value={merchant_shortname} style={{ width: 240 }}
                                        placeholder='请输入商户简称(最多21字)'
                                        maxLength={21} />
                                    <p className='explain' style={{ marginLeft: 20 }}>示例值：高新区美味团、优品店</p>
                                    <Tips ctx={{ ctx: '请输入商户简称', type: (!merchant_shortname.length && nomerchant_shortname) ? 'error' : 'none' }} />
                                </div>

                                {/* <div className='inputBox flex'>
                        <p className='inputTitle'>店铺链接：</p>
                        <Input
                            onBlur={async () => { await this.setState({ noshopUrl: shopUrl.length ? false : true }); this.checkFrom() }}
                            onChange={async (e) => { await this.setState({ shopUrl: e.target.value }) }}
                            value={shopUrl} style={{ width: 240 }}
                            placeholder='请输入店铺链接'
                            maxLength={100} />
                        <p className='explain' style={{ marginLeft: 20 }}>示例值：http://www.qq.com</p>
                        <Tips ctx={{ ctx: '请输入店铺链接', type: (!shopUrl.length && noshopUrl) ? 'error' : 'none' }} />
                    </div> */}


                                <p className='title'>经营者基本信息</p>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>姓名:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ norealname: realName.length ? false : true }); }}
                                        onInput={async (e) => { await this.setState({ realName: e.target.value }) }}
                                        value={realName}
                                        style={{ width: 240 }} placeholder='请输入经营者/法定代表人姓名' maxLength={10} />
                                    <Tips ctx={{ ctx: '请输入经营者/法定代表人姓名', type: (!realName.length && norealname) ? 'error' : 'none' }} />
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>邮箱:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ nomailbox: mailbox.length ? false : true }); }}
                                        onInput={async (e) => { await this.setState({ mailbox: e.target.value }) }}
                                        value={mailbox}
                                        style={{ width: 240 }} placeholder='请填写邮箱号码' maxLength={30} />
                                    <Tips ctx={{ ctx: '请填写邮箱号码', type: (!mailbox.length && nomailbox) ? 'error' : 'none' }} />
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>手机号:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ nomobile: mobile.length ? false : true }); }}
                                        onInput={async (e) => { await this.setState({ mobile: e.target.value }) }}
                                        value={mobile}
                                        style={{ width: 240 }} placeholder='请填写手机号码' maxLength={11} />
                                    <Tips ctx={{ ctx: '请填写手机号码', type: (!mobile.length && nomobile) ? 'error' : 'none' }} />
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>身份证号:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ noidnum: idnum.length ? false : true }); }}
                                        onInput={async (e) => { await this.setState({ idnum: e.target.value }) }}
                                        value={idnum}
                                        style={{ width: 240 }} placeholder='请填写身份证号码' maxLength={18} />
                                    <Tips ctx={{ ctx: '请填写身份证号码', type: (!idnum.length && noidnum) ? 'error' : 'none' }} />
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>身份证有效期:</p>
                                    <Radio.Group onChange={(e) => this.idislong(e)} defaultValue={idislongVal}>
                                        <Radio value={1}>非长期</Radio>
                                        <Radio value={2}>长期</Radio>
                                    </Radio.Group>
                                </div>

                                {
                                    idislongVal < 2 ? (
                                        <>
                                            <div className='flex'>
                                                <p className='inputTitle'></p>
                                                <DatePicker defaultValue={moment(notlongdate, dateFormat)} locale={locale} onChange={(e) => { this.notlongdateval(e) }} />
                                            </div>

                                            <div className='flex'>
                                                <p className='inputTitle'></p>
                                                <p className='warn'>请确保上方期限与您 身份证背面 的有效期限一致, 注：请选择 结束日期！</p>
                                            </div>
                                            <div className='flex'>
                                                <p className='inputTitle'></p>
                                                <p className='warn'>身份证有效期需大于60天</p>
                                            </div>
                                        </>

                                    ) : null
                                }

                                <div className='inputBox flex uploadsId'>
                                    <p className='inputTitle'>身份证件图片:</p>

                                    <div className='identity flex'>
                                        <div className='identityPhoto font'>
                                            {
                                                !fontImg ? (<p className='photoTips'>身份证正面照</p>) : null
                                            }
                                            <div className='uploads'>
                                                <Upload {...fontprops}>
                                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                                </Upload>
                                            </div>
                                            {fontImg ? (
                                                <img src={fontImg} alt='' />
                                            ) : null}

                                        </div>
                                        <div className='identityPhoto back'>
                                            {!backImg ? (<p className='photoTips'>身份证反面照</p>) : null}

                                            <div className='uploads'>
                                                <Upload {...backprops}>
                                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                                </Upload>
                                            </div>
                                            {backImg ? (<img src={backImg} alt='' />) : null}

                                        </div>
                                    </div>

                                </div>
                                <div className='flex'>
                                    <p style={{ width: 148, marginRight: 20 }}>
                                        <Tips ctx={{ ctx: '请上传身份证正面', type: (!fontImg.length && nofontImg) ? 'error' : 'none' }} />
                                    </p>

                                    <p style={{ width: 148 }}>
                                        <Tips ctx={{ ctx: '请上传身份证反面', type: (!backImg.length && nobackImg) ? 'error' : 'none' }} />
                                    </p>
                                </div>
                            </>

                        ) : null
                    }



                    {/* 入驻商户证件信息 */}

                    {
                        whereStep > 1 ? (
                            <>
                                <p className='title'>入驻商户证件信息</p>
                                <div className='inputBox flex'>
                                    <p className='inputTitle'>商户/公司名称:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ nocompanyName: companyName.length ? false : true }); }}
                                        value={companyName}
                                        onInput={async (e) => { await this.setState({ companyName: e.target.value }) }}
                                        style={{ width: 240 }} placeholder='请填写商户/公司名称' />

                                    <p className='explain' style={{ marginLeft: 20 }}>示例值：腾讯科技有限公司 </p>
                                    <Tips ctx={{ ctx: '请输入商户/公司名称', type: (!companyName.length && nocompanyName) ? 'error' : 'none' }} />
                                </div>
                                <div className='inputBox flex'>
                                    <p className='inputTitle'></p>

                                    <div>
                                        <p className='explain'>
                                            1.个体户：个体户的商户名称不能以“公司”结尾；
                                        </p>
                                        <p className='explain'>
                                            2.个体户：若营业执照上商户名称为空或为“无”，请填写【个体户+经营者姓名】，如“个体户小明”。
                                        </p>
                                    </div>
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>经营者/法定代表人姓名:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ nomanager: manager.length ? false : true }); }}
                                        value={manager}
                                        onInput={async (e) => { await this.setState({ manager: e.target.value }) }}
                                        style={{ width: 240 }} placeholder='请填写经营者/法定代表人姓名' />

                                    <p className='explain' style={{ marginLeft: 20 }}>示例值：张三 </p>
                                    <Tips ctx={{ ctx: '请输入经营者/法定代表人姓名', type: (!manager.length && nomanager) ? 'error' : 'none' }} />
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>注册号/统一社会信用代码：</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ noregnum: regnum.length ? false : true }); }}
                                        onInput={async (e) => { await this.setState({ regnum: e.target.value }) }}
                                        value={regnum}
                                        style={{ width: 240 }}
                                        placeholder='请填写注册号/统一社会信用代码' />
                                    <p className='explain' style={{ marginLeft: 20 }}>请填写营业执照上的注册号/统一社会信用代码。(15/18)位</p>
                                    {/* <Tips ctx={{ ctx: '请填写注册号/统一社会信用代码', type: (!regnum.length && noregnum) ? 'error' : 'none' }} /> */}
                                    {
                                        noregnum ? (
                                            <Tips ctx={{ ctx: '注册号(15位)/统一社会信用代码(18位)', type: (regnum.length != 15 || regnum.length != 18) ? 'error' : 'none' }} />
                                        ) : null
                                    }
                                </div>

                                <div className='inputBox flex uploadsId'>
                                    <p className='inputTitle'>营业执照：</p>
                                    <div className='identity flex'>
                                        <div className='identityPhoto font'>
                                            {
                                                !business_license ? (<p className='photoTips'>添加照片</p>) : null
                                            }
                                            <div className='uploads'>
                                                <Upload {...business_license_props}>
                                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                                </Upload>
                                            </div>
                                            {business_license ? (
                                                <img src={business_license} alt='' />
                                            ) : null}

                                        </div>

                                        <p style={{ width: 148 }}>
                                            <Tips ctx={{ ctx: '请上传营业执照', type: (!business_license.length && nobusiness_license) ? 'error' : 'none' }} />
                                        </p>
                                    </div>
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'></p>

                                    <div>
                                        <p className='explain'>
                                            1.请上传证件的彩色扫描件或彩色数码拍摄件，黑白复印件需加盖公章（公章信息需完整）
                                        </p>
                                        <p className='explain'>
                                            2.不得添加无关水印（非微信支付商户申请用途的其它水印）
                                        </p>
                                        <p className='explain'>
                                            3.需提供证件的正面拍摄件，完整、照片信息清晰可见。信息不清晰、扭曲、压缩变形、反光、不完整均不接受
                                        </p>
                                        <p className='explain'>
                                            4.不接受二次裁剪、翻拍、PS的证件照片
                                        </p>

                                    </div>
                                </div>

                            </>
                        ) : null
                    }





                    {/* 超级管理员 */}
                    {
                        whereStep > 2 ? (
                            <>
                                {
                                    materialType === '2' ? (
                                        <>
                                            <p className='title'>超级管理员</p>

                                            <div className='inputBox flex'>
                                                <p className='inputTitle'>超管姓名:</p>
                                                <Input
                                                    onBlur={async () => { await this.setState({ nocontact_name: contact_name.length ? false : true }); }}
                                                    value={contact_name}
                                                    onInput={async (e) => { await this.setState({ contact_name: e.target.value }) }}
                                                    style={{ width: 240 }} placeholder='请填写超级管理员姓名' maxLength={20} />
                                                <Tips ctx={{ ctx: '请填写超级管理员姓名', type: (!contact_name.length && nocontact_name) ? 'error' : 'none' }} />
                                            </div>

                                            <div className='inputBox flex'>
                                                <p className='inputTitle'>证件号码:</p>
                                                <Input
                                                    onBlur={async () => { await this.setState({ nocontact_id_card_number: contact_id_card_number.length ? false : true }); }}
                                                    value={contact_id_card_number}
                                                    onInput={async (e) => { await this.setState({ contact_id_card_number: e.target.value }) }}
                                                    style={{ width: 240 }} placeholder='请填写证件号码' maxLength={20} />
                                                <Tips ctx={{ ctx: '请填写证件号码', type: (!contact_id_card_number.length && nocontact_id_card_number) ? 'error' : 'none' }} />
                                            </div>

                                            <div className='inputBox flex'>
                                                <p className='inputTitle'></p>

                                                <div>
                                                    <p className='explain'>
                                                        请填写 身份证、来往内地通行证、来往大陆通行证、护照 等证件号码
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='inputBox flex'>
                                                <p className='inputTitle'>手机号码:</p>
                                                <Input
                                                    onBlur={async () => { await this.setState({ nomobile_phone: open_account_name.length ? false : true }); }}
                                                    value={mobile_phone}
                                                    onInput={async (e) => { await this.setState({ mobile_phone: e.target.value }) }}
                                                    style={{ width: 240 }} placeholder='请填写手机号码' maxLength={20} />
                                                <Tips ctx={{ ctx: '请填写手机号码', type: (!mobile_phone.length && nomobile_phone) ? 'error' : 'none' }} />
                                            </div>
                                            <div className='inputBox flex'>
                                                <p className='inputTitle'></p>

                                                <div>
                                                    <p className='explain'>
                                                        用于接收微信支付的重要管理信息及日常操作验证码，超管签约时可自助修改手机号，并进行短信验证
                                                        <span onClick={() => { this.jumpFixMobile() }} className='themeColor'>超管修改手机号指引</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='inputBox flex'>
                                                <p className='inputTitle'>邮箱:</p>
                                                <Input
                                                    onBlur={async () => { await this.setState({ nocontact_email: contact_email.length ? false : true }); }}
                                                    value={contact_email}
                                                    onInput={async (e) => { await this.setState({ contact_email: e.target.value }) }}
                                                    style={{ width: 240 }} placeholder='请填写邮箱' maxLength={20} />
                                                <Tips ctx={{ ctx: '请填写邮箱', type: (!contact_email.length && nocontact_email) ? 'error' : 'none' }} />
                                            </div>
                                            <div className='inputBox flex'>
                                                <p className='inputTitle'></p>

                                                <div>
                                                    <p className='explain'>
                                                        用于接收微信支付的开户邮件及日常业务通知
                                                    </p>
                                                </div>
                                            </div>
                                        </>

                                    ) : null
                                }
                            </>
                        ) : null
                    }



                    {/* 结算账户信息（选填） */}
                    {
                        whereStep > 3 ? (
                            <>
                                <p className='title'>结算账户信息</p>
                                <div className='inputBox flex'>
                                    <p className='inputTitle'>账户类型:</p>
                                    <Select defaultValue='74' placeholder='可选择' style={{ width: 120 }} onChange={(e) => { this.bank_account_type(e) }}>
                                        <Option value="74">对公账户</Option>
                                        <Option value="75">对私账户</Option>
                                        {/* <Option value="">请选择</Option> */}
                                    </Select>
                                    <Tips ctx={{ ctx: '请选择账户类型', type: (!bank_account_type && need_account_info) ? 'error' : 'none' }} />
                                </div>
                                <div className='inputBox flex'>
                                    <p className='inputTitle'></p>

                                    <div>
                                        <p className='explain'>
                                            主体为企业，账户类型只能选择对公账户
                                        </p>
                                    </div>
                                </div>
                                <div className='inputBox flex'>
                                    <p className='inputTitle'>开户名称:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ noopen_account_name: open_account_name.length ? false : true }); }}
                                        value={open_account_name}
                                        onInput={async (e) => { await this.setState({ open_account_name: e.target.value }) }}
                                        style={{ width: 240 }} placeholder='请填写开户名称' maxLength={20} />
                                    <Tips ctx={{ ctx: '请填写开户名称', type: (!open_account_name.length && noopen_account_name && need_account_info) ? 'error' : 'none' }} />
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'></p>

                                    <div>
                                        <p className='explain'>
                                            1、选择经营者个人银行卡时，开户名称必须与身份证姓名一致。
                                        </p>
                                        <p className='explain'>
                                            2、选择对公账户时，开户名称必须与营业执照上的“商户名称”一致。
                                        </p>
                                    </div>
                                </div>


                                <div className='inputBox flex'>
                                    <p className='inputTitle'>开户银行:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ noSub_branch: Sub_branch.length ? false : true }); }}
                                        value={Sub_branch}
                                        disabled={true}
                                        onInput={e => { this.setState({ Sub_branch: e.target.value }) }}
                                        style={{ width: 240 }} placeholder='请选择下方开户银行' maxLength={20} />

                                    <p className='explain' style={{ marginLeft: 20 }}>示例值：工商银行</p>
                                    <Tips ctx={{ ctx: '请输入开户银行', type: (!Sub_branch.length && need_account_info) ? 'error' : 'none' }} />
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'></p>

                                    <div>
                                        <p className='explain'>
                                            支持银行(点击可选择)
                                        </p>
                                        <p className='explain' style={{ width: 400, margin: '5px 0' }}>

                                            {
                                                bankList.map((v, i) => {
                                                    return <>
                                                        {i + 1}，<b onClick={() => { this.setBankName(v) }}>{v.val}</b>
                                                    </>
                                                })
                                            }
                                        </p>

                                        {
                                            otherBank ? (
                                                <Input placeholder='请输入其他银行全称' onInput={e => { this.setState({ bank_name: e.target.value }) }} />
                                            ) : null
                                        }

                                        <p className='warn'>如为其他银行,需填写银行全称，如"深圳农村商业银行XXX支行"! </p>
                                    </div>
                                </div>

                                <div className='inputBox flex'>
                                    <p className='inputTitle'>开户地:</p>
                                    <Cascader
                                        fieldNames={{ label: 'name', value: 'code' }}//自定义字段名
                                        options={region}
                                        onChange={async (e) => { await this.setState({ bank_address_code: e, nobank_address_code: e.length ? false : true }); }}
                                        displayRender={(e) => {//选择后展示的渲染函数
                                            return (
                                                e.join('/')
                                            )
                                        }}
                                        showSearch={(val, arrs) => { return arrs.some(option => option.label.includes(val)); }}//筛选
                                        placeholder="请选择开户地"
                                    />
                                    <Tips ctx={{ ctx: '请选择开户地', type: (!bank_address_code.length && need_account_info) ? 'error' : 'none' }} />
                                </div>
                                <div className='inputBox flex'>
                                    <p className='inputTitle'>银行账号:</p>
                                    <Input
                                        onBlur={async () => { await this.setState({ noaccount_number: account_number.length ? false : true }); }}
                                        value={account_number}
                                        onInput={async (e) => { await this.setState({ account_number: e.target.value }) }}
                                        style={{ width: 240 }} placeholder='请填写银行账号' maxLength={30} />

                                    <Tips ctx={{ ctx: '请填写银行账号', type: (!account_number.length && noaccount_number && need_account_info) ? 'error' : 'none' }} />
                                </div>
                            </>
                        ) : null
                    }


                    {
                        whereStep > 3 ? (
                            <>
                                <div className='nextStep'>
                                    <div className='stepCtrl flex'>
                                        <Button className='themebtn prev' onClick={() => { this.stepCtrl(-1) }}>上一步</Button>
                                        <Button className='themebtn' onClick={() => { this.stepCtrl(1) }}>下一步</Button>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }


                </div>
            </div >
        )
    }
}