import { Request } from '@zoranwong/acc-engine.js'
//let merchant_id = window.localStorage.getItem('merchant_id')

export default class PurchaseListRequest extends Request {
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    search: null,
    page: 1,
    with_batch: true
  };
  _method = 'GET';
  _uri = '/goods/group-buy/group-template/group-template-list';
  constructor(params) {
    super(params);
    this._data['search'] = params['search'] || '';//params.merchant_id;
    this._data['page'] = params['page'] || 1;
    this._data['page_size'] = params['page_size'] || 15
  }
  // get middleware(){
  //   return [...super.middleware, PasswordHashMiddleware];
  // }
  rules() {
    return {
    };
  }

  messages() {
    return {
    };
  }
}