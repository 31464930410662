import {withRouter as router} from "react-router-dom";
import Base from "../../components/base";
import React from "react";

class Detail extends Base {
    render () {
        return (<div>商品详情</div>);
    }
}

export default router(Detail);