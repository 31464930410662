import {Request} from '@zoranwong/acc-engine.js';
export default class AddSpecificationValueRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
     spec_id	:'',
     //merchant_id: null,
     spec_value:[]

   };
   _method = 'POST';
   _uri = '/goods/shop-goods/specification/spec-value-add';
   constructor(params) {
     super(params);
     this._data['spec_id'] = params.spec_id;
     //this._data['merchant_id'] = params.merchant_id;
     this._data['spec_value'] = params.spec_value
   }
   rules(){
     return {
        
     };
   }

   messages(){
     return  {
         
    };
   }
}
