import { Request } from '@zoranwong/acc-engine.js';
export default class AddCommanderRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null,
        name: '',
        remark: '',
        tags: []
    };
    _method = 'POST';
    _uri = '/member/supplier/admin/channel/supplier-group-add';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['name'] = params['name'];
        this._data['remark'] = params['remark'];
        this._data['tags'] = params['tags'];
    }
    rules() {
    }

    messages() {
    }
}
