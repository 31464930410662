import { Request } from '@zoranwong/acc-engine.js';
export default class AddGrouperToRequest extends Request {//后台 供应商给分组添加团长
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        token: null,
        grouper_id_array: [],//团长id数组
        supplier_group_id: ''//分组id
    };
    _method = 'POST';
    _uri = '/member/supplier/admin/channel/add-grouper-to-supplier-group';
    constructor(params) {
        super(params);
        this._data['token'] = window.localStorage.getItem('token');
        this._data['grouper_id_array'] = params['grouper_id_array'];
        this._data['supplier_group_id'] = params['supplier_group_id'];
    }
    rules() {
    }

    messages() {
    }
}
