import React from "react";
import {
	withRouter as router
} from "react-router-dom";
import Base from "../../../components/base";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
	Input,
	Button,
	Table,
	Modal,
	message,
	Image
} from 'antd';
import 'moment/locale/zh-cn';
import qs from '../../../utils/qs';
import OrderDetailRequest from '../api/OrderDetailRequest';
import AddRemarkRequest from '../api/AddRemarkRequest';
import '../css/orderDetail.sass'

class OrderDetail extends Base {
	constructor(props) {
		//console.log(props)
		super(props)

		const columns = [
			{
				title: '商品名称',
				dataIndex: 'goods_name',
				key: 'goods_name',
			},
			{
				title: '商品图片',
				// dataIndex: 'entity_img',
				render:(ctx)=>{
					//console.log(ctx, )
					return(
						<Image style={{maxHeight: 50,maxWidth: 50}} src={ctx['entity_img']} />
					)
				}
			},
			{
				title: '单价',
				dataIndex: 'entity_price',
				key: 'entity_price',
			},
			{
				title: '数量',
				dataIndex: 'buy_quantity',
				key: 'buy_quantity',
			},
			// {
			// 	title: '商品状态',
			// 	dataIndex: 'goods_state',
			// 	key: 'goods_state',
			// },
			// {
			// 	title: '退款状态',
			// 	dataIndex: 'goods_refund',
			// 	key: 'goods_refund',
			// },
			{
				title: '实收款',
				dataIndex: 'item_payment_amount',
				key: 'item_payment_amount',
			}
		]

		this.state = {
			order_id: props.location.state.id,
			detail: props.location.state.detail,
			columns: columns,
			dataSource: []
		}
	}

	componentDidMount(props) {
		//console.log(props)
		this.orderDetail()//商家查看订单详情
	}

	orderDetail = async () => {//商家查看订单详情
		const { order_id } = this.state
		let param = {
			order_id: order_id
		}

		let res = await OrderDetailRequest.send(param)
		if (res.status) {
			this.setState({
				detail: res['data']
			})
		}
		//console.log(res)

	}

	cancel = () => {//取消visable
		this.setState({
			remarkVisible: false
		})
	}

	addRemark = async (param) => {
		const hide = message.loading('添加备注中')
		let res = await AddRemarkRequest.send(param)
		if (res.status) {
			message.success('添加成功！')
		} else {
			message.error(res.message)
		}
		hide()
		this.cancel()
		this.orderDetail();//重新拉取
	}

	conAddremark = () => {
		let { merchant_remark, detail } = this.state
		let param = {
			order_id: detail['id'],
			merchant_remark: merchant_remark
		}
		this.addRemark(param)
	}



	render() {
		const { detail, remarkVisible, columns } = this.state;
		const giftColumns = [
			{
				title: '商品名称',
				render: (ctx) => {
					//console.log(ctx, )
					return (
						<p>
							{ctx['goods_name']}
							{Object.values(ctx['spec_name']) ? ':' : ''}
							{Object.values(ctx['spec_name'])}
						</p>
					)
				}
			},
			{
				title: '赠品数量',
				dataIndex: 'buy_quantity',
				key: 'buy_quantity',
			}
		]
		return (
			<div className='orderDetail'>
				{detail ? (
					<div className='detailBox'>
						<div className='line'>
							<div className='title'>基本信息</div>
							<ul className='lineinfo'>
								<li><p>活动标题：</p>{detail['group_buy']['alpha_group_name']}</li>
								<li><p>所属团长：</p>{detail['group_buy']['alpha_name']}</li>
								<li><p>跟团号/昵称：</p>{detail['group_buy'] ? detail['group_buy']['alpha_batch_no'] : '-'}</li>
								<li><p>订单编号：</p>{detail['id']} <CopyToClipboard text={detail['id']}
									onCopy={() => { message.success('复制成功！') }}><span className='copy'>复制</span></CopyToClipboard></li>
								<li><p>下单时间：</p>{detail['created_at']}</li>
								<li><p>订单状态：</p>{qs.orderStatus(detail['order_status'])}</li>
								<li>
									<p>实付金额：</p>
									<span className='price'>￥{Number(detail['payment_amount']).toFixed(2)} </span>
									{
										!detail['need_pay'] ? (
											<span className='copy'>线下支付</span>
										) : null
									}
								</li>
							</ul>
						</div>
						{!Array.isArray(detail['order_mailing']) ? (
							<div className='line'>
								<div className='title'>自提点信息 </div>
								<ul className='lineinfo'>
									<li><p>自提点：</p>{detail['order_mailing']['shipping_address']}</li>
									<li><p>详细地址：</p>{detail['order_mailing']['shipping_address']}</li>
									<li><p>提货联系人：</p>{detail['order_mailing']['consignee_name']}</li>
									<li><p>提货电话：</p>{detail['order_mailing']['consignee_phone']}</li>
									<li style={{ alignItems: 'flex-start' }}>
										<p>自提点照片：无</p>
										{/* <img className='selfgetpic'></img>
															<img className='selfgetpic'></img> */}
									</li>
								</ul>
							</div>
						) : null}

						{!Array.isArray(detail['order_mailing_home']) ? (
							<div className='line'>
								<div className='title'>收货人信息 </div>
								<ul className='lineinfo'>
									<li><p>联系人：</p>{detail['order_mailing']['consignee_name']}</li>
									<li><p>联系电话：</p>{detail['order_mailing']['consignee_phone']}</li>
									<li><p>详细地址：</p>{detail['order_mailing']['shipping_address']}</li>
									{/* <li><p className='copy'>仅复制收货人信息</p> <p className='copy'>复制商品信息和收货人信息</p></li> */}
								</ul>
							</div>
						) : null}


						<div className='line'>
							<div className='title'>备注</div>
							<ul className='lineinfo'>
								<li><p>团员备注：</p>{detail['user_remark'] ? detail['user_remark'] : '无'}</li>
								<li><p>团长备注：</p>{detail['alpha_remark'] ? detail['alpha_remark'] : '无'}</li>
								<li><p>商户备注：</p>{detail['merchant_remark'] ? detail['merchant_remark'] : '无'}</li>
								<li><p onClick={() => this.setState({ remarkVisible: true })} className='copy' style={{ marginLeft: '60px' }}>添加备注</p></li>
								<Modal
									centered
									title='添加备注'
									onCancel={this.cancel}
									visible={remarkVisible}
									footer={false}>
									<div className='flex'>
										<label style={{ display: 'inline-block', lineHeight: '32px', whiteSpace: 'nowrap' }}>
											添加备注：</label>
										<Input style={{ borderRadius: '4px' }}
											onChange={(e) => { this.setState({ merchant_remark: e.target.value }) }}
											placeholder='添加备注' >
										</Input>
									</div>
									<div className='flex' style={{ marginTop: '20px', justifyContent: 'center' }}>
										<Button onClick={this.cancel} style={{ marginRight: '20px' }} className='ant-btn ant-btn-default ant-btn-sm button-bac'>取消</Button>
										<Button onClick={this.conAddremark} className='ant-btn ant-btn-primary ant-btn-sm button-bac'>保存</Button>
									</div>
								</Modal>
							</ul>
						</div>

						<div className='line'>
							<div className='title'>商品信息</div>
						</div>

						<div className="lists" >
							<Table dataSource={
								detail['order_items']
							}
								loading={false}
								columns={
									columns
								}
								rowClassName={(e, index) => { return index % 2 ? 'gary' : '' }}
								pagination={false}
							/>
						</div>


						{
							detail['gifts'] && detail['gifts'].length ? (
								<>

									<div className='line'>
										<div className='title'>满赠：</div>
									</div>

									<div className="lists" >
										<Table dataSource={
											detail['gifts']
										}
											loading={false}
											columns={
												giftColumns
											}
											rowClassName={(e, index) => { return index % 2 ? 'gary' : '' }}
											pagination={false}
										/>
									</div>
								</>
							) : null
						}


						<div className='line' style={{ overflow: 'hidden' }}>
							<ul className='lineinfo total'>
								<li><p>商品总价：</p>￥{qs.toFixed(detail['total_amount'], 2)}</li>
								<li><p>订单总价：</p>￥{qs.toFixed(detail['total_amount'], 2)}</li>
								<li><p>优惠券金额：</p>{qs.toFixed(detail['shipping_amount'], 2)}</li>
								<li><p>实付金额：</p> <span style={{ color: '#FD7B35', fontSize: '16px' }}>￥{qs.toFixed(detail['payment_amount'], 2)} </span></li>
							</ul>
						</div>



					</div>
				) : (
					<p>加载数据中</p>
				)}
			</div>
		)
	}
}

export default router(OrderDetail);