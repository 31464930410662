import { Request } from '@zoranwong/acc-engine.js';
export default class ConditionListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        
    };
    _method = 'GET';
    _uri = '/order/summary/merchant/get-group-order-summary-condition-list';
    constructor(params) {
        super(params)
        this.state={}
    }
    rules() {

    }

    messages() {

    }
}