import Layout from '../components/layout';
import Probably from '../pages/finance/probably/probably'
import FinanceDetail from '../pages/finance/detailed/detailed'
import Merchant from '../pages/finance/merchant';
import WxMerchant from '../pages/finance/wxMerchant';
import assetsIcon from '../assets/assetsIcon.png'
import financeDIcon from '../assets/financeDIcon.png'
import { WechatOutlined, PayCircleOutlined } from '@ant-design/icons';
const sider = [
    {
        name: '资产概况',
        path: '/finance',
        icon: assetsIcon
    },
    // {
    //     name: '资产明细',
    //     path: '/finance/detailed',
    //     icon: financeDIcon
    // },
    {
        name: '微信商户号',
        path: '/finance/wxMerchant',
        icon: <WechatOutlined style={{ color: '#366FBB', fontSize: '16px' }} />
    },
    {
        name: '商户号',
        path: '/finance/merchant',
        icon: <PayCircleOutlined style={{ color: '#366FBB', fontSize: '16px' }} />
    }
];
const finance = {
    path: "/finance",
    component: Layout,
    sider: sider,
    routes: [
        {
            path: "/finance",
            exact: true,
            name: 'probably',
            component: Probably
        },
        {
            path: "/finance/detailed",
            exact: true,
            name: 'detailed',
            component: FinanceDetail
        },
        {
            path: "/finance/wxMerchant",
            exact: true,
            name: 'wxMerchant',
            component: WxMerchant
        },
        {
            path: "/finance/merchant",
            exact: true,
            name: 'merchant',
            component: Merchant
        }
    ]
};
export default finance;