import { Request } from '@zoranwong/acc-engine.js';
export default class DelTpRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        group_ids: '',
        //merchant_id: null,

    };
    _method = 'DELETE';
    _uri = '/goods/group-buy/group-template/group-template-destroy';
    constructor(params) {
        super(params);
        this._data['group_ids'] = params.group_ids;
        //this._data['merchant_id'] = params.merchant_id;
    }
    rules() {
        return {

        };
    }

    messages() {
        return {

        };
    }
}
