import {Request} from '@zoranwong/acc-engine.js';
//let merchant_id = window.localStorage.getItem('merchant_id')

export default class EditClassificationRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
    cate_id: '',
    cate_name:'',
     //merchant_id: null
   };
   _method = 'POST';
   _uri = '/goods/shop-goods/category/cate-edit';
  constructor(params) {
    super(params);
    //this._data['merchant_id'] = merchant_id;//params.merchant_id;
    this._data['cate_id'] = params.cate_id;
    this._data['cate_name'] = params.cate_name;
  }
   rules(){
     return {
     };
   }

   messages(){
     return  {   
    };
   }
}
