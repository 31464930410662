import React from "react";
import { withRouter as router } from 'react-router-dom'
import Base from '../../../../components/base'
import config from '../../../../configs/upload'
import ClassificationListRequest from '../../api/Classification/classificationListRequest'
import AddStandardGoodsRequest from '../../api/StandardGoods/AddStandardGoodsRequest'
import SpecificationListRequest from '../../api/SpecificationMangagemen/SpecificationListRequest'
import AddSpecificationValueRequest from '../../api/SpecificationMangagemen/AddSpecificationValueRequest'
import SpecsInfoRequest from '../../api/SpecificationMangagemen/SpecsInfoRequest'
import {
    Anchor,
    Divider,
    Input,
    Select,
    Modal,
    Radio,
    Switch,
    InputNumber,
    Button,
    Table,
    message,
    Spin,
    Icon
} from 'antd';
import Sku from '../../../../components/sku'

import Editor from '../../../../components/editor'
import BigImgBar from '../../../../components/editor/bigImgBar'
import BigImgView from '../../../../components/editor/bigImgView'
import SmallImgBar from '../../../../components/editor/smallImgBar'
import SmallImgView from '../../../../components/editor/smallImgView'
import TextBar from '../../../../components/editor/textBar'
import TextView from '../../../../components/editor/textView'
import ComponentType from '../../../../components/editor/ComponentType'
import Tips from '../../../../components/tips/tips'
import UploadImage from '../../components/upLoadList'
import '../../css/newGoods.sass'
import 'moment/locale/zh-cn';
import { env } from "../../../../utils/functions";

class NewGoods extends Base {
    constructor(props) {
        super(props)
        this.state = {
            member_type: '',
            memberType: [],
            goodsName: '',
            readioValue: 1,
            frozenFoodValue: '',
            goodsCode: '',
            goodssalePrice: '',
            goodscostPrice: '',
            maxSaleNum: '',
            textArea: '',
            specification: false,
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: [],
            //merchantId: env('REACT_APP_MERCHANT_ID'),
            specsVal: false,
            spinning: false,//spinning值
            specsList: [],//规格
            spec_pre: {},//规格名
            specsSonList: [],//规格值
            addSpecVal: [],//增加规格值
            selectSpec: [],//选择的规格值
            allSpecList: [],//所有选中规格值
            goods_image: '',//商品主图
            canUpGoods: true,
            entities: [],
            main_img_flag: 0 //主图序号

        }
    }

    async componentDidMount() {
        await this.getType();
        await this.getUrlSpec();
    }

    getType = async () => {//获取物品类型
        let params = {
            //merchant_id: this.state.merchantId
        }
        let res = await ClassificationListRequest.send(params)

        if (res.status && res.data.length) {
            this.setState({ memberType: [...res.data] })
        }

    }

    scrollToBottom() {
        if (this.messagesEnd) {
            const scrollHeight = this.messagesEnd.scrollHeight;//里面div的实际高度  2000px
            const height = this.messagesEnd.clientHeight;  //网页可见高度  200px
            const maxScrollTop = scrollHeight - height;
            this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。

            //console.log('scrollHeight',scrollHeight)
        }
    }

    getUrlSpec = async () => {//获取接口规格值
        let param = {
            page: -1,
            //merchant_id: this.state.merchantId
        }
        const hide = message.loading('获取规格值中')
        let res = await SpecificationListRequest.send(param);

        if (res.status) {
            let allSpec = res.data.data;
            let list = allSpec.map((v, i) => {
                return {
                    id: v['id'],
                    name: v['spec_name'],
                    values: v['spec_value'].map((sv) => {
                        return {
                            value: sv['spec_value'],
                            id: sv['id']
                        }
                    })
                }
            });
            await this.setState({ specsList: [...list] });
        }

        hide();

    }

    getBase64 = (file) => {
        //console.log(file, 'getBase64')
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    beforeUpload = (e) => {

        let type = e.type.includes('image');

        return new Promise((resolve, reject) => {
            if (!type) {
                message.error('格式不正确！')
                reject(false)
            } else if (e.size > 2000000) {
                message.error('图片尺寸应小于2MB')
                reject(false)
            } else {
                message.loading('上传图片中...', 1)
                resolve(e)
            }
        })
    }

    handleChange = async ({ file }) => {
        await file
        const { fileList } = this.state;
        //let hide = null;
        if (file['response'] && file['response']['status']) {
            let data = file['response']['data']['url'];
            let list = fileList;
            list.push(data);

            if (list.length === 1) {
                this.setState({
                    goods_image: list[0]
                })
            }
            this.setState({ fileList: list });
            message.success('上传成功！', 1);

        } else if (file['status'] && file['status'] == 'error') {
            //console.log(file,)
            message.error('上传失败！', 1);
        }

    };


    openSpecs = async (e) => {//开启规格
        this.setState({
            specsVal: e
        })
    }

    tabchange = (e) => {//spec table
        // console.log(e, 'ggggggggggg')
    }

    tabList = () => {
        //console.log(this.state.selectSpec)
    }

    specsInfo = async () => {//规格详情
        let param = {
            spec_id: this.state.spec_pre['id'],
            //merchant_id: this.state.merchantId
        }
        let res = await SpecsInfoRequest.send(param);
        return res;

    }

    arrCon = (arr1, arr2) => {//数组合成
        arr1.forEach((v, index) => {
            if (typeof (v) == 'string') {
                arr1[index] = JSON.parse(v)
            }
        })
        arr2.forEach((v, index) => {
            if (typeof (v) == 'string') {
                arr2[index] = JSON.parse(v)
            }
        })
        let lastArr = arr1.concat(arr2)
        this.setState({
            allSpecList: lastArr
        })
        return lastArr;
    }

    selectSpecsSonName = (data) => {//选择规格值
        const newarr = [...data];
        let { selectSpec, spec_pre } = this.state;

        newarr.forEach((v, i) => {
            newarr[i] = JSON.parse(v);
            let keys = spec_pre['id']
            newarr[i]['spec'] = {}
            newarr[i]['spec'][keys] = newarr[i]['id']
            selectSpec.forEach((k, j) => {
                if (k['id'] == newarr[i]['id']) {
                    newarr[i]['entity_code'] = selectSpec[j]['entity_code'] || 0
                    newarr[i]['market_price'] = selectSpec[j]['market_price'] || 0
                    newarr[i]['sell_price'] = selectSpec[j]['sell_price'] || 0
                    newarr[i]['stock_num'] = selectSpec[j]['stock_num'] || 0
                }
            })
        })

        this.setState({
            selectSpec: [...newarr]
        })
    }

    addSpec = () => {//增加规格


    }

    addSpecValAction = () => {//增加规格值

        const { addSpecVal } = this.state

        addSpecVal.forEach((v, index) => {
            if (!v['name'] || !v['name'].trim().length) {
                addSpecVal.splice(index, 1)
            }
        })
        addSpecVal.push({ name: '', id: new Date().getTime() })
        this.setState({
            addSpecVal
        })
    }

    delAddSpecs = (item, index) => {//删除规格值
        const { selectSpec, addSpecVal } = this.state;
        addSpecVal.splice(index, 1)
        this.setState({
            addSpecVal
        })
        this.arrCon([...selectSpec], [...addSpecVal])
    }

    addSpecBlur = (e, index) => {//键入值
        let { addSpecVal } = this.state;
        addSpecVal[index]['name'] = e.target.value;
        addSpecVal[index]['spec_value'] = e.target.value;
        //console.log(addSpecVal)
        this.setState({
            addSpecVal
        })

        // 非空判断
        addSpecVal.map(async (v, index) => {
            let canup = true
            if (!v || !v['name'].trim().length || v === '') {
                return canup = false
            }

            this.setState({
                spinning: true
            })
            //获取规格值

            let getSpec = await this.specsInfo();
            let getSpecList = getSpec['data']['spec_value'];

            getSpecList.map(list => {
                if (v['name'] === list['spec_value']) {
                    addSpecVal.splice(index, 1)
                    this.setState({
                        addSpecVal
                    })
                    message.error('有相同规格值')
                    return canup = false
                }
            })

            if (canup) {
                let param = {
                    spec_id: this.state.spec_pre.id,
                    spec_value: [v['name']],
                    //merchant_id: this.state.merchantId
                }
                let res = await AddSpecificationValueRequest.send(param)
                if (res && res.code === 200) {
                    message.success(res.message)
                    const { selectSpec, addSpecVal } = this.state;
                    this.arrCon([...selectSpec], [...addSpecVal])
                } else {
                    message.error(res.message)
                }
                this.setState({
                    spinning: false
                })
            }
        })
    }

    getEditor = (e) => {//updateEditor  editor
        //console.log(e)
    }

    callback = (msg) => {
        message.success(msg)
    }

    writeSpec = (e, index) => {//键入值

    }

    getSpec = (data) => {//接收Spec 来自子组件addSpecCom
        this.setState({
            selectSpec: [...data]
        })
    }

    replace = () => {
        this.props.history.go(-1)
    }
    save = async () => {
        const { canUpGoods, maxSaleNum, goodssalePrice } = this.state;

        if (!canUpGoods) {
            return message.error('请稍后再试！')
        }


        let edom = this.refs.edom;//编辑商品 component
        let edomCont = edom['state']['components'];
        let editorData = [];
        edomCont.map((v, i) => {
            let data = {};
            data[v['type']] = v['data'];
            editorData.push(data);
        })

        const { specsVal } = this.state
        let SkuList
        if (specsVal) {
            SkuList = this.refs.SkuList ? this.refs.SkuList.state['specEntities'] : [];
            SkuList.map((v, i) => {
                v['spec'] = {};
                let spec_values = v['spec_values'];
                spec_values.map(j => {
                    let specValue = j['specValue']
                    v['spec'][j['specId']] = specValue['id']
                })
            })
        }

        let fileList = this.state.fileList;

        let arr = []
        let arr1 = arr.concat(this.state.member_type)
        let params = {
            goods_type: 1,
            goods_name: this.state.goodsName,
            //merchant_id: this.state.merchantId,
            category_ids: arr1,
            image_list: fileList,
            goods_code: this.state.goodsCode,
            market_price: this.state.goodscostPrice,
            storage_type: this.state.frozenFoodValue,
            goods_image: this.state.goods_image
        }

        if (maxSaleNum) {
            if (isNaN(maxSaleNum)) {
                maxSaleNum = 0
            }
            params['stock_num'] = maxSaleNum
        }

        if (goodssalePrice) {
            if (isNaN(goodssalePrice)) {
                goodssalePrice = 0
            }
            params['sell_price'] = goodssalePrice
        }

        if (SkuList) {
            params['entities'] = [...SkuList]
        }

        if (edomCont.length) {
            params['goods_content'] = editorData;
        }
        if (!params['goods_name']) {
            return message.error('请填写商品名称')
        }

        if (!params['goods_image']) {
            return message.error('请上传商品图片')
        }

        if (!SkuList && !params['sell_price']) {
            return message.error('请填写商品售价')
        }

        const hide = message.loading('提交中', 1)


        this.setState({
            canUpGoods: false
        })

        let res = await AddStandardGoodsRequest.send(params)

        if (res.status == true) {
            message.success(res.message)
            this.replace();
        } else {
            message.error(res.message)
        }

        this.setState({
            canUpGoods: true
        })

        hide()

    }

    delUploadImg = (index) => {//删除上传图片
        let { fileList, main_img_flag } = this.state;
        if (index == main_img_flag) {
            return message.error('不能删除主图')
        }
        if (index < main_img_flag) {
            main_img_flag -= 1;
        }
        fileList.splice(index, 1)
        this.setState({
            fileList,
            main_img_flag
        })
    }

    setMainImg = (index) => {//设为主图
        const { fileList } = this.state;

        let main_img = fileList[index];
        this.setState({ fileList, goods_image: main_img, main_img_flag: index })
    }

    fontImg = (index) => {
        let { fileList } = this.state

        if (index != 0) {
            fileList[index] = fileList.splice(index - 1, 1, fileList[index])[0];
        } else {
            fileList.push(fileList.shift());
        }

        this.setState({
            fileList
        })
    }

    backImg = (index) => {
        let { fileList } = this.state
        if (index != fileList.length - 1) {
            fileList[index] = fileList.splice(index + 1, 1, fileList[index])[0];
        } else {
            fileList.unshift(fileList.splice(index, 1)[0]);
        }
        this.setState({
            fileList
        })
    }

    findOrCreateCategory({ key, target: { value } }) {
        if (key === 'Enter') {
            // 查询分类列表,有就选择第一个匹配的

            // 添加新分类
        }
    }

    render() {
        const EditorBar = [
            { title: '大图', barTemplate: BigImgBar, template: BigImgView, type: ComponentType.BIG_IMG },
            {
                title: '小图',
                barTemplate: SmallImgBar,
                template: SmallImgView,
                type: ComponentType.SMALL_IMG,
                data: { count: 3 }
            },
            { title: '文字', barTemplate: TextBar, template: TextView, type: ComponentType.TEXT }
        ]

        const { previewVisible, previewImage, fileList, previewTitle, main_img_flag } = this.state;

        const uploadProps = {
            action: `${config.apiUrl}/admin/upload/upload-image`,
            listType: "picture-card",
            name: 'file',
            accept: '.png,.jpg,.jpeg,.gif',
            showUploadList:false,
            onPreview: this.handlePreview,
            onChange: this.handleChange,
            beforeUpload: this.beforeUpload
        }

        const { entities } = this.state;
        //console.log(entities)

        return (
            <div className='Newgoods'>
                {/* 发布商品 */}


                <div className='conton'>
                    <h3 className='goods-title'>商品库 / 商品创建</h3>
                    <Divider style={({ borderWidth: 2, borderColor: '#707070', })} />

                    <div className='creat-conton'>
                        <div className='mainUpGoods'>
                            <h3 style={({ fontSize: '26px', fontWeight: 700 })} id='components-anchor-demo-basic'>
                                <span style={({
                                    height: "35px",
                                    width: '3px',
                                    background: '#366FBB',
                                    display: 'inline-block',
                                    lineHeight: '35px',
                                    verticalAlign: 'middle'
                                })}></span>基本信息
                            </h3>
                            <ul className='openSpecs'>
                                <li className='needMargin'>
                                    <label>商品名称：</label>
                                    <Input placeholder="请输入商品名称，最多20字"
                                        maxLength={20}
                                        className='needInput'
                                        value={this.state.goodsName}
                                        onChange={(e) => {
                                            this.setState({ goodsName: e.target.value })
                                        }} />
                                    {!this.state.goodsName ? (
                                        <Tips ctx={({ ctx: '*请输入商品名称', type: 'error' })} />
                                    ) : null}
                                </li>
                                <li className='needMargin'>
                                    <label>商品分类：</label>
                                    <Select
                                        className='needSelect'
                                        style={({ borderRadius: '30px' })}
                                        value={this.state.member_type}
                                        showSearch
                                        onChange={(e) => {
                                            this.setState({ member_type: e })
                                        }}
                                        onInputKeyDown={(e) => {
                                            this.findOrCreateCategory(e);
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            Array.isArray(this.state.memberType) ?
                                                this.state.memberType.map(item => (
                                                    <Select.Option key={item.id} lable={item.cate_name} value={item.id}>
                                                        {item.cate_name}
                                                    </Select.Option>
                                                )) : {}
                                        }
                                    </Select>
                                </li>
                                <li className='needMargin'>

                                    <div className='flex' style={({ alignItems: 'flex-start' })}>
                                        <label>商品图片：</label>

                                        <div style={{ marginLeft: '15px' }}>
                                            <UploadImage
                                                uploadProps={uploadProps}
                                                files={fileList}
                                                deleteImg={(index) => {
                                                    this.delUploadImg(index)
                                                }

                                                } setMainImg={(index) => {
                                                    this.setMainImg(index)
                                                }}
                                                main_img={main_img_flag}
                                                fontImg={(index) => { this.fontImg(index) }}
                                                backImg={(index) => { this.backImg(index) }}
                                            />
                                        </div>
                                    </div>


                                    <Modal
                                        visible={previewVisible}
                                        title={previewTitle}
                                        footer={null}
                                        onCancel={this.handleCancel}
                                    >
                                        <img alt="example" style={({ width: '100%' })} src={previewImage} />
                                    </Modal>
                                    <p style={({
                                        fontSize: '12px',
                                        paddingLeft: '115px'
                                    })}>建议尺寸：800x800，单张大小不超过2M，最多可上传10张</p>
                                    {!fileList.length ? (
                                        <Tips ctx={({ ctx: '*请选择商品图片', type: 'error' })} />
                                    ) : null}
                                </li>
                                <li className='needMargin'>
                                    <label>是否冻品：</label>
                                    <Radio.Group onChange={(e) => {
                                        this.setState({ frozenFoodValue: e.target.value })
                                    }} value={this.state.frozenFoodValue}>
                                        <Radio value={'freezing'}>冻品</Radio>
                                        <Radio value={'cold'}>鲜品</Radio>
                                        <Radio value={'normal'}>常温品</Radio>
                                        <Radio value={'heat'}>其他</Radio>
                                    </Radio.Group>
                                </li>
                            </ul>
                        </div>

                        <div className='mainUpGoods'>
                            <h3 style={({ fontSize: '26px', fontWeight: 700 })} id='components-anchor-demo-static'>
                                <span style={({
                                    height: "35px",
                                    width: '3px',
                                    background: '#366FBB',
                                    display: 'inline-block',
                                    lineHeight: '35px',
                                    verticalAlign: 'middle'
                                })}></span>价格/规格
                            </h3>
                            <ul className='openSpecs'>

                                <li className='needMargin'>

                                    <label>开启规格：</label>
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭"
                                        defaultChecked={false} onChange={this.openSpecs} />

                                    {this.state.specsVal && this.state.specsList.length > 0 ? (
                                        <Sku ref='SkuList' specData={this.state.specsList} entities={entities}
                                            changeEntities={(entities) => {
                                                this.setState({ entities })
                                            }} />
                                    ) : (
                                        <div className='addSpecval'>
                                            暂无规格值,
                                            <span>点击添加</span>
                                        </div>

                                    )}

                                </li>
                                {!this.state.specsVal ? (
                                    <>
                                        <li className='needMargin'>
                                            <label>商品编码：</label>
                                            <Input placeholder='请输入商品编码'
                                                disabled={this.state.specsVal}
                                                className='needInput'
                                                value={this.state.goodsCode} onChange={(e) => {
                                                    this.setState({ goodsCode: e.target.value })
                                                }} />
                                        </li>
                                        <li className='needMargin'>
                                            <label>商品售价：</label>
                                            <InputNumber disabled={this.state.specsVal}
                                                className='needInput' onChange={(e) => {
                                                    this.setState({ goodssalePrice: e })
                                                }} />元
                                            {!this.state.goodssalePrice ? (
                                                <Tips ctx={({ ctx: '*请输入商品售价', type: 'error' })} />
                                            ) : null}
                                        </li>
                                        <li className='needMargin'>
                                            <label>商品划线价：</label>
                                            <InputNumber disabled={this.state.specsVal}
                                                className='needInput' onChange={(e) => {
                                                    this.setState({ goodscostPrice: e })
                                                }} />元
                                        </li>
                                        <li className='needMargin'>
                                            <label>最大可售数量：</label>
                                            <Input className='needInput' placeholder='不填则为无限大'
                                                value={this.state.maxSaleNum}
                                                onChange={(e) => {
                                                    this.setState({ maxSaleNum: e.target.value })
                                                }} />
                                        </li>
                                    </>
                                ) : null}
                            </ul>

                        </div>
                        <div className='mainUpGoods'>
                            <h3 style={({ fontSize: '26px', fontWeight: 700 })} id='components-anchor-demo-desc'>
                                <span style={({
                                    height: "35px",
                                    width: '3px',
                                    background: '#366FBB',
                                    display: 'inline-block',
                                    lineHeight: '35px',
                                    verticalAlign: 'middle'
                                })}></span>商品描述
                            </h3>
                            <div className='needMargin'>
                                <div ref={(el) => { this.messagesEnd = el; }} className='goods_Editor' id='goods_Editor' style={{ marginRight: '45px', marginTop: '20px' }}>
                                    <Editor updateEditor={this.getEditor} ref='edom' bars={EditorBar} scrollBottom={() => { this.scrollToBottom() }} />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='conton' style={({ boxShadow: 'none' })}>
                        <div style={({ textAlign: 'center' })}>
                            <Button className='save' onClick={() => {
                                this.replace()
                            }}>返回</Button>
                            <Button style={{ marginRight: 0 }} className='save' onClick={() => {
                                this.save()
                            }}>保存</Button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default router(NewGoods)