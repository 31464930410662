import { withRouter as router } from "react-router-dom";
import Base from "../../components/base";
import StarSky from '../../components/starSky'
import './css/login.sass'
import logo_login from '../../assets/logo_login.png'
import Qrcode from './components/Qrcode'
import { message } from 'antd'
import qs from "../../utils/qs";


//import waitimg from '../../assets/waitting.gif'

new StarSky();//星空

class Login extends Base {
    constructor(args) {
        super(args)
        this.state = {
            load: false,
            userName: ''
        }
    }

    componentWillUnmount() {
        //去除星空图
        document.getElementById('fwhfCanvas').style.display = 'none';

    }

    async componentDidMount() {
        let noToken = qs.hrefParam('noToken');

        document.getElementById('fwhfCanvas').style.display = 'block';
        const token = await window.localStorage.getItem('token');
        const merchantId = await window.localStorage.getItem('merchant_id')
        if (token && merchantId !== 'undefined') {
            this.props.history.push({
                pathname: '/home'
            })
            message.success('登录成功！')
        } else {
            if (noToken) { message.error('身份失效，重新登录') }
            localStorage.clear();
        }

    }

    render() {
        return (
            <div className='login'>
                <div className='content'>
                    <div className='flex ctx'>
                        <Qrcode></Qrcode>
                        <div className='advert wx_ctx'>
                            <img width={160} src={logo_login} alt=''/>
                            <p className='logo_txt'>磁力圈</p>
                            <p>助力触达千万私域创业者</p>
                        </div>
                    </div>

                    <div className='brand'>
                        <p>Copyright ©合肥涌现信息技术有限公司 <a href='http://www.cili-app.com/' target='_blank'>《隐私协议》</a></p>
                        <p>法律声明 皖ICP备18009712号-2</p>
                    </div>

                </div>
            </div>
        );
    }
}

export default router(Login);