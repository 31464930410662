import React from 'react'
import { withRouter as router } from 'react-router-dom'
import Base from '../../../../components/base'

import StandardGoodsListRequest2 from '../../../goods/api/StandardGoods/StandardGoodsListRequest2'
import AddPurchaseRequest from '../../api/groupsList/addPurchaseRequest'
import TempUpdateRequest from '../../api/groupsList/tempUpdateRequest'
import ClassificationListRequest from '../../api/groupsList/classificationListRequest'
import SpecificationListRequest from '../../api/SpecificationListRequest'
import GroupTemplateInfoRequest from '../../api/groupsList/groupTemplateInfoRequest'
import GroupGoodsListRequest from '../../api/groupsList/groupGoodsListRequest'
//import config from '../../../../configs/upload'
import _, { find, findIndex, map, wrap } from 'lodash'
import {
    Input,
    Button,
    Switch,
    Modal,
    Select,
    Table,
    Radio,
    TimePicker,
    DatePicker,
    InputNumber,
    message,
    Calendar,
    Spin,
    Checkbox,
    Pagination,
    Image
} from 'antd'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
//import Tips from '../../../../components/tips/tips';
import '../../css/newgroups.sass';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment';
import qs from '../../../../utils/qs';
import Sku from '../../components/sku';
import emitter from "../../../../ev";
//import SelectGoods from '../../components/selectGoods'
import groupsList from '../../api/groupListRequest';

import ContrlShop from '../../../../components/upshop/contrlShop'
import ShowShop from '../../../../components/upshop/showShop'

import TemplateIntroduce from '../components/TemplateIntroduce'
import Reduce from '../components/Reduce'
import getCommuneListRequest from '../../api/getCommuneListRequest'
import { env } from "../../../../utils/functions";

const format = 'HH:mm';
const { Option } = Select;
const { RangePicker } = DatePicker;


//初始化数据
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `${k + 1}`,
        content: `this is content ${k + 1}`
    }));

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};


class NewGroups extends Base {
    constructor(props) {
        super(props)
        let creatType = props.location.query ? props.location.query.type : window.localStorage.getItem('creatType') ? window.localStorage.getItem('creatType') : 1;
        window.localStorage.setItem('creatType', creatType)
        this.state = {
            //merchantId: env('REACT_APP_MERCHANT_ID'),
            showList: [],
            isModalVisible: false,
            selecttimeVisible: false,
            selectOption: '',
            goodTable: [],
            condition: 1,
            delivery_type: 2,
            open_team: false,
            end_day: '',
            end_time: '00:00',
            group_name: '',
            group_detail: '',//团购模板详情
            is_open: 'on', //是否开启该团购
            goodsList: [], //选择的列表
            newFixArr: [], //展示选择的列表
            selectgoods: [],
            goods_list: [],
            arrival_datetime: [],//
            old_arrival_datetime: [],//详情arrival_datetime
            hours: '',
            minutes: '',
            limitstartTime: '00:00',
            limitendTime: '23:59',
            timeLimitDis: false,
            selectarrivalTime: '',
            min_pay: '',
            min_pay_flag: false,
            min_order: '',
            min_order_falg: false,
            freebieValue: 'y',
            LadderPreferential: [],
            earlyPeservation: [],
            discountcoupon: [],
            LadderPreferentialVis: false,
            earlyPeservationVis: false,
            discountcouponVis: false,
            coupondata: [],
            selectconpondata: [],
            addladderlist: [],
            items: getItems(3),
            creatDom: [],
            bronDom: [],
            descList: [],
            canRelease: true,//是否可以发布
            goodstype: [],//商品分类
            spinning: false,//加载中
            saveVisible: false,
            group_rule: {},
            release: 'off',
            params: {},
            editorDetail: {},//编辑时详情
            edom: [],//编辑框内容
            allcansee: true, //全部渠道可见
            skuvisible: false,//选择规格
            specEntities: [],
            specsList: [],//规格列表
            moreEntitiesIndex: 0,//多规格商品的INDEX
            teamPrice: 0,
            commission_rate: 0,//分佣比例
            fee_rate: 0,//服务费比例
            allNum: 0,//已选择0件
            groupsListNum: [],
            selectedRowKeys: [],
            visible_channel: [],
            open_audit: 0,//需要供应商确认开团
            open_fee: 0,//市场推广服务费
            reloadSku: false,
            tempSelectedEntities: [],
            shopArr: [],//编辑arr
            promotions: [],//优惠规则数组
            copyaddOrchange: '',
            group_id: null,
            changeParam: {},
            edomCont: [],//图文详情
            creatType,//创建类型 （1:普通团购合约，100:订货合约）
            group_notice: '',//供应商说明,开团须知
            refund_limit: 0,//退款限制
            goods_name: '',//商品名称
            category_id: '',//商品分类
            need_pay: false,//false==非线下支付
            defaultSelectedRowKeys: [],//默认选中的渠道
            limitBuy: 0, //限购
            can_see_circle: false,//可见圈子
            see_circle: true,
            communeList: [],//圈子列表
            commune_id: '', //可见圈子ID
            circle_name: '',//圈子名称 
            commune_page: 1,//圈子页数
            commune_total: 0,//圈子总数
            select_circle_name: '',//已选圈子名
        };
        this.watchSkuData();//sku 监听
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const bronDom = reorder(
            this.state.bronDom,
            result.source.index,
            result.destination.index
        );

        this.setState({
            bronDom
        });
    }


    componentDidMount = async (e) => {

        await window.localStorage.setItem('promotions', null)
        let location = await this.props['location'];
        if (location['state']) {
            let group_id = await location['state']['id'];
            let type = await location['state']['type'];
            //console.log(type,)
            if (group_id) {

                await this.setState({
                    group_id
                })

                await this.setState({
                    copyaddOrchange: type
                })

                await this.getTeamDetail(group_id, type);//获取团购详情
            }
        }
        // emitter.addListener('edmo', data => {
        //     //console.log(data,)
        // })
        let res = await this.getData();//
        let specs = await this.getUrlSpec();
        let clas = await this.getClassList();
        let getgroupsList = await this.getgroupsList();
        let getCommune = await this.search_circle();
    }

    componentDidUpdate(e) {
        //console.log(e, )

    }


    componentWillReceiveProps(e) {

        //console.log(e, this.props)

    }

    shouldComponentUpdate(newProps, newState) {
        return true
    }

    componentWillUnmount() {
        //emitter.removeListener('skuData', () => { })
        //emitter.removeListener('selectList', () => { })
        //emitter.removeListener('edmo', () => { })
        //emitter.removeAllListeners();
    }


    getgroupsList = async () => {//渠道列表
        let param = {
            page_size: 500
        }
        let res = await groupsList.send(param);
        //console.log(res,)
        let resArr = [];
        if (res.code == 200 && res.data) {
            if (res.data.length) {
                res.data.map(v => {
                    resArr.push(
                        {
                            id: v.id,
                            name: v.name
                        }
                    )
                })
            }
            this.setState({
                groupsListNum: resArr
            })
        }
        //console.log(resArr)
    }

    goodsListRefresh = async (entity, goodsIndex) => {//更新goodslist
        //console.log(entity, goodsIndex)
        let { goodTable, goodsList } = await this.state;
        await goodsList.map((v, i) => {
            v['goods_index'] = i
        })

        //console.log(goodsList,goodTable )
        let goodsInfo = await goodTable[goodsIndex];

        if (entity['checked'] && goodsInfo) {
            //console.log(goodsInfo['checked'], )
            goodsInfo['checked'] = true;
            let item = { ...goodsInfo };
            item['entity'] = entity;
            item['goods_index'] = goodsIndex;
            item['goods_id'] = goodsInfo['id'];
            item['entity_id'] = entity.id;
            item['stock_num'] = entity.stock_num;
            item['sell_price'] = entity.sell_price;
            let goods = goodsList;
            goods.unshift(item);
            await this.setState({ goodsList: goods });
        } else {
            let goods = goodsList;
            let index = findIndex(goods, function (e) {
                return e['entity_id'] === entity['id'];
            });
            //console.log(index,)
            if (index > -1) {
                let deleteGoods = goods.splice(index, 1);
                if (deleteGoods.length > 0) {
                    await this.setState({ goodsList: goods });
                    let idx = findIndex(goods, function (e) {
                        //console.log(e,goodsInfo )
                        return e['id'] === goodsInfo['id'];
                    });
                    if (idx < 0) {
                        if (!goodsInfo) return
                        goodsInfo['checked'] = goodsInfo['checked'] ? false : false;
                    }
                }
            } else {
                goods.splice(goodsIndex, 1)
                await this.setState({ goodsList: goods });
            }
        }


        await goodsList.map((v, i) => {
            v['goods_index'] = i
        })

        await this.setState({
            goodsList
        })

        await this.addGoodsListSku();
        await this.setState({ goodTable });
    }

    addGoodsListSku = async () => {//重组newFixArr，位置移动时 展示 2021-09-26添加
        let { goodsList } = await this.state;
        let idArr = []
        for (let i in goodsList) {
            idArr.push(goodsList[i]['id'])
        }

        idArr = Array.from(new Set(idArr))
        let newFixArr = [];
        for (let i in idArr) {
            newFixArr[i] = []
            for (let k in goodsList) {
                goodsList[k]['old_index'] = k;//添加原index用于删除
                if (idArr[i] == goodsList[k]['id']) {
                    newFixArr[i].push(goodsList[k])
                }
            }
        }

        await this.setState({
            newFixArr
        })

        //console.log(newFixArr, 'addGoodsListSku')

    }

    watchSkuData = () => {
        emitter.addListener('skuData', async (data) => {
            this.goodsListRefresh(data, this.state.moreEntitiesIndex);
        });
    }


    skuFix = async () => {

    }

    getData = async () => {//获取数据 ===  goods
        //const hide = message.loading('加载数据中')
        let { goods_name, category_id } = this.state

        let sqlarr = [
            { name: 'goods_name', val: goods_name },
            { name: 'category_id', val: category_id }
        ]

        let sql = qs.sqlSearch(sqlarr)//sql

        let params = {
            //merchant_id: this.state.merchantId
        }

        if (sql) {
            params['search'] = sql['search']
        }

        let res = await StandardGoodsListRequest2.send(params)
        if (!res.status) return
        let alldata = res.data.data || [];
        alldata.map(v => {
            v['checked'] = false;
            v['selectSku'] = [];
            let isNullkeys = Object.values(v['entities'][0]['spec']);
            if (isNullkeys[0] === '' || !isNullkeys[0]) {
                v['multi_spec'] = 'off'
            }
        })
        this.setState({ goodTable: alldata })
        window.localStorage.setItem('goodsTable', JSON.stringify(alldata))//reduce.js
        //hide();
    }

    onChange = (checked, index) => {//选择开启
        if (checked) {
            //console.log(`switch to ${checked}`);
            this.setState({ is_open: 'off' })
        }
    }

    getTeamDetail = async (id) => {//获取团购详情
        let { copyaddOrchange } = this.state
        let param = {
            group_id: id
        }
        const hide = message.loading('获取详情中')
        let res = await GroupTemplateInfoRequest.send(param)
        if (res.code === 200) {
            let editorDetail = res.data;
            editorDetail['batch'].map(v => {
                v['date'] = v['arrival_date'];
                v['time'] = v['arrival_time'];
                v['action'] = 1;
            })
            this.setState({
                group_name: editorDetail['group_name'],
                edom: editorDetail['group_detail'],
                end_day: editorDetail['end_day'],
                arrival_datetime: copyaddOrchange === 'copy' || !copyaddOrchange ? [] : editorDetail['batch'],
                old_arrival_datetime: copyaddOrchange === 'copy' || !copyaddOrchange ? [] : editorDetail['batch'],
                promotions: editorDetail['promotions'],
                commission_rate: editorDetail['commission_rate_percentage'],
                end_time: editorDetail['end_time'],
                visible_channel: copyaddOrchange ? editorDetail['visible_channel'] : [],
                //defaultSelectedRowKeys: copyaddOrchange ? editorDetail['visible_channel'] : [],
                need_pay: !editorDetail['need_pay'],
                allcansee: editorDetail['visible_channel'].length ? false : true
            })
            if (editorDetail['visible_channel'] && editorDetail['visible_channel'].length) {
                if (editorDetail['visible_channel'][0]['name'] == '公开渠道') {
                    this.setState({ allcansee: true })
                }
            }
            await this.getGoodsList(id, editorDetail['batch'].length ? editorDetail['batch'][0]['batch_id'] : null)
        }
        await this.addGoodsListSku();
        hide()
    }

    getGoodsList = async (id, batch_id) => {
        let params = {
            group_id: id,
            group_batch_id: batch_id
        }
        let resgoods = await GroupGoodsListRequest.send(params)
        if (resgoods.status === false) return
        let arr = await resgoods.data.data;
        let new_goods_arr = [];//new goods  
        await arr.map((v, i) => {
            //是否多条规格
            //let moreSpec = v['entities'].length > 1 ? true : false;
            v['entities'].map((data, index) => {
                let multi_spec = Object.keys(data['spec_id_json']) ? 'on' : 'off';
                let obj = {
                    id: v['goods_id'],
                    goods_id: v['goods_id'],
                    goods_name: v['goods_name'],
                    sell_price: data['sell_price'],
                    goods_image: v['goods_image'],
                    checked: true,
                    multi_spec,
                    entity: {
                        checked: true,
                        spec_values: []
                    },
                    goods_index: i + index,
                    stock_num: data['stock_num'],
                    entity_id: data['goods_entity_id']
                }

                if (!Array.isArray(data['spec_id_json'])) {
                    obj['entity']['spec_values'].push(
                        {
                            specId: Object.keys(data['spec_id_json'])[0],
                            specName: Object.keys(data['spec_name_json'])[0],
                            specValue: {
                                id: Object.values(data['spec_id_json'])[0],
                                value: Object.values(data['spec_name_json'])[0],
                            }
                        }
                    )
                }

                new_goods_arr.push(
                    obj
                )
            })

            new_goods_arr.map((v, i) => {
                v['goods_index'] = i
            })


        })
        await this.setState({
            goodsList: new_goods_arr
        })
        //console.log(new_goods_arr,)

    }

    getClassList = async () => {

        this.setState({
            spinning: true
        })
        let param = {}
        let res = await ClassificationListRequest.send(param)
        const allSelect = {
            cate_code: "",
            cate_depth: 1,
            cate_image: "",
            cate_name: "全部",
            cate_sort: 0,
            created_at: "2021-02-24 19:06:29",
            id: "",
            //merchant_id: "",
            parent_id: ""
        }
        if (Array.isArray(res.data)) {
            res.data.unshift(allSelect)
        }
        if (res.status && Array.isArray(res.data)) {
            this.setState({
                goodstype: [...res.data]
            })
        }
        this.setState({
            spinning: false
        })
    }

    selectGoods = async () => {
        if (!this.state.spinning) {
            this.setState({ isModalVisible: true })
        }
    }

    handleOk = () => {
        this.setState({ isModalVisible: false })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            saveVisible: false,
            skuvisible: false
        })
    };

    getUrlSpec = async () => {//获取接口规格值
        let param = {
            page: -1,
            //merchant_id: this.state.merchantId
        }
        //const hide = message.loading('获取规格值中')
        let res = await SpecificationListRequest.send(param);
        let list = [];
        if (res.status) {
            let allSpec = res.data.data || [];
            list = allSpec.map((v, i) => {
                return {
                    id: v['id'],
                    name: v['spec_name'],
                    values: v['spec_value'].map((sv) => {
                        return {
                            value: sv['spec_value'],
                            id: sv['id']
                        }
                    })
                }
            });
            this.setState({ specsList: [...list] });
        }
        //hide();
        return list;
    }

    //隐藏sku
    hideSku = () => {
        this.setState({
            skuvisible: false
        })
    }

    //提示相同产品
    tipPro = async (item, type) => {

        window.localStorage.setItem('specId', [])

        let idarr = [];

        let canAdd = true;

        let { goodsList } = await this.state;

        await goodsList.map(v => {
            //console.log(v,)

            //单规格
            if (item['multi_spec'] != 'on') {
                if (item.id == v.id && type != 'cancel') {
                    message.error('列表里有相同产品');
                    canAdd = false
                }
            }
            //多规格
            else {

                idarr.push(v['entity_id']);

            }

        })

        window.localStorage.setItem('specId', idarr)
        return canAdd;
    }

    //  选择商品
    selectGoodslist = async (record, index, entities, contrl) => {

        //console.log(entities,)
        let { specsList } = await this.state

        let canSelect = this.tipPro(record, contrl)
        if (! await canSelect) return

        let newEntities = [];
        if (entities && entities.length) {
            //组装
            newEntities = entities.map((v, i) => {
                return {
                    id: v['id'],
                    cost_price: v['market_price'],
                    sell_price: v['sell_price'],
                    stock_num: v['stock_num'],
                    entity_code: v['entity_code'],
                    spec_values: map(v['spec'], (specVId, specId) => {
                        let spec = find(specsList, (specss) => {
                            return specId === specss.id;
                        });

                        if (spec) {
                            let specValue = find(spec['values'], (v) => {
                                return v.id === specVId;
                            });
                            if (specValue) {
                                return {
                                    specId: specId,
                                    specName: spec['name'],
                                    specValue: {
                                        id: specVId,
                                        value: specValue['value']
                                    }
                                };
                            }
                        }
                        return null;
                    })
                };
            })
        }

        //多规格商品的 index
        await this.setState({
            moreEntitiesIndex: index,
        });


        await this.setState({
            specEntities: [...newEntities]
        })

        //是否多规格商品
        if (entities && entities.length && specsList.length > 0) {//多规格
            //隐藏选择商品
            this.setState({
                //isModalVisible: false,
                skuvisible: true //弹出规格选择
            })
            return 0
        } else {
            let goodsInfo = this.state.goodTable[index];
            goodsInfo['checked'] = !goodsInfo['checked'];
            goodsInfo['entities'][0]['checked'] = !goodsInfo['entities'][0]['checked'];
            this.goodsListRefresh(goodsInfo['entities'][0], index);
        }

        //console.log(goodsList,)

    }

    changeMaxSale = (e, i, ii) => {//最大可售数量
        let { goodsList, newFixArr } = this.state
        newFixArr[i][ii]['stock_num'] = e.target.value;

        this.setState({
            newFixArr
        })

    }

    changeMaxPrice = (e, i, ii) => {//团购价格
        //console.log(e, i, ii)
        let { goodsList, newFixArr } = this.state;
        newFixArr[i][ii]['sell_price'] = e.target.value;
        this.setState({
            newFixArr
        })
    }

    getEndDay = (e) => {//* 截单时间
        let num = e.target.value
        this.setState({ end_day: num })
    }

    setRate = (e) => {//设置分佣比例
        if (isNaN(e)) {
            e = 0
        }
        if (e > 30) return message.error('分佣比例不得大于30')
        if (e < 0) return message.error('分佣比例不得小于0')
        this.setState({
            commission_rate: e
        })
    }

    setFeeRate = (e) => {//设置服务费比例
        if (isNaN(e)) {
            e = 0
        }
        this.setState({
            fee_rate: e
        })
    }

    save = async (release) => {
        let {
            creatType,
            canRelease,
            group_rule,
            visible_channel,
            open_audit,
            goodsList,
            arrival_datetime,
            end_day,
            delivery_type,
            commission_rate,
            fee_rate,
            open_fee,
            edomCont,
            end_time,
            edom,
            group_notice,
            refund_limit,
            copyaddOrchange,
            need_pay,
            commune_id
        } = this.state;

        if (commission_rate > 30) return message.error('分佣比例不得大于30')

        //console.log(arrival_datetime, 'arrival_datetimearrival_datetime')

        let promotions = await JSON.parse(window.localStorage.getItem('promotions')) || [];

        //每满赠金额不得为零
        if (promotions.length) {
            let promotions_arr = await promotions[0]['rules'];//gifts
            let promotions_type = await promotions[0]['type'];//type

            if (promotions_type === 'PRE_ORDER_TOTAL_FULL_GIFT') {
                await promotions_arr.find((v, i) => {
                    if (!v['amount'] || v['amount'] === '0') {
                        canRelease = false
                        return message.error('每满赠金额不得为0')
                    }
                })
            }
        }
        if (!canRelease) {
            return message.error('请稍后发布！')
        }

        let introduce = this.child.getEditor()//1获取填写团购介绍
        let editorData = edomCont.length ? [] : edom;

        if (edomCont && edomCont.length) {
            edomCont.map((v, i) => {
                let data = {};
                data[v['type']] = v['data'];
                editorData.push(data);
            })
        }

        let newGoodsList = []
        await goodsList.map((v, i) => {
            let listParam =
            {
                goods_id: v['goods_id'],
                entity_id: v['entity_id'],
                stock_num: v['stock_num'],
                sell_price: v['sell_price']
            }

            if (parseInt(v['sort']) > -1) {
                listParam['sort'] = parseInt(v['sort'])
            }

            newGoodsList[i] = listParam

        })

        let checked = qs.isNull(introduce['group_name'], '请输入团购标题')
        if (!checked) return

        checked = qs.isNull(goodsList, '请选择团购商品')
        if (!checked) return

        checked = qs.isNull(arrival_datetime, '请添加到货时间')
        if (!checked) return

        checked = qs.isNull(end_day.toString(), '请填写截单日期')
        if (!checked) return

        let arrTime = arrival_datetime;

        let lastArrTime = []

        for (let i in arrTime) {
            let { date, time, batch_id, action } = arrTime[i];

            if (batch_id && action > 0) {
                continue
            }

            if (!batch_id && action < 1) {
                continue
            }

            let params = {
                batch_id,
                date,
                time,
                action: action < 0 ? 1 : action
            }

            lastArrTime.push({ ...params })
        }

        //console.log(lastArrTime, 'lastArrTime')

        let params = {//
            group_type: creatType,
            group_name: introduce['group_name'],
            end_day,
            end_time,
            delivery_type,
            is_open: release,
            group_detail: editorData,
            group_rule,
            visible_channel,
            open_audit,
            open_fee,
            goods_list: newGoodsList,
            arrival_datetime: lastArrTime,
            commission_rate: commission_rate / 100,
            fee_rate: fee_rate / 100,
            promotions,
            group_notice,
            refund_limit,
            need_pay: !need_pay,
        };

        if (commune_id) {//如有圈子
            params['commune_id'] = commune_id
        }

        let type = this.props.location.state ? this.props.location.state.type : '';
        //console.log(this.props.location, )
        //if(this.props.location.state.type){}
        //let { old_arrival_datetime } = this.state;
        // if (type == 'change' && old_arrival_datetime == arrival_datetime) {//如果修改团购，且arrival_datetime不变
        //     delete params['arrival_datetime']
        // }
        //console.log(this.props, )

        this.setState({
            release,
            saveVisible: true,
            params
        })

    }

    conSub = async (release) => {

        const { canRelease, group_rule, visible_channel, copyaddOrchange, group_id, can_see_circle } = this.state;
        if (!canRelease) {
            return message.error('请稍后发布！')
        }
        let { params } = this.state
        //console.log(params, )
        params['visible_channel'] = visible_channel;
        params['is_open'] = release;
        if (group_id) {//修改 携带 原id
            params['group_id'] = group_id
        }

        if (can_see_circle) {// can_see_circle === true  打开圈子时
            params['visible_channel'] = []
        } else {
            params['commune_id'] = ''
        }

        this.setState({
            canRelease: false
        })
        let hide
        let res

        //return console.log(params,)
        if (copyaddOrchange === 'copy' || !copyaddOrchange) {
            hide = message.loading('发布团购中', 1)
            res = await AddPurchaseRequest.send(params)
        } else {
            hide = message.loading('修改团购中', 1)
            res = await TempUpdateRequest.send(params)
        }

        if (res.status === true) {
            message.success(res.message)
            window.localStorage.setItem('promotions', [])
            this.props.history.go(-1)

        } else {
            message.error(res.message)
        }

        this.setState({
            canRelease: true
        })
        hide()
    }

    addtime = () => {
        this.setState({ selecttimeVisible: true })
        return
        let newDate = new Date(moment());
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1;
        let day = newDate.getDate();
        let beginDate = year + '-' + month + '-' + day;
        if (!this.state.selectarrivalTime) {
            this.setState({ selectarrivalTime: beginDate })
        }
    }

    deleteDate = (record, index) => {//删除到货日期
        let { arrival_datetime } = this.state

        arrival_datetime[index]['action'] = 0

        this.setState({ arrival_datetime })

        //console.log(this.state.arrival_datetime,)
    }

    timeOK = async () => {
        let { limitstartTime, limitendTime, arrival_datetime, selectarrivalTime } = this.state
        let res = false//不能选择重复的日期

        for (let i in arrival_datetime) {
            if (selectarrivalTime.includes(arrival_datetime[i]['date']) && arrival_datetime[i]['action'] !== 0) {
                res = true
            }
        }

        //console.log(selectarrivalTime, arrival_datetime, res)
        if (res) return message.error('不能选择重复的日期')

        if (!selectarrivalTime) {
            message.error('请选择具体到货日期')
            return
        }

        let arrObj = []
        selectarrivalTime.map((v, i) => {

            arrObj.push({
                action: 1,
                index: i,
                id: Date.now(),
                date: v,
                time: limitstartTime + '~' + limitendTime
            })

        })

        this.setState({
            arrival_datetime: arrival_datetime.concat(arrObj),
            selecttimeVisible: false,
            hours: '',
            minutes: ''
        })

    }

    timeCancel = () => {
        this.setState({ selecttimeVisible: false })
    }

    deletegoods_ = (id, skuparam, skuindex, entity_id) => {
        //console.log(this.state.goodsList, this.state.newFixArr)
        //return
        skuparam['entity']['checked'] = false;
        skuparam['checked'] = false;
        this.goodsListRefresh(skuparam['entity'], skuparam['goods_index']);
    }

    fixDeteleGoods = async (item, i, ii) => { //新修改 删除 商品 2021-09-26
        //console.log(this.state.goodsList, this.state.newFixArr)
        item['entity']['checked'] = false;
        item['checked'] = false;
        this.goodsListRefresh(item['entity'], item['goods_index']);
    }

    //选择日期
    getlimitDate = (e, i) => {
        //console.log(e,i )
        let newDate = new Date(e);
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1;
        let day = newDate.getDate();
        //console.log(year, month, day, moment())
        this.setState({
            selectarrivalTime: year + '-' + month + '-' + day
        })
    }

    //添加选择日期
    addArrDate = async (e, arr) => {
        let selectarrivalTime = qs.getdiffdate(arr[0], arr[1]);
        //console.log(selectarrivalTime, )
        this.setState({
            selectarrivalTime
        })
    }

    //选择时间
    timeLimit = (e, index) => {
        //console.log(new Date(e), index)
        let newDate = new Date(e);
        let hours = qs.transTen(newDate.getHours());
        let min = qs.transTen(newDate.getMinutes());
        //console.log(hours, min)
        if (!index) {
            this.setState({
                limitstartTime: hours + ':' + min
            })
        } else {
            this.setState({
                limitendTime: hours + ':' + min
            })
        }
    }

    upBigImgCg = async (e) => {
        const hide = message.loading('上传图片中...', 1)
        let res = await e.file.response;
        if (res && res.code == 200) {
            hide();
            message.success('上传成功！')
            this.creatBigImg(res.data.url)
        }
    }

    creatBigImg = (url) => {//大图
        const { creatDom, items } = this.state;
        let bigimg = document.createElement('img');
        bigimg.className = 'bigimg';
        bigimg.src = 'http://' + url;
        bigimg.width = 50
        bigimg.height = 50
        //let box = document.getElementById('dndBox');
        let index = creatDom.length;
        //box.append(bigimg)
        this.addDom(bigimg, index + 1)
    }

    addDom = (dom, index) => {//
        const { creatDom, items, bronDom } = this.state;
        creatDom.push(dom);
        let res = getItems(creatDom.length)
        this.setState({
            creatDom,
            bronDom: [...res]
        })
        document.getElementById('born' + index).append(dom);
    }


    // 选择优惠券
    selectCoupon = () => {
        this.setState({ discountcouponVis: true })
    }
    couponOK = () => {
        this.setState({ discountcouponVis: false, discountcoupon: this.state.selectconpondata })
    }
    couponCancel = () => {
        this.setState({ discountcouponVis: false })
    }
    selectconpon = (record) => {
        let list = {
            id: record.id,
            couponName: record.name,
            indate: record.time
        }
        this.setState({ selectconpondata: this.state.selectconpondata.concat([list]) })
    }
    delCoupon = (record) => {
        let list = _.filter(this.state.discountcoupon, function (item) {
            return item.id !== record.id
        })
        this.setState({ discountcoupon: list })
    }
    // 选择阶梯
    ladder = () => {
        this.setState({ LadderPreferentialVis: true })
    }
    ladderok = () => {
        // this.setState({LadderPreferentialVis:false})
        //console.log(this.state.addladderlist)

    }
    ladderCancel = () => {
        this.setState({ LadderPreferentialVis: false })
    }
    addladder = () => {
        // console.log(Date.now())
        let list = {
            id: Date.now(),
            package_tour: '',
            discounts_money: ''
        }
        this.setState({ addladderlist: this.state.addladderlist.concat([list]) })
    }
    delladderlist = (record) => {
        //console.log(record)
        let list = _.filter(this.state.addladderlist, item => {
            return item['id'] !== record
        })
        // console.log(list)
        this.setState({ addladderlist: list })
    }
    // 早预定早优惠
    veryearly = () => {
        this.setState({ earlyPeservationVis: true })
    }
    onRef = (ref) => {
        this.child = ref
    }



    bornTeam = (arr) => {//成团条件
        //console.log(arr, '成团条件')

        let { min_order, min_pay, group_rule } = this.state;
        //console.log(min_order, min_pay)
        //'min_pay','min_order'
        group_rule = {

        }

        if (arr.find(v => v == 'min_order')) {
            group_rule['min_orders'] = min_order
        }

        if (arr.find(v => v == 'min_pay')) {
            group_rule['min_pay_amount'] = min_pay
        }

        this.setState({
            group_rule
        })

    }

    //移动位置
    movePositon = async (arr, index, length, type) => {

        let goodsList = []//重新赋值

        let resArr = []

        switch (type) {
            case 'up':
                resArr = qs.zIndexUp(arr, index, length);
                break;
            case 'down':
                resArr = qs.zIndexDown(arr, index, length);
                break;
            case 'top':
                resArr = qs.zIndexBottom(arr, index, length);
                break;
            default:
                break;
        }


        for (let i in resArr) {

            //resArr[i]['goods_index'] = i
            for (let k in resArr[i]) {
                resArr[i][k]['sort'] = resArr.length - i;
                goodsList.push(resArr[i][k])
            }
        }

        await goodsList.map((v, i) => {
            v['goods_index'] = i
        })

        await this.setState({
            newFixArr: resArr,
            goodsList
        })

        //console.log(this.state.goodsList, this.state.newFixArr)

    }

    onSelectChange = (selectedRowKeys) => {
        let arr = [];
        selectedRowKeys.map(v => {
            arr.push({
                id: v.id,
                name: v.name
            })
        })
        //console.log(selectedRowKeys,)
        this.setState({ selectedRowKeys, visible_channel: arr });
    };

    select_circle = (item, boole) => {//选择圈子

        let { communeList, circle_name } = this.state;
        communeList.map(v => {
            v['checked'] = false
        })
        console.log(item, 'select_circle')
        item['checked'] = boole ? true : false;
        if (boole) {
            this.setState({
                commune_id: item.id,
                select_circle_name: item.name
            })
        }

    }

    search_circle = async (e) => {//搜索 圈子

        if (e) {
            await this.setState({
                commune_page: e
            })
        }

        let { circle_name, commune_total, commune_page } = await this.state;
        let param = {
            page: commune_page,
            page_size: 10
        }
        let { customer_id } = JSON.parse(await window.localStorage.getItem('userInfo'))

        let sqlarr = [
            { name: 'member_user_id', val: customer_id },
            { name: 'name', val: circle_name }
        ]

        let sql = qs.sqlSearch(sqlarr)//sql

        //console.log(sql)

        param['search'] = sql['search']

        this.getCommuneList(param)

    }

    getCommuneList = async (param) => {//获取圈子列表

        let res = await getCommuneListRequest.send(param);

        if (res.code === 200) {

            let dataArr = res.data.data

            dataArr.map(v => {
                v['checked'] = false
            })

            this.setState({
                communeList: dataArr,
                commune_total: res.data.total
            })
        }

        console.log(res, 'getCommuneListRequest')

    }

    render() {

        let {
            allcansee,
            specsList,
            min_pay,
            min_order,
            groupsListNum,
            goodsList,
            promotions,
            end_time,
            creatType,
            goods_name,
            need_pay,
            arrival_datetime,
            newFixArr,
            selectedRowKeys,
            creatDom,
            release,
            specEntities,
            reloadSku,
            shopArr,
            goodTable,
            copyaddOrchange,
            defaultSelectedRowKeys,
            can_see_circle,
            see_circle,
            communeList,
            commune_page,
            commune_total,
            select_circle_name
        } = this.state;

        // console.log('------------------------ group create render -----------------', specEntities, reloadSku, shopArr)

        const circle_columns = [
            {
                title: '圈子名',
                dataIndex: 'name',
                key: 'name',
                render: text => <p>{text}</p>,
            },
            {
                title: '操作',
                key: 'id',
                render: (ctx) => {
                    //console.log(ctx)
                    return (
                        ctx['checked'] ? (
                            <p onClick={() => { this.select_circle(ctx, false) }}>已选择</p>
                        ) : (<p onClick={() => { this.select_circle(ctx, true) }}>未选择</p>)
                    )
                }
            }
        ]

        const rowSelection = {
            selectedRowKeys,
            //defaultSelectedRowKeys,
            onChange: this.onSelectChange,
        };

        function onPanelChange(value, mode) {
            //console.log(value, mode);
        }

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginBottom: '10px'
        };
        const columns = [
            {
                title: '商品名称',
                dataIndex: 'goods_name'
            },
            {
                title: '销售价（元）',
                dataIndex: 'sell_price'
            },
            {
                title: '最大可售数量',
                render: (ctx) => {
                    let entities = ctx['entities'];
                    if (entities.length == 1) {
                        return (
                            <p>{entities[0]['stock_num']}</p>
                        )
                    } else {
                        return (
                            <p>选规格，查看</p>
                        )
                    }
                }
            },
            {
                title: '操作',
                render: (text, record, index) => {

                    let has = _.find(this.state.selectgoods, item => {
                        return item['goods_id'] === record['id']
                    })

                    //是否多规格
                    let isEntities = record['multi_spec'] === 'on' ? true : false;

                    if (record.checked) {
                        if (!isEntities) {
                            return <span onClick={() => this.selectGoodslist(record, index, [], 'cancel')}
                                style={{ 'color': '#2F76C4', cursor: 'pointer' }}>取消选择</span>
                        } else {
                            return <span onClick={() => this.selectGoodslist(record, index, record['entities'], 'specselect')}
                                style={{ 'color': '#2F76C4', cursor: 'pointer' }}>重新选择</span>
                        }
                    } else {
                        if (!isEntities) {//单规格
                            return <span onClick={() => this.selectGoodslist(record, index, [], 'select')}
                                style={{ 'color': '#2F76C4', 'cursor': "pointer" }}>选择</span>
                        } else {
                            return <span onClick={() => {
                                if (this.state.specsList.length > 0)
                                    this.selectGoodslist(record, index, record['entities'], 'specselect')
                            }}
                                style={{ 'color': '#2F76C4', 'cursor': "pointer" }}>选择规格</span>
                        }

                    }
                }
            },
        ]

        const groupColumns = [
            {
                title: '选择渠道',
                dataIndex: 'name'
            }
        ]

        const timecolumn = [
            {
                title: '日期',
                render: (ctx, record) =>

                    <p style={{ 'color': '#909090', 'cursor': "pointer", fontSize: '12px' }}>{record['date']}</p>

            },
            {
                title: '时间点',
                render: (ctx, record) =>
                    <p style={{ 'color': '#909090', 'cursor': "pointer", fontSize: '12px' }}>{record['time']}</p>

            },
            {
                title: '操作',
                width: 60,
                render: (text, record, index) =>

                    <div>
                        <span onClick={() => this.deleteDate(record, index)}
                            style={{ 'color': '#909090', 'cursor': "pointer", fontSize: '12px' }}>删除</span>
                    </div>
            }
        ]
        const coupondatacol = [
            {
                title: '优惠券名称',
                dataIndex: 'name'
            },
            {
                title: '优惠券类型',
                dataIndex: 'type'
            }, {
                title: '适用团购',
                dataIndex: 'group'
            }, {
                title: '有效期',
                dataIndex: 'time'
            },
            {
                title: '操作',
                render: (text, record) => {
                    let has = _.find(this.state.selectconpondata, item => {
                        return item['id'] === record.id
                    })
                    if (has) {
                        return <span style={({ 'color': '#2F76C4' })}>已选择</span>
                    } else {
                        return <span onClick={() => this.selectconpon(record)}
                            style={({ 'color': '#2F76C4', 'cursor': "pointer" })}>选择</span>

                    }
                }
            },
        ]

        function disabledDate(current) {
            // Can not select days before today and today
            return current && current < moment().endOf('day');
        }


        //console.log(arrival_datetime, )

        return (
            <div style={{ height: '100%', overflowY: 'auto' }}>
                {/* 保存分组 */}
                <Modal
                    title={release === 'off' ? '保存' : '立即发布'}
                    visible={this.state.saveVisible}
                    onCancel={this.handleCancel}
                    footer={false}>
                    <div>团购名称: {this.state.group_name}</div>
                    {/* <div className='flex' style={({ alignItems: 'flex-start' })}>
                        可见范围：
                        <Radio.Group value={allcansee} onChange={(e) => { this.setState({ allcansee: e.target.value }) }}>
                            <Radio style={({ display: 'block' })} value={true}>全部渠道可见</Radio>
                            <Radio value={false}>
                                部分渠道可见
                                {
                                    !allcansee ? (
                                        <>
                                            <span>(</span>
                                            {
                                                this.state.visible_channel.map((data) => {
                                                    return (
                                                        <span>{data['name']}、</span>
                                                    )
                                                })
                                            }
                                            <span>)</span>
                                        </>
                                    ) : null
                                }
                            </Radio>
                        </Radio.Group>
                    </div>

                    {!allcansee ? (
                        <>
                            <Table
                                scroll={
                                    {
                                        scrollToFirstRowOnChange: false,
                                        x: false,
                                        y: 300
                                    }
                                }
                                rowKey={record => record}
                                rowSelection={rowSelection}
                                columns={groupColumns}
                                dataSource={groupsListNum}
                                pagination={false}
                                onChange={(e) => {

                                }}
                            >
                            </Table>

                        </>
                    ) : null} */}

                    {this.state.release === 'off' ? (
                        <div className='flex newGroupBtn'>
                            <div onClick={() => { this.conSub('off') }} className='contrlbtn'>保存</div>
                            <div onClick={() => { this.conSub('on') }} className='contrlbtn'>一键发布</div>
                        </div>
                    ) : (<div className='flex newGroupBtn'>
                        <div onClick={() => { this.conSub('on') }} className='contrlbtn'>确定</div>
                    </div>)}

                </Modal>
                {/* 选择商品 */}
                <Modal
                    style={{ textAlign: 'center' }}
                    title="选择商品"
                    centered
                    onCancel={this.handleCancel}
                    visible={this.state.isModalVisible}
                    footer={false}>
                    <div style={{ marginBottom: '20px', textAlign: 'left', marginTop: '10px' }}>
                        <Input
                            onChange={async (e) => {
                                await this.setState({ goods_name: e.target.value })
                                this.getData()
                            }}
                            value={goods_name}
                            style={({ width: 200, marginRight: '10px' })}
                            placeholder='请输入商品名称/商品条码' />
                        <Select placeholder='请选择'
                            value={this.state.selectOption}
                            style={({ width: 120, marginRight: '10px' })}
                            onChange={async (e) => {
                                this.setState({ selectOption: e })
                                await this.setState({ category_id: e })
                                this.getData()
                            }}>
                            {this.state.goodstype.map(v => {
                                return <Select.Option key={v['id']} value={v['id']}>{v['cate_name']}</Select.Option>
                            })}
                        </Select>
                        <Button style={{ float: 'right' }} icon={<SearchOutlined />}>搜索</Button>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={goodTable}
                        rowKey={record => record.id}
                        scroll={({ y: 300 })}
                        pagination={false}
                    />

                    {/* <SelectGoods allList={this.state.goodTable}></SelectGoods> */}

                    <div className='flex' style={({ overflow: 'hidden', textAlign: 'center', justifyContent: 'center', margin: '20px 0 10px 0' })}>
                        <div style={({ lineHeight: '32px', padding: '0 10px', float: 'right', marginRight: '20px' })}
                            className='ant-btn ant-btn-default ant-btn-sm button-bac' onClick={this.handleCancel}>取消
                        </div>
                        <div style={({ lineHeight: '32px', padding: '0 10px', float: 'right' })}
                            className='ant-btn ant-btn-default ant-btn-sm button-bac' onClick={this.handleOk}>确定
                        </div>
                    </div>
                </Modal>

                <Modal
                    title='选择规格'
                    centered
                    visible={this.state.skuvisible}
                    onCancel={() => { this.setState({ skuvisible: false }) }}
                    footer={
                        <Button key="back" onClick={() => this.setState({ skuvisible: false })}>
                            确定
                        </Button>
                    }
                >
                    <Sku
                        ref='SkuList'
                        selectedEntities={this.state.tempSelectedEntities}
                        specData={specsList}
                        entities={specEntities}
                        changeEntities={(entities) => {
                            let selectedEntities = this.state.tempSelectedEntities;
                            for (let entity of entities) {
                                let index = findIndex(selectedEntities, function (e) {
                                    return e['id'] === entity['id'];
                                });
                                if (index === -1) {
                                    selectedEntities.push(entity);
                                } else {
                                    selectedEntities[index] = entity;
                                }
                            }
                            this.setState({ tempSelectedEntities: selectedEntities });

                            //console.log(selectedEntities, 'selectedEntitiesselectedEntitiesselectedEntities')
                        }} />

                </Modal>

                <Modal
                    centered
                    title='选择到货时间'
                    style={{ height: '400px', textAlign: 'center' }}
                    visible={this.state.selecttimeVisible}
                    onOk={this.timeOK} onCancel={this.timeCancel}
                    footer={
                        <div className='flex'>
                            <Button onClick={this.timeCancel}>取消</Button>
                            <Button onClick={this.timeOK}>确定</Button>
                        </div>
                    }
                >
                    {/* <div className="site-calendar-demo-card">
                        <Calendar
                            locale={locale}
                            fullscreen={false}
                            disabledDate={disabledDate}
                            onPanelChange={onPanelChange}
                            onChange={this.getlimitDate}
                            defaultValue={moment()}
                        />
                    </div> */}
                    <div style={{
                        textAlign: 'left', marginBottom: 15
                    }}>
                        请选择到货日期：<RangePicker locale={locale} onChange={(e, arr) => {
                            //console.log(e, arr, qs.getdiffdate(arr[0], arr[1]));
                            this.addArrDate(e, arr)
                        }} />
                    </div>
                    {/* <DatePicker style={{ width: '300px' }} locale={locale} onChange={this.arrivalTime} /> */}
                    <div style={{ textAlign: 'left' }}>
                        请填写具体时间：
                        {/* <InputNumber onChange={(e) => {
                     e < 10 ? this.setState({ hours: '0' + e }) : this.setState({ hours: e })
                  }} value={this.state.hours} max={23} min={0} /> <span>时</span>

                  <InputNumber value={this.state.minutes} max={59} min={0}
                     onChange={(e) => {
                        e < 10 ? this.setState({ minutes: '0' + e }) : this.setState({ minutes: e })
                     }}
                  />
                  <span>分</span> */}
                        <TimePicker
                            onChange={(e) => this.timeLimit(e, 0)}
                            format={'HH:mm'}
                            locale={locale}
                        />
                        <span style={{ padding: '0 5px' }}>至</span>
                        <TimePicker
                            onChange={(e) => this.timeLimit(e, 1)}
                            format={'HH:mm'}
                            locale={locale}
                        />
                    </div>
                </Modal>

                <Modal
                    title='选择优惠券'
                    style={{ textAlign: 'center' }}
                    visible={this.state.discountcouponVis}
                    onOk={this.couponOK}
                    onCancel={this.couponCancel}>
                    <Table
                        dataSource={this.state.coupondata}
                        columns={coupondatacol}
                        scroll={{ y: 300 }}
                        rowKey={record => record.id}
                    />
                </Modal>

                <Modal
                    title='设置阶梯优惠'
                    style={{ textAlign: 'center' }}
                    visible={false}
                    onOk={this.ladderok}
                    onCancel={this.ladderCancel}>
                    {/* this.state.LadderPreferentialVis */}

                    <span>当跟团数≥设定的阶梯数量时，参与人将自动获得相对应的优惠，截团时进行结
                        算，之前支付的差价会原路退回给参与者</span>
                    <ul style={{ maxHeight: 500, overflow: "auto" }}>
                        {
                            this.state.addladderlist.map(item => {
                                return <li className='ladder' key={item.id}>
                                    <div style={{ paddingTop: 20 }}>
                                        <span>跟团数：</span> <Input value={item.package_tour}
                                            style={{ width: 250, borderRadius: 20 }} />
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <span>优惠：</span> 实付金额优惠<Input value={item.discounts_money}
                                            style={{ width: 80, borderRadius: 20 }} />元 <span
                                                onClick={(e) => this.delladderlist(item.id)}
                                                style={{ float: 'right', paddingRight: 30, cursor: 'pointer' }}>删除</span>
                                    </div>
                                </li>
                            })
                        }
                    </ul>

                    <Button className='add' onClick={this.addladder}>添加</Button>
                </Modal>

                <div className='group-content'>
                    <ul className='modal'>
                        <li className='modal-con'>
                            <TemplateIntroduce
                                onRef={this.onRef}
                                edmo={(e) => { this.setState({ edomCont: e }) }}
                                group_name={this.state.group_name}
                                groupName={e => { this.setState({ group_name: e }) }}
                                edom={this.state.edom} />
                        </li>
                        <li className='modal-con'>
                            <div className='boxShadow'>
                                <h1 className='title'>2.选择团购商品</h1>
                                <Button className='search-btn' onClick={this.selectGoods} type='primary'>选择商品</Button>
                                <span style={{ fontSize: '12px' }}>已选择{goodsList ? goodsList.length : 0}件</span>
                                <Spin spinning={this.state.spinning} />

                                <div className='goods_card'>
                                    {
                                        newFixArr.map((data, index) => {

                                            return (
                                                <ul className='list-bg' key={data.id + '_' + index} data-id={data.entity_id}>
                                                    {/* <CloseCircleOutlined
                                                        className="inco-sty"
                                                        onClick={() => { this.fixDeteleGoods(data.id, data, index, data.entity_id) }} /> */}

                                                    <li>
                                                        <div className='flex movePosition'>
                                                            <div className='goods_Number'>
                                                                {index + 1}
                                                            </div>
                                                            <div className='flex'>
                                                                {
                                                                    index > 0 ? (<div className='btn' onClick={() => { this.movePositon(newFixArr, index, newFixArr.length, 'down') }}>上移</div>) : null
                                                                }
                                                                {
                                                                    index < newFixArr.length - 1 ? (<div className='btn' onClick={() => { this.movePositon(newFixArr, index, newFixArr.length, 'up') }}>下移</div>) : null
                                                                }
                                                                {
                                                                    index > 0 ? (<div className='btn' onClick={() => { this.movePositon(newFixArr, index, newFixArr.length, 'top') }} >置顶</div>) : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <span className='title-lab'>商品名称：</span>
                                                        <span
                                                            style={{ fontSize: '12px', color: '#8c8c8c' }}>{data[0].goods_name}</span>
                                                    </li>

                                                    <li>
                                                        <table className='fixArrTable' border="0" style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                            <tr>
                                                                <th>规格</th>
                                                                <th>团购价格(元)</th>
                                                                <th>可售数量</th>
                                                                <th>操作</th>
                                                            </tr>

                                                            {
                                                                data.map((item, bot_index) => {

                                                                    return item.multi_spec === 'on' && item.entity && item.entity['spec_values'].length ? (
                                                                        <>
                                                                            <tr>
                                                                                <td>
                                                                                    {item.entity['spec_values'].length ? item.entity['spec_values'].map(k => {

                                                                                        return (
                                                                                            <span key={k} style={{ color: '#8c8c8c' }}>{k['specName']}: {k['specValue']['value']}  </span>
                                                                                        )

                                                                                    }) : '-'}
                                                                                </td>
                                                                                <td>
                                                                                    <Input className='writeBox' onChange={(e) => this.changeMaxPrice(e, index, bot_index)} value={item['sell_price']} />
                                                                                </td>
                                                                                <td>
                                                                                    <Input className='writeBox' onChange={(e) => this.changeMaxSale(e, index, bot_index)} value={item['stock_num']} />
                                                                                </td>
                                                                                <td className='pointer' onClick={() => { this.fixDeteleGoods(item, index, bot_index) }}>删除</td>
                                                                            </tr>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <td>-</td>
                                                                            <td>
                                                                                <Input className='writeBox' onChange={(e) => this.changeMaxPrice(e, index, bot_index)} value={item['sell_price']} />
                                                                            </td>
                                                                            <td>
                                                                                <Input className='writeBox' onChange={(e) => this.changeMaxSale(e, index, bot_index)} value={item['stock_num']} />
                                                                            </td>
                                                                            <td className='pointer' onClick={() => { this.deletegoods_(item.id, item, index, item.entity_id) }}>删除</td>
                                                                        </>
                                                                    )

                                                                })
                                                            }

                                                        </table>
                                                    </li>

                                                    <li style={{
                                                        marginTop: '10px',
                                                        display: 'flex',
                                                        alignItems: 'flex-start'
                                                    }}>
                                                        <span className='title-lab'>商品图片：</span>
                                                        <Image style={{ maxWidth: 60, maxHeight: 60, marginLeft: '5px' }} src={data[0].goods_image} alt='' />
                                                    </li>

                                                    {/* <li style={{
                                                        marginTop: '10px',
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        fontSize: 13
                                                    }}>
                                                        <span className='title-lab'>可购数量：</span>
                                                        <Radio.Group onChange={() => { }} value={1}>
                                                            <Radio value={1}>不限购数量</Radio>
                                                            <br />
                                                            <Radio value={2}>
                                                                限购
                                                                <Input style={{
                                                                    width: 40,
                                                                    padding: '0 5px',
                                                                    border: 0,
                                                                    borderBottom: '1px solid #8c8c8c',
                                                                    borderRadius: 0,
                                                                    backgroundColor: '#f6f6f6'
                                                                }} />件</Radio>

                                                        </Radio.Group>
                                                    </li> */}

                                                </ul>
                                            )

                                        })
                                    }
                                </div>
                            </div>
                        </li>
                        <li className='modal-con'>

                            <div className='boxShadow'>
                                <h1 className='title'>3.团购设置</h1>
                                <div className='flex'>
                                    <div><span style={({ color: '#ff0000', fontSize: '13px' })}>* </span> 到货日期:</div>
                                    <div className='flex addGoods' onClick={this.addtime}
                                        style={({ marginLeft: '15px', padding: '3px 10px' })}>
                                        + 添加到货时间
                                    </div>
                                </div>
                                {arrival_datetime.length ? (
                                    <>
                                        {/* <Table
                                            columns={timecolumn}
                                            locale={locale}
                                            dataSource={arrival_datetime}
                                            rowKey={record => JSON.stringify(record)}
                                            pagination={false}
                                            style={{ fontSize: '12px' }}
                                            scroll={({ y: 150 })}
                                        /> */}
                                        <table className='add_arrival_time'>
                                            <tr>
                                                <th>日期</th>
                                                <th>时间点</th>
                                                <th>操作</th>
                                            </tr>
                                            {arrival_datetime.map((v, i) => {
                                                if (v['action'] !== 0) {
                                                    return <tr>
                                                        <td>{v.date}</td>
                                                        <td>{v.time}</td>
                                                        <td className='pointer' onClick={() => this.deleteDate(v, i)}>删除</td>
                                                    </tr>
                                                }

                                            })}


                                        </table>

                                        <div style={{ margin: '10px 0' }}>
                                            <div style={{ display: 'inline-block', position: 'relative' }}>
                                                <div>
                                                    <span className="sty-left" style={{ verticalAlign: 'middle' }}>
                                                        <span style={{
                                                            color: '#ff0000',
                                                            fontSize: '13px'
                                                        }}>* </span>截单时间：</span>
                                                    到货日期前 <Input style={{ width: '100px' }}
                                                        value={this.state.end_day}
                                                        className='condition' onChange={(e) => {
                                                            this.getEndDay(e)
                                                        }} />天
                                                </div>
                                                <div><span className="sty-left" style={{ verticalAlign: 'middle' }}>
                                                    <span style={{
                                                        color: '#ff0000',
                                                        fontSize: '13px'
                                                    }}>* </span>截单时刻：</span>
                                                    <TimePicker
                                                        defaultValue={moment(end_time, format)}
                                                        onChange={(e, i) => { this.setState({ end_time: i }) }}
                                                        format={'HH:mm'}
                                                        locale={locale}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </>

                                ) : null}


                                {
                                    creatType == 1 ? (
                                        <div style={{ margin: '10px 0', alignItems: 'flex-start' }} className="flex">
                                            <span className="sty-left">成团条件：</span>
                                            <div className='flex bronTeam'>
                                                <Checkbox.Group onChange={this.bornTeam}>
                                                    <div>
                                                        <Checkbox value="min_order"></Checkbox>
                                                        <label> 订单数
                                                            <InputNumber keyboard={false} bordered={false} min={0} width={100}
                                                                style={{ width: '70px' }} placeholder='选填' value={min_order}
                                                                onChange={(e) => {
                                                                    let num = e
                                                                    let { group_rule } = this.state
                                                                    if (isNaN(num)) { num = 0 }
                                                                    num = parseInt(Math.abs(num))
                                                                    group_rule['min_orders'] = num;
                                                                    this.setState({ min_order: num, group_rule })
                                                                }} />
                                                            笔 </label>
                                                    </div>
                                                    <div>
                                                        <Checkbox value="min_pay"></Checkbox>
                                                        <label> 实收金额
                                                            <InputNumber keyboard={false} bordered={false} min={0} width={100}
                                                                style={{ width: '70px' }} placeholder='选填' value={min_pay}
                                                                onChange={(e) => {
                                                                    let num = e
                                                                    let { group_rule } = this.state
                                                                    if (isNaN(num)) { num = 0 }
                                                                    num = Math.abs(num)
                                                                    group_rule['min_pay_amount'] = num;
                                                                    this.setState({ min_pay: num, group_rule })
                                                                }} />
                                                            元 </label>
                                                    </div>
                                                </Checkbox.Group>
                                            </div>
                                        </div>

                                    ) : (
                                        <div style={{ margin: '10px 0', alignItems: 'center' }} className="flex">
                                            <span className="sty-left">订货条件：</span>
                                            每单需> <Input onChange={(e) => {
                                                let num = e.target.value
                                                let { group_rule } = this.state
                                                if (isNaN(num)) { num = 0 }
                                                num = Math.abs(num)
                                                group_rule['min_pay_amount'] = num;
                                                this.setState({ min_pay: num, group_rule })
                                            }} style={{ width: 50, borderStyle: 'none none solid none' }}></Input> 元
                                        </div>
                                    )
                                }


                                <div className='flex' style={{ margin: '10px 0' }}>
                                    <span className="sty-left">配送方式：</span>
                                    <Radio.Group onChange={(e) => {
                                        this.setState({ delivery_type: e.target.value })
                                    }} value={this.state.delivery_type}>
                                        <Radio style={radioStyle} value={2}>
                                            配送到团长自提点
                                        </Radio>
                                        {/* <Radio style={radioStyle} value={1}>
                                            快递发货到用户
                                        </Radio> */}
                                    </Radio.Group>
                                </div>

                                {
                                    creatType == 1 ? (
                                        <div className='flex' style={{ margin: '20px 0' }}>
                                            <span className="sty-left">需要供应商确认开团：</span>
                                            <Switch onChange={async (e) => { await this.setState({ open_audit: e ? 1 : 0 }); }}></Switch>
                                        </div>
                                    ) : null
                                }

                                <div style={{ margin: '10px 0' }}>
                                    <span className="sty-left">退款设置：</span>
                                    <Select defaultValue="0" style={{ width: 120 }} onChange={(e) => { e = parseInt(e); this.setState({ refund_limit: e }) }}>
                                        <Option value="0">禁止退款</Option>
                                        <Option value="100">允许截单前退款</Option>
                                        {/* {
                                            creatType == 1 ? (
                                                <Option value="100">允许截单前退款</Option>
                                            ) : null
                                        } */}
                                        {/* <Option value="200">允许截单后退款</Option> */}
                                    </Select>
                                </div>

                                {/* 满赠 */}
                                <Reduce
                                    getPromotions={promotions}
                                    specsList={specsList}
                                    promotions={async (e) => {
                                        await window.localStorage.setItem('promotions', JSON.stringify(e));
                                        this.setState({ promotions: e })
                                    }}>
                                </Reduce>

                                <div className='flex' style={{ margin: '20px 0' }}>
                                    <span className="sty-left">是否允许用户线下支付：</span>
                                    <Switch checked={need_pay} onChange={async (e) => { await this.setState({ need_pay: e }); }}></Switch>
                                </div>

                            </div>
                        </li>
                        <li className='modal-con'>
                            <div className='boxShadow'>
                                <h1 className='title'>4.营销设置</h1>
                                <p className='onlySee'>仅团长可见</p>
                                <div>
                                    <ul>
                                        {/* <li style={{ marginBottom: 10 }}>
                                 是否有增品：<Radio.Group onChange={(e) => { this.setState({ freebieValue: e.target.value }) }} value={this.state.freebieValue}>
                                    <Radio value='y'>是</Radio>
                                    <Radio value='n'>否</Radio>
                                 </Radio.Group>
                              </li>
                              <li style={{ marginBottom: 10 }}>
                                 是否有优惠券：<Button className='search-btn' type='primary' onClick={this.selectCoupon}>选择优惠券</Button>
                                 {
                                    this.state.discountcoupon.length > 0 ?
                                       <div>
                                          <Table
                                             dataSource={this.state.discountcoupon}
                                             columns={discountcouponcol}
                                          />
                                       </div> : ""

                                 }
                              </li> */}
                                        {
                                            creatType == 1 ? (
                                                <li style={{ marginBottom: 10 }}>
                                                    分佣设置：
                                                    <InputNumber min={0} value={this.state.commission_rate} onChange={this.setRate} style={{ width: 80, borderRadius: 4 }} ></InputNumber> %
                                                </li>
                                            ) : null
                                        }


                                        <li className='openTeamKnow'>
                                            开团须知：
                                            <textarea maxLength={50} onChange={e => { this.setState({ group_notice: e.target.value }) }} placeholder='请输入开团须知(50字内)：' className='textarea' rows={3}></textarea>
                                        </li>


                                        {
                                            see_circle ? (
                                                <div className='flex' style={{ margin: '20px 0' }}>
                                                    <span className="sty-left">可见圈子：</span>
                                                    <Switch checked={can_see_circle} onChange={async (e) => { await this.setState({ can_see_circle: e }); }}></Switch>
                                                </div>
                                            ) : null
                                        }

                                        {
                                            can_see_circle && see_circle ? (
                                                <div className='can_see_circle'>

                                                    {
                                                        select_circle_name ? (
                                                            <div className='select_circle_name flex'>
                                                                <p >已选: {select_circle_name}</p>
                                                                <CloseCircleOutlined onClick={() => {
                                                                    let { communeList, circle_name } = this.state;
                                                                    communeList.map(v => {
                                                                        v['checked'] = false
                                                                    })
                                                                    this.setState({
                                                                        select_circle_name: null,
                                                                        commune_id: null
                                                                    })
                                                                }} />
                                                            </div>
                                                        ) : null
                                                    }

                                                    <div className='flex searchBox'>
                                                        <Input placeholder='输入圈子名称' onChange={async (e) => { await this.setState({ circle_name: e.target.value }) }} />
                                                        <Button type="primary" icon={<SearchOutlined />} onClick={() => { this.search_circle() }}>
                                                            搜索
                                                        </Button>
                                                    </div>

                                                    <Table columns={circle_columns} dataSource={communeList} pagination={false} />
                                                    <div className='page_style'>
                                                        <Pagination
                                                            pageSize={10}
                                                            hideOnSinglePage={true}
                                                            onChange={(e) => { this.search_circle(e) }}
                                                            defaultCurrent={1} showSizeChanger={false}
                                                            current={commune_page}
                                                            total={commune_total} />
                                                    </div>

                                                </div>
                                            ) : null
                                        }


                                        <div className='flex' style={{ margin: '20px 0' }}>
                                            <span className="sty-left">可见渠道：</span>
                                            <Switch checked={!can_see_circle} onChange={async (e) => { await this.setState({ can_see_circle: !e }); }}></Switch>
                                        </div>

                                        {
                                            !can_see_circle ? (
                                                <>
                                                    {/* <div>团购名称: {this.state.group_name}</div> */}
                                                    <div className='flex' style={({ alignItems: 'flex-start' })}>
                                                        可见范围：
                                                        <Radio.Group value={allcansee} onChange={(e) => { this.setState({ allcansee: e.target.value }) }}>
                                                            <Radio style={({ display: 'block' })} value={true}>全部渠道可见</Radio>
                                                            <Radio value={false}>
                                                                部分渠道可见
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>

                                                    {
                                                        !allcansee && this.state.visible_channel.length ? (
                                                            <p>
                                                                <span>(</span>
                                                                {
                                                                    this.state.visible_channel.map((data) => {
                                                                        return (
                                                                            <span>{data['name']}、</span>
                                                                        )
                                                                    })
                                                                }
                                                                <span>)</span>
                                                            </p>
                                                        ) : null
                                                    }

                                                    {!allcansee ? (
                                                        <>
                                                            <Table
                                                                scroll={
                                                                    {
                                                                        scrollToFirstRowOnChange: false,
                                                                        x: false,
                                                                        y: 300
                                                                    }
                                                                }
                                                                rowKey={record => record}
                                                                rowSelection={rowSelection}
                                                                columns={groupColumns}
                                                                dataSource={groupsListNum}
                                                                pagination={false}
                                                                onChange={(e) => {
                                                                    //console.log(e,)
                                                                }}
                                                            >
                                                            </Table>
                                                            {/* <Pagination defaultCurrent={1} total={50} /> */}
                                                        </>
                                                    ) : null}
                                                </>
                                            ) : null
                                        }









                                        {/* <li style={{ marginBottom: 10 }}>
                                            市场推广服务费：<Switch onChange={async (e) => { await this.setState({ open_fee: e ? 1 : 0 }); }}></Switch>
                                        </li>
                                        <li style={{ marginBottom: 10 }}>
                                            选择公社：
                                            <Select defaultValue="lucy" style={{ width: 120 }} onChange={()=>{}}>
                                                <Option value="jack">Jack</Option>
                                                <Option value="lucy">Lucy</Option>
                                                <Option value="Yiminghe">yiminghe</Option>
                                            </Select>
                                        </li>
                                        <li style={{ marginBottom: 10 }}>
                                            服务费比例：<InputNumber min={0} value={this.state.fee_rate} onChange={this.setFeeRate} style={{ width: 80, borderRadius: 4 }} ></InputNumber> %
                                        </li> */}
                                        {/* <li style={{ marginBottom: 10 }}>
                                 阶梯优惠：<Button className='search-btn' type='primary' onClick={this.ladder}>设置</Button>
                                 {
                                    this.state.LadderPreferential.length > 0 ?
                                       <div>
                                          <Table
                                             dataSource={this.state.LadderPreferential}
                                             columns={LadderPreferentialcol}
                                          />
                                       </div> : ''
                                 }
                              </li>
                              <li style={{ marginBottom: 10 }}>
                                 早预定早优惠：<Button className='search-btn' type='primary' onClick={this.veryearly}>设置</Button>
                                 {
                                    this.state.LadderPreferential.length > 0 ?
                                       <div>
                                          <Table
                                             dataSource={this.state.earlyPeservation}
                                             columns={earlyPeservationCol}
                                          />
                                       </div> : ''
                                 }
                              </li> */}
                                    </ul>
                                    {/* <div>
                                        <ShowShop receive={this.state.shopArr}></ShowShop>
                                        <ContrlShop sendRes={(e) => { this.setState({shopArr: e}) }}></ContrlShop>
                                    </div> */}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className='fotter flex' style={{ justifyContent: 'center' }}>
                        <Button type='primary' onClick={() => this.save('off')} className='issue'>
                            <i className='save'></i>保存
                        </Button>
                        <Button type='primary' onClick={() => this.save('on')} className='issue'>
                            <i className='send'></i>立即发布
                        </Button>
                    </div>
                    {/* {
                        !copyaddOrchange ? (
                            <div className='fotter flex' style={{ justifyContent: 'center' }}>
                                <Button type='primary' onClick={() => this.save('off')} className='issue'>
                                    <i className='save'></i>保存
                                </Button>
                                <Button type='primary' onClick={() => this.save('on')} className='issue'>
                                    <i className='send'></i>立即发布
                                </Button>
                            </div>
                        ) : (
                            <div className='fotter flex' style={{ justifyContent: 'center' }}>
                                <Button type='primary' onClick={() => { this.props.history.go(-1) }} className='issue'>
                                    <i className='save'></i>返回
                                </Button>
                                <Button type='primary' onClick={() => this.save('on')} className='issue'>
                                    <i className='send'></i>立即发布
                                </Button>
                            </div>
                        )
                    } */}
                </div>
            </div>
        )
    }
}

export default router(NewGroups)