import { Request } from '@zoranwong/acc-engine.js';
export default class PrintRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        
    };
    _method = 'GET';
    _uri = '/order/summary/merchant/export-summary-order-url2';
    constructor(params) {
        super(params);
        this._data['end_date'] = params['end_date'];
        this._data['search_json'] = params['search_json'];
    }
    rules() {

    }

    messages() {

    }
}