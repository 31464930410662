
//路书
//路书
import React from 'react';
import arrivalPng from './images/arrival.png'

const BMap = window.BMap;
const BMapLib = window.BMapLib;
export default class BaiduMapBookLushu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lushu: {},
            lushu2: {},
            lushu3: {},
        }
    }
    componentDidMount = () => {
        // 百度地图API功能
        var map = new BMap.Map('allmap');
        map.enableScrollWheelZoom();
        map.centerAndZoom(new BMap.Point(116.404, 39.915), 13);
        const BMAP_STATUS_SUCCESS = 0
        const that = this
        // 实例化一个驾车导航用来生成路线
        var drv = new BMap.DrivingRoute('合肥', {
            onSearchComplete: function (res) {
                console.log(drv.getStatus(), res)
                if (drv.getStatus() == BMAP_STATUS_SUCCESS) {
                    var arrPois = res.getPlan(0).getRoute(0).getPath();
                    map.addOverlay(new BMap.Polyline(arrPois, { strokeColor: '#366FBB' }));
                    map.setViewport(arrPois);
                    console.log(0, BMapLib.LuShu)

                    that.setState({
                        lushu: new BMapLib.LuShu(map, arrPois, {
                            defaultContent: "",//"从天安门到百度大厦"
                            autoView: true,//是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
                            icon: new BMap.Icon(arrivalPng, new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) }),
                            speed: 500,
                            enableRotation: true,//是否设置marker随着道路的走向进行旋转
                        })
                    })
                }
            }
        });

        // 实例化一个驾车导航用来生成路线
        var drv2 = new BMap.DrivingRoute('合肥', {
            onSearchComplete: function (res) {
                console.log(drv.getStatus(), res)
                if (drv.getStatus() == BMAP_STATUS_SUCCESS) {
                    var arrPois = res.getPlan(0).getRoute(0).getPath();
                    map.addOverlay(new BMap.Polyline(arrPois, { strokeColor: '#111' }));
                    map.setViewport(arrPois);
                    console.log(0, BMapLib.LuShu)

                    that.setState({
                        lushu2: new BMapLib.LuShu(map, arrPois, {
                            defaultContent: "",//"从天安门到百度大厦"
                            autoView: true,//是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
                            icon: new BMap.Icon(arrivalPng, new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) }),
                            speed: 1000,
                            enableRotation: true,//是否设置marker随着道路的走向进行旋转
                        })
                    })
                }
            }
        });

        // 实例化一个驾车导航用来生成路线
        var drv3 = new BMap.DrivingRoute('合肥', {
            icon: new BMap.Icon(arrivalPng),
            onSearchComplete: function (res) {
                console.log(drv.getStatus(), res)
                if (drv.getStatus() == BMAP_STATUS_SUCCESS) {
                    var arrPois = res.getPlan(0).getRoute(0).getPath();
                    map.addOverlay(new BMap.Polyline(arrPois, { strokeColor: '#ffff00' }));
                    map.setViewport(arrPois);
                    console.log(0, BMapLib.LuShu)

                    that.setState({
                        lushu3: new BMapLib.LuShu(map, arrPois, {
                            defaultContent: "",//"从天安门到百度大厦"
                            autoView: true,//是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
                            icon: new BMap.Icon(arrivalPng, new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) }),
                            speed: 1000,
                            enableRotation: true,//是否设置marker随着道路的走向进行旋转
                        })
                    })
                }
            }
        });
        var pt = new BMap.Point(116.350658, 39.938285); // 图片所在的位置点
        var myIcon = new BMap.Icon(arrivalPng, new BMap.Size(24, 24));  // 70 80表示图片的宽高
        var marker2 = new BMap.Marker(pt, { icon: myIcon });  // 创建标注
        map.addOverlay(marker2);              // 将标注添加到地图中


        drv.search('西湖国际广场A座', '蜀山森林公园');
        drv2.search('合肥高铁南站', '安徽新华学院');
        drv3.search('合肥高铁南站', '合肥客运总站');

    }

    clearAll = () => {

    }

    componentWillReceiveProps(e) {

        if (e.clear) {
            this.clearAll()
        }

    }

    render() {
        let { lushu, lushu2, lushu3 } = this.state
        return (
            <>
                <div id="allmap" style={{ width: '100%', height: '500px' }}></div>
                <button onClick={() => { lushu.start(); }}>run</button>
                <button onClick={() => { lushu2.start(); }}>run2</button>
                <button onClick={() => { lushu3.start(); }}>run3</button>
            </>
        )
    }
}