import { Request } from '@zoranwong/acc-engine.js';
export default class OrderExportListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        
    };
    _method = 'GET';
    _uri = '/order/group-order/merchant-order/get-order-export-task-list';
    constructor(params) {
        super(params);
        this._data['page'] = params['page'] || 1;
        this._data['page_size'] = params['page_size'] || 15;
    }
    rules() {

    }

    messages() {

    }
}