import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import { Input, DatePicker, Button, InputNumber, Pagination, Tooltip, message } from 'antd'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import commanderListRequest from '../api/commanderListRequest';//列表
import { SearchOutlined } from '@ant-design/icons';
import '../css/commanderList.sass'
import nodataimg from '../../../assets/nodetaillist.png'
import moneyicon from '../../../assets/money.png'
import openteamicon from '../../../assets/openteam.png'
import gifticon from '../../../assets/gift.png'
import membersicon from '../../../assets/members.png'
import qs from "../../../utils/qs";
import { env } from "../../../utils/functions";

const { RangePicker } = DatePicker;

class CommanderList extends Base {
   constructor(props) {
      super(props)
      this.state = {
         seachname: '',
         min_num: '',
         max_num: '',
         list: [],
         page: 1,
         current_page: 1,
         total: 0,
         created_at: '',
         sell_price: ''
      }
   }

   becamegroup = (data, dataString) => {
      this.setState({
         created_at: dataString
      })
   }

   componentDidMount() {
      this.getCommanderList()//列表请求
   }

   clearAll = async () => {//清空所有搜索条件
      await this.setState({
         seachname: '',
         min_num: '',
         max_num: '',
         created_at: '',
         sell_price: ''
      })
      this.getCommanderList()//列表请求
   }

   export_merchant = async () => {//导出团长
      let merchant_id = await window.localStorage.getItem('merchant_id');
      if (!merchant_id) return message.error('请重新登录！');
      //console.log(process.env)
      qs.download(`${process.env.REACT_APP_EXPORT_URL}/member/supplier/admin/channel/supplier-export-grouper-list-info?merchant_id=${merchant_id}`)
   }


   getCommanderList = async () => {

      const hide = message.loading('加载数据中')

      let { seachname, created_at, min_num, max_num, page } = this.state

      let sqlarr = [
         { name: 'first_name', val: seachname }
      ]

      if (created_at[0]) {
         created_at = created_at.join('~');
         sqlarr.push({ name: 'created_at', val: created_at })
      }

      if (min_num || max_num) {
         sqlarr.push({ name: 'sell_price', val: `${min_num}~${max_num}` })
      }

      let sql = qs.sqlSearch(sqlarr)//sql 

      let param = {
         page: page
      }

      if (sql) {
         param['search'] = sql['search']
      }

      let res = await commanderListRequest.send(param);

      if (res.code == 200) {
         this.setState({
            list: res['data']['data'],
            total: res['data']['total']
         })
      }

      hide();
   }

   render() {
      const { list, current_page, total } = this.state
      return (
         <>
            <div className='commanderList' style={{ margin: '0 0 0 20px' }}>
               <div className='search-title'>
                  <div className='flex'>
                     <div style={{ marginRight: '40px' }}>
                        <label>团长搜索：</label>
                        <Input
                           value={this.state.seachname}
                           className='search-inp'
                           placeholder='请输入详细地址/团长昵称/团长手机号'
                           onChange={(e) => { this.setState({ seachname: e.target.value }) }}
                        />
                     </div>
                     <div style={{ marginRight: '40px' }}>成为团长时间：<RangePicker onChange={this.becamegroup} style={{ borderRadius: '4px', width: '200px' }} locale={locale} /></div>
                     <div>
                        累计销售额：
                        <InputNumber onChange={(e) => { this.setState({ min_num: e }) }} placeholder='请输入数字' value={this.state.min_num} style={{ width: '120px', borderRadius: "4px" }} /> ~
                        <InputNumber onChange={(e) => { this.setState({ max_num: e }) }} placeholder='请输入数字' value={this.state.max_num} style={{ width: '120px', borderRadius: "4px" }} />
                     </div>
                     <div className='export_merchant'>

                        <Button style={{backgroundColor:'#366FBB'}} type="primary" onClick={() => { this.export_merchant() }}>导出团长</Button>

                     </div>
                  </div>
                  <div>
                     <Button className='search-btn' onClick={() => { this.getCommanderList() }}><SearchOutlined style={{ fontSize: '15px' }} />搜索</Button>
                     <span className='clear' onClick={this.clearAll}>清空所有搜索条件</span>
                  </div>

               </div>

               {
                  list && list.length ? (

                     <ul className='datalist'>
                        {
                           list.map((v, index) => {
                              return <li className='datalist_son' key={index}>
                                 <div className='flex'>
                                    <div className='list-num'>{index + 1}</div>
                                    <div>

                                       {
                                          v['avatar'] ? (
                                             <img style={{ width: '60px', height: 60 }} src={v['avatar']} alt="" />
                                          ) : (<img style={{ width: '60px', height: 60 }} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" alt="" />)
                                       }

                                    </div>
                                    <div style={{ verticalAlign: 'middle', marginLeft: '20px' }}>
                                       <div>
                                          <span className='con-name'>{v['grouper_name']}</span>
                                          <span className='con-tit'>{qs.ellipsis(v['desc'], 15)}</span>
                                          <span className='con-group'>{v['created_at']} 成为团长</span>
                                       </div>
                                       <div style={{ marginTop: '20px' }}>
                                          <span className='sale'> <img style={{ marginRight: '10px' }} src={moneyicon} width={16} alt='' /> 累计销售额：{v['sell_price']}</span>
                                          <span className='sale'> <img style={{ marginRight: '10px' }} src={openteamicon} width={16} alt='' /> 总开团数：{v['open_group_number']}</span>
                                          <span className='sale'> <img style={{ marginRight: '10px' }} src={gifticon} width={16} alt='' /> 总团员数：{v['customer_number']}</span>
                                          <span className='sale'> <img style={{ marginRight: '10px' }} src={membersicon} width={16} alt='' /> 当前进行的活动数：{v['active_number']}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div style={{ verticalAlign: 'middle', color: '#666666' }}><Tooltip title={v['address']}>查看自提点&gt;</Tooltip></div>
                              </li>
                           })
                        }
                     </ul>

                  ) : (
                     <div className='nodata'>
                        <div className='center'>
                           <img src={nodataimg} width={100} alt='' />
                           <p>暂无团长信息~</p>
                        </div>
                     </div>
                  )
               }
            </div>

            <div className='page_style'>
               <Pagination
                  hideOnSinglePage={false}
                  onChange={async (e) => { await this.setState({ page: e, current_page: e }); this.getCommanderList() }}
                  defaultCurrent={1}
                  current={current_page}
                  pageSize={15}
                  total={total} />
            </div>
         </>
      )
   }

}

export default router(CommanderList)