import {Request} from '@zoranwong/acc-engine.js'

//let merchant_id = window.localStorage.getItem('merchant_id')
export default class GroupTemplateInfoRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        //merchant_id: null,
        group_id: null

    };
    _method = 'GET';
    _uri = '/goods/group-buy/group-template/group-template-info';

    constructor (params) {
        super(params);
        //this._data['merchant_id'] = merchant_id ? merchant_id : (params.merchant_id ? params.merchant_id : null);//params.merchant_id;
        this._data['group_id'] = params.group_id;
    }
    rules () {
        return {};
    }

    messages () {
        return {};
    }
}