import Layout from '../components/layout';
import CommandList from '../pages/commander/commanderList/commanderList'
import GroupingSets from '../pages/commander/groupingSets/groupingSets'
import Chief from '../assets/Chief.png'
import grouping from '../assets/grouping.png'
const sider = [
    {
        name: '团长列表',
        path: '/commander/list',
        icon: Chief
    },
    {
        name: '分组设置',
        path: '/commander/groupset',
        icon: grouping
    },
];
const commander = {
    path: "/commander",
    component: Layout,
    sider: sider,
    routes:[
        {
            path: "/commander",
            exact: true,
            name: 'commanderIndex',
            component: CommandList
        },
        {
            path: "/commander/list",
            exact: true,
            name: 'commanderList',
            component: CommandList
        },{
            path: "/commander/groupset",
            exact: true,
            name: 'commanderIndex',
            component: GroupingSets
        }
    ]
};
export default commander;