import React from "react";
import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import { Input, InputNumber, Row, Col, Select, Button, Table, Modal, Popconfirm, message, Tooltip, Pagination, Image } from 'antd'
import StandardGoodsListRequest from '../api/StandardGoods/StandardGoodsListRequest'
import ClassificationListRequest from '../api/Classification/classificationListRequest'
import DeleteStandardGoodsRequest from '../api/StandardGoods/DeleteStandardGoodsRequest'
import { SearchOutlined } from '@ant-design/icons';
import "../css/standard.sass"
import qs from "../../../utils/qs";

import SearchModel from '../../../components/search/index'

// const { Option } = Select;


class StandardGoods extends Base {
  constructor(props) {
    const columns = [
      {
        title: '商品名称',
        render: (text, record) =>
          <div style={{ 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap', width: '140px', display: 'block' }}>
            <Tooltip title={record.goods_name}><span >{qs.ellipsis(record.goods_name)}</span></Tooltip>
          </div>
      },
      {
        title: '商品图片',
        render: (text, record) => {
          if (record.goods_image && typeof (record.goods_image) == 'string') {
            //record.goods_image = JSON.parse(record.goods_image)
            //record.goods_image.url = record.goods_image.url
            return <Image style={{ maxHeight: 60, maxWidth: 60 }} src={record.goods_image} alt="" />
          } else if (typeof (record.goods_image) == 'object') {
            return <Image style={{ maxHeight: 60, maxWidth: 60 }} src={record.goods_image} alt="" />
          } else {
            return <p>-</p>
          }
        }
      },
      {
        title: '商品分类',
        dataIndex: 'cate_name',
      },
      {
        title: '销售价格（元）',
        render: (record) => {
          //console.log(record)
          return (
            <p>{record['sell_price']}</p>
          )
        }
      },
      // {
      //   title: '最大可售数量',
      //   dataIndex: 'sell_price',
      // }, 
      {
        title: '创建时间',
        dataIndex: 'created_at',
      },
      {
        title: '操作',
        render: (text, record) =>
          <div>
            <span onClick={() => this.edit(record)} style={{ 'color': '#909090', 'cursor': "pointer" }}>编辑</span>
            <Popconfirm
              title="您确定要删除吗？"
              onConfirm={this.confirm}
              onCancel={this.cancel}
              okText="确定"
              cancelText="取消"
            >
              <span onClick={() => this.delete(record)} style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '20px' }}>删除</span>
            </Popconfirm>

          </div>
      },
    ];
    super(props)
    this.state = {
      memberType: [],
      columns: columns,
      alldata: [],
      category_id: '',
      searchInput: "",
      selectedRowKeys: [],
      isModalVisible: false,
      goodsId: '',
      //merchantId: 'RXZgrV',//商户id
      min_price: '',
      max_price: '',
      result_price: '',
      loading: true,
      moredel: false,
      moredelNum: 0,
      page: 1,
      total: 0
    }

  }
  async componentDidMount() {

    await this.getclassList();

    await this.standargoods();
  }

  getclassList = async () => {
    let param = {
      page: 1,
      page_size: 500
    }
    let list = await ClassificationListRequest.send(param)

    const allSelect = {
      cate_code: "",
      cate_depth: 1,
      cate_image: "",
      cate_name: "全部",
      cate_sort: 0,
      created_at: "2021-02-24 19:06:29",
      id: "",
      //merchant_id: "",
      parent_id: ""
    }

    if (list['data'] && list['data'].length) {
      list['data'].unshift(allSelect)//添加全部
    }

    if (list.status) {
      this.setState({ memberType: list['data'] })
    }

    this.setState({ loading: false })
  }

  standargoods = async (param, page) => {
    let params = {
      page: page
    }
    if (param) {
      params['search'] = param['search']
    }

    this.setState({
      loading: true
    })

    let res = await StandardGoodsListRequest.send(params)

    this.setState({
      alldata: []
    })

    if (res.status) {
      let alldata = res['data']['data'] || []
      if (res.status && alldata.length) {
        this.setState({
          alldata: alldata,
          loading: false,
          total: res['data']['total'],
          page: res['data']['current_page']
        })
      }
    }
    this.setState({ loading: false })
  }

  searchGoods = (page, sqlarr) => {

    let sql = qs.sqlSearch(sqlarr)//sql 

    if (!page) {
      page = this.state.page
    }

    this.standargoods(sql, page)
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  // 批量修改价格
  batchPrice = () => {
    message.warning('暂不支持批量改价')
  }
  // 批量删除
  batchDelete = () => {
    const { selectedRowKeys } = this.state;
    if (selectedRowKeys.length > 0) {
      this.setState({
        moredel: true,
        moredelNum: selectedRowKeys.length
      })

    } else {
      message.error('您还没选择要删除的商品哟！')
    }
  }

  delCancel = () => {//取消删除
    this.setState({
      moredel: false
    })
  }

  conDel = async () => {//确定批量删除
    const { selectedRowKeys } = this.state;
    let params = {
      //merchant_id: this.state.merchantId,
      goods_ids: selectedRowKeys
    }
    this.delCancel()

    const hide = message.loading('删除中..', 1);
    let res = await DeleteStandardGoodsRequest.send(params)
    if (res.code == 200) {
      hide()
      message.success(res.message);
      this.standargoods();
      this.setState({
        selectedRowKeys: []
      })
    } else {
      hide()
      message.error(res.message);
    }
  }

  handleOk = () => {
    this.setState({ isModalVisible: false })
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false })
  };
  // 编辑
  edit = async (record) => {
    await record
    this.props.history.push({ pathname: `/goods/editgoods`, state: { data: record } })
  }
  // 删除
  delete = (record) => {
    this.setState({ goodsId: record.id })
  }
  confirm = async () => {
    let arr = []
    let arr1 = arr.concat(this.state.goodsId)
    let params = {
      //merchant_id: this.state.merchantId,
      goods_ids: arr1
    }
    const hide = message.loading('删除中..', 1);
    let res = await DeleteStandardGoodsRequest.send(params)
    if (res.status == true) {
      hide();

      message.success(res.message);
      this.standargoods();
    }

  }

  cancel = (e) => {
    message.error('您已取消删除！');
  }

  // 发布商品
  creatGoods = () => {
    this.props.history.push({ pathname: `/goods/newGoods` })
  }

  //清空
  clearAll = async () => {
    let { searchInput, category_id, result_price } = this.state
    await this.setState({
      searchInput: '',
      category_id: '',
      result_price: '',
      min_price: '',
      max_price: ''
    })
    this.standargoods()
  }

  render() {
    let { selectedRowKeys, moredel, total, searchInput, moredelNum, loading, memberType } = this.state;
    //console.log(memberType)
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className={'standardgoods'}>
        {/* <Modal title="批量改价" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <h3>你还未选择哦</h3>
        </Modal> */}
        <div className="search">
          <div className='flex'>
            <SearchModel memberType={memberType} sqlarr={(e) => { this.searchGoods(null, e) }} />
            <div>
              <Button onClick={this.creatGoods} className='issue' type="primary">
                <i></i>
                发布商品
              </Button>
            </div>
          </div>

          {/* <Row>
            <Col span={19} className='search-left'>
              <Row>
                <Col span={10} className='needMargin'>
                  <label className='minWidth80'>商品搜索：</label>
                  <Input className='input-radius' placeholder='请输入关键词' onBlur={(e) => { this.setState({ searchInput: e.target.value }) }} />
                </Col>
                <Col span={10} className='needMargin' style={{ marginLeft: '20px' }}>
                  <label className='minWidth80'>商品分类：</label>
                  <Select
                    value={this.state.category_id}
                    className='input-radius'
                    showSearch
                    placeholder="请选择"
                    onChange={async (e) => {
                      await this.setState({ category_id: e })
                      this.searchGoods()
                    }}

                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.memberType.length ?
                      this.state.memberType.map(item => (
                        <Select.Option key={item.id} lable={item.cate_name} value={item.id}>
                          {item.cate_name}
                        </Select.Option>
                      )) : null
                    }
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <label className='minWidth80'>商品销售价：</label>
                  <InputNumber className='input-radius' value={this.state.min_price} onBlur={(e) => {
                    this.setState({
                      min_price: e.target.value,
                      result_price: e.target.value
                    })
                    if (this.state.max_price > 0) {
                      this.setState({
                        result_price: e.target.value + '~' + this.state.max_price
                      })
                    }
                  }} />
                  <span style={{ margin: '0 10px' }}>~</span>
                  <InputNumber className='input-radius' value={this.state.max_price} onBlur={(e) => {
                    this.setState({
                      max_price: e.target.value,
                      result_price: e.target.value
                    })
                    if (this.state.min_price > 0) {
                      this.setState({
                        result_price: this.state.min_price + '~' + e.target.value
                      })
                    }
                  }} />
                </Col>
                <Col>
                  <Button className='search-btn' type="primary" icon={<SearchOutlined />} onClick={() => { this.searchGoods() }}>
                    搜索
                        </Button>
                  <span onClick={this.clearAll} className='clear'>清空所有搜索条件</span>

                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Button onClick={this.creatGoods} className='issue' type="primary">
                <i></i>
                发布商品
              </Button>
            </Col>
          </Row> */}
        </div>
        <div className='table-data'>
          <div>
            <Button className='btn' type="primary" onClick={this.batchPrice}>批量改价</Button>
            <Button className='btn' style={{ marginLeft: '10px' }} onClick={this.batchDelete} type="primary">批量删除</Button>
          </div>

          <div className='goods_list_show'>
            <Table
              scroll={
                {
                  scrollToFirstRowOnChange: false,
                  x: false,
                  y: 500
                }
              }
              pagination={false}
              rowSelection={rowSelection}
              columns={this.state.columns}
              dataSource={this.state.alldata}
              rowKey={record => record.id}
              loading={this.state.loading}
              rowClassName={(record, index) => {
                let className = '';
                if (index % 2) className = 'dark-row';
                return className;
              }}
            />
          </div>

          <div className='page_style'>
            <Pagination hideOnSinglePage={true} onChange={(e) => { this.searchGoods(e) }} defaultCurrent={1} current={this.state.page} total={total} pageSize={15} />
          </div>
        </div>

        {/* 批量删除项 */}
        <Modal
          style={{ textAlign: 'center' }}
          visible={moredel}
          centered
          title='提示'
          footer={false}
          onCancel={this.delCancel}
        >
          <div style={{ overflow: 'hidden' }}>
            <p style={{ fontSize: '15px', fontWeight: 'bold', margin: '30px 0 35px 0' }}>确定批量删除这 <span style={{ color: 'red' }}>{moredelNum}</span> 件商品吗？</p>
            <ul className='flex delcontrl' style={{ justifyContent: 'center' }}>
              <li className='cancel button-bac' onClick={this.delCancel}>取消</li>
              <li className='button-bac' onClick={this.conDel}>确定</li>
            </ul>
          </div>

        </Modal>

      </div>
    );
  }
}

export default router(StandardGoods);