import React from 'react';
import { Input } from 'antd'
import BaiduMapLine from "../../../../components/baiduMap/BaiduMapLine";

import '../../css/mapBox.sass';

export default class MapBox extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            searchTxt: '',
            searchSwitch: false,
            MapData: {},
            clear: false
        }
    }

    componentWillReceiveProps(props) {
        let { MapData, clear } = props;

        this.setState({
            MapData,
            clear
        })
    }

    searchAddress = () => {
        let { searchTxt, searchSwitch } = this.state;
        this.setState({
            searchSwitch: true
        })
    }

    render() {
        let { searchTxt, searchSwitch, MapData, clear } = this.state
        return (
            <div className='MapBox'>
                <div className='search_and_info'>
                    <div className='routeName'>
                        物流排线名称：小荷悄悄的线路
                    </div>
                    <div className='flex'>
                        <Input
                            style={{ width: 160, height: 30, lineHeight: 30 }}
                            allowClear={true}
                            onChange={e => { this.setState({ searchTxt: e.target.value }) }}
                            id='suggestId'
                            placeholder='请输入地址/团长电话'
                            value={searchTxt} />
                        <div
                            className='search_btn'
                            onClick={() => { this.searchAddress() }}>
                            搜索
                        </div>
                    </div>
                </div>

                <BaiduMapLine
                    clear={clear}
                    MapData={MapData}
                    param={{ name: 'lyp', address: searchTxt, searchSwitch }}
                    address={e => {
                        //console.log(e,)
                        this.setState({
                            searchTxt: e.city + e.district + e.business,
                            searchSwitch: false
                        })
                    }} />
            </div>
        )
    }
}