import { extend } from 'lodash'
import React from 'react'
import Base from '../../../components/base'
import { withRouter as router } from "react-router-dom";
import { DatePicker, Space, Button, Table, Modal, Pagination, message, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import GroupListAuditRequest from '../api/groupListAuditRequest';
import GroupListCtrlRequest from '../api/groupListCtrlRequest';
import locale from 'antd/es/date-picker/locale/zh_CN';
import qs from '../../../utils/qs';
import '../css/groupExamine.sass';
import { color } from 'echarts/core';
const { TextArea } = Input;

class GroupExamine extends Base {
    constructor(props) {
        super(props)

        this.state = {
            type: 0,
            visible: false,
            modalTitle: '提示',
            agress: 0,
            loading: true,
            page: 1,
            ctrlId: '',
            remark: '',
            created_at: '',
            total: 0
        }
    }

    componentDidMount() {
        this.getAuditList()
    }

    getAuditList = async (page) => {

        this.setState({
            loading: true
        })

        let { type, created_at } = this.state;
        let param = {
            type
        }


        let sqlarr = [
            //{ name: 'type', val: type }
        ]

        if (created_at[0]) {
            created_at = created_at.join('~');
            sqlarr.push({ name: 'created_at', val: created_at })
        }

        let sql = await qs.sqlSearch(sqlarr)//sql 

        if (sql) {
            param['search'] = sql['search']
        }

        if (!page) {
            param['page'] = this.state.page
        }

        let res = await GroupListAuditRequest.send(param);

        if (res.code == 200 && res.data) {
            this.setState({
                dataSource: res.data.data,
                total: res.data.total
            })
        }

        this.setState({
            loading: false
        })

        //console.log(res, param, sql, sqlarr);


    }

    auditCtrl = async (param) => {
        const hide = message.loading('操作中')
        let res = await GroupListCtrlRequest.send(param);
        if (res.code == 200) {
            message.success('操作成功！');
            this.getAuditList();
        } else {
            message.error(res.message)
        }
        hide();
        this.cancel();
        //console.log(res)
    }

    comCtrl = () => {
        let { ctrlId } = this.state;
        let param = {
            is_agree: 2,
            id: ctrlId
        }
        this.auditCtrl(param)
    }

    refuse = () => {
        let { ctrlId, remark } = this.state;
        let param = {
            is_agree: 1,
            id: ctrlId,
            remark
        }
        this.auditCtrl(param)
    }

    tabType = async (i) => {//tab
        await this.setState({
            type: i
        })
        this.getAuditList();
    }

    examine = (i, ctx) => {//examine
        let { modalTitle } = this.state;
        modalTitle = i ? '提示' : '拒绝说明'
        this.setState({
            modalTitle,
            visible: true,
            agress: i,
            ctrlId: ctx['id']
        })
        //console.log(ctx)
    }

    changeTime = async (e, data) => {
        await this.setState({
            created_at: data
        })
        this.getAuditList()
    }

    cancel = () => {//cancel
        this.setState({
            visible: false
        })
    }

    write = (e) => {
        this.setState({
            remark: e.target.value
        })
    }


    render() {
        const { type, visible, modalTitle, agress, loading, dataSource, total, page } = this.state
        const { RangePicker } = DatePicker;
        const modalBtnStyle = {
            'justify-content': 'center',
            'margin-top': '',
            'margin-bottom': '15px'
        }

        const btnStyle = {
            'margin-right': '20px',
            'background': '#9AB6DD',
            'font-size': '12px',
            'color': '#fff',
            'padding': '7px 27px',
            'border-radius': '4px',
            'cursor': 'pointer'
        }
        const btnStyle1 = {
            'background': '#9AB6DD',
            'font-size': '12px',
            'color': '#fff',
            'padding': '7px 27px',
            'border-radius': '4px',
            'cursor': 'pointer',
            'background': '#366FBB'
        }

        const cancel = (e) => {
            this.cancel()
        }

        const pageChange = async(e) => {
            //console.log(e, 'pageChange')
            await this.setState({
                page: e
            })

            this.getAuditList()
        }

        const Agress = () => {//
            let tpl = <div className='agressWindow'>
                <p style={{ textAlign: 'center', padding: '40px 0' }}>确定同意该团购开团吗？</p>
                <div className='modalBtn flex' style={modalBtnStyle}>
                    <p onClick={() => cancel()} className='btn' style={btnStyle}>取消</p>
                    <p onClick={() => { this.comCtrl() }} className='btn' style={btnStyle1}>确定</p>
                </div>
            </div>
            return tpl;
        }

        const DisAgress = () => {//
            let tpl = <div className='agressWindow noagree'>
                <div className='flex' style={{ alignItems: 'flex-start', marginTop: '10px', marginBottom: '60px' }}>
                    <p>拒绝说明</p>
                    {/* <TextArea rows={4}  placeholder='请输入拒绝理由' onChange={e => { this.setState({ remark: e.target.value }) }}/> */}
                    <textarea
                        onChange={e => { this.write(e) }}
                        value={this.state.remark}
                        className='refus'
                        placeholder='请输入拒绝理由'
                        style={{ resize: 'none', marginLeft: '20px', flex: 1, borderColor: '#D9D9D9', padding: '5px', fontSize: '12px', width: '300px', height: '60px' }}></textarea>
                </div>
                <div className='modalBtn flex' style={modalBtnStyle}>
                    <p onClick={() => cancel()} className='btn' style={btnStyle}>取消</p>
                    <p onClick={() => { this.refuse() }} className='btn' style={btnStyle1}>确定</p>
                </div>
            </div>
            return tpl;
        }


        // const dataSource = [
        //     {
        //         key: '1',
        //         name: '测试数据',
        //         age: 32,
        //         address: '即将开发完成',
        //     },
        //     {
        //         key: '2',
        //         name: '测试数据',
        //         age: 42,
        //         address: '即将开发完成',
        //     },
        // ];

        const columns = [
            {
                title: '团购名称',
                dataIndex: 'group_name',
                key: 'group_name',
            },
            {
                title: '团购类型',
                dataIndex: 'group_type',
                key: 'group_type',
            },
            {
                title: '团长昵称',
                render: (ctx) => {
                    return (
                        <p>{ctx['grouper_name']}</p>
                    )
                }
            },
            {
                title: '团长手机号',
                render: (ctx) => {
                    return (
                        <p>{ctx['grouper_mobile']}</p>
                    )
                }
            },
            {
                title: '团长地址',
                render: (ctx) => {
                    return (
                        <p>{ctx['position']['pickup_address']}</p>
                    )
                }
            },
            {
                title: '申请时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '操作',
                render: (ctx) => {
                    return (
                        <div className='flex contrlBtn'>
                            <p onClick={() => { this.examine(1, ctx) }} className='btn'>同意</p>
                            <p onClick={() => { this.examine(0, ctx) }} className='btn'>拒绝</p>
                        </div>
                    )
                }
            }
        ];

        const columnsRecord = [
            {
                title: '团购名称',
                dataIndex: 'group_name',
                key: 'group_name',
            },
            {
                title: '团购类型',
                dataIndex: 'group_type',
                key: 'group_type',
            },
            {
                title: '团长昵称',
                render: (ctx) => {
                    return (
                        <p>{ctx['grouper_name']}</p>
                    )
                }
            },
            {
                title: '团长手机号',
                render: (ctx) => {
                    return (
                        <p>{ctx['grouper_mobile']}</p>
                    )
                }
            },
            {
                title: '团长地址',
                render: (ctx) => {
                    return (
                        <p>{ctx['position']['pickup_address']}</p>
                    )
                }
            },
            {
                title: '申请时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '审核结果',
                dataIndex: 'audit_state_desc',
                key: 'audit_state_desc',
            },
            {
                title: '拒绝原因',
                dataIndex: 'remark',
                key: 'remark',
            }
        ]
        //
        let rescolumns;
        if (!type) {
            rescolumns = columns
        } else {
            rescolumns = columnsRecord
        }

        return (
            <div className='groupExamine'>
                <ul className='tab flex'>
                    <li onClick={() => { this.tabType(0) }} className={!type ? 'active' : ''}>待审核</li>
                    <li onClick={() => { this.tabType(1) }} className={type == 1 ? 'active' : ''}>已审核记录</li>
                </ul>

                <div className='flex rule'>
                    <div className='flex'><label style={{ fontSize: '12px', marginRight: '10px' }}>时间： </label> <RangePicker onChange={this.changeTime} locale={locale} /></div>
                    <div style={{ marginLeft: '120px' }}>
                        <Button className='searchBtn' type="primary" onClick={() => { this.searchGoods() }}>
                            <SearchOutlined style={{ fontSize: '15px', fontWeight: 'bold' }} />搜索</Button>
                        <span onClick={this.clearAll} className='clear'>清空所有搜索条件</span>
                    </div>
                </div>

                <Table
                    columns={rescolumns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={loading}
                    scroll={
                        {
                            scrollToFirstRowOnChange: false,
                            x: false,
                            y: 500
                        }
                    }
                />


                <Modal
                    style={{ textAlign: 'center', border: 0 }}
                    width={320}
                    height={210}
                    visible={visible && agress}
                    onCancel={this.cancel}
                    footer={false}
                    centered
                >
                    <div style={{ textAlign: 'center', padding: '15px 0', borderBottom: '1px solid rgba(0,0,0,0.1)', fontSize: '16px' }}>
                        {modalTitle}
                    </div>

                    {agress ? (<Agress />) : (<DisAgress />)}

                </Modal>

                <Modal
                    style={{ textAlign: 'center', border: 0 }}
                    width={420}
                    height={240}
                    visible={visible && !agress}
                    title={modalTitle}
                    onCancel={this.cancel}
                    footer={false}
                    centered
                >
                    {agress ? (<Agress />) : null}

                    {!agress ? (
                        <div className='agressWindow noagree'>
                            <div className='flex' style={{ alignItems: 'flex-start', marginTop: '10px', marginBottom: '60px' }}>
                                <p>拒绝说明</p>
                                {/* <TextArea rows={4}  placeholder='请输入拒绝理由' onChange={e => { this.setState({ remark: e.target.value }) }}/> */}
                                <textarea
                                    onChange={e => { this.write(e) }}
                                    value={this.state.remark}
                                    className='refus'
                                    placeholder='请输入拒绝理由'
                                    style={{ resize: 'none', marginLeft: '20px', flex: 1, borderColor: '#D9D9D9', padding: '5px', fontSize: '12px', width: '300px', height: '60px' }}></textarea>
                            </div>
                            <div className='modalBtn flex' style={modalBtnStyle}>
                                <p onClick={() => cancel()} className='btn' style={btnStyle}>取消</p>
                                <p onClick={() => { this.refuse() }} className='btn' style={btnStyle1}>确定</p>
                            </div>
                        </div>
                    ) : null}

                </Modal>

                <div className='right_bottom'>
                    <Pagination hideOnSinglePage={true} defaultCurrent={1} current={page} total={total} onChange={pageChange} />
                </div>



            </div>
        )
    }
}

export default router(GroupExamine)
