import React from "react";
import { NavLink, useHistory } from 'react-router-dom'
import '../../style/nav.sass'
import '../../style/leftMenus.sass'
import { Menu, Dropdown, message, Image, List, Avatar } from 'antd'
import { CheckOutlined } from '@ant-design/icons';
import logoUrl from '../../assets/logo.png'
import emitter from "../../ev"
import GetAgentRequest from "../../pages/home/api/GetAgentRequest";
import GetConsignorRequest from "../../pages/home/api/GetConsignorRequest";
import UserInfoRequest from "../../pages/home/api/userInfoRequest";
import VirtualList from 'rc-virtual-list';

const Header = ({ navs, userInfodata }) => {
    let [page, setPage] = React.useState(1);

    let [last_page, setLastPage] = React.useState(1);

    let userInfo = JSON.parse(window.localStorage.getItem('userInfo')) || JSON.parse(userInfodata);


    let agent_id = window.localStorage.getItem('main_customer_id');

    let [agentList, setAgentList] = React.useState([]);

    let [now_id_index, setNow_id_index] = React.useState(-1);


    //管理员列表
    async function getAgent() {
        let param = {
            page,
            page_size: 15,
            agent_id,
            consignor_type: 'MERCHANT'
        }

        if (window.localStorage.getItem('now_id_index')) {
            setNow_id_index(window.localStorage.getItem('now_id_index'))
        }

        let res = await GetAgentRequest.send(param);

        if (res.code === 200 && res.data.data.length > 0) {
            setAgentList(agentList.concat(res.data.data))
            setLastPage(res.data.last_page)
        }


        //console.log(res, 'GetAgentRequest', navs, userInfodata)
    }

    React.useEffect(() => {
        //console.log("每次更新后对会执行", topActive);

    }, []);

    //let showChange = false;

    let [showChange, isNoVal] = React.useState(false);//isNoVal是一个dispatchAction

    function LoginOut(e, i) {

        let res = e.key;
        if (res === 'tmp_key-0') {
            showChange = isNoVal(true)
            agentList = []
            getAgent()
        } else if (res === 'tmp_key-1') {
            localStorage.clear();
            message.success('退出成功！')
            window.location.href = '/';
        }
    }

    let beginActive = window.localStorage.getItem('topActive') || '/home'

    let [topActive, setActive] = React.useState(beginActive);

    function hide(params) {
        showChange = isNoVal(false)
    }

    async function changeMenu(e) {
        //console.log(e.key, )

        await setActive(topActive = e.key)

        window.localStorage.setItem('topActive', e.key);

        window.localStorage.setItem('leftActive', null);

        history.push(e.key)
    }

    async function changeIdentity(item, index, isClick) {
        //console.log(item);
        if (isClick) return

        setNow_id_index(index);

        let userInfo = await JSON.parse(window.localStorage.getItem('userInfo')) || await JSON.parse(userInfodata);

        let param = {
            relation_agent_id: item['consignors'][0]['id']
        }

        let res = await GetConsignorRequest.send(param)

        //console.log(res, 'GetConsignorRequest')

        //userInfo['avatar'] = item['user_avatar'];
        //userInfo['customer_id'] = item['consignors'][0]['id'];
        userInfo['name'] = item['user_name'];
        userInfo['mobile'] = item['user_mobile'];

        window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
        window.localStorage.setItem('merchant_id', res['data']['consignor_info']['id']);
        window.localStorage.setItem('now_id_index', index);
        message.loading('正在切换');
        window.location.reload();

        //console.log(item);


    }

    async function backMainIdentity() {//回主账号

        let main_merchant_id = await window.localStorage.getItem('main_merchant_id');
        window.localStorage.setItem('merchant_id', main_merchant_id);
        window.localStorage.setItem('now_id_index', -1);
        let token = window.localStorage.getItem('token');

        let param = {
            token
        }

        let res = await UserInfoRequest.send(param)

        if (res['code'] === 200) {
            let merchant_id = res['data']['merchant_id'];
            if (merchant_id == undefined || merchant_id == 'undefined') return
            //message.success(res.message)
            window.localStorage.setItem('merchant_id', res['data']['merchant_id'])
            window.localStorage.setItem('main_merchant_id', res['data']['merchant_id'])
            window.localStorage.setItem('main_customer_id', res['data']['customer_id'])
            window.localStorage.setItem('userInfo', JSON.stringify(res.data))
            window.localStorage.setItem('token', token)
        } else {
            message.error(res.message)
        }

        message.loading('正在切换');

        window.location.reload();

    }

    const menu = (
        <Menu onClick={LoginOut}>
            <Menu.Item>
                切换身份
            </Menu.Item>
            <Menu.Item>
                退出
            </Menu.Item>
        </Menu>
    );

    let history = useHistory();

    const ContainerHeight = 250;

    const onScroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop === ContainerHeight) {

            if (page < last_page) {
                setPage(page += 1)
                getAgent();
            } else {
                message.error('已经是最后一页了')
            }

        }
    };

    //console.log(navs, 'navs', showChange, isNoVal, topActive)
    return (
        <div className="ttq-app-header nav" >
            <div className="main" >
                <div className="flex">
                    {/* <h1 style={{width:'300px'}}>111</h1> */}
                    <div className='logo' style={{ width: '200px' }}>
                        <img src={logoUrl} width={120} alt='' />
                    </div>
                    {/* <ul className="navUl" style={{ flex: 1 }}>
                    {
                        navs.map((item, index) => {
                            return (
                                <li name={item.title} key={index} onClick={()=>{window.localStorage.setItem('leftActive', null)}}>
                                    <NavLink exact={item.exact} to={item.route}>
                                        {item.title}
                                    </NavLink>
                                </li>
                            )
                        })
                    }

                </ul> */}
                    <Menu className="topMenu" onClick={(e) => { changeMenu(e) }} selectedKeys={[topActive]} mode="horizontal">

                        {
                            navs.map((item, index) => {
                                return (
                                    <Menu.Item key={item.route} icon=''>
                                        {item.title}
                                    </Menu.Item>
                                )
                            })
                        }
                    </Menu>
                </div>
                <ul className="navUl">
                    <li className='flex' style={{ width: 'unset' }}>

                        {userInfo ? (
                            <Dropdown overlay={menu} placement="bottomCenter">
                                <div className='flex' style={{ width: 'unset' }}>
                                    <img
                                        alt=''
                                        src={userInfo['avatar']}
                                        style={{ width: '50px', height: '50px', borderRadius: '50%', background: '#fff', marginRight: '20px', overflow: 'hidden' }} />
                                    <p className='main_user_name' style={{ color: '#fff' }}>欢迎，{userInfo['name']}</p>

                                </div>
                            </Dropdown>
                        ) : null}

                    </li>
                    {/* <li>
                        <NavLink exact={true} to={'/set'}>
                            设置
                        </NavLink>
                    </li> */}
                </ul>
            </div>

            {/* 切换身份 */}
            {
                showChange ? (
                    <div className='idChange'>
                        <div className='hide' onClick={hide}></div>
                        <div className='idBox'>
                            <div className='title flex' style={{ justifyContent: 'center' }}>
                                点击头像进行账户切换
                                {
                                    now_id_index > -1 ? (
                                        <p className='backMainIdentity' onClick={() => { backMainIdentity() }}>回主账号</p>
                                    ) : null
                                }
                            </div>

                            <List>
                                <VirtualList
                                    data={agentList}
                                    height={ContainerHeight}
                                    itemHeight={0}
                                    itemKey="user_name"
                                    onScroll={onScroll}
                                >
                                    {(item, index) => (
                                        <List.Item key={item.email}>
                                            {/* <List.Item.Meta
                                                avatar={<Avatar src={item['user_avatar']} />}
                                                title={<a href="https://ant.design">{item.name}</a>}
                                                description={item.email}
                                            /> */}

                                            <div onClick={() => { changeIdentity(item, index, now_id_index == index) }} className={now_id_index == index ? 'select_active consignor_user_info' : 'consignor_user_info'}>
                                                <img className='avatar-image' src={item['user_avatar']} alt='' />
                                                <p>{item.user_name}</p>
                                                <CheckOutlined className='select_icon' />
                                            </div>

                                        </List.Item>
                                    )}
                                </VirtualList>
                            </List>

                            {/* <ul className='flex idList'>
                                {
                                    agentList.length ? agentList.map((v, index) => {

                                        return (
                                            <li
                                                onClick={() => { changeIdentity(v, index) }}
                                                className={now_id_index == index ? 'select_active' : ''}
                                            >
                                                <img src={v['user_avatar']} />
                                                <p>
                                                    {v['user_name']}
                                                </p>
                                            </li>
                                        )

                                    }) : null
                                }
                            </ul> */}

                            <ul className='flex contrlBtn'>
                                <li onClick={hide}>取消</li>
                                {/* <li>确定</li> */}
                            </ul>

                        </div>
                    </div>
                ) : ''
            }

        </div >
    );
}
export default Header;