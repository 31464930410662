import { Input } from "@alifd/next";
import React from "react";
import emitter from "../../../../../ev"

const SkuTableItem = ({
    entity, index, change = () => {
    }
}) => {
    let values = entity['spec_values'] || [];

    let rowSpan = entity.rowSpan;

    function changeEntity(value, key) {
        entity[key] = value;
        change(entity, index);
    }

    async function selected() {
        entity['checked'] = true;
        await change(entity, index);

        emitter.emit('skuDatagift', entity);
    }

    async function unselected() {
        entity['checked'] = false;
        await change(entity, index);

        emitter.emit('skuDatagift', entity);
    }

    return (
        <tr key={'sku_table_tr_' + index}>
            {values.map((v, vIdx) => {
                return (<SpecValuesRender value={v} rowSpan={rowSpan} valueIndex={vIdx}
                    rowIndex={index} key={'sku_td_' + index + '_' + vIdx} />)
            })}
            <td>
                <Input disabled placeholder="请输入商品售价" value={entity['sell_price']} onChange={(value) => {
                    changeEntity(value, 'sell_price');
                }} />
            </td>
            <td>
                <Input disabled placeholder="请输入最大可售数量" value={entity['stock_num']} onChange={(value) => {
                    changeEntity(value, 'stock_num');
                }} />
            </td>
            <td>
                {
                    !entity['checked'] ? (
                        <p style={({ cursor: 'pointer' })} onClick={selected}>选择</p>
                    ) : null
                }

                {
                    entity['checked'] ? (
                        <p style={({ cursor: 'pointer' })} onClick={unselected}>取消</p>
                    ) : null
                }

            </td>
        </tr>
    );
}

function SpecValuesRender({ value, valueIndex, rowIndex }) {
    if(!value)return
    let rowSpan = value.rowSpan;
    if (rowIndex % rowSpan === 0)
        return (<td rowSpan={rowSpan}>{value['specValue']['value']}</td>);
    else
        return null;
}

export default SkuTableItem;