import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Input } from 'antd'
import Editor from '../../../../components/editor'
import BigImgBar from '../../../../components/editor/bigImgBar'
import BigImgView from '../../../../components/editor/bigImgView'
import SmallImgBar from '../../../../components/editor/smallImgBar'
import SmallImgView from '../../../../components/editor/smallImgView'
import TextBar from '../../../../components/editor/textBar'
import TextView from '../../../../components/editor/textView'
import ComponentType from '../../../../components/editor/ComponentType'
import emitter from "../../../../ev"
import '../../css/TempalteIntroduce.sass'

export default class TempalteIntroduce extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            group_name: '',
            edom: [],
            loadEditor: false
        }
    }

    componentDidMount() {//
        this.props.onRef(this)
    }

    componentWillReceiveProps = (e) => {
        this.upcom(e);
        //console.log(e, 'TempalteIntroduce')
    }

    shouldComponentUpdate() {
        //this.upcom()
        return true
    }

    upcom = (e) => {
        if (e.group_name) {
            this.setState({
                group_name: e.group_name
            })
        }
        if (e.edom) {
            this.setState({
                edom: e.edom
            })
        }

        this.setState({
            loadEditor: true
        })
    }

    scrollToBottom() {
        if (this.messagesEnd) {
            const scrollHeight = this.messagesEnd.scrollHeight;//里面div的实际高度  2000px
            const height = this.messagesEnd.clientHeight;  //网页可见高度  200px
            const maxScrollTop = scrollHeight - height;
            this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。

            //console.log('scrollHeight', scrollHeight)
        }
    }


    getEditor = () => {//测试！！！
        let { edom } = this.state
        if (this.refs.edom) {
            this.setState({
                edom: this.refs.edom.state
            })
            return this.state
        }
    }

    addGroupDetailContextItem(item) {

    }

    delGroupDetailContextItem(index) {

    }

    updateGroupDetailContextItem(index, item) {

    }

    render() {

        const EditorBar = [
            { title: '大图', barTemplate: BigImgBar, template: BigImgView, type: ComponentType.BIG_IMG },
            { title: '小图', barTemplate: SmallImgBar, template: SmallImgView, type: ComponentType.SMALL_IMG, data: { count: 3 } },
            { title: '文字', barTemplate: TextBar, template: TextView, type: ComponentType.TEXT }
        ]

        let { edom, loadEditor } = this.state;

        return (
            <>
                <div className='boxShadow'>
                    <h1 className='title'>1.填写团购介绍</h1>
                    <div style={{ marginBottom: '20px' }}>
                        <span style={{ marginLeft: '3px', marginBottom: '10px', display: 'inline-block' }}>团购活动标题:</span>
                        <Input
                            maxLength={40}
                            className='input-style'
                            placeholder='请输入团购活动标题，最多40字'
                            value={this.state.group_name}
                            onChange={(e) => { this.setState({ group_name: e.target.value }); this.props.groupName(e.target.value) }}
                        />
                    </div>
                    <div>
                        <span className='sty-left'>团购介绍：</span>
                        <div className='goods_Editor' id='goods_Editor' ref={(el) => { this.messagesEnd = el; }}>
                            {loadEditor ? (
                                <Editor
                                    goodsContent={edom}
                                    updateEditor={(e) => {
                                        this.props.edmo(e)
                                    }}
                                    ref='edom'
                                    bars={EditorBar}
                                    scrollBottom={() => { this.scrollToBottom() }} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}