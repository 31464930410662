import React from "react";
import qs from "../../../utils/qs";
import { message } from 'antd'
import './caledar.sass'
import Nongli from "../../../components/nongli";
import emitter from "../../../ev";
import { LeftCircleOutlined, RightOutlined, CloseOutlined, RightCircleOutlined } from '@ant-design/icons';

export default class Caledar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lis: [],
            caledarData: [
                {
                    year: 2020,
                    months: [
                        {
                            month: 1,
                            days: 31
                        }
                    ]
                }
            ],
            threeMonthDays: {},
            lastSevenArr: [],
            dragDistance: 0,//拖拽距离
        }
    }

    componentDidMount() {
        this.rili()
    }

    componentWillReceiveProps(props) {

        console.log(props,)
        let { setTime } = props;
        if (setTime) {
            let time = setTime.split('~')[0];
            console.log(time, )
            //this.rili(time, (e) => { e = null })
        }

    }

    rili = async (setTime = '', callback) => {

        let now = new Date();
        if (setTime) {
            now = new Date(setTime);
            callback(setTime)
        }
        let nowYear = now.getFullYear();//今年
        let preYear = nowYear;//预设等于去年
        let nextYear = nowYear;//预设等于明年
        let nowMonth = now.getMonth() + 1;//本月
        let preMonth = nowMonth - 1;//上个月
        let nextMonth = nowMonth + 1;//下个月

        if (nowMonth - 1 < 1) {
            preYear = nowYear - 1;
            preMonth = 12
        }

        if (nowMonth + 1 > 12) {
            nextYear = nowYear + 1
            nextMonth = 1
        }

        let threeYearDays = this.getThreeYearDays(nowYear, preYear, nextYear)//近3年天数
        let threeMonthDays = await this.getThreeMonthDays(nowYear, preYear, nextYear, nowMonth, preMonth, nextMonth)//近3月天数
        let preMonthFirstDay = this.getMonthFirst(preYear, preMonth);//获取上一月第一天星期
        let threeMonthTotal = threeMonthDays.preMonthDay + threeMonthDays.nowMonthDay + threeMonthDays.nextMonthDay;//3月总天数

        // for (let i = preMonthFirstDay; i <= threeMonthTotal;) {
        //     for (let k = 0; k < 7; k++) {
        //         if (i <= threeMonthTotal && i >= 1) {
        //             console.log(i,)
        //         } else {
        //             console.log('gg',)
        //         }
        //         i++;
        //     }
        // }

        this.setState({
            threeMonthDays
        })

        return threeMonthDays;
        //console.log(threeYearDays, threeMonthDays, nowMonth, preMonth, nextMonth, nowYear, preYear, nextYear, preMonthFirstDay)
    }

    getThreeYearDays = (nowYear, preYear, nextYear) => {//获取近3年天数
        let threeYearDays = qs.yearDays(nowYear) + qs.yearDays(preYear) + qs.yearDays(nextYear);
        return threeYearDays;
    }

    getThreeMonthDays = async (nowYear, preYear, nextYear, nowMonth, preMonth, nextMonth) => {//获取近3月天数
        let preMonthFirstDay = this.getMonthFirst(preYear, preMonth);//获取上一月第一天星期
        let nowMonthFirstDay = this.getMonthFirst(nowYear, nowMonth);//获取本月第一天星期
        let nextMonthFirstDay = this.getMonthFirst(nextYear, nextMonth);//获取下一月第一天星期
        let week = ['日', '一', '二', '三', '四', '五', '六'];

        let preMonthFirstArr = []
        let preweek = [];
        let preweeknum = [];
        for (let i = 1 - preMonthFirstDay; i <= new Date(preYear, preMonth, 0).getDate();) {
            for (let k = 0; k < 7; k++) {
                if (i <= new Date(preYear, preMonth, 0).getDate() && i > 0) {
                    preMonthFirstArr.push(i)
                    preweek.push(week[k])
                    preweeknum.push(k)
                } else {

                    //preweek.push('')
                }
                i++;
            }
        }

        let nowMonthFirstArr = []
        let nowweek = []
        let nowweeknum = []
        for (let i = 1 - nowMonthFirstDay; i <= new Date(nowYear, nowMonth, 0).getDate();) {
            for (let k = 0; k < 7; k++) {
                if (i <= new Date(nowYear, nowMonth, 0).getDate() && i > 0) {
                    nowMonthFirstArr.push(i)
                    nowweek.push(week[k])
                    nowweeknum.push(k)
                } else {
                    //nowweek.push('')
                }
                i++;
            }
        }


        let nextMonthFirstArr = []
        let nextweek = []
        let nextweeknum = []
        for (let i = 1 - nextMonthFirstDay; i <= new Date(nextYear, nextMonth, 0).getDate();) {
            for (let k = 0; k < 7; k++) {
                if (i <= new Date(nextYear, nextMonth, 0).getDate() && i > 0) {
                    nextMonthFirstArr.push(i)
                    nextweek.push(week[k])
                    nextweeknum.push(k)
                } else {
                    //nextweek.push('')
                }
                i++;
            }
        }

        //合并
        let total = [];

        //上月
        preMonthFirstArr.map((v, i) => {
            let res = {
                month: preMonth,
                date: v,
                day: preweek[i],
                daynum: preweeknum[i],
                year: preYear
            }
            total.push(res)
        })


        //本月
        nowMonthFirstArr.map((v, i) => {
            let res = {
                month: nowMonth,
                date: v,
                day: nowweek[i],
                daynum: nowweeknum[i],
                year: nowYear
            }
            total.push(res)
        })

        //下月
        nextMonthFirstArr.map((v, i) => {
            let res = {
                month: nextMonth,
                date: v,
                day: nextweek[i],
                daynum: nextweeknum[i],
                year: nextYear
            }
            total.push(res)
        })


        //以周日为起点，七天一节
        let weekStart = [0, 1, 2, 3, 4, 5, 6];
        let looparr = []
        for (let i = 0; i < total.length; i++) {
            if (total[i].daynum === 0) {

                for (let j = 0; j < 7; j++) {
                    if (i + j < total.length) {
                        looparr.push(
                            total[i + j]
                        )
                    }
                }
            }
        }

        //删除末尾
        let loopLength = looparr.length - 1
        if (looparr[loopLength] !== 0) {
            let reduceDay = looparr[loopLength]['daynum'];
            for (let i = 0; i <= reduceDay; i++) {
                looparr.pop()
            }
        }

        let lastSevenArr = [];
        let nowString = qs.gettoday().year + '/' + qs.gettoday().month + '/' + qs.gettoday().day;
        //nowString = nowString.replace(/\./g, "/");
        let nowStr = new Date(nowString).getTime();//当前时间戳
        let unitNum = 0

        for (let i = 0; i < looparr.length;) {
            let detail = looparr[i]['year'] + '/' + qs.transTen(looparr[i]['month']) + '/' + qs.transTen(looparr[i]['date']) +
                '~' + looparr[i + 6]['year'] + '/' + qs.transTen(looparr[i + 6]['month']) + '/' + qs.transTen(looparr[i + 6]['date'])
            //detail = detail.replace(/\./g, "/");
            let beginDetail = looparr[i]['year'] + '/' + qs.transTen(looparr[i]['month']) + '/' + qs.transTen(looparr[i]['date'])
            //beginDetail = beginDetail.replace(/\./g, "/");
            let endDetail = looparr[i + 6]['year'] + '/' + qs.transTen(looparr[i + 6]['month']) + '/' + qs.transTen(looparr[i + 6]['date'])
            //endDetail = endDetail.replace(/\./g, "/");

            let beginStr = new Date(beginDetail).getTime()
            let endStr = new Date(endDetail).getTime()

            //console.log(nowStr, beginStr, endStr, nowString, detail)

            if (nowStr >= beginStr && nowStr <= endStr) {

                unitNum = i / 7
            }

            lastSevenArr.push({
                date: looparr[i],
                arr: looparr.slice(i, i += 7),
                detail,
                beginDetail,
                endDetail
            });
        }

        await looparr;

        this.setState({
            lastSevenArr
        })


        let alldays = new Date(preYear, preMonth, 0).getDate() + new Date(nowYear, nowMonth, 0).getDate() + new Date(nextYear, nextMonth, 0).getDate();//3个月天数
        this.refs['swip'].style.width = (lastSevenArr.length) * 238 + 'px';//初始宽度
        // 找到当前日期在第几节===unitnum

        this.refs['swip'].style.marginLeft = -unitNum * 238 + 'px';//初始位置

        let lastRes = {
            preMonthDay: new Date(preYear, preMonth, 0).getDate(),
            preMonthFirstArr,
            preweek,
            preMonth,
            preYear,
            nowMonthDay: new Date(nowYear, nowMonth, 0).getDate(),
            nowMonthFirstArr,
            nowweek,
            nowMonth,
            nowYear,
            nextMonthDay: new Date(nextYear, nextMonth, 0).getDate(),
            nextMonthFirstArr,
            nextweek,
            nextMonth,
            nextYear,
            alldays,
            today: new Date().getDate(),
            swipWidth: this.refs['swip'].style.width,
            beginLeft: this.refs['swip'].style.marginLeft,
            total,
            looparr,
            lastSevenArr,
            unitNum
        };

        window.localStorage.setItem('lastRes', JSON.stringify(lastRes));//

        this.props.lastRes(lastRes);

        return lastRes;
    }

    getMonthFirst = (preYear, preMonth) => {//获取上一月第一天星期
        let preMonthCopy = preMonth - 1
        if (preMonthCopy < 0) {
            preMonthCopy = 11
        }
        if (preMonthCopy > 11) {
            preMonthCopy = 0
        }
        return new Date(preYear, preMonthCopy, 1).getDay()
    }

    prevDay = () => {
        let oldMl = parseInt(this.refs['swip'].style.marginLeft);
        if (oldMl < 0) {
            oldMl += 238;
        } else {
            oldMl = 0
        }
        this.refs['swip'].style.marginLeft = oldMl + 'px'
        emitter.emit('swipLeft', oldMl)
    }

    nextDay = () => {
        let oldMl = parseInt(this.refs['swip'].style.marginLeft);
        if (Math.abs(oldMl) < parseInt(this.refs['swip'].style.width) - 798) {
            oldMl -= 238;
        } else {
            oldMl = 0
        }
        this.refs['swip'].style.marginLeft = oldMl + 'px';
        emitter.emit('swipLeft', oldMl)
    }

    dragStart = (e) => {

        this.setState({
            dragDistance: e.clientX
        })

    }

    dragEnd = (e) => {
        let { dragDistance } = this.state;
        dragDistance = e.clientX - dragDistance
        let oldMl = parseInt(this.refs['swip'].style.marginLeft);
        oldMl += dragDistance
        this.refs['swip'].style.marginLeft = oldMl + 'px'
        emitter.emit('swipLeft', oldMl)
    }

    render() {
        let { lis, threeMonthDays, lastSevenArr } = this.state
        //console.log(threeMonthDays, )
        return (
            <div className='home_main_caledar'>
                <li className='lineLiBtn prev' onClick={() => { this.prevDay() }}>
                    <LeftCircleOutlined />
                </li>
                <div className='home_caledar'>

                    <div
                        className='flex swip'
                        ref='swip'
                        style={{ marginLeft: 0 }}
                        draggable='true'
                        onDrag={e => { }}
                        onDragStart={e => { this.dragStart(e) }}
                        onDragEnd={e => { this.dragEnd(e) }}>

                        {
                            lastSevenArr.length ? (
                                lastSevenArr.map((v, i) => {
                                    return (
                                        <div className='home_ul noflex' key={i + 'home_ul'}>
                                            <p className='loop_date'>{v['detail']}</p>
                                            <ul className='home_ul_son'>
                                                {v.arr.map((x, y) => {
                                                    //console.log(x, threeMonthDays)
                                                    return (
                                                        <li key={y + 'nongli_show'} className={x.month === threeMonthDays.nowMonth && x.date === threeMonthDays.today ? 'nongli_show' : ''}>
                                                            <p>{x.date}</p>
                                                            <p>{x.day}</p>
                                                            <Nongli data={{ 'year': x.year, 'month': x.month, 'day': x.date }} />
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    )
                                })
                            ) : null
                        }





                        {/* <ul className='home_ul' ref='home_ul'>
                            {
                                threeMonthDays.preweek && threeMonthDays.preweek.length ? (
                                    threeMonthDays.preweek.map((v, i) => {
                                        return (
                                            <li className='lineLi'>
                                                <div style={{ color: 'blue' }}>
                                                    {threeMonthDays.preMonthFirstArr[i]}
                                                </div>
                                                {
                                                    v ? (
                                                        <div>{v ? v : ''}</div>
                                                    ) : null
                                                }
                                                

                                                <div>
                                                    {i === 0 ? (
                                                        threeMonthDays.preMonth + '月'
                                                    ) : null}
                                                </div>
                                                <Nongli data={{ 'year': threeMonthDays.preYear, 'month': threeMonthDays.preMonth, 'day': threeMonthDays.preMonthFirstArr[i] }} />
                                            </li>
                                        )
                                    })
                                ) : null
                            }
                        </ul>
                        <ul className='home_ul' ref='home_ul'>
                            {
                                threeMonthDays.nowweek && threeMonthDays.nowweek.length ? (
                                    threeMonthDays.nowweek.map((v, i) => {
                                        return (
                                            <li className={
                                                threeMonthDays.nowMonthFirstArr[i] === threeMonthDays.today ? 'lineLi active' : 'lineLi normal'
                                            }>
                                                <div>

                                                    {
                                                        threeMonthDays.nowMonthFirstArr[i]
                                                    }

                                                </div>
                                                {
                                                    v ? (
                                                        <div>{v ? v : 'ok'}</div>
                                                    ) : null
                                                }
                                                

                                                <div>
                                                    {threeMonthDays.nowMonthFirstArr[i] === threeMonthDays.today ? (
                                                        threeMonthDays.nowMonth + '月'
                                                    ) : null}
                                                </div>
                                                <Nongli data={{ 'year': threeMonthDays.nowYear, 'month': threeMonthDays.nowMonth, 'day': threeMonthDays.nowMonthFirstArr[i] }} />
                                            </li>
                                        )
                                    })
                                ) : null
                            }
                        </ul>
                        <ul className='home_ul' ref='home_ul'>
                            {
                                threeMonthDays.nextweek && threeMonthDays.nextweek.length ? (
                                    threeMonthDays.nextweek.map((v, i) => {
                                        return (
                                            <li className='lineLi'>
                                                <div style={{ color: 'blue' }}>
                                                    {threeMonthDays.nextMonthFirstArr[i]}
                                                </div>
                                                {
                                                    v ? (
                                                        <div >{v ? v : 'ok'}</div>
                                                    ) : null
                                                }
                                                

                                                <div>
                                                    {i === 0 ? (
                                                        threeMonthDays.nextMonth + '月'
                                                    ) : null}
                                                </div>
                                                <Nongli data={{ 'year': threeMonthDays.nextYear, 'month': threeMonthDays.nextMonth, 'day': threeMonthDays.nextMonthFirstArr[i] }} />
                                            </li>
                                        )
                                    })
                                ) : null
                            }
                        </ul> */}
                    </div>

                </div>
                <li className='lineLiBtn next' onClick={() => { this.nextDay() }}>
                    <RightCircleOutlined />
                </li>
            </div>
        )
    }
}