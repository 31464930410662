import React from "react";

const SkuTableHeader = ({ specs }) => {
    return (
        <thead>
            <tr>
                <SpecsTHRender specs={specs} />
                <th>规格编码</th>
                <th>商品售价（元）</th>
                <th>商品成本价（元）</th>
                <th>最大可售数量</th>
            </tr>
        </thead>
    );
}

function SpecsTHRender({ specs }) {
    return (specs.map((item, index) => {
        return (<th key={index}>{item.name}</th>);
    }));
}

export default SkuTableHeader;