import React from 'react'
import ReactDOM from 'react-dom'
import { createStore } from 'redux'
//import Counter from './components/Counter'
import counter from './reducer.js'

const store = createStore(counter)

store.subscribe(()=>{
    //console.log(store.getState())
})

export default store;