import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import React, { Component } from "react";
import { EllipsisOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import AddSupplierTagRequest from '../api/addSupplierTagRequest';//后台 供应商添加标签
import DelSupplierTagRequest from '../api/delSupplierTagRequest';//后台 供应商删除分组的标签
import SupplierTagRequest from '../api/supplierTagRequest';//后台 供应商设置的标签列表
import AddCommanderRequest from '../api/addCommanderRequest';//供应商添加分组信息
import DelSupplierRequest from '../api/delSupplierRequest';//后台 供应商删除分组信息
import AddGrouperToRequest from '../api/addGrouperToRequest';//后台 供应商给分组添加团长
//import DelGrouperToRequest from '../api/delGrouperToRequest';//后台 供应商删除分组下的团长
import GrouperUpdateRequest from '../api/grouperUpdateRequest';//后台 编辑供应商分组信息

import groupListRequest from '../api/groupListRequest';//供应商的分组列表
import GroupHasRequest from '../api/groupHasRequest';//后台 供应商设置的分组下的团长列表
import GetCommanderRequest from '../api/getCommanderRequest';//后台 获取团长列表
import DelCommanderRequest from '../api/delCommanderRequest';//后台 供应商删除分组下的团长
import ChangeGrouperRequest from '../api/changeGrouperRequest';//换组
import 'moment/locale/zh-cn';
import qs from '../../../utils/qs'
import '../css/grouping.sass'
import {
   Row,
   Col,
   Input,
   Button,
   Dropdown,
   Popconfirm,
   Modal,
   Tooltip,
   message,
   Table,
   Menu,
   Radio,
   Checkbox,
   Pagination
} from 'antd';
import stepImgURL from '../../../assets/step.png'
import linkcardUrl from '../../../assets/linkcard.png'
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
let ifBool = false;//判断鼠标是否按下
let moveY = 0;//垂直移动距离
let lineDiv;//长线条
let minDiv;//小圆点
let cards;//长卡片

class GroupingSets extends Base {

   constructor(props) {
      super(props)

      const cardLists = [//数据模拟
         { name: '加载数据中...' }
      ]

      const columns = [
         {
            title: '团长信息',
            key: 'grouper_name',
            render: (ctx) => {
               return (
                  <Tooltip title={ctx['grouper_name']}><p>{qs.ellipsis(ctx['grouper_name'])}</p></Tooltip>
               )
            }
         },
         {
            title: '团长手机号',
            key: 'grouper_mobile',
            render: (ctx) => {
               return (
                  <p>{ctx['grouper_mobile']}</p>
               )
            }
         },
         {
            title: '进行中的团',
            dataIndex: 'shopping_grouping_count',
            key: 'shopping_grouping_count',
         },
         {
            title: '总开团数',
            dataIndex: 'total_shopping_group_count',
            key: 'total_shopping_group_count',
         },
         {
            title: '累计销售额',
            dataIndex: 'sales_total_fee_count',
            key: 'sales_total_fee_count',
         },
         {
            title: '操作',
            render: (ctx) => {
               return (
                  <div className='flex teamContrl'>
                     <Popconfirm
                        title="您确定要删除吗？"
                        onConfirm={() => this.delTeam(ctx)}
                        okText="确定"
                        cancelText="取消"
                     >
                        <p>删除</p>
                     </Popconfirm>

                     <p onClick={() => { this.changeGroup(ctx) }}>换组</p>
                  </div>
               )
            }
         }
      ]

      const fenzhuColumns = [//分组数据
         {
            title: '选择分组',
            dataIndex: 'name',
            key: 'name',
         }
      ]

      const teamColumns = [
         {
            title: '团长昵称',
            dataIndex: 'grouper_name',
            key: 'grouper_name',
         },
         {
            title: '手机号',
            dataIndex: 'grouper_mobile',
            key: 'grouper_mobile',
         },
         // {
         //    title: '操作',
         //    render: () => {
         //       return (
         //          <p>删除</p>
         //       )
         //    }
         // },
      ]

      const dataSource = [
         { grouper_id: '0', grouper_name: '暂无数据', grouper_mobile: '暂无数据', team_ing: '暂无数据', team_all_num: '暂无数据', team_total: '暂无数据' }
      ]

      this.state = {
         limitNum: 0,//分组名称字数限制
         moveTop: 0,
         cardFlag: 0,
         cardLists: cardLists,
         startMoveFlag: 0,
         delVisible: false,
         editorVisible: false,
         tableLoading: true,
         contrlItem: '',
         cardMoveTop: 0,
         dataSource: dataSource,
         selectTeam: [],
         columns: columns,
         fenzhuColumns: fenzhuColumns,
         changeVisible: false,
         fenzhuName: '',
         addLabelVisible: false,
         addTeamVisible: false,
         teamColumns: teamColumns,
         selectedRowKeys: [],
         selectedRowKeysFz: [],
         confirmTeamVisible: false,
         groupName: '',//分组名称
         groupRemark: '',//分组备注
         searchName: '',//搜索团长
         tagName: '',//标签名
         supplierList: [],//标签列表
         fixSupplierList: [],
         delsgid: null,//待删除分组ID
         supplier_group_id: null,//
         changeParam: {},
         teamPage: 1,//团长分组
      }
   }

   async componentDidMount() {//
      lineDiv = document.getElementById('stepLine'); //长线条
      minDiv = document.getElementById('contrl');//小圆点
      cards = document.getElementById('cards');//长卡片
      minDiv.addEventListener("touchstart", this.downDom);
      minDiv.addEventListener("mousedown", this.downDom);
      //拖动
      window.addEventListener("touchmove", this.moveDom);
      window.addEventListener("mousemove", this.moveDom);
      //鼠标松开
      window.addEventListener("touchend", this.moveOut);
      window.addEventListener("mouseup", this.moveOut);
      //
      await this.getGroupList();//供应商的分组列表

      //
   }

   componentWillMount() {

   }

   getGroupList = async (id) => {
      let { supplier_group_id } = this.state
      const hide = message.loading('加载数据中')
      let param = {
         page_size: 500
      }
      let res = await groupListRequest.send(param);
      if (res.code == 200) {
         this.setState({
            cardLists: res['data']
         })
      }
      //供应商设置的分组下的团长列表
      //默认第一个
      if (!res['data'] || !res['data'].length) {
         return this.setState({ tableLoading: false })
      };
      //let firstId = await res['data'][0]['id'] || null;
      // this.setState({
      //    supplier_group_id: firstId
      // })
      //if (!firstId) return
      await this.getHasList(supplier_group_id, hide)
      //
   }

   supplierTag = async () => {//供应商设置的标签列表
      let param = {}
      let res = await SupplierTagRequest.send(param);
      if (!res['data']) return
      res['data'].map(v => {
         v['checked'] = false
      })
      if (res['code'] == 200) {
         this.setState({
            supplierList: res['data']
         })
      }
   }

   addSupplierTag = async (data) => {//供应商添加标签
      let param = {
         name: data['name'],
         supplier_group_id: data['supplier_group_id']
      }
      const hide = message.loading('添加标签中')

      let res = await AddSupplierTagRequest.send(param);

      if (res.code == 200) {

         message.success('添加成功！')

         this.supplierTag();
      } else {
         message.error(res.message)
      }

      hide()
      //console.log(res)

   }

   writeSearch = (e) => {//输入团长昵称，tel
      this.setState({
         searchName: e.target.value
      })
   }

   getCommander = async () => {//后台 获取团长列表
      let { searchName, dataSource } = this.state

      let param = {//搜索条件
         search: `grouper_name::${searchName}`
      }

      //是否为手机号
      if (qs.isMobile(searchName)) {
         param = {
            search: `grouper_mobile::${searchName}`
         }
      }

      let res = await GetCommanderRequest.send(param)

      if (res.status) {
         let lastData = res['data'];
         lastData.map(v => {
            v['key'] = v['grouper_mobile']
         })
         this.setState({
            selectTeam: lastData
         })
      }
      //console.log(this.state.selectTeam)
   }

   setCommander = async () => {//保存分组

      const { groupName, groupRemark, fixSupplierList } = await this.state
      let check = true
      check = qs.isNull(groupName, '请输入分组名称', () => { })
      if (!check) return
      // check = qs.isNull(groupRemark, '请输入分组备注',()=>{})
      // if (!check) return

      let saveTagList = [];

      fixSupplierList.map(v => {
         saveTagList.push(v)
      })

      const hide = message.loading('分组添加中', 1)

      let param = {
         name: groupName,
         remark: groupRemark,
         tags: saveTagList
      }

      let res = await AddCommanderRequest.send(param)

      if (res.code == 200) {
         message.success('添加成功')
         this.getGroupList();//刷新list
         this.setState({
            groupRemark: '',
            groupName: ''
         })
      } else {
         message.error(res.message)
      }
      hide();

   }

   getHasList = async (id, hide) => {//后台 供应商设置的分组下的团长列表
      let param = {
         supplier_group_id: id
      }
      let resHas = await GroupHasRequest.send(param)
      if (resHas.code == 200 && resHas['data']) {
         this.setState({
            dataSource: resHas['data']
         })
      }
      hide()

      this.setState({
         tableLoading: false
      })
   }

   moveflag = (i) => {//移动card切换menu控制
      this.setState({
         startMoveFlag: i
      })
   }

   fenzhu = (e) => {
      this.setState({
         groupName: e.target.value,
         limitNum: e.target.value.length
      })
   }

   writeRemark = (e) => {
      //console.log(e)
      this.setState({
         groupRemark: e.target.value
      })
   }

   tabCard = (v, index) => {//切换card
      const hide = message.loading('加载数据中')
      this.setState({
         cardFlag: index,
         supplier_group_id: v['id']
      })
      //console.log(v['id'])
      this.getHasList(v['id'], hide);
   }

   downDom = (e) => {
      ifBool = true;
      //console.log("鼠标按下")
   }

   moveDom = (e) => {//鼠标拖动
      if (ifBool) {
         moveY = e.clientY;
         let lineDiv_top = this.getPosition(lineDiv).top;
         let minDiv_top = moveY - lineDiv_top; //小方块相对于父元素（长线条）的top值

         if (minDiv_top >= lineDiv.offsetHeight - 20) {
            minDiv_top = lineDiv.offsetHeight - 20;
         }
         if (minDiv_top < 0) {
            minDiv_top = 0
         }

         //设置拖动后小方块的top值
         this.setState({
            moveTop: minDiv_top
         })


         // %
         let persents = parseInt((minDiv_top / (lineDiv.offsetHeight - 20)) * 100);//获取小圆点移动百分比

         let cardHeight = cards.offsetHeight; //获取card长度

         this.setState({
            cardMoveTop: -(cardHeight * persents) / 100 //设置card移动
         })

      }
   }

   moveOut = (e) => {//鼠标移出
      ifBool = false
   }

   getPosition = (node) => {//获取元素属性
      let left = node.offsetLeft; //获取元素相对于其父元素的left值var left
      let top = node.offsetTop;
      let current = node.offsetParent; // 取得元素的offsetParent
      // 一直循环直到根元素

      while (current != null) {
         left += current.offsetLeft;
         top += current.offsetTop;
         current = current.offsetParent;
      }
      return {
         "left": left,
         "top": top
      };
   }

   handleCancel = () => {//取消
      this.setState({
         delVisible: false,
         changeVisible: false,
         addLabelVisible: false,
         addTeamVisible: false,
         confirmTeamVisible: false,
         editorVisible: false
      })
   }

   editorOk = async () => {//编辑
      const hide = message.loading('编辑中')
      let { groupName, delsgid, remark, groupRemark } = this.state;
      let param = {
         name: groupName,
         supplier_group_id: delsgid,
         remark: groupRemark
      }
      //console.log(param)

      let res = await GrouperUpdateRequest.send(param);
      if (res.code == 200) {
         message.success('编辑成功！')
         this.setState({
            editorVisible: false
         })
         this.getGroupList()
      } else {
         message.error(res.message)
      }

      hide()

      this.setState({
         groupName: '',
         groupRemark: ''
      })

      //console.log(res)

   }

   delOk = async () => {//删除OK

      this.setState({
         delVisible: false
      })

      let { delsgid } = this.state
      const hide = message.loading('删除分组信息中')
      let param = {
         supplier_group_id: delsgid
      }
      let res = await DelSupplierRequest.send(param);
      //console.log(res)

      if (res.code == 200) {
         message.success('删除成功')
         this.getGroupList()

      } else {
         message.error(res.message)
      }

      hide()
   }

   changeOk = async () => {//切换分组OK
      let { selectedRowKeysFz, supplier_group_id, changeParam } = this.state;

      if (!selectedRowKeysFz.length) {
         return message.error('请选择分组')
      }

      const hide = message.loading('换组中，请稍后')

      let param = {
         ids: [changeParam['id']],
         grouper_id: changeParam['grouper_id'],
         supplier_group_id: supplier_group_id
      }

      let res = await ChangeGrouperRequest.send(param)

      if (res.code == 200) {
         message.success(res.message)
         this.getGroupList()
      } else {
         message.error(res.message)
      }

      hide()

      this.setState({
         changeVisible: false
      })
   }

   addLabelOk = () => {//add标签ok

      const { tagName, fixSupplierList, supplierList } = this.state;

      let tagNameChecked = qs.isNull(tagName, '尚未输入标签')
      if (!tagNameChecked) return

      if (fixSupplierList.find((v) => v == tagName)) {
         return message.error('存在相同标签')
      }

      fixSupplierList.push(tagName)

      // for (let i in supplierList) {//重复的值
      //    if (supplierList[i]['name'].indexOf(tagName) > -1) {
      //       return message.error('该标签已存在！')
      //    }
      // }

      // fixSupplierList.push(tagName);

      this.setState({
         //fixSupplierList,
         tagName: ''
      })

      this.handleCancel();


      // let param = {
      //    name: tagName,
      //    supplier_group_id: 0
      // }

      //this.addSupplierTag(param)
   }

   confirmDel = async (v, i) => {
      let param = {
         tag_id: v['id']
      }
      const hide = message.loading('删除标签中')
      let res = await DelSupplierTagRequest.send(param)
      if (res.code == 200) {
         message.success(res.message)
         this.supplierTag()
      } else {
         message.error(res.message)
      }
      hide()
      // let {fixSupplierList} = this.state;
      // fixSupplierList.splice(i,1)
      // this.setState({
      //    fixSupplierList
      // })
   }

   delFixsupperList = (i) => {//删除标签
      let { fixSupplierList } = this.state;

      fixSupplierList.splice(i, 1)

      this.setState({
         fixSupplierList
      })

   }

   addFixsupperList = (v, i) => {//添加标签
      let newArrlist = []
      let { supplierList } = this.state;
      supplierList[i]['checked'] = !supplierList[i]['checked'];//取反
      supplierList.map(v => {
         if (v['checked']) {
            newArrlist.push(v)
         }
      })
      this.setState({
         fixSupplierList: newArrlist
      })

   }

   addTeamOk = async () => {//add团长
      this.handleCancel();
      const { selectedRowKeys, supplier_group_id } = await this.state
      const hide = message.loading('添加团长中')
      let param = {
         grouper_id_array: selectedRowKeys,
         supplier_group_id: supplier_group_id
      }
      let res = await AddGrouperToRequest.send(param)
      if (res.status) {
         this.getHasList(supplier_group_id, hide)
         message.success(res.message)
         this.getGroupList(supplier_group_id);
         //console.log()
      }
      else {
         message.error(res.message)
      }
      hide()
      // this.setState({
      //    confirmTeamVisible: true//弹出确认框
      // })
   }

   confirmTeamOk = () => {//添加团长确认
      this.handleCancel()
   }

   onSelectChange = selectedRowKeys => {
      //console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.setState({ selectedRowKeys });
   }

   onSelectChangeFz = selectedRowKeysFz => {
      let { supplier_group_id } = this.state
      supplier_group_id = selectedRowKeysFz[0]['id'];
      //console.log('selectedRowKeysFz changed: ', selectedRowKeysFz);
      this.setState({ selectedRowKeysFz, supplier_group_id });
   }

   setCharge = (e, v) => {//菜单
      //console.log(e.key, v)
      switch (e.key) {
         case 'item_0':
            break;
         case 'tmp_key-1':
            this.editorCard(v)
            break;
         case 'tmp_key-2':
            this.delCard(v)
            break;
         case 'item_3':
            break;
         default:
            break;
      }
   }

   delCard = (v) => {//删除card,model
      this.setState({
         delVisible: true,
         delsgid: v['id']
      })
   }

   editorCard = (v) => {//编辑card
      this.setState({
         editorVisible: true,
         delsgid: v['id']
      })
   }

   delTeam = async (ctx) => {//删除团长
      //console.log(ctx)
      const hide = message.loading('删除中');
      const { selectedRowKeys, supplier_group_id } = this.state
      const hide1 = message.loading('数据加载中')

      let param = {
         ids: [ctx['id']]
      }
      let res = await DelCommanderRequest.send(param);
      if (res.status) {
         message.success(res.message)
         this.getHasList(supplier_group_id, hide1)
      } else {
         message.error(res.message)
      }
      hide();
   }

   pageChange = (e) => {
      //console.log(e) //切换分页
      this.setState({
         teamPage: e
      })
   }

   changeGroup = (e) => {//分组modal
      //console.log(e,'changeGroupchangeGroup')
      this.setState({
         changeVisible: true,
         changeParam: {
            id: e.id,
            grouper_id: e.grouper_id
         }
      })
   }

   fenzhuonChange = (e) => {//分组切换
      this.setState({
         fenzhuName: e.target.value
      })
   }

   addLabel = async () => {//添加label
      //await this.supplierTag();//供应商设置的标签列表
      this.setState({
         addLabelVisible: true
      })
   }


   render() {
      const { groupName, groupRemark, selectedRowKeys, tableLoading, supplierList, fixSupplierList, tagName, selectedRowKeysFz } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange
      };
      const rowSelectionFz = {
         selectedRowKeysFz,
         onChange: this.onSelectChangeFz
      }
      // const menu = (
      //    <Menu onClick={(e) => this.setCharge(e, 1)}>
      //       <Menu.Item>
      //          设置负责人
      //      </Menu.Item>
      //       <Menu.Item>
      //          编辑
      //      </Menu.Item>
      //       <Menu.Item>
      //          删除
      //      </Menu.Item>
      //       <Menu.Item>
      //          生成二维码
      //      </Menu.Item>
      //    </Menu>
      // );
      const that = this;

      const Menus = function (params) {
         return (
            <Menu onClick={(e, i) => that.setCharge(e, params, i)}>
               <Menu.Item style={{ display: 'none' }}>
                  设置负责人
               </Menu.Item>
               <Menu.Item>
                  编辑
               </Menu.Item>
               <Menu.Item>
                  删除
               </Menu.Item>
               <Menu.Item style={{ display: 'none' }}>
                  生成二维码
               </Menu.Item>
            </Menu>
         )
      }


      return (
         <div className='grouping' style={{ marginRight: 0 }}>
            <div className='flex' style={{ alignItems: 'flex-start' }}>
               <div className='step1'>
                  <p className='step1tips'>供应商可以灵活地运用分组功能，把某些团长进行归类并给组打上标签用于筛选，开始创建吧！</p>
                  <img style={{ width: '36px', display: 'block', margin: '10px auto' }} src={stepImgURL} alt='' />
                  <div className='box'>
                     <p className='title'>第一步.填写渠道</p>
                     <div>
                        <div className='flex' style={{ justifyContent: 'space-between', color: '#8C8C8C', fontSize: '16px', margin: '40px 0 10px 0' }}>
                           <p>渠道名称：</p> <p>{this.state.limitNum}/20</p>
                        </div>
                        <Input style={{ fontSize: '12px' }} value={groupName} placeholder='请输入渠道名称' onChange={this.fenzhu} maxLength={20}></Input>
                     </div>
                     <div>
                        <div className='flex' style={{ justifyContent: 'space-between', color: '#8C8C8C', fontSize: '16px', margin: '40px 0 10px 0' }}>
                           <p>渠道备注：</p> <p>{groupRemark.length}/50</p>
                        </div>
                        <div className='fenzhuRemarks'>
                           <TextArea value={groupRemark} placeholder='请输入渠道备注' onChange={this.writeRemark} className='textinit' style={{ resize: 'none', height: '120px', fontSize: '12px' }} maxLength={50} />
                        </div>
                     </div>
                     <div style={{ overflow: 'hidden' }}>
                        <div className='flex' style={{ color: '#8C8C8C', fontSize: '16px', margin: '20px 0 10px 0', flexWrap: 'wrap' }}>
                           {/* <p></p> */}
                           <ul className='flex fenzhuList'>
                              <li>渠道标签：</li>
                              {
                                 fixSupplierList.length ? (
                                    fixSupplierList.map((v, i) => {
                                       return (
                                          <li key={v['id']} style={{ cursor: 'pointer' }}>
                                             {v}
                                             <Popconfirm
                                                title="您确定要删除吗？"
                                                onConfirm={() => this.delFixsupperList(i)}
                                                okText="确定"
                                                cancelText="取消"
                                             >
                                                <div className='delSupplier'>
                                                   <CloseCircleOutlined />
                                                </div>
                                             </Popconfirm>
                                          </li>
                                       )
                                    })
                                 ) : (
                                    <li style={{ background: 'none' }}>点击下方按钮添加</li>
                                 )
                              }
                           </ul>
                        </div>
                        <Button className='add_fenzhu' type='primary' onClick={this.addLabel}>+<span style={{ width: '10px', display: 'inline-block' }}></span>添加</Button>
                     </div>
                     <div className='flex' style={{ justifyContent: 'center', marginTop: '60px' }}>
                        <Button style={{ width: '78px', height: '30px', marginRight: '40px' }} type='primary' className='button-bac slow'>清空</Button>
                        <Button style={{ width: '78px', height: '30px' }} onClick={() => { this.setCommander() }} type='primary' className='button-bac'>保存</Button>
                     </div>
                  </div>
               </div>
               <div className='step2 flex'>
                  <div className='stepLine' id='stepLine'>
                     <span style={{ top: this.state.moveTop + 'px' }} id='contrl'></span>
                  </div>
                  <ul className='cards' id='cards' style={{ marginTop: this.state.cardMoveTop + 'px' }}>
                     {this.state.cardLists.length ? (
                        this.state.cardLists.map((v, index) => {
                           return <li
                              className={this.state.cardFlag == index ? 'card active' : 'card'}
                              onClick={() => { this.tabCard(v, index) }}
                              key={index}
                           // onMouseOver={() => { this.moveflag(index) }}

                           >
                              <div className='linkcard'>
                                 <img src={linkcardUrl} alt="" />
                                 <img src={linkcardUrl} alt="" />
                              </div>

                              <div className='title flex'>
                                 <p style={{ cursor: 'pointer' }}>{v['name']}</p>
                                 {
                                    this.state.cardFlag == index ? (
                                       <Dropdown overlay={Menus(v)} placement="bottomCenter">
                                          <EllipsisOutlined className='bot' />
                                       </Dropdown>
                                    ) : null
                                 }
                              </div>
                              <p>团长数：{v['grouper_count']}</p>
                           </li>
                        })
                     ) : (
                        <li className='card'>
                           <div className='linkcard'>
                           </div>

                           <div className='title flex'>
                              <p>暂无数据</p>
                           </div>
                           <p>团长数：暂无数据</p>
                        </li>
                     )}
                  </ul>
                  <div className='cardDetail box'>
                     <div className='flex addTeam'>
                        <p className='title'>第二步.添加或删除团长</p>
                        <p className='btn' onClick={async () => { if (!this.state.cardLists.length) { return message.error('请您先填写渠道') } await this.getCommander(); this.setState({ addTeamVisible: true }); }}>添加团长</p>
                     </div>
                     <Table
                        dataSource={
                           this.state.dataSource
                        }
                        style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                        loading={tableLoading}
                        columns={
                           this.state.columns
                        }
                        maxLength={10}
                        rowKey={(e) => { return e.team_tel }}
                        rowClassName={(e, index) => { return index % 2 ? 'gary' : '' }}
                        pagination={false}
                        scroll={
                           { y: 400, x: 100 }
                        }
                     />
                     <div className='pageContent'>
                        {/* <Pagination defaultCurrent={1} total={50} onChange={this.pageChange} /> */}
                     </div>
                  </div>
               </div>
            </div>

            <Modal title="提示"
               visible={this.state.delVisible}
               centered
               footer={
                  <div className='flex' style={{ justifyContent: 'center' }}>
                     <Button style={{ marginRight: '40px' }} type='primary' className='button-bac slow' onClick={this.handleCancel}>取消</Button>
                     <Button type='primary' className='button-bac' onClick={this.delOk}>确定</Button>
                  </div>
               }
            >
               <p>确定删除该组吗？</p>
            </Modal>

            <Modal
               title="切换分组"
               onCancel={this.handleCancel}
               visible={this.state.changeVisible}
               style={{ textAlign: 'center' }}
               className='searchTeamLists'
               centered
               footer={
                  <div className='flex' style={{ justifyContent: 'center' }}>
                     <Button style={{ marginRight: '40px' }} type='primary' className='button-bac slow' onClick={this.handleCancel}>取消</Button>
                     <Button type='primary' className='button-bac' onClick={this.changeOk}>确定</Button>
                  </div>
               }
            >
               <div className='flex searchRules'>
                  <Input className='searchInput' placeholder='请输入分组名称'></Input>
                  <Button type="primary" shape="circle" icon={<SearchOutlined />}>搜索</Button>
               </div>
               <Table
                  rowSelection={{
                     type: 'radio',
                     ...rowSelectionFz,
                  }}
                  dataSource={
                     this.state.cardLists
                  }
                  style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                  loading={false}
                  columns={
                     this.state.fenzhuColumns
                  }
                  maxLength={10}
                  rowKey={(e) => { return e }}
                  rowClassName={(e, index) => { return index % 2 ? 'gary' : '' }}
                  pagination={false}
               />
            </Modal>

            <Modal
               title='添加标签'
               onCancel={this.handleCancel}
               visible={this.state.addLabelVisible}
               centered
               style={{ textAlign: 'center' }}
               className='addLabel'
               footer={
                  <div className='flex' style={{ justifyContent: 'center' }}>
                     <Button type='primary' className='button-bac slow' onClick={this.handleCancel}>返回</Button>
                     <Button type='primary' className='button-bac' onClick={this.addLabelOk}>确定</Button>
                  </div>
               }

            >
               <div className='flex write'>
                  <label htmlFor="">新增标签：</label>
                  <Input value={tagName} onChange={(e) => { this.setState({ tagName: e.target.value }) }} placeholder='请输入标签名'></Input>
               </div>
               <div className='flex' style={{ color: '#8C8C8C', fontSize: '16px', flexWrap: 'wrap' }}>
                  {/* <p></p> */}
                  {/* <ul className='flex fenzhuList'>
                     <li>分组标签：</li>
                     {
                        supplierList.length ? (
                           supplierList.map((v, i) => {
                              return (
                                 <li className={v['checked'] ? 'active' : ''} onClick={() => { this.addFixsupperList(v, i) }} key={v['id']} style={{ cursor: 'pointer' }}>
                                    {v['name']}
                                    <Popconfirm
                                       title="您确定要删除吗？"
                                       onConfirm={() => this.confirmDel(v, i)}
                                       okText="确定"
                                       cancelText="取消"
                                    >
                                       <div className='delSupplier'>
                                          <CloseCircleOutlined />
                                       </div>
                                    </Popconfirm>
                                 </li>
                              )
                           })
                        ) : (
                           <li style={{ background: 'none' }}>点击确定按钮添加</li>
                        )
                     }
                  </ul> */}
               </div>
            </Modal>

            <Modal
               title="添加团长"
               visible={this.state.addTeamVisible}
               centered
               style={{ textAlign: 'center' }}
               className='addTeam'
               onCancel={this.handleCancel}
               footer={false}
            >
               <div className="add_teamer">
                  {/* <div>添加团长</div> */}
                  <div className='flex' style={{ marginTop: '30px' }}>
                     <label htmlFor="">搜索团长：</label>
                     <div className='flex searchTeam'>
                        <Input onChange={this.writeSearch} maxLength={20} placeholder='请输入团长昵称/手机号等'></Input>
                        <div className='searchTeamBtn' onClick={this.getCommander}>
                           <SearchOutlined />
                        </div>
                     </div>
                  </div>
                  <div className='flex' style={{ alignItems: 'flex-start', marginTop: '20px' }}>
                     <label htmlFor="">选择团长：</label>
                     <Table
                        rowSelection={{ ...rowSelection }}
                        columns={this.state.teamColumns}
                        dataSource={this.state.selectTeam}
                        maxLength={10}
                        style={{ flex: 1 }}
                        rowKey={(e) => { return e.grouper_id }}
                        rowClassName={(e, index) => { return index % 2 ? 'gary' : '' }}
                        pagination={false}
                        scroll={
                           {
                              scrollToFirstRowOnChange: false,
                              x: false,
                              y: 400
                           }
                        }
                     >
                     </Table>
                  </div>

                  <div className='flex' style={{ justifyContent: 'center', margin: '80px 0 10px 0' }}>
                     <Button style={{ width: '78px', height: '30px', marginRight: '40px' }} type='primary' className='button-bac slow' onClick={this.handleCancel}>取消</Button>
                     <Button style={{ width: '78px', height: '30px' }} type='primary' className='button-bac' onClick={this.addTeamOk}>确定</Button>
                  </div>

               </div>
            </Modal>

            <Modal
               title='提示'
               visible={this.state.confirmTeamVisible}
               style={{ textAlign: 'center' }}
               className='confirmModal'
               centered
               footer={
                  <div className='flex' style={{ justifyContent: 'center' }}>
                     <Button style={{ marginRight: '40px' }} type='primary' className='button-bac slow' onClick={this.handleCancel}>取消</Button>
                     <Button type='primary' className='button-bac' onClick={this.confirmTeamOk}>确定</Button>
                  </div>
               }
            >
               <div className='confirmTxt'>
                  <p>当前团长在 <span style={{ color: '#366FBB' }}>特拉斯</span> 分组下</p>
                  <p>确定添加至 <span style={{ color: '#366FBB' }}>比亚迪</span>分组</p>
               </div>

            </Modal>

            <Modal
               onCancel={this.handleCancel}
               title='提示'
               visible={this.state.editorVisible}
               style={{ textAlign: 'center' }}
               className='confirmModal'
               centered
               footer={
                  <div className='flex' style={{ justifyContent: 'center' }}>
                     <Button style={{ marginRight: '40px' }} type='primary' className='button-bac slow' onClick={this.handleCancel}>取消</Button>
                     <Button type='primary' className='button-bac' onClick={this.editorOk}>确定</Button>
                  </div>
               }
            >
               <div className='box'>
                  <div>
                     <div className='flex' style={{ justifyContent: 'space-between', color: '#8C8C8C', fontSize: '16px', margin: '40px 0 10px 0' }}>
                        <p>渠道名称：</p> <p>{this.state.limitNum}/20</p>
                     </div>
                     <Input style={{ fontSize: '12px' }} value={groupName} placeholder='请输入渠道名称' onChange={this.fenzhu} maxLength={20}></Input>
                  </div>
                  <div>
                     <div className='flex' style={{ justifyContent: 'space-between', color: '#8C8C8C', fontSize: '16px', margin: '40px 0 10px 0' }}>
                        <p>渠道备注：</p> <p>{groupRemark.length}/50</p>
                     </div>
                     <div className='fenzhuRemarks'>
                        <TextArea value={groupRemark} placeholder='请输入渠道备注' onChange={this.writeRemark} className='textinit' style={{ resize: 'none', height: '120px', fontSize: '12px' }} maxLength={50} />
                     </div>
                  </div>
               </div>

            </Modal>

         </div>
      )
   }

}

export default router(GroupingSets)