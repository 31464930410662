import React from "react";
import qs from '../../../utils/qs'

import './line.sass';

export default class Line extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            domStyle: {}
        }
    }

    componentDidMount(e) {
        //console.log(, )
        this.getStyle()
    }

    componentWillReceiveProps(e) {
        //console.log(e, )
        this.getStyle(e)
    }

    getStyle = (e=this.props) => {//计算
        let props = e;
        let x = props.params.endDay;
        let y = 1.06;
        let z = Math.sqrt(x * x + y).toFixed(2);
        let rota = qs.getAngle(x, y);
        //style
        this.setState({
            domStyle: {
                width: z * (32 + 2),
                rotate: `rotateZ(${rota}deg)`
            }
        })
        //this.props.width(z * 32)
        return {
            z, rota
        }
    }

    render() {
        let { domStyle } = this.state
        return (
            <div
                className='ligature'
                ref='ligature'
                style={{ width: domStyle.width, transform: domStyle.rotate }}>
            </div>
        )
    }
}