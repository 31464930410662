import {ServiceProvider} from "@zoranwong/acc-engine.js";
import Router from "../services/Router";
import routes from "../routes";
export default class RouterServiceProvider extends ServiceProvider {
    register () {
        this.app.singleton('$routes', () => {
            return routes;
        });
        this.app.singleton('$router', () => {
            return new Router(this.app['$routes']);
        });
    }
}