import { Request } from '@zoranwong/acc-engine.js';
export default class OrderDetailRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        order_id: ''
    };
    _method = 'GET';
    _uri = '/order/group-order/merchant-order/order-detail';
    constructor(params) {
        super(params);
        this._data['order_id'] = params['order_id'];
    }
    rules() {

    }

    messages() {

    }
}
