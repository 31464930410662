import React from 'react'
import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import MapBox from './com/MapBox';
import RouteList from './com/RouteList';
import '../css/mapBox.sass'

class Itinerary extends Base {
    constructor(props) {
        super(props)

        this.state = {
            MapData: {},
            clear: false
        }

    }

    render() {
        let { MapData, clear } = this.state
        return (
            <div className='itinerary'>
                <MapBox MapData={MapData} clear={clear} />

                <RouteList MapData={e => { this.setState({ MapData: e }) }} clearOverlays={e => { this.setState({clear: e}) }} />
            </div>
        )
    }
}


export default router(Itinerary);