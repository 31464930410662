import Layout from '../components/layout';
import LogisticsIndex from '../pages/logistics';
import LogisticsRouteIndex from '../pages/logistics/logisticsRoute/logisticsRoute'
import SelfLiftingPointManagement from '../pages/logistics/selfLiftingPointManagement/selfLiftingPointManagement'
import Itinerary from '../pages/logistics/itinerary'
import logisticsIcon from '../assets/logisticsIcon.png'
import deliveryIcon from '../assets/deliveryIcon.png'
const sider = [
    	{
            name: '自提点管理',
            path: '/logistics/routes',
            icon: logisticsIcon
        },
        // {
        //     name: '配送线路',
        //     path: '/logistics/selfliftinglointmanagement',
        //     icon: deliveryIcon
        // },
        {
            name: '常用路线排线',
            path: '/logistics/itinerary',
            icon: deliveryIcon
        },
];
const logistics = {
    path: "/logistics",
    component: Layout,
    sider: sider,
    routes:[
        {
            path: "/logistics",
            exact: true,
            name: 'logisticsIndex',
            component: LogisticsIndex
        },
        {
            path: "/logistics/routes",
            exact: true,
            name: 'logisticsRouteIndex',
            component: LogisticsRouteIndex
        },
        {
            path: "/logistics/selfliftinglointmanagement",
            exact: true,
            name: 'selfLiftingPointManagement',
            component: SelfLiftingPointManagement
        },
        {
            path: "/logistics/itinerary",
            exact: true,
            name: 'Itinerary',
            component: Itinerary
        }
    ]
};
export default logistics;