import React, { useState } from 'react';
import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Popconfirm,
  Modal,
  message,
  Cascader,
  Upload,
  Pagination
} from 'antd';
import config from '../../../configs/upload'
import '../css/logisticsRoute.sass'
import { Map, Marker, NavigationControl, InfoWindow } from 'react-bmap'
import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';



class LogisticsRoute extends Base {
  constructor(args) {
    super(args)
    const hoverContent = <div>This is hover content.</div>;
    const clickContent = <div>This is click content.</div>;

    const columns = [
      {
        title: '所属团长',
        dataIndex: 'name'
      },
      {
        title: '详细地址',
        dataIndex: 'address',
      },
      {
        title: '团长联系电话',
        dataIndex: 'address',
      },
      {
        title: '管理天数',
        dataIndex: 'age',
      },
      {
        title: '操作',
        render: (dataSource, i) =>

          <div className='flex contrlTxt'>
            <p onClick={() => this.editor(i)}>编辑</p>

            <Popconfirm
              title="您确定要删除吗？"
              onConfirm={() => this.del_list(dataSource)}
              onCancel={this.del_cancel}
              okText="确定"
              cancelText="取消"
            >
              <p>删除</p>
            </Popconfirm>

          </div>
      }
    ];
    const dataSource = [
      {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
      },
      {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
      },
      {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
      },
      {
        key: '4',
        name: 'Disabled User',
        age: 99,
        address: 'Sidney No. 1 Lake Park',
      }
    ];



    this.state = {
      selfGetAddress: '',
      columns: columns,
      dataSource: dataSource,
      selectedRowKeys: [],
      del_visible: false,
      isModalVisible: false,
      fileList: [
        {
          uid: '-4',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        }
      ],
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      total: 0,
      page: 1
    }

  }

  del_cancel = () => {
    message.error('您已取消删除！');
  }

  del_list = (data) => {
    if (Array.isArray(data)) {
      //console.log(data, this.state.dataSource)

    } else {
      const screenArr = this.state.dataSource.filter((item) => {
        return !data['key'].includes(item.key)
      });

      this.setState({
        dataSource: screenArr
      })
    }
    message.success('删除成功！')
  }


  getSelf = (e) => {
    this.setState({
      selfGetAddress: e.target.value
    })
    //console.log(e.target.value, 'e.target.value')
  }

  clearParam = (e) => {

  }

  onSelectChange = (selectedRowKeys) => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  }

  del_more = () => {
    const data = this.state.selectedRowKeys
    if (!data.length) {
      return message.error('未选择！');
    }
    this.del_list(data)
  }

  editor = (i) => {
    this.setState({
      isModalVisible: true
    })
  }

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      previewVisible: false
    })
  }

  handleOk = () => {
    this.setState({
      isModalVisible: false
    })
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  }


  handleChange = ({ fileList }) => {
    this.setState({ fileList })
    //console.log(fileList)
  }

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    function onChange(value) {
      //console.log(value);
    }

    const options = [
      {
        value: 'zhejiang',
        label: 'Zhejiang',
        children: [
          {
            value: 'hangzhou',
            label: 'Hangzhou',
            children: [
              {
                value: 'xihu',
                label: 'West Lake',
              },
            ],
          },
        ],
      },
      {
        value: 'jiangsu',
        label: 'Jiangsu',
        children: [
          {
            value: 'nanjing',
            label: 'Nanjing',
            children: [
              {
                value: 'zhonghuamen',
                label: 'Zhong Hua Men',
              },
            ],
          },
        ],
      },
    ];
    return (
      <div className='logisticsRoute'>
        <div className='box top'>
          <Row>
            <Col span={18}>
              <Row>
                <Col className='flex'>
                  <label>搜索自提点：</label><Input style={{ width: '320px' }} className='' onBlur={this.getSelf}
                    placeholder='请输入活动名称/跟团号/手机号/姓名/微信' />
                </Col>

                <Col className='flex'>
                  <Button className='searchBtn' type="primary" icon={< SearchOutlined />}>搜索 </Button>
                  <div style={{ marginLeft: "20px", cursor: 'pointer' }} onClick={() => this.clearParam()} > 清空所有搜索条件 </div>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Button className='primaryBtn' type='primary'>导出自提点数据</Button>
              <Button className='primaryBtn' type='primary' onClick={this.del_more}>批量删除</Button>
            </Col>
          </Row>
        </div>

        <div className="lists" >
          <Table
            pagination={false}
            rowSelection={rowSelection}
            columns={this.state.columns}
            style={{ textAlign: 'center' }}
            dataSource={this.state.dataSource}
            rowKey={record => record.key}
            rowClassName={(record, index) => { return index % 2 ? 'active' : '' }}
            scroll={
              {
                scrollToFirstRowOnChange: false,
                x: false,
                y: 400
              }
            }

          />

          <div style={{ marginTop: '50px', textAlign: 'center' }}>
            {this.state.total ? (
              <Pagination hideOnSinglePage={true} onChange={(e) => { this.searchList(e) }} defaultCurrent={1} current={this.state.page} pageSize={15} total={this.state.total} />
            ) : null}
          </div>
        </div>

        <Modal
          title="编辑自提点"
          style={{ textAlign: 'center' }}
          centered
          footer={false}
          className='editorBox'
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}>
          <div className='citySelect'>
            <label htmlFor="">自提点省市区：</label>
            <Cascader
              defaultValue={['zhejiang', 'hangzhou', 'xihu']}
              options={options}
              onChange={onChange}
            />
          </div>
          <div className='BMap'>
            <Map center={{ lng: 116.402544, lat: 39.928216 }} zoom="11">
              <Marker position={{ lng: 116.402544, lat: 39.928216 }} />
              <NavigationControl />
              <InfoWindow position={{ lng: 116.402544, lat: 39.928216 }} text="内容" title="标题" />
            </Map>
          </div>
          <div className='citySelect flex' style={{ flexWrap: 'nowrap' }}>
            <label htmlFor="">详细地址：</label>
            <Input style={{ flex: 1, borderRadius: '4px' }} placeholder='' value='宁西路23号'></Input>
          </div>
          <div className='citySelect flex' style={{ alignItems: "flex-start" }}>
            <label htmlFor="">所属团长：</label>
            <div>
              <div className='flex teamInfo' style={{ marginBottom: '10px' }}>
                <img width={50} height={50} src="" alt="" />
                <div className='teamInfoText'>
                  <p style={{ fontSize: '16px', color: '#000' }}>安之若素</p>
                  <p>2020-10-02  13：00：32</p>
                </div>
              </div>
              <div className='total'>
                <div className='headLine'></div>
                <ul >
                  <li className='flex'><p>累计订单数</p><p>管理天数</p></li>
                  <li className='flex'><p>50笔</p><p>23天</p></li>
                </ul>
              </div>
            </div>
          </div>
          <div className='citySelect flex'>
            <label htmlFor="">团长联系电话：</label>
            <p style={{ color: '#666666' }}>18023283233</p>
          </div>
          <div className='citySelect flex' style={{ alignItems: 'flex-start' }}>
            <label htmlFor="">自提点照片：</label>
            <div>
              <Upload
                action={`${config.apiUrl}/admin/upload/upload-image`}
                listType="picture-card"
                fileList={fileList}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
              >
                {fileList.length >= 6 ? null : uploadButton}
              </Upload>
              <Modal
                visible={this.state.previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
              <p style={{ color: '#8C8C8C', fontSize: '12px' }}>最多添加6张</p>
            </div>
          </div>
          <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
            <Button
              style={{ marginRight: '40px' }}
              size="small"
              className='button-bac cancel'
              onClick={this.handleCancel}
              type="default">取消</Button>
            <Button
              size="small"
              className='button-bac'
              onClick={this.handleOk}
              type="primary">确定</Button>
          </div>
        </Modal>
      </div>

    );
  }
}

export default router(LogisticsRoute);