import React from 'react'
import '../css/merchant.sass'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import { Button, message, Image } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

export default class Sign extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div className='sign examine'>
                <div className='beginStep'>
                    <p className='title'>
                        协议签署
                    </p>
                </div>
                <div className='signCtx'>
                    <p className='line'><span className='label'>商户号：</span>1245154245</p>
                    <p className='line'><span className='label'>商户全称：</span>提莫队长</p>
                    <p className='line'><span className='label'>电商平台：</span>合肥峡谷科技有限公司</p>
                    <p className='line'><span className='label'>经营者：</span>提莫</p>
                    <p className='line'><span className='label'>联系电话：</span>180****1726</p>
                    <p className='line'><span className='label'>联系邮箱：</span>836******@qq.com</p>
                    <p className='line'><span className='label'>入账方式：</span>即时入账至商户号</p>
                    <p className='line'><span className='label'>结算费率：</span>0.6% <span>（你授权由电子商务平台承担你应缴纳的应付手续费）</span></p>
                    <p className='line'><span className='label'>提现方式：</span>由你授权电商平台根据电商平台规则代为发起提现请求，资金将提现至你的银行账户</p>
                    <p className='line'><span className='label'>开户名称：</span>提莫</p>
                    <p className='line'><span className='label'>银行账户：</span>6214671946374174111</p>
                </div>
                <p className='agreeSign'>
                    点击签署表示你同意
                    <span className='themeColor'
                        style={{ cursor: 'pointer' }}
                        onClick={() => { window.open('https://pay.weixin.qq.com/index.php/public/apply_sign/protocol_v2') }}>
                        《微信支付服务协议》、
                    </span> <span className='themeColor'>《开户意愿确认说明》</span>
                </p>
            </div>
        )
    }
}