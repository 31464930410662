//鼠标绘制工具
//鼠标绘制工具
import React from 'react';
import arrivalPng from './images/arrival.png'
import qs from '../../utils/qs.js'

const BMap = window.BMap;
const BMapLib = window.BMapLib;
const BMapGL = window.BMapGL;
export default class BaiduMapLine extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            local: {},
            map: {},//百度地图
            MapData: props.props || {}
        }
        //console.log(props,)
    }
    
    componentDidMount() {
        //创建地图

        // 百度地图API功能
        var map = new BMap.Map('allmap');
        var poi = new BMap.Point(117.29479814650127, 31.870660575809308);//中心点
        map.centerAndZoom(poi, 16);
        map.enableScrollWheelZoom();

        //浏览器定位
        var geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(function (r) {
            if (this.getStatus() == 0) {
                var mk = new BMap.Marker(r.point);
                map.addOverlay(mk);
                map.panTo(r.point);
                console.log(r, r.point)
                //alert('您的位置：' + r.point.lng + ',' + r.point.lat);
            }
            else {
                alert('请您打开定位功能');
            }
        });

        this.mapInit(map)//创建Map后方法
        this.setState({
            map
        })
    }

    componentWillReceiveProps(props) {
        let { local, map } = this.state;
        let { MapData } = props;

        this.delOverlays(map);

        if (MapData) {
            this.mapInit(map, MapData)
        }

        if (props.param.address) {//address
            local.search(props.param.address)
        }
    }


    delOverlays = (map) => {//清除地图上的点

        map.clearOverlays();//清除地图上所有覆盖物

        // let overlays = map.getOverlays();

        // for (let i in overlays) {
        //     map.removeOverlay(overlays[i]);
        // }

    }

    mapInit = (map, data = []) => {//地图init******************

        var that = this;

        // var geolocation = new BMap.Geolocation();
        // //开启SDK辅助定位
        // geolocation.enableSDKLocation();
        // geolocation.getCurrentPosition(function (r) {
        //     if (this.getStatus() == 0) {
        //         var mk = new BMap.Marker(r.point);
        //         map.addOverlay(mk);
        //         map.panTo(r.point);
        //         console.log(r, )
        //         alert('您的位置：' + r.point.lng + ',' + r.point.lat);
        //     }
        //     else {
        //         alert('failed' + this.getStatus());
        //     }
        // });


        // 添加可视的点
        try {
            data = data.filter(item => item['eye'] !== false)//过滤非可视点

            let lastPoint;//要移动到最后一个点

            for (let i in data) {
                let res = data[i]
                for (let k in res['point']) {
                    //标记
                    var content = `<div class='mapPoint'>
            <div class='limit'>
            <div class='info'>
             <p class='title'>格里姆团长</p>
             <p class='phone'>电话：18019943267</p>
             <p class='address' tittle='动物森友会小店放大客户包括不打算'>自提点：动物森友会小店放大客户包括不打算</p>
            </div>
            <img class='mapPoint_img' width=60 height=60 src=${res.imgUrl} />
            </div>
            <p class='triangle' style='border-bottom:16px solid ${res.color}'></p>
    
            <p class='circular' style='background: ${res.color}'>${res.key}</p>
            </div>`

                    var label = new BMap.Label(content, {       // 创建文本标注
                        position: new BMap.Point(res['point'][k]['lng'], res['point'][k]['lat']),                          // 设置标注的地理位置
                        //offset: new BMap.Size(30, -30) // 设置文本偏移量
                    })
                    map.addOverlay(label);
                }

                lastPoint = res['point'][data[i]['point'].length - 1]//得到最后一个点

            }

            map.panTo(new BMap.Point(lastPoint.lng, lastPoint.lat), 2000)//开始移动

        } catch (error) {

            console.log(error,)

        }


        //地图绘制类型
        const BMAP_DRAWING_MARKER = 'marker';//点
        const BMAP_DRAWING_POLYLINE = 'polyline';//折线
        const BMAP_DRAWING_POLYGON = 'polygon';//多边


        //实例画一个多边形
        var polygon = new BMap.Polygon([
            new BMap.Point(117.253042, 31.85119),
            new BMap.Point(117.263042, 31.86119),
            new BMap.Point(117.264042, 31.87119)
        ], { strokeColor: "blue", strokeWeight: 2, strokeOpacity: 0.5 });
        //map.addOverlay(polygon); //解开画多边


        //添加鼠标绘制工具监听事件，用于获取绘制结果
        var overlays = [];//线的集合
        var overlaycomplete = function (e) {
            console.log(e,)
            overlays.push(e.overlay);
        };


        //polygoncomplete绘制多边形完成后，派发的事件接口
        var polygoncomplete = function (e) {//多边形
            console.log(e,)
        }


        var pois = [];//点的集合
        var markercomplete = function (e) {//画完点后，连线
            //console.log(e,)
            pois.push(
                new BMap.Point(e.point.lng, e.point.lat)
            )

            var polyline = new BMap.Polyline(pois, {
                enableEditing: false,//是否启用线编辑，默认为false
                enableClicking: true,//是否响应点击事件，默认为true
                strokeWeight: '8',//折线的宽度，以像素为单位
                strokeOpacity: 0.8,//折线的透明度，取值范围0 - 1
                strokeColor: "#18a45b" //折线颜色
            });

            //map.addOverlay(polyline);
        }


        var local = new BMap.LocalSearch(map, {//地址搜索功能
            renderOptions: { map: map }
        });

        this.setState({//下方搜索调用
            local
        })

        function G(id) {
            return document.getElementById(id);
        }

        function setPlace() {
            map.clearOverlays();    //清除地图上所有覆盖物
            function myFun() {
                var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                map.centerAndZoom(pp, 18);
                map.addOverlay(new BMap.Marker(pp));    //添加标注
            }
            var local = new BMap.LocalSearch(map, { //智能搜索
                onSearchComplete: myFun
            });
            local.search(myValue);
        }

        var ac = new BMap.Autocomplete(    //建立一个自动完成的对象//地图搜索功能
            {
                "input": "suggestId"
                , "location": map
            });

        ac.addEventListener("onhighlight", function (e) {  //鼠标放在下拉列表上的事件
            var str = "";
            var _value = e.fromitem.value;
            var value = "";
            if (e.fromitem.index > -1) {
                value = _value.province + _value.city + _value.district + _value.street + _value.business;
            }
            //str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

            value = "";
            if (e.toitem.index > -1) {
                _value = e.toitem.value;
                value = _value.province + _value.city + _value.district + _value.street + _value.business;
            }
            //str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
            //G("searchResultPanel").innerHTML = str;
        });

        var myValue;
        ac.addEventListener("onconfirm", function (e) {    //鼠标点击下拉列表后的事件
            var _value = e.item.value;
            myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
            //G("searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;

            //回传address
            that.props.address(_value)

            setPlace();
        });


        //样式
        var styleOptions = {
            strokeColor: "red",    //边线颜色。
            fillColor: "red",      //填充颜色。当参数为空时，圆形将没有填充效果。
            strokeWeight: 3,       //边线的宽度，以像素为单位。
            strokeOpacity: 0.8,	   //边线透明度，取值范围0 - 1。
            fillOpacity: 0.6,      //填充的透明度，取值范围0 - 1。
            strokeStyle: 'solid' //边线的样式，solid或dashed。
        }
        //实例化鼠标绘制工具
        var drawingManager = new BMapLib.DrawingManager(map, {
            isOpen: false, //是否开启绘制模式
            enableDrawingTool: true, //是否显示工具栏
            drawingToolOptions: {
                anchor: 1, //位置
                offset: new BMap.Size(5, 5), //偏离值
                drawingModes: [BMAP_DRAWING_POLYGON],//绘制模式
            },
            // circleOptions: styleOptions, //圆的样式
            //polylineOptions: styleOptions, //线的样式
            //polygonOptions: styleOptions, //多边形的样式
            //rectangleOptions: styleOptions //矩形的样式
        });
        //添加鼠标绘制工具监听事件，用于获取绘制结果
        drawingManager.addEventListener('overlaycomplete', overlaycomplete);
        //绘制点完成后，派发的事件接口
        drawingManager.addEventListener('markercomplete', markercomplete);
        //polygoncomplete绘制多边形完成后，派发的事件接口
        drawingManager.addEventListener('polygoncomplete', polygoncomplete);

        function clearAll() {
            for (var i = 0; i < overlays.length; i++) {
                map.removeOverlay(overlays[i]);
            }
            overlays.length = 0
        }

    }

    render() {
        return (
            <div id="allmap" style={{ width: '100%', height: '100%' }}></div>
        )
    }
}