import React from 'react'
import { Radio, Checkbox } from 'antd'
import '../css/progress.sass'
import { CheckOutlined } from '@ant-design/icons';
import store from '../../../store/index.js'

export default class MainStay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            identity: 'personal',
            organization_type: '4'
        }
    }

    onChange = (e) => {
        this.props.canNext({
            state: e.target.checked,
            msg: '请勾选服务协议'
        })

    }

    componentDidMount() {
        let { organization_type } = this.state
        store.dispatch({ type: '', state: { ...store.getState(), organization_type: organization_type } })
    }

    changeIdentity = async (e) => {
        await this.setState({ identity: e.target.value })
        this.props.changeIdentity(e.target.value)
        let organization_type = e.target.value === 'personal' ? '4' : '2';
        //console.log(organization_type, )
        store.dispatch({ type: '', state: { ...store.getState(), organization_type } })
    }

    render() {
        let { identity } = this.state
        return (
            <div className='mainStay'>
                <Radio.Group onChange={(e) => { this.changeIdentity(e) }} value={identity}>
                    <ul className='flex'>
                        <li>
                            <label for='personal'>
                                <div className='flex spabet marb20'>
                                    <p className='title'>个体工商户</p>
                                    <Radio id='personal' value={'personal'}></Radio>
                                </div>
                                <div className='marb10'>定义：个体户、个体工商户、个体经营</div>
                                <div className='marb10'>
                                    所需资料：营业执照、经营者证件
                                </div>
                                <p>收款限额：不限额</p>
                            </label>
                        </li>

                        <li>
                            <label for='enterprise'>
                                <div className='flex spabet marb20'>
                                    <p className='title'>企业</p>
                                    <Radio id='enterprise' value={'enterprise'}></Radio>
                                </div>
                                <div className='marb10'>定义：有限公司、有限责任公司</div>
                                <div className='marb10'>
                                    所需资料：营业执照、经营者证件
                                </div>
                                <p>收款限额：不限额</p>
                            </label>
                        </li>
                    </ul>
                </Radio.Group>
                <div className='treaty'>
                    <Checkbox defaultChecked={true} onChange={(e) => { this.onChange(e) }}>已阅读并同意</Checkbox>
                    <span className='treatyCtx' onClick={() => { window.open('https://pay.weixin.qq.com/index.php/public/apply_sign/protocol_v2') }}>《磁力圈支付服务协议》</span>
                </div>
            </div>
        )
    }
}