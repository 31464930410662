import React, { Component } from "react";
import detail from "../../../goods/detail";
import { message } from 'antd'
import AlphaGroupRequest from '../../api/groupsList/alphaGroupBatchRequest'
import qs from '../../../../utils/qs'
import emitter from '../../../../ev'

export default class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: props.list
        }

        //console.log(props.list)
    }


    alpha_groups = async (data) => {//
        const hide = message.loading('手动成团中')
        let param = {
            alpha_id: data['status']['alpha_group_id'],
            alpha_group_batch_id: data['status']['alpha_batch_id']
        }
        let res = await AlphaGroupRequest.send(param)

        if (res.code == 200) {

            //clear data
            emitter.emit('changeSiblingsData', []);
            message.success(res.message)
            emitter.emit('alpha_groups', true)

        } else {
            message.error(res.message)
        }
        hide();
        //console.log(res)
    }

    render() {
        let { list } = this.state
        const that = this
        const Status = function (v) {
            let colortxt = {

            }

            //console.log(v,'111')

            let state = v['status']['batch_state'];
            //let running = v['status']['is_running'];
            //let runtxt = running ? '进行中' : '已结束';

            if (state == 0) {
                colortxt = {
                    txt: '未开始',
                    cla: 'txtColor',
                    state
                }
            } else if (state == 10) {
                colortxt = {
                    txt: '未结束，待成团',
                    cla: 'txtColor',
                    state
                }
            } else if (state == 11) {
                colortxt = {
                    txt: '未结束，已成团',
                    cla: 'txtColor',
                    state
                }
            } else if (state == 100) {
                colortxt = {
                    txt: '已结束，已成团',
                    cla: 'txtColor',
                    state
                }
            } else if (state == 101) {
                colortxt = {
                    txt: '已结束，未成团',
                    cla: 'none',
                    state
                }
            }

            return <div>
                <p className={colortxt['cla']}>{colortxt['txt']}</p>
                {
                    colortxt['state'] == 0 || colortxt['state'] == 10 ? (
                        <span className='handleOpen' onClick={() => { that.alpha_groups(v) }}>手动成团</span>
                    ) : null
                }
            </div>
        }
        return (
            <>
                {list['alpha_group_batches'].length ? (
                    <>
                        {list['group_type'] == 1 && list['alpha_group_batches'].map((v, i) => {
                            //console.log(list,)
                            return (
                                <li className='transList flex'>
                                    <div className='listLeft'>
                                        <p>{v['arrived_date_format']}</p>
                                        <p>{v['arrived_day_format']}</p>
                                    </div>

                                    <div className='listRight flex'>
                                        <div>
                                            <div className='flex' style={{ justifyContent: 'space-between' }}>
                                                <p>当前销售额：<span className='txtColor'>{v['sale_amount']}</span></p>
                                                <span style={{ width: '20px' }}></span>
                                                <p>跟团数：<span className='txtColor'>{v['order_num']}</span></p>
                                            </div>
                                            <div>
                                                <p className='endTime'>截单时间： {v['end_date']}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <Status status={v} list={list}></Status>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}

                    </>
                ) : null}
            </>
        )
    }
}