import * as React from 'react'
import './calendar.sass'
import qs from "../../utils/qs";
import nodetaillist from '../../assets/nodetaillist.png'
import emitter from '../../ev'

const WEEK_NAMES = ['日', '一', '二', '三', '四', '五', '六']
const LINES = [1, 2, 3, 4, 5, 6]
const MONTH_DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export default class Calendar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            month: 0,
            year: 0,
            marginLeft: 0,
            currentDate: new Date(),
            channelList: props.channelList
        }
    }

    componentDidMount() {
        emitter.addListener('channelList', (data) => {
            this.setState({
                channelList: data
            })
            this.cardWidth()
        })
    }

    async componentWillMount() {
        this.setCurrentYearMonth(this.state.currentDate)
        this.props.onMonth(this)
        this.props.onYear(this)
        this.props.onMove(this)
        this.setState({//父组件传来月份
            month: this.props.month - 1
        })
    }

    componentWillUnmount() {
        emitter.removeAllListeners()
    }

    componentWillReceiveProps(e) {
    }


    shouldComponentUpdate() {
        return true
    }

    setCurrentYearMonth(date) {
        var month = Calendar.getMonth(date)
        var year = Calendar.getFullYear(date)
        this.setState({
            month,
            year
        })
    }

    static getMonth(date: Date): number {
        return date.getMonth()
    }

    static getFullYear(date: Date): number {
        return date.getFullYear()
    }

    static getCurrentMonthDays(month: number): number {
        return MONTH_DAYS[month]
    }

    static getWeeksByFirstDay(year: number, month: number): number {
        var date = Calendar.getDateByYearMonth(year, month)
        return date.getDay()
    }

    static getDayText(line: number, weekIndex: number, weekDay: number, monthDays: number): any {
        var number = line * 7 + weekIndex - weekDay + 1
        if (number <= 0 || number > monthDays) {
            return <span>&nbsp;</span>
        }

        return number
    }

    static formatNumber(num: number): string {
        var _num = num + 1
        return _num < 10 ? `0${_num}` : `${_num}`
    }

    static getDateByYearMonth(year: number, month: number, day: number = 1): Date {
        var date = new Date()
        date.setFullYear(year)
        date.setMonth(month, day)
        return date
    }

    checkToday(line: number, weekIndex: number, weekDay: number, monthDays: number): Boolean {
        var { year, month } = this.state
        var day = Calendar.getDayText(line, weekIndex, weekDay, monthDays)
        var date = new Date()
        var todayYear = date.getFullYear()
        var todayMonth = date.getMonth()
        var todayDay = date.getDate()

        return year === todayYear && month === todayMonth && day === todayDay
    }

    monthChange(monthChanged: number) {
        var { month, year } = this.state
        var monthAfter = month + monthChanged
        var date = Calendar.getDateByYearMonth(year, monthAfter)
        this.setCurrentYearMonth(date)
    }

    myDateChange(year, month) {
        //console.log(year, month)
        var date = Calendar.getDateByYearMonth(year, month - 1)
        this.setCurrentYearMonth(date)
    }

    myMove(left) {
        this.setState({
            marginLeft: left
        })
    }

    groupDetail = (record) => {
        // console.log(record,'团购详情')
        this.props.getgroupDetail(record)
        //this.props.history.push({ pathname: `/groups/details`, state: { data: record } })
    }

    cardWidth() {//卡片长度
        let { channelList } = this.state
        channelList.map(v => {
            v['width'] = qs.getDayNumInt(v['begin'], v['over']) * (80 + 5) - 5;
            //计算开始
            let begin = new Date(v['begin']).getDate() - 1;
            v['marLeft'] = begin * (80 + 5)
        })
        this.setState({
            channelList: channelList
        })

    }

    render() {
        var { year, month, marginLeft, channelList } = this.state

        //console.log(channelList, this.state,this.props)

        var monthDays = Calendar.getCurrentMonthDays(month)
        var weekDay = Calendar.getWeeksByFirstDay(year, month)

        return (<div style={{ position: 'relative' }}>
            {/* {this.state.month}
            <table cellPadding={0} cellSpacing={0} className="table">
                <caption>
                    <div className="caption-header">
                        <span className="arrow" onClick={this.monthChange.bind(this, -1)}>&#60;</span>
                        <span>{year} - {Calendar.formatNumber(month)}</span>
                        <span className="arrow" onClick={this.monthChange.bind(this, 1)}>&gt;</span>
                    </div>
                </caption>
                <thead>
                    <tr>
                        {
                            WEEK_NAMES.map((week, key) => {
                                return <td key={key}>{week}</td>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        LINES.map((l, key) => {
                            return <tr key={key}>
                                {
                                    WEEK_NAMES.map((week, index) => {
                                        return <td className='ggg' key={index} style={{ color: this.checkToday(key, index, weekDay, monthDays) ? 'red' : '#000' }}>
                                            {Calendar.getDayText(key, index, weekDay, monthDays)}
                                        </td>
                                    })
                                }
                            </tr>
                        })
                    }
                </tbody>
            </table> */}

            <div style={{ overflow: 'hidden', marginTop: '20px' }}>
                <ul className='home_calendar' style={{ display: 'flex', marginLeft: marginLeft + 'px' }}>
                    {
                        LINES.map((l, key) => {
                            return <li key={key}>
                                {
                                    WEEK_NAMES.map((week, index) => {
                                        if (!isNaN(Calendar.getDayText(key, index, weekDay, monthDays))) {
                                            return <div className={this.checkToday(key, index, weekDay, monthDays) ? 'line' : 'line'} key={index}>
                                                {Calendar.getDayText(key, index, weekDay, monthDays)}
                                            </div>
                                        }
                                    })
                                }
                            </li>
                        })
                    }
                </ul>

                <div
                    ref='home_channel'
                    className='home_channel'
                    style={{ marginLeft: marginLeft + 'px' }}
                    onScrollCapture={(e) => { this.props.channelTop(e.target.scrollTop) }}
                >
                    {
                        channelList.length ? (
                            channelList.map((v, index) => {
                                return (
                                    <div key={index} onClick={() => { this.groupDetail(v) }} className={'channelList ' + 'status' + v['status']} style={{ width: v['width'] + 'px', marginLeft: v['marLeft'] + 'px' }}>
                                        <div className={v['width'] == 80 ? 'transform small' : 'transform'}>
                                            <div className='flex title' style={{ justifyContent: 'space-between' }}>
                                                <p style={{ fontSize: '16px' }}>{v['group_name']}</p>
                                                <p style={{ textAlign: 'right', cursor: 'pointer' }}>查看详情>></p>
                                            </div>
                                            <div className='flex' style={{ justifyContent: 'space-around', textAlign: 'left' }}>
                                                <p>已开团数：{v['alpha_group_count']}</p>
                                                <p>实收金额：￥{v['total_sale_amount']}</p>
                                            </div>
                                            <div className='flex'>
                                                <p>已浏览：{v['visit_num']}</p>
                                                <p>新团长：0</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        ) : (<div className='nodata'>
                            <img src={nodetaillist} width={100} alt='' />
                            <p>暂无拼团信息，点击发布拼团即可进行发布~</p>
                        </div>)
                    }
                </div>
            </div>
        </div>)
    }
}