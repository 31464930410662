import React, { Component } from 'react';
import '../css/qrcode.sass';
import LoginRequst from '../api/LoginRequst';
import { env } from '../../../utils/functions';

export default class Qrcode extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        this.getWxcode();
    }

    getWxcode = async () => {

        let params = {
            home_url: env('REACT_APP_URL') + 'home',
            register_url: env('REACT_APP_URL') + 'register'
        }
        let wxcode = await LoginRequst.send(params);

        if (!wxcode || wxcode.code !== 200) return;
        var customeStyle = "data:text/css;base64,Lnd4X3FyY29kZSBpZnJhbWUgeyB3aWR0aDogMjAwcHg7IGhlaWdodDogMjAwcHg7IG1hcmdpbjogMDsgcGFkZGluZzogMDsgfQoubG9naW5QYW5lbCB7IG1hcmdpbjowOyBwYWRkaW5nOiAwOyB9Ci5sb2dpblBhbmVsIC50aXRsZSwgLmxvZ2luUGFuZWwgLmluZm8geyBkaXNwbGF5OiBub25lOyB9Ci5pbXBvd2VyQm94IC5xcmNvZGUgeyBtYXJnaW46IDA7IHdpZHRoOiAyMDBweDsgYm9yZGVyOiBub25lOyB9";

        let res = await new window.WxLogin({
            self_redirect: false,
            id: "wx_login_container",
            appid: wxcode['data']['app_id'],//env('REACT_APP_APPID'), //微信开放平台网站应用appid
            scope: "snsapi_login",
            redirect_uri: encodeURIComponent(wxcode['data']['redirect_uri']), //设置扫码成功后回调页面
            state: "" + (new Date()).getTime(),
            style: "black",
            href: customeStyle, //location.origin + "/css/wx_qrcode.css", //自定义微信二维码样式文件
        });

        if (!res) return;

        //设置iframe标签可以进行跨域跳转
        let qrcodeBox = document.getElementById("wx_login_container");
        let iframes = qrcodeBox.getElementsByTagName("iframe");

        if (iframes.length) {
            let ifr = iframes[0];
            ifr.setAttribute("sandbox", "allow-scripts allow-top-navigation allow-same-origin");
        }
    }

    render() {
        return (
            <div className='wx_ctx'>
                <div id='wx_login_container'></div>
                <p>微信扫码登录</p>
            </div>
        )
    }
}