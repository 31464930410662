import React, { Component } from 'react';
import { message, Upload } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import config from '../../configs/upload'
import morePicUrl from '../../assets/morePic.png'

export default class SmallImgView extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    del = (e, i) => {
        //console.log(e,i)
        this.props.delComponentData(e, i, 'small_img')
    }
    beforeUpload = (e) => {
        let type = e.type.includes('image');

        return new Promise((resolve, reject) => {
            if (!type) {
                message.error('格式不正确！')
                reject(false)
            } else if (e.size > 2000000) {
                message.error('图片尺寸应小于2MB')
                reject(false)
            } else {
                message.loading('上传图片中...', 1)
                resolve(e)
            }
        })
    }
    upSmallImgCg = async (e) => {//

        //const hide = message.loading('上传图片中...', 1)

        let res = await e.file.response;
        //console.log(res, )
        if (res && res.code == 200) {
            //hide();
            message.success('上传成功！')
            //console.log(e, this.props.data)
            this.updateComponentData(res.data.url)
        }
    }
    updateComponentData(data) {
        this.props.updateComponentData([...this.props.data, data])
    }
    render() {
        return (
            <div className='smallImg'>

                {this.props.data.map((v, i) => {
                    return (
                        <div className='small_img'>
                            <CloseCircleOutlined className='closeIcon small' onClick={()=>{this.del(v, i)}} />
                            <img src={v} key={i} alt='' />
                        </div>
                    )
                })}
                <Upload
                    beforeUpload={this.beforeUpload}
                    class="small-image-upload"
                    progress={false}
                    name='file'
                    action={`${config.apiUrl}/admin/upload/upload-image`}
                    onChange={this.upSmallImgCg}
                    accept='.png,.jpg,.jpeg,.gif'
                >
                    <div style={{ textAlign: 'center' }}>
                        <div className='addimgicon'>
                            <PlusOutlined />
                        </div>
                        <p>上传小图</p>
                    </div>
                </Upload>
            </div>
        )
    }
}