import {Request} from '@zoranwong/acc-engine.js'

//let merchant_id = window.localStorage.getItem('merchant_id')

export default class GroupGoodsListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        group_id: null,
        group_batch_id: null

    };
    _method = 'GET';
    _uri = '/goods/group-buy/group-template/group-template-goods-list';

    constructor (params) {
        super(params);
        this._data['group_id'] = params.group_id;
        this._data['group_batch_id'] = params.group_batch_id;
    }

    // get middleware(){
    //   return [...super.middleware, PasswordHashMiddleware];
    // }
    rules () {
        return {};
    }

    messages () {
        return {};
    }
}