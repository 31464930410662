import { Request } from '@zoranwong/acc-engine.js';
export default class SpecificationListRequest extends Request {
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    page_size: 200
  };
  _method = 'GET';
  _uri = '/goods/shop-goods/specification/spec-list';
  constructor(params) {
    super(params);
    this._data['page_size'] = 200
  }
  rules() {
    return {

    };
  }

  messages() {
    return {

    };
  }
}
