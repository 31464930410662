import React from "react";
//import { createBrowserHistory } from "history";
import './listson.sass';
import emitter from "../../../ev";
import { SearchOutlined } from '@ant-design/icons';

export default class ListSon extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            empty: []
        }
    }

    componentDidMount = () => {
        //console.log(this.props,)
    }

    componentWillReceiveProps = async (e) => {
        //console.log(e,)
        let empty = []
        let dataArr = e.listSonData;
        for (let i in dataArr) {
            let arrSon = dataArr[i]['visible_channel']
            for (let k in arrSon) {
                if (arrSon[k]['id'] == e.channel_id) {
                    empty.push(dataArr[i])
                }
            }
        }
        if(!e.channel_id){
            empty = dataArr;
        }
        //empty = e.listSonData
        await this.setState({
            list: empty
        })

        //console.log(this.props, )
        await this.props.listLength({ key: 'channel_name' + this.props.channel_id, val: empty.length })
    }

    seeDetail = (id) => {
        emitter.emit('goGroupDetail', { id })
    }

    render() {
        let { list } = this.state
        return (
            <div className='list_son'>
                {
                    list.map((v, i) => {
                        //console.log(v, 'vvvvv')
                        return (

                            <div key={i + 'team_list_li'} className={v.state > 200 ? 'team_lists over' : 'team_lists'} >
                                <div className='flex team_list_li' style={{ justifyContent: 'space-between' }}>
                                    <p className='limit'>{v['group_name']}</p>

                                    <p onClick={() => { this.seeDetail(v['id']) }} style={{ cursor: 'pointer' }}>
                                        <SearchOutlined style={{ fontSize: 16 }} />
                                        {/* 查看详情 */}
                                    </p>
                                </div>

                                <div className='flex team_list_li' style={{ justifyContent: 'space-between' }}>
                                    <p>截单时间：到货日前{v['end_day']}天</p>
                                </div>

                                <div className='flex team_list_li' style={{ justifyContent: 'space-between' }}>
                                    <p>实收金额： ￥{v['total_sale_amount']}</p>

                                    <p>开团团长： {v['buyer_num']} 人 </p>
                                </div>
                            </div>

                        )
                    })
                }
            </div>
        )
    }
}