import {Select} from "antd";
import React from "react";
import {last, find} from 'lodash';
const SpecValueSelector = ({delSpecValue, selectSpecValue, specValues, selectedValues}) => {
    let selected = [];
    for (const v of selectedValues) {
        selected.push(v.id);
    }
    return (
        <div className="tagcustom">
            <div className="custom">
                <Select
                    showSearch
                    mode="multiple"
                    allowClear={true}
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    defaultValue = {selected}
                    value={selected}
                    onChange={(value) => {
                        if(value.length > 0) {
                            let id = last(value);
                            let v = find(specValues, (v) => {
                                return v.id === id;
                            });
                            if(v)
                                selectSpecValue(v);
                        }else{
                            delSpecValue(null);
                        }
                    }}
                    onDeselect={(value) => {
                        delSpecValue(value)
                    }}
                    placeholder='选择规格值'
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {specValues.map(v => {
                        return <Select.Option key={v['id']} value={v['id']}>{v['value']}</Select.Option>
                    })}
                </Select>
            </div>
        </div>
    );
};
export default SpecValueSelector;