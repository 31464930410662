import React, { Component } from "react";
import { Table, Checkbox, Radio, Select, Input, Pagination, message } from 'antd';
import { withRouter as router } from 'react-router-dom';
import OrderListRequest from '../api/OrderListRequest';
import ReactToPrint from 'react-to-print';
import qs from '../../../utils/qs'
import '../css/receipt.sass'
import _ from 'lodash';

const { Option } = Select;
class Receipt extends Component {
    constructor(props) {
        super(props)
        let searchParam = props.location.state.param
        if (!searchParam) return this.props.history.go(-1)
        this.state = {
            hideLiaison: true,//敏感
            enlarge: false,//微信名放大
            receiving: false,//收货信息放大
            totalamount: false,//总价
            exportArr: props.location.state.exportArr,
            shortName: false,//截断
            receiptInfo: false,//主页信息
            subsequence: 1,
            total: 0,
            searchParam,
            searchPeople: '',//输入框电话、姓名
            goodsName: '',//商品名
            pageSize: 20,//每页xx条
            page: 1,
            loadTimer: null,//加载计时器
        }
    }


    componentDidMount = () => {
        this.searchList()
    }


    setPrint = async (arr) => {//勾选的框
        let { hideLiaison, enlarge, receiving, totalamount, shortName, receiptInfo } = await this.state;
        enlarge = arr.includes('WeChatFont')
        hideLiaison = arr.includes('tactful')
        receiving = arr.includes('Receiving')
        totalamount = arr.includes('Totalamount')
        shortName = arr.includes('ShortName')
        receiptInfo = arr.includes('receiptInfo')

        await this.setState({
            hideLiaison,
            enlarge,
            receiving,
            totalamount,
            shortName,
            receiptInfo
        })
    }

    searchListRule = async () => {
        //1如果subsequence = commander 团长排序
        let { searchParam, searchPeople, goodsName, pageSize, subsequence, page, total, loadTimer } = await this.state;

        if (subsequence !== 'commander') return
        //初始化页数1
        await this.setState({
            page: 1
        })

        message.loading(`正在加载第${page}页数据`);
        let res = await this.searchList(true);
        let resArr = res.data.data;

        loadTimer = setInterval(async () => {
            let { page } = await this.state;

            //如果小于总页数

            if (page < res.data.last_page) {

                page += 1;
                await this.setState({
                    page
                })
                res = await this.searchList(true);
                let data = res.data.data;
                resArr = resArr.concat(data);
                message.loading(`正在加载第${page}页数据`)
                await this.setState({
                    exportArr: this.arrGroup(resArr)
                })

            } else {

                clearInterval(loadTimer)
                loadTimer = null;
                //结束拉取数据开始赋值
                await this.setState({
                    page: 1,//拉取结束，返回1
                    exportArr: this.arrGroup(resArr)
                })

            }

        }, 1500)
    }

    arrGroup = (resArr) => {//数据整合
        let groupByList = _.groupBy(resArr, function (item) {

            return item['group_buy']['alpha_id']
        });

        let list = [];
        _.forEach(groupByList, function (item) {
            list = list.concat(item);
        });

        return list;

    }

    searchList = async (nodata) => {
        let { searchParam, searchPeople, goodsName, pageSize, subsequence, page } = this.state
        //console.log(subsequence,)
        let sqlarr = [
            // { name: 'id', val: channel_arr.join(',') },
            { name: 'end_datetime', val: `${searchParam['end_date']}~${qs.nextDate(searchParam['end_date'])}` },
            { name: 'goods_name', val: goodsName }
        ]

        if (searchPeople) {
            if (isNaN(searchPeople)) {
                sqlarr.push(
                    { name: 'user_name', val: searchPeople }
                )
            } else {
                sqlarr.push(
                    { name: 'user_mobile', val: searchPeople }
                )
            }
        }

        let sql = qs.sqlSearch(sqlarr)//sql 
        let param = {}
        param['search'] = sql['search'] || '';
        param['searchFields'] = 'id::in;state::in;end_datetime::between';
        param['page_size'] = pageSize;
        param['page'] = page;
        //param['orderBy'] = `${subsequence}:desc`;
        let res = await OrderListRequest.send(param);
        if (!res && res.data) return
        let dataArr = res.data.data;
        if (!Array.isArray(dataArr)) return

        dataArr.map(v => {
            let arrTtemTotal = 0;
            v['order_items'].map(item => arrTtemTotal += item['buy_quantity'])
            v['arrTtemTotal'] = arrTtemTotal
        })

        this.setState({
            exportArr: nodata ? [] : dataArr,
            total: res.data.total
        })

        return res;
    }


    render() {
        const pageStyle = `
        @page {
            /*size: 80mm 80mm !important;*/
        }
        
        @media all {
            .pagebreak {
                /* display: none; */
            }
        }

        @media print {
            .pagebreak {
                /*page-break-after: always !important;*/
                padding: 10px
            }
            .flex{
                display: flex
            }
            .print-hide{
                display: none
            }
            .space_between{
                justify-content: space-between
            }
        }
        `;

        const addressee = [
            { label: '微信名字体放大', value: 'WeChatFont' },
            { label: '敏感信息字打码', value: 'tactful' },
            { label: '收货信息字体放大', value: 'Receiving' }
        ];

        const goods = [
            { label: '显示商品总金额等金额信息', value: 'Totalamount' },
            { label: '商品名截断', value: 'ShortName' }
        ];

        const homepage = [
            { label: '小票底部显示主页名等信息', value: 'receiptInfo' }
        ];

        function onChange(params) {
            //console.log(params,)
        }


        let { hideLiaison, enlarge, receiving,
            totalamount, exportArr, shortName,
            receiptInfo, subsequence, total,
            searchPeople, goodsName, pageSize, page } = this.state;

        return (
            <div className='receipt allprint'>
                <div className='poll flex'>

                    <div className='left' style={{ flex: '0 0 320px', padding: 0 }} ref={el => (this.printRef = el)}>
                        {exportArr && exportArr.length ? exportArr.map(
                            (data, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='pagebreak'
                                        style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '3px dashed #333' }}>

                                        {/* <p className='print_state'>未打印</p> */}
                                        {/* <div className='flex space_between' style={{ justifyContent: 'space-between' }}>
                                            <p>
                                                快递—
                                                {
                                                    !data['need_pay'] ? '线下支付' : '已支付'
                                                }
                                            </p>
                                            <p>导出时间：{qs.gettoday().year}/{qs.gettoday().month}/{qs.gettoday().day}</p>
                                        </div> */}


                                        {
                                            hideLiaison ? (
                                                <div className="flex" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                                                    <div className='big_user' style={{ fontSize: 30, fontWeight: 'bold', lineHeight: '36px' }}>
                                                        {data['user']['name']} <br /> {qs.hideMobile(data['user']['mobile'])}
                                                    </div>

                                                    <div>
                                                        <p>{qs.gettoday().year}/{qs.gettoday().month}/{qs.gettoday().day}</p>
                                                        <p style={{ lineHeight: '36px' }}>
                                                            {(data['group_buy']['alpha_batch_no']).split('-')[0]}-
                                                            {(data['group_buy']['alpha_batch_no']).split('-')[1]}-
                                                            <span style={{ fontSize: 55 }}>{(data['group_buy']['alpha_batch_no']).split('-')[2]}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="flex" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                                                    <div className='big_user' style={{ fontSize: 30, fontWeight: 'bold', lineHeight: '36px' }}>
                                                        {data['user']['name']} <br /> {data['user']['mobile']}
                                                    </div>

                                                    <div>
                                                        <p>{qs.gettoday().year}/{qs.gettoday().month}/{qs.gettoday().day}</p>
                                                        <p style={{ lineHeight: '36px' }}>
                                                            {(data['group_buy']['alpha_batch_no']).split('-')[0]}-
                                                            {(data['group_buy']['alpha_batch_no']).split('-')[1]}-
                                                            <span style={{ fontSize: 55 }}>{(data['group_buy']['alpha_batch_no']).split('-')[2]}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }



                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: 16, fontWeight: 'bold', margin: '5px 0', }}>{data['group_buy']['alpha_group_name']}</p>
                                        </div>

                                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="flex">

                                            <p
                                                className={enlarge ? 'bold font24 margin10_0' : 'margin10_0'}
                                                style={enlarge ? { fontSize: 24, margin: '5px 0', fontWeight: 'bold' } : { margin: '5px 0' }}>{data['order_mailing']['consignee_name']}</p>

                                            <p style={{ fontSize: 40, textAlign: "right", fontWeight: 'bolder', lineHeight: '40px' }}>{(data['group_buy']['alpha_batch_no']).split('-')[2]}</p>

                                        </div> */}

                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="flex">

                                            <p
                                                className='liaison margin10_0'
                                                style={receiving ? { fontSize: 15, margin: '5px 0' } : { margin: '5px 0' }}>
                                                团长昵称：{data['order_mailing']['consignee_name']} {data['order_mailing']['consignee_phone']}
                                            </p>

                                        </div>

                                        <p
                                            className='margin10_0'
                                            style={receiving ? { fontSize: 15, margin: '5px 0' } : { margin: '5px 0' }}>
                                            到货时间：{data['group_buy']['arrival_date']}  {data['group_buy']['arrival_time']}
                                        </p>

                                        <p
                                            className='margin10_0'
                                            style={receiving ? { fontSize: 15, margin: '5px 0' } : { margin: '5px 0' }}>
                                            收货地址：{data['order_mailing']['shipping_address']}
                                        </p>



                                        <table
                                            border='0'
                                            style={{ margin: '15px 0', width: '100%', textAlign: 'left' }}
                                            className='shops_table margin10_0'>
                                            <tr className='bold'>
                                                <th style={{ whiteSpace: 'nowrap' }}>商品</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>金额</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>数量</th>
                                            </tr>
                                            {
                                                data['order_items'].map(shop => {

                                                    return (
                                                        <tr>

                                                            {shortName ? (
                                                                <td>{qs.ellipsis(shop['goods_name'], 6, '...')}</td>
                                                            ) : (
                                                                <td>{shop['goods_name']}</td>
                                                            )}

                                                            <td>{Number(shop['item_total_amount']).toFixed(2)}</td>

                                                            <td>*{shop['buy_quantity']}</td>
                                                        </tr>
                                                    )

                                                })
                                            }
                                            {
                                                totalamount ? (
                                                    <tr className='bold'>

                                                        <td>总计</td>

                                                        <td>{Number(data['total_amount']).toFixed(2)}</td>

                                                        <td>*{data['arrTtemTotal']}</td>
                                                    </tr>
                                                ) : null
                                            }

                                        </table>


                                        {
                                            data['gifts'].length ? (

                                                <table
                                                    border='0'
                                                    style={{ margin: '15px 0', width: '100%', textAlign: 'left' }}
                                                    className='shops_table margin10_0'>
                                                    <tr className='bold'>
                                                        <th style={{ whiteSpace: 'nowrap' }}>赠品</th>
                                                        <th style={{ whiteSpace: 'nowrap' }}>金额</th>
                                                        <th style={{ whiteSpace: 'nowrap' }}>数量</th>
                                                    </tr>
                                                    {
                                                        data['gifts'].map(shop => {

                                                            return (
                                                                <tr>

                                                                    {shortName ? (
                                                                        <td>{qs.ellipsis(shop['goods_name'], 6, '...')}</td>
                                                                    ) : (
                                                                        <td>{shop['goods_name']}</td>
                                                                    )}

                                                                    {/* <td>{shop['item_total_amount']}</td> */}
                                                                    <td>/</td>
                                                                    <td>*{shop['buy_quantity']}</td>
                                                                </tr>
                                                            )

                                                        })
                                                    }
                                                    {/* {
                                                    totalamount ? (
                                                        <tr className='bold'>
                                                            <td>总计</td>
                                                            <td>{data['total_amount']}</td>
                                                            <td>{data['arrTtemTotal']}</td>
                                                        </tr>
                                                    ) : null
                                                } */}

                                                </table>

                                            ) : null
                                        }

                                        <p className='margin10_0' style={{ margin: '5px 0' }}>下单时间：{data['created_at']}</p>


                                        {
                                            receiptInfo ? (
                                                <p className='margin10_0 flex' style={{ margin: '5px 0' }}>
                                                    团信息：
                                                    {data['group_buy']['alpha_group_name']}-
                                                    <img style={{ width: 30, height: 30, borderRadius: '50%' }} src={data['group_buy']['alpha_avatar']} alt=''/>
                                                    {data['group_buy']['alpha_name']}
                                                </p>
                                            ) : null
                                        }

                                        <div className='flex' style={{ margin: '5px 0', justifyContent: 'space-between' }}>
                                            <p>备注：{data['user_remark'] ? data['user_remark'] : '无'}</p>
                                            <p>{index + 1}/{exportArr.length}</p>
                                        </div>


                                    </div>
                                )
                            }
                        ) : (
                            <p>暂无数据</p>
                        )}

                    </div>

                    <div className='center'></div>

                    <div className='right'>
                        <div className='marb10'>
                            <p className='set_title'>打印筛选项</p>
                            <div className='flex'>
                                <p>搜索：
                                    <Input
                                        value={searchPeople}
                                        onChange={async (e) => {
                                            await this.setState({ searchPeople: e.target.value })
                                            this.searchList()
                                        }}
                                        style={{ width: 150 }}
                                        placeholder='可根据手机号，姓名搜索' /></p>
                                <p>跟团号：<Input style={{ width: 200 }} placeholder='起始跟团号' /> 至 <Input style={{ width: 200 }} placeholder='结尾跟团号' /></p>
                                <p>商品：
                                    <Input
                                        value={goodsName}
                                        onChange={async (e) => {
                                            await this.setState({ goodsName: e.target.value })
                                            this.searchList()
                                        }}
                                        style={{ width: 150 }}
                                        placeholder='可根据商品名称搜索' /></p>
                            </div>
                        </div>


                        <div className='marb10'>
                            <p className='set_title'>设置打印模板</p>
                            <Checkbox.Group defaultValue={'tactful'} onChange={(arr) => { this.setPrint(arr) }}>
                                <div className='flex receipt_line'>
                                    <p className='receipt_title'>收件人信息:</p>
                                    <Checkbox value={'WeChatFont'}>微信名字体放大</Checkbox>
                                    <Checkbox value={'tactful'}>敏感信息字打码</Checkbox>
                                    <Checkbox value={'Receiving'}>收货信息字体放大</Checkbox>
                                </div>

                                <div className='flex receipt_line'>
                                    <p className='receipt_title'>商品信息:</p>
                                    <Checkbox value={'Totalamount'}>显示商品总金额等金额信息</Checkbox>
                                    <Checkbox value={'ShortName'}>商品名截断</Checkbox>
                                </div>

                                <div className='flex receipt_line'>
                                    <p className='receipt_title'>主页信息:</p>
                                    <Checkbox value={'receiptInfo'}>小票底部显示主页名等信息</Checkbox>
                                </div>

                            </Checkbox.Group>
                        </div>

                        <div className='marb10'>
                            <p className='set_title'>打印顺序</p>
                            <Radio.Group onChange={async (e) => { await this.setState({ subsequence: e.target.value }); this.searchListRule() }} value={subsequence}>
                                <Radio value={1}>按跟团号排序</Radio>
                                <Radio value={2}>按物流方式排序</Radio>
                                <Radio value={3}>按下单人排序</Radio>
                                <Radio value={'commander'}>按照团长排序</Radio>
                            </Radio.Group>
                        </div>

                        {/* <div className='marb10'>
                            <p className='set_title'>打印方式</p>
                            <Radio.Group onChange={e => { this.setState({ subsequence: e.target.value }) }} value={subsequence}>
                                <Radio value={1}>按订单打印</Radio>
                                <Radio value={2}>按商品打印</Radio>
                            </Radio.Group>
                        </div> */}

                        {
                            subsequence === 'commander' ? null : (
                                <div className='marb10 flex'>
                                    共有{total}条

                                    <div style={{ margin: '0 5px' }}>
                                        <Pagination onChange={async (e) => {
                                            await this.setState({ page: e })
                                            this.searchList();
                                        }} current={page} pageSize={pageSize} total={total} />
                                    </div>

                                    每页
                                    <Select
                                        defaultValue="20"
                                        style={{ width: 60 }}
                                        onChange={async (e) => {
                                            await this.setState({ pageSize: e })
                                            this.searchList()
                                        }}>
                                        <Option value="20">20</Option>
                                        <Option value="60">60</Option>
                                        <Option value="100">100</Option>
                                    </Select>
                                    条
                                </div>
                            )
                        }


                        <div style={{ margin: '10px 0' }}>
                            {
                                exportArr && exportArr.length ? (
                                    <ReactToPrint
                                        copyStyles={false}
                                        pageStyle={pageStyle}
                                        trigger={() => <div className='print_btn'>打印</div>}
                                        content={() => this.printRef}
                                    />
                                ) : null
                            }
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default router(Receipt)