import { withRouter as router } from "react-router-dom";
import Base from "../../components/base";
import React from "react";
import config from '../../configs/upload'
import { Button, message, Input, Upload, Icon } from 'antd'
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import RegisterRequest from './api/RegisterRequest'
import SmsRequest from './api/SmsRequest'
import StarSky from '../../components/starSky'
import Tips from '../../components/tips/tips'
import qs from '../../utils/qs'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { env } from '../../utils/functions'
import './css/register.sass'
import 'moment/locale/zh-cn';
import _ from 'lodash'

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class Register extends Base {
    constructor(args) {
        super(args)
        this.state = {
            customer_id: null,
            loading: false,
            imageUrl: '',
            noimageUrl: false,
            fontImg: '',
            nofontImg: false,
            backImg: '',
            nobackImg: false,
            supplyName: '',
            nosupplyName: false,
            supplyMobile: null,
            nosupplyMobile: false,
            businessImg: '',
            nobusinessImg: false,
            smsTxt: '获取验证码',
            smsSeconds: 60,
            smsTimer: null,
            smscode: null,
            nosmscode: false
        }
    }

    componentWillUnmount() {
        document.getElementById('fwhfCanvas').style.display = 'none';
    }

    async componentDidMount() {
        document.getElementById('fwhfCanvas').style.display = 'block';
        let customer_id = qs.getQueryVariable('customer_id');
        if (customer_id) {
            this.setState({
                customer_id: customer_id
            })
        }
        //console.log(customer_id)
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            message.success('图片上传成功！')
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl: info.file['response']['data']['url'],
                    loading: false,
                    noimageUrl: false
                }),
            );
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    handlePreview = (e) => {
        //console.log(e)
    }


    beforeUpload = (e) => {
        message.loading('图片上传中')
    }

    getSms = async () => {//
        let { smsTimer, smsSeconds, smsTxt, supplyMobile } = this.state;

        if (smsTimer) {
            return message.error('不能重复请求')
        }


        let arg = /^1[0-9]{10}$/;

        if (!arg.test(supplyMobile)) {
            return message.error('手机号格式不正确！')
        }

        const hide = message.loading('请求中')

        let res = await SmsRequest.send({ mobile: supplyMobile });

        if (res.code == 200) {
            message.success('验证码发送成功！')

            smsTimer = setInterval(() => {
                if (smsSeconds > 1) {
                    smsSeconds--;
                    this.setState({
                        smsTxt: smsSeconds + 's'
                    })
                } else {
                    smsSeconds = 60;
                    this.setState({
                        smsTxt: '获取验证码',
                        smsTimer: null
                    })
                    clearInterval(smsTimer)
                }
            }, 1000)

            this.setState({
                smsTimer,
                smsSeconds
            })
        }

        hide()
        //console.log(res);
    }

    clearAll = () => {//重置
        this.setState({
            supplyName: '',
            nosupplyName: false,
            supplyMobile: '',
            nosupplyMobile: false,
            smscode: '',
            nosmscode: false,
            imageUrl: '',
            noimageUrl: false,
            businessImg: '',
            fontImg: '',
            nofontImg: false,
            backImg: '',
            nobackImg: false
        })

    }

    submit = async () => {//提交
        const { customer_id, supplyName, supplyMobile, imageUrl, businessImg, fontImg, nofontImg, backImg, nobackImg, smscode, nosmscode } = this.state
        let check
        check = qs.isNull(supplyName, '请输入供应商名称', () => { this.setState({ nosupplyName: true }) })
        if (!check) return
        check = qs.isNull(supplyMobile, '请输入手机号', () => { this.setState({ nosupplyMobile: true }) })
        if (!check) return
        check = qs.isNull(smscode, '请输入验证码', () => { this.setState({ nosmscode: true }) })
        if (!check) return
        // check = qs.isNull(imageUrl, '请上传头像', () => { this.setState({ noimageUrl: true }) })
        // if (!check) return
        // check = qs.isNull(businessImg, '请上传营业执照片', () => { this.setState({ nobusinessImg: true }) })
        // if (!check) return
        // check = qs.isNull(fontImg, '请上传身份证正面照片', () => { this.setState({ nofontImg: true }) })
        // if (!check) return
        // check = qs.isNull(backImg, '请上传身份证反面照片', () => { this.setState({ nobackImg: true }) })
        // if (!check) return

        const hide = message.loading('正在提交，请稍后')

        let param = {
            customer_id: customer_id,
            name: supplyName,
            mobile: supplyMobile,
            captcha: smscode,
            avatar: imageUrl,
            business_photos: businessImg,
            id_card_photos: [fontImg, backImg]
        }

        //console.log(param)

        let res = await RegisterRequest.send(param);
        if (res.status) {
            message.success(res.message)
            await window.localStorage.setItem('customer_id', customer_id);
            await window.localStorage.setItem('userInfo', JSON.stringify(res['data']));
            await window.localStorage.setItem('token', res['data']['token']);
            setTimeout(() => {
                this.props.history.push({
                    pathname: '/home'
                })
                message.success('注册成功！')
            }, 1000);
        } else {
            message.error(res.message)
        }

        hide()
        //console.log(res)
    }


    render() {

        const { loading, imageUrl, fontImg, backImg, supplyName, nosupplyName, supplyMobile, nosupplyMobile, noimageUrl, businessImg, nobusinessImg, nofontImg, nobackImg, smsTxt, smscode, nosmscode } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传头像</div>
            </div>
        );

        const uploadProps = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            listType: "picture-card",
            accept: '.png,.jpg,.jpeg,.gif',
            onPreview: this.handlePreview,
            onChange: this.handleChange,
            beforeUpload: this.beforeUpload
        }

        const props = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            accept: '.png,.jpg,.jpeg',
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: () => {
                message.loading('图片上传中')
            },
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                }
                if (info.file.status === 'done') {
                    message.success('图片上传成功！')
                    getBase64(info.file.originFileObj, businessImg =>
                        this.setState({
                            businessImg: info.file['response']['data']['url'],
                            nobusinessImg: false
                        })
                    );
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        const fontprops = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            accept: '.png,.jpg,.jpeg,.gif',
            headers: {
                authorization: 'authorization-text',
            },
            showUploadList: false,
            beforeUpload: () => {
                message.loading('图片上传中')
            },
            onChange: (info) => {
                //console.log(info)
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success('图片上传成功！')
                    getBase64(info.file.originFileObj, fontImg => {
                        this.setState({
                            fontImg: info.file['response']['data']['url'],
                            nofontImg: false
                        })
                    }
                    );
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };


        const backprops = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            accept: '.png,.jpg,.jpeg,.gif',
            headers: {
                authorization: 'authorization-text',
            },
            showUploadList: false,
            beforeUpload: () => {
                message.loading('图片上传中')
            },
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success('图片上传成功！')
                    getBase64(info.file.originFileObj, backImg => {
                        this.setState({
                            backImg: info.file['response']['data']['url'],
                            nobackImg: false
                        })
                    }
                    );
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <div className='register'>
                <div className='title'>
                    欢迎注册供应商
                </div>
                <div className='fromTitle'>
                    基本信息
                </div>
                <div className='line'>
                    <label>供应商名称：</label>
                    <Input value={supplyName} onChange={e => { this.setState({ supplyName: e.target.value, nosupplyName: false }) }} placeholder='请输入供应商名称'></Input>

                    {nosupplyName ? (
                        <Tips ctx={{ ctx: '请输入供应商名称', type: 'error' }}></Tips>
                    ) : null}

                </div>
                <div className='line'>
                    <label>手机号：</label>
                    <Input value={supplyMobile} style={{ width: '200px' }} onChange={e => { this.setState({ supplyMobile: e.target.value, nosupplyMobile: false }) }} placeholder='请输入手机号'></Input>
                    <button style={{ width: '90px', marginLeft: '10px', height: '30px', cursor: 'pointer' }} onClick={() => { this.getSms() }}>{smsTxt}</button>
                    {nosupplyMobile ? (
                        <Tips ctx={{ ctx: '请输入手机号', type: 'error' }}></Tips>
                    ) : null}

                </div>

                <div className='line'>
                    <label>验证码：</label>
                    <Input value={smscode} style={{ width: '110px' }} onChange={e => { this.setState({ smscode: e.target.value, nosmscode: false }) }} placeholder='请输入验证码'></Input>

                    {nosmscode ? (
                        <Tips ctx={{ ctx: '请输入验证码', type: 'error' }}></Tips>
                    ) : null}

                </div>


                {/* <div className='line'>
                    <label>头像：</label>
                    <Upload {...uploadProps}>
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                    {noimageUrl ? (
                        <Tips ctx={{ ctx: '请上传头像', type: 'error' }}></Tips>
                    ) : null}

                </div> */}


                <div className='line flex' style={{ alignItems: 'flex-start' }}>
                    <label>营业执照片：</label>

                    <div className='identity flex'>
                        <div className='identityPhoto font'>
                            {
                                !businessImg ? (<p>营业执照</p>) : null
                            }
                            <div className='uploads'>
                                <Upload {...props}>
                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                </Upload>
                            </div>
                            {businessImg ? (
                                <img src={businessImg} alt='' />
                            ) : null}
                        </div>
                    </div>
                    {nobusinessImg ? (
                        <Tips ctx={{ ctx: '请上传营业执照', type: 'error' }}></Tips>
                    ) : null}

                </div>
                <div className='line flex' style={{ alignItems: 'flex-start' }}>
                    <label>身份证正反照：</label>

                    <div className='identity flex'>
                        <div className='identityPhoto font'>
                            {
                                !fontImg ? (<p>身份证正面照</p>) : null
                            }
                            <div className='uploads'>
                                <Upload {...fontprops}>
                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                </Upload>
                            </div>
                            {fontImg ? (
                                <img src={fontImg} alt='' />
                            ) : null}


                        </div>
                        <div className='identityPhoto back'>
                            {!backImg ? (<p>身份证反面照</p>) : null}

                            <div className='uploads'>
                                <Upload {...backprops}>
                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                </Upload>
                            </div>
                            {backImg ? (<img src={backImg} alt='' />) : null}

                        </div>
                    </div>
                    {
                        nofontImg ? (<Tips ctx={{ ctx: '请上传身份证正面照', type: 'error' }}></Tips>) : null
                    }
                    {
                        nobackImg ? (<Tips ctx={{ ctx: '请上传身份证反面照', type: 'error' }}></Tips>) : null
                    }

                </div>

                <div className='contrl flex'>
                    <div onClick={() => { this.clearAll() }} className='btn'>重置</div>
                    <div onClick={() => { this.submit() }} className='btn com'>确定</div>
                </div>
            </div>
        );
    }
}

export default router(Register);