import React from "react";
import {
    withRouter as router
} from "react-router-dom";
import Base from "../../../components/base";
import {
    Row,
    Col,
    Pagination,
    Button,
    Table,
    Radio,
    Popconfirm,
    Modal,
    message
} from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import '../css/templateProducts.sass'
import TProductsRequest from '../api/TProducts/TProductsRequest'
import DelTpRequest from '../api/DelTpRequest/DelTpRequest'
import { SyncOutlined } from '@ant-design/icons';

class TProducts extends Base {
    constructor(args) {
        super(args)

        const dataSource = [
        ]
        this.state = {
            syncoutlined: false,
            dataSource: dataSource,
            loading: true,
            delvisible: false,
            editorTp:{}
        }
    }

    getTPlist = async () => {
        let param = {
            //merchant_id: 'RXZgrV',//商户id
            search: ''
        }
        let res = await TProductsRequest.send(param)
        this.setState({
            dataSource: [...res['data']['data']],
            loading: false,
            syncoutlined: false
        })
        //console.log(res)
    }

    updateList = () => {//更新
        this.setState({
            syncoutlined: true,
            loading: true
        })
        this.getTPlist()
    }

    delList=(data)=>{//删除
        this.setState({
            delvisible: true,
            editorTp: data
        })
    }

    delOk=async()=>{//确认删除
        let param = {
            group_ids: [this.state.editorTp['id']],
            //merchant_id: 'RXZgrV'
        }
        let res = await DelTpRequest.send(param)
        this.setState({
            delvisible: false
        })
        if(res.code==200){
            message.success(res.message)
        }else{
            message.error(res.message)
        }
    }

    componentDidMount() {
        this.getTPlist()
    }




    render() {
        const expandable = {
            columnWidth: 400
        }
        const columns = [
            { title: '模板名称', dataIndex: 'goods_name', className: 'tp_name' },
            { title: '描述', dataIndex: 'tp_desc', className: 'tp_desc' },
            { title: '创建时间', dataIndex: 'created_at', className: 'tp_creat_time' },
            {
                title: '操作',
                className: 'tp_contrl',
                render: (list) => {
                    return (
                        <ul className='flex contrl'>
                            <li>商品管理</li><li>编辑</li><li onClick={()=>this.delList(list)}>删除</li>
                        </ul>
                    )
                }
            }
        ]
        return (
            <div className='tproducts'>
                <div className='flex contrl' style={{ marginBottom: '10px' }}>
                    <Button className='button-bac' onClick={this.addSpeci} >创建模板</Button>
                    <div className='flex' style={{ marginLeft: '40px', fontSize: '16px', color: '#6F6F6F' }}> <div className='updateList' onClick={this.updateList}><SyncOutlined style={{ color: '#ffffff', fontSize: '20px' }} spin={this.state.syncoutlined} /></div> 更新</div>
                </div>
                <Table
                    style={{ textAlign: 'center' }}
                    columns={columns}
                    pagination={false}
                    expandable={expandable}
                    dataSource={this.state.dataSource}
                    loading={this.state.loading}
                    rowKey={record => record.id}
                    rowClassName={(record, index) => {
                        let className = '';
                        if (index % 2) className = 'dark-row';
                        return className;
                    }}
                />
                <div className='pageContrl'>
                    <Pagination
                        total={85}
                        locale={locale}
                        showSizeChanger
                        showQuickJumper
                        showTotal={total => `Total ${total} items`}
                    />
                </div>

                <Modal
                    title='提示'
                    visible={this.state.delvisible}
                    footer={
                        <div className='flex'>
                            <Button size='small' type='primary' className='button-bac slow'> 取消 </Button>
                            <Button size='small' type='primary' className='button-bac' onClick={this.delOk}> 确认 </Button>
                        </div>
                    }
                >
                    <p>确认删除吗？</p>
                </Modal>
            </div>
        )
    }

}

export default router(TProducts);