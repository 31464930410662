import {
    Row,
    Col,
    Input,
    InputNumber,
    Button,
    Table,
    Radio,
    Popconfirm,
    Modal,
    message,
    Cascader,
    Pagination,
    Select,
    DatePicker
} from 'antd';

import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import React from "react";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import '../css/detailed.sass'
import GetdetailRequest from '../api/GetdetailRequest';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import qs from '../../../utils/qs'
const { Option } = Select;
class FinanceDetail extends Base {
    constructor(args) {
        super(args)

        const columns = [
            {
                title: '入账时间',
                dataIndex: 'created_at'
            },
            {
                title: '客户订单号',
                dataIndex: 'trade_no'
            },
            {
                title: '账务类型',
                dataIndex: 'trade_type_desc'
            },
            {
                title: '收支金额（元）',
                dataIndex: 'amount'
            },
            {
                title: '备注',
                dataIndex: 'remark'
            }
        ]

        const dataSource = [
            {
                time: '2020-01-28 16:00',
                order: '222221121215',
                type: '交易收入',
                get: '500',
                remarks: 'ffffffffff'
            },
            {
                time: '2020-01-28 16:00',
                order: '222221121215',
                type: '交易收入',
                get: '500',
                remarks: 'ffffffffff'
            },
            {
                time: '2020-01-28 16:00',
                order: '222221121215',
                type: '交易收入',
                get: '500',
                remarks: 'ffffffffff'
            }
        ]

        this.state = {
            direction: '',
            columns: columns,
            dataSource: dataSource,
            allDetail: {},
            created_at: '',
            is_in: 0,
            trade_type: '',
            amount: '',
            trade_no: '',
            page: 1,
            total_page: 1,
            min_price: 0,
            max_price: 0,
            total: 15
        }
    }

    componentDidMount() {
        this.getDetail();
    }

    changePage = (e) => {
        //console.log(e)

    }

    minPrice = (e) => {
        this.setState({
            min_price: e ? e : '',
            amount: e + '~' + this.state.max_price
        })
    }

    maxPrice = (e) => {
        this.setState({
            max_price: e ? e : '',
            amount: this.state.min_price + '~' + e
        })
    }

    getDetail = async () => {
        const { is_in, trade_type, created_at, amount, trade_no, page } = this.state;
        let param = {
            is_in,
            trade_type,
            created_at,
            amount,
            trade_no,
            page
        }
        const hide = message.loading('加载数据中')
        let res = await GetdetailRequest.send(param)
        if (res.code == 200 && res.data) {
            this.setState({
                allDetail: res.data,
                dataSource: res.data.data
            })
        }
        hide()
        //console.log(res)
    }

    onChange = (e) => {
        this.setState({
            created_at: qs.betweenDate(e)
        })
    }
    directionChange = (e) => {
        this.setState({
            is_in: e.target.value
        })
    }
    changeSelect = (e) => {
        this.setState({
            trade_type: e
        })
    }
    clearAll = (e) => {
        this.setState({
            trade_type: '',
            created_at: '',
            amount: '',
            trade_no: ''
        })
    }
    searchGoods = async (e) => {
        await this.setState({
            page: e
        })

        this.getDetail()
    }
    render() {
        const { allDetail, page, created_at, trade_type, min_price, max_price, trade_no, total } = this.state;
        return (
            <div className='detailed flex'>
                <div className='box boxLeft'>
                    <div className='title'>明细查询</div>
                    <div className='citySelect flex selectDate' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">日期选择：</label>
                        <DatePicker style={{ width: '300px' }} locale={locale} onChange={this.onChange} />
                    </div>
                    <div className='citySelect flex selectDate' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">资金流向：</label>
                        <Radio.Group onChange={this.directionChange} defaultValue={0}>
                            <Radio value={0}>收入</Radio>
                            <Radio value={1}>支出</Radio>
                        </Radio.Group>
                    </div>
                    <div className='citySelect flex selectDate' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">类型：</label>
                        <Select placeholder='请选择类型' style={{ width: 120 }} onChange={this.changeSelect} value={trade_type}>
                            <Option value="">全部</Option>
                            <Option value="WITH_DRAW">提现</Option>
                            <Option value="RETURN_COMMISSION">返佣</Option>
                            <Option value="ORDER_CASHBACK">交易收入</Option>
                            <Option value="RECHARGE">充值</Option>
                            <Option value="PUNISH">罚款</Option>
                            <Option value="SERVICE_FEE">技术服务费</Option>
                            <Option value="REFUND">退款</Option>
                        </Select>
                    </div>
                    <div className='citySelect flex selectDate' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">金额：</label>
                        <div className='flex writeInput'>
                            <InputNumber onChange={this.minPrice} min={0} type='number' placeholder='最低金额' />
                            ~
                            <InputNumber onChange={this.maxPrice} min={0} type='number' placeholder='最高金额' />
                        </div>
                    </div>
                    <div className='citySelect flex selectDate' style={{ flexWrap: 'nowrap' }}>
                        <label htmlFor="">商户订单号：</label>
                        <Input value={trade_no} onChange={(e) => { this.setState({ trade_no: e.target.value }) }} type='text' placeholder='请输入商户订单号'></Input>
                    </div>
                    <div className='flex' style={{ justifyContent: 'center', marginTop: '70px' }}>
                        <Button className='button-bac' type='primary' onClick={() => { this.clearAll() }}>重置</Button>
                        <Button className='button-bac' type='primary' onClick={() => { this.getDetail() }}>查询</Button>
                    </div>
                </div>
                <div className='box boxRight'>
                    <ul className='flex wealth'>
                        <li>
                            <div>收入金额（元）</div>
                            <div>{allDetail['in_amount'] ? Number(allDetail['in_amount']).toFixed(2) : '0'}</div>
                        </li>
                        <li>
                            <div>收入笔数（笔）</div>
                            <div>{allDetail['in_count'] ? allDetail['in_count'] : '0'}</div>
                        </li>
                        <li>
                            <div>支出金额（元）</div>
                            <div>{allDetail['out_amount'] ? allDetail['out_amount'] : '0'}</div>
                        </li>
                        <li>
                            <div>支出笔数（笔）</div>
                            <div>{allDetail['out_count'] ? allDetail['out_count'] : '0'}</div>
                        </li>
                    </ul>

                    <Table
                        columns={this.state.columns}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        scroll={
                            {
                                scrollToFirstRowOnChange: false,
                                x: false,
                                y: 500
                            }
                        }
                    ></Table>

                    <div className='page_style'>
                        <Pagination
                            hideOnSinglePage={true}
                            onChange={(e) => { this.searchGoods(e) }}
                            defaultCurrent={1}
                            current={this.state.page}
                            total={total} />
                    </div>
                </div>
            </div>
        )
    }

}

export default router(FinanceDetail);