import { BrowserRouter as Router } from "react-router-dom";
import React, { Suspense } from "react";
import { renderRoutes } from "react-router-config";
import Application from "@zoranwong/acc-engine.js";
import './App.css';
const app = Application.getInstance();

const render = () => {
    require('./font.css')
    let { $router } = app;
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                {renderRoutes($router.routes)}
            </Suspense>
        </Router>
    );
}

export default render;