import {Button} from "@alifd/next";
import {Select} from "antd";
import React from "react";
import {find} from 'lodash';
function optionDisabled (id, selectedSpecs) {
    return (find(selectedSpecs, (spec) => (spec.id === id))) !== undefined;
}
const SpecSelector = ({selectSpec, specList, delSpec, selectedSpec, selectedSpecs}) => {
    let selected = selectedSpec && selectedSpec['id'] ? {
        id: selectedSpec['id'],
        name: selectedSpec['name'],
        values: selectedSpec['values']
    }: null;
    return (
        <div className="tagtitle">
            <Button style={{ float: 'right' }} type="primary" warning
                    onClick={() => delSpec()}>删</Button>
            <Select
                style={{ width: 200 }}
                optionFilterProp="children"
                onChange={(id) => {
                    let spec = find(specList, (res) => {
                        return (res.id === id);
                    });
                    if(spec) {
                        selectSpec(spec.id, spec.name, spec.values);
                    }
                }}
                defaultValue = {selected ? selected['id'] : null}
                value = {selected ? selected['id'] : null}
                placeholder='选择规格'
                filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}>
                {specList.map((v, i) => {
                    return <Select.Option key={v['id']} value={v['id']} title={v['name']} disabled={optionDisabled(v['id'], selectedSpecs)}>{v['name']}</Select.Option>
                })}
            </Select>
        </div>
    );
};

export default SpecSelector;