import React from 'react'
import '../css/progress.sass'
import { CheckOutlined } from '@ant-design/icons';

export default class BeginStep extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div className='beginStep'>
                <p className='title'>微信支付商户号</p>
                <div className='contents'>
                    <p style={{margin: '30px 0 20px 0'}}>账户升级后你将获得</p>

                    <ul className='tipsLine'>
                        <li>专属的微信支付商户号，资金管理更安全</li>
                        <li>每日提现金额不受限</li>
                        <li>实名认证标识，用户更放心</li>
                        <li>合法合规，便于社群持续经营</li>
                    </ul>

                    <p>相关服务由<span className='href'>磁力圈&微信支付</span>官方联合支持，请放心使用</p>
                </div>
            </div>
        )
    }
}