import React, { Component } from 'react';
import { message, Upload } from 'antd'
import config from '../../configs/upload'
import morePicUrl from '../../assets/morePic.png'

export default class SmallImgBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            images: [],
            count: props.data['count'],
            uploadedCount: 0
        }
        this.uploadedCount = 0;
        this.images = [];
    }

    beforeUpload = async (e) => {//
        let type = e.type.includes('image');

        return new Promise((resolve, reject) => {
            if (!type) {
                message.error('格式不正确！')
                reject(false)
            } else if (e.size > 2000000) {
                message.error('图片尺寸应小于2MB')
                reject(false)
            } else {
                message.loading('上传图片中...', 1)
                resolve(e)
            }
        })

    }

    upSmallImgCg = async (e) => {//上传

    }

    render() {
        return (
            <div
                className='editor_bar'
                onClick={() => {this.props.addComponent([]);}}
            >
                <img width={20} src={morePicUrl} alt='' />
                <p>{this.props.title}</p>
            </div>
        )
    }
}