import React, { Component } from "react";
import Header from './header'
import List from './list'
import './css/index.sass'
import qs from '../../../../utils/qs'
import emitter from "../../../../ev"
import nodataImg from '../../../../assets/nodetaillist.png'

export default class BornList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            demoList: props.demoList,
            scrollHeight: 0,
            hasMore: true,// 判断接口是否还有数据，通过接口设置
            loadData: props.canUpdate
        }
        //console.log(props)
    }

    componentDidMount() {
        this.watchList()//监听数据
    }
    componentWillReceiveProps(next) {
        // console.log(next)
        //this.watchList()//监听数据
    }
    componentWillUnmount() {
        emitter.removeAllListeners()
    }
    shouldComponentUpdate() {
        return true
    }


    // 处理滚动监听
    handleScroll(e) {
        const { hasMore } = this.state;
        if (!hasMore) {
            return;
        }
        //下面是判断页面滚动到底部的逻辑
        if (this.scrollDom.scrollTop + this.scrollDom.clientHeight >= this.scrollDom.scrollHeight) {
            emitter.emit('isBottom', true);//触发更新
            //console.log('到底了~~~~~~')
        }
    }

    watchList = () => {
        emitter.addListener('changeSiblingsData', async (data) => {

            await data;
            //console.log(data)
            let { demoList } = this.state;
            if (data.length) {
                demoList = demoList.concat(data)
                demoList = demoList.filter((x, index, self) => self.indexOf(x) === index)
                this.setState({
                    demoList: demoList
                })
            } else {
                this.setState({
                    demoList: []
                })
            }

            //emitter.removeAllListeners()
        });
    }

    render() {
        let { demoList, scrollHeight, loadData } = this.state;

        return (
            <div
                onScroll={this.handleScroll.bind(this)}
                ref={body => this.scrollDom = body}
                className='bornList'>
                {demoList&&demoList.length ? (
                    demoList.map((v, index) => {
                        return (
                            <div key={index}>
                                <Header detail={v}></Header>
                                <List list={v}></List>
                            </div>
                        )
                    })
                ) : (
                    <>
                        <div className='nodata'>
                            <img width={100} height={100} src={nodataImg} alt=''/>
                            <p style={{ marginTop: '10px' }}>暂无信息</p>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

