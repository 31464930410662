import React from 'react';
import { Input, Button, Switch, Select, Checkbox, InputNumber, Spin, message, Modal, Table } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import _, { find, findIndex, map, wrap } from 'lodash'
import Sku from '../../pages/groups/groupsList/components/sku';
import emitter from "../../ev";
const { Option } = Select;

export default class SelectGoods extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            protype: props.type,//gift赠品
            spinning: false,
            useTable: props.useTable,
            selectOption: '',
            goodstype: props.goodstype,//类别
            specsList: props.specsList,//规格列表
            goodsLists: [],//团购商品列表
            giftList: [],//赠品列表
            tempSelectedEntities: [],
            moreEntitiesIndex: 0
        }
        this.watchSkuDataGift();//sku 监听
    }

    componentDidMount = async () => {
        
    }


    componentWillUnmount() {
        //emitter.removeAllListeners()
    }

    watchSkuDataGift = () => {
        emitter.addListener('skuDatagift', async (data) => {
            this.goodsListRefresh(data, this.state.moreEntitiesIndex);
        });
    }

    handleCancel = () => {
        this.props.hideGooods(true)
    }

    handleOk = () => {
        this.handleCancel()
        this.props.canconcat({state: true, data: this.state.giftList})
    }


    //  选择商品
    selectGoodslistGift = async (record, index, entities, contrl) => {
        const { specsList, useTable } = await this.state

        let newEntities = [];
        if (entities && entities.length) {
            //组装
            newEntities = entities.map((v, i) => {
                return {
                    id: v['id'],
                    cost_price: v['market_price'],
                    sell_price: v['sell_price'],
                    stock_num: v['stock_num'],
                    entity_code: v['entity_code'],
                    spec_values: map(v['spec'], (specVId, specId) => {
                        let spec = find(specsList, (specss) => {
                            return specId === specss.id;
                        });

                        if (spec) {
                            let specValue = find(spec['values'], (v) => {
                                return v.id === specVId;
                            });
                            if (specValue) {
                                return {
                                    specId: specId,
                                    specName: spec['name'],
                                    specValue: {
                                        id: specVId,
                                        value: specValue['value']
                                    }
                                };
                            }
                        }
                        return null;
                    })
                };
            })
        }

        //多规格商品的 index
        await this.setState({
            moreEntitiesIndex: index,
        });


        await this.setState({
            specEntities: [...newEntities]
        })

        //是否多规格商品
        if (entities && entities.length && specsList.length > 0) {//多规格
            //隐藏选择商品
            this.setState({
                //isModalVisible: false,
                skuvisible: true //弹出规格选择
            })
            return 0
        } else {
            let goodsInfo = this.state.useTable['data'][index];
            goodsInfo['checked'] = !goodsInfo['checked'];
            goodsInfo['entities'][0]['checked'] = !goodsInfo['entities'][0]['checked'];
            this.goodsListRefresh(goodsInfo['entities'][0], index);
        }
    }

    goodsListRefresh = (entity, goodsIndex) => {
        let { useTable, protype, goodsLists, giftList } = this.state;
        let goodsInfo = useTable['data'][goodsIndex];
        let goods

        goodsLists.map((v,i)=>{
            v['goods_index'] = i
        })

        //console.log(protype)

        if (protype == 'gift') {
            goods = giftList
        } else {
            goods = goodsLists
        }

        if (entity['checked']) {
            goodsInfo['checked'] = true;
            let item = { ...goodsInfo };
            item['entity'] = entity;
            item['goods_index'] = goodsIndex;
            item['goods_id'] = goodsInfo['id'];
            item['entity_id'] = entity.id;
            item['stock_num'] = entity.stock_num;
            item['sell_price'] = entity.sell_price;

            goods.push(item);

            if (protype == 'gift') {
                this.setState({ giftList: goods });
                //this.props.giftList(goods)
            } else {
                this.setState({ goodsLists: goods });
            }

        } else {

            let index = findIndex(goods, function (e) {
                return e['entity_id'] === entity['id'];
            });
            if (index > -1) {
                let deleteGoods = goods.splice(index, 1);
                if (deleteGoods.length > 0) {
                    if (protype == 'gift') {
                        this.setState({ giftList: goods });
                        //this.props.giftList(goods)
                    } else {
                        this.setState({ goodsLists: goods });
                    }
                    let idx = findIndex(goods, function (e) {
                        return e['id'] === goodsInfo['id'];
                    });
                    if (idx < 0) {
                        goodsInfo['checked'] = false;
                    }
                }
            }
        }
        //this.props.giftList(this.state.giftList)
    }

    render() {

        let { selectedRowKeys, creatDom, bronDom, release, specEntities, reloadSku, shopArr, specsList } = this.state;
        
        const columns = [
            {
                title: '商品名称',
                dataIndex: 'goods_name'
            },
            {
                title: '销售价（元）',
                dataIndex: 'sell_price'
            },
            {
                title: '最大可售数量',
                render: (ctx) => {
                    let entities = ctx['entities'];
                    if (entities.length == 1) {
                        return (
                            <p>{entities[0]['stock_num']}</p>
                        )
                    } else {
                        return (
                            <p>选规格，查看</p>
                        )
                    }
                }
            },
            {
                title: '操作',
                render: (text, record, index) => {

                    let has = _.find(this.state.selectgoods, item => {
                        return item['goods_id'] == record['id']
                    })

                    //是否多规格
                    let isEntities = record['multi_spec'] == 'on' ? true : false;

                    if (record.checked) {
                        if (!isEntities) {
                            return <span onClick={() => this.selectGoodslistGift(record, index, [], 'cancel')}
                                style={{ 'color': '#2F76C4', cursor: 'pointer' }}>取消选择</span>
                        } else {
                            return <span onClick={() => this.selectGoodslistGift(record, index, record['entities'], 'specselect')}
                                style={{ 'color': '#2F76C4', cursor: 'pointer' }}>重新选择</span>
                        }
                    } else {
                        if (!isEntities) {//单规格
                            return <span onClick={() => this.selectGoodslistGift(record, index, [], 'select')}
                                style={{ 'color': '#2F76C4', 'cursor': "pointer" }}>选择</span>
                        } else {
                            return <span onClick={() => {
                                if (this.state.specsList.length > 0)
                                    this.selectGoodslistGift(record, index, record['entities'], 'specselect')
                            }}
                                style={{ 'color': '#2F76C4', 'cursor': "pointer" }}>选择规格</span>
                        }

                    }
                }
            },
        ]
        return (
            <div className='selectGoods'>
                {/* 选择商品 */}
                <Modal
                    style={{ textAlign: 'center' }}
                    title="选择商品"
                    centered
                    onCancel={this.handleCancel}
                    visible={this.state.useTable}
                    footer={false}>
                    <div style={{ marginBottom: '20px', textAlign: 'left', marginTop: '10px' }}>
                        <Input style={({ width: 200, marginRight: '10px' })} placeholder='请输入商品名称/商品条码' />
                        <Select placeholder='请选择'
                            value={this.state.selectOption}
                            style={({ width: 120, marginRight: '10px' })}
                            onChange={(e) => {
                                this.setState({ selectOption: e })
                            }}>
                            {this.state.goodstype.map(v => {
                                return <Select.Option key={v['id']} value={v['id']}>{v['cate_name']}</Select.Option>
                            })}
                        </Select>
                        <Button style={{ float: 'right' }} icon={<SearchOutlined />}>搜索</Button>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={this.state.useTable['data']}
                        rowKey={record => record.id}
                        scroll={({ y: 300 })}
                        pagination={false}
                    />
                    <div className='flex' style={({ overflow: 'hidden', textAlign: 'center', justifyContent: 'center', margin: '20px 0 10px 0' })}>
                        <div style={({ lineHeight: '32px', padding: '0 10px', float: 'right', marginRight: '20px' })}
                            className='ant-btn ant-btn-default ant-btn-sm button-bac' onClick={this.handleCancel}>取消
                        </div>
                        <div style={({ lineHeight: '32px', padding: '0 10px', float: 'right' })}
                            className='ant-btn ant-btn-default ant-btn-sm button-bac' onClick={this.handleOk}>确定
                        </div>
                    </div>
                </Modal>

                <Modal
                    title='选择规格'
                    centered
                    visible={this.state.skuvisible}
                    footer={
                        <Button key="back" onClick={() => this.setState({ skuvisible: false })}>
                            确定
                        </Button>
                    }
                >
                    <Sku ref='SkuList' selectedEntities={this.state.tempSelectedEntities} specData={specsList} entities={specEntities} changeEntities={(entities) => {
                        let selectedEntities = this.state.tempSelectedEntities;
                        for (let entity of entities) {
                            let index = findIndex(selectedEntities, function (e) {
                                return e['id'] === entity['id'];
                            });
                            if (index === -1) {
                                selectedEntities.push(entity);
                            } else {
                                selectedEntities[index] = entity;
                            }
                        }
                        this.setState({ tempSelectedEntities: selectedEntities });
                    }} />

                </Modal>
            </div>
        )
    }

}