import {Request} from '@zoranwong/acc-engine.js';
export default class DeleteStandardGoodsRequest extends Request {
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
     goods_ids:[],
     //merchant_id: null,

   };
   _method = 'DELETE';
   _uri = '/goods/shop-goods/goods/goods-destroy';
   constructor(params) {
     super(params);
     this._data['goods_ids'] = params.goods_ids;
     //this._data['merchant_id'] = params.merchant_id;
   }
   rules(){
     return {
        
     };
   }

   messages(){
     return  {
         
    };
   }
}
