import React from 'react'
import { Radio, Checkbox, Input, DatePicker, Upload, message, Button, Select, Cascader } from 'antd'
import '../css/merchant.sass'
import { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import config from '../../../configs/upload'
import locale from 'antd/es/date-picker/locale/zh_CN';
import Tips from './tips';
import region from '../../../utils/region.json'


const { RangePicker } = DatePicker;
const { Option } = Select;


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export default class Enterprise extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            canNext: {},
            next: false,
            fontImg: '',
            nofontImg: false,
            backImg: '',
            nobackImg: false,
            nickName: '',//简称
            nonickname: false,
            realName: '',//
            norealname: false,
            mailbox: '',
            nomailbox: false,
            mobile: '',
            nomobile: false,
            idnum: '',
            noidnum: false
        }
    }

    checkFrom = async (state = false) => {
        let { nobackImg, nofontImg, noidnum, nomailbox, nomobile, nonickname, norealname, backImg, fontImg, idnum, mailbox, mobile, nickName, realName } = await this.state
        nobackImg = backImg ? false : true
        nofontImg = fontImg ? false : true
        noidnum = idnum ? false : true
        nomailbox = mailbox ? false : true
        nomobile = mobile ? false : true
        nonickname = nickName ? false : true
        norealname = realName ? false : true
        await this.setState({
            nobackImg,
            nofontImg,
            noidnum,
            nomailbox,
            nomobile,
            nonickname,
            norealname
        })
        let checkArr = [nobackImg, nofontImg, noidnum, nomailbox, nomobile, nonickname, norealname]
        if (checkArr.includes(true)) {
            this.setState({
                canNext: {
                    state: false,
                    msg: '请将资料填写完整'
                }
            })
        } else {
            this.setState({
                canNext: {
                    state: true,
                    msg: ''
                }
            })
        }
    }

    stepCtrl = async (param) => {//步骤前进后退
        await this.checkFrom(true)
        let { canNext } = this.state;
        //console.log(canNext,)
        //准备去下一步
        //this.props.prepare(true)
        if (!canNext['state'] && param > 0) return message.error(canNext['msg'])
        //let {step} = this.state
        this.props.goOrback(param)
    }

    render() {
        const fontprops = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            accept: '.png,.jpg,.jpeg',
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: () => {
                message.loading('图片上传中')
            },
            onChange: (info) => {
                //console.log(info)
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success('图片上传成功！')
                    getBase64(info.file.originFileObj, fontImg => {
                        this.setState({
                            fontImg: info.file['response']['data']['url'],
                            nofontImg: false
                        })
                    }
                    );
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        const backprops = {
            name: 'file',
            action: `${config.apiUrl}/admin/upload/upload-image`,
            accept: '.png,.jpg,.jpeg',
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: () => {
                message.loading('图片上传中')
            },
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success('图片上传成功！')
                    getBase64(info.file.originFileObj, backImg => {
                        this.setState({
                            backImg: info.file['response']['data']['url'],
                            nobackImg: false
                        })
                    }
                    );
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        function onChange(params) {

        }
        function handleChange(params) {

        }


        let {
            next,
            fontImg,
            nofontImg,
            backImg,
            nobackImg,
            nickName,
            nonickname,
            realName,
            norealname,
            mailbox,
            nomailbox,
            mobile,
            nomobile,
            idnum,
            noidnum
        } = this.state


        return (
            <div className='material'>
                <div className='beginStep'>
                    {/*------------- 店铺(商户)基本信息 ------------*/}
                    <p className='title'>店铺(商户)基本信息</p>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>商户简称：</p>
                        <Input
                            onBlur={async () => { await this.setState({ nonickname: nickName.length ? false : true }); }}
                            onChange={async (e) => { await this.setState({ nickName: e.target.value }) }}
                            value={nickName} style={{ width: 240 }}
                            placeholder='请输入商户简称(10字)'
                            maxLength={10} />
                        <p className='explain' style={{ marginLeft: 20 }}>如：高新区美味团、优品店</p>
                        <Tips ctx={{ ctx: '请输入商户简称(10字)', type: (!nickName.length && nonickname) ? 'error' : 'none' }} />
                    </div>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>店铺名称:</p>
                        <Input style={{ width: 240 }} placeholder='请输入店铺名称' maxLength={10} />
                    </div>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>补充材料:</p>
                        <Input style={{ width: 240 }} placeholder='请输入经营者/法定代表人姓名' />
                    </div>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>补充说明:</p>
                        <Input style={{ width: 240 }} placeholder='对您的业务进行简单的文字补充说明' />
                    </div>

                    {/* ----------经营者基本信息------------- */}

                    <p className='title'>店铺（商户）经营者/法人基本信息</p>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>姓名:</p>
                        <Input style={{ width: 240 }} placeholder='请输入经营者/法定代表人姓名' maxLength={10} />
                    </div>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>手机号:</p>
                        <Input style={{ width: 240 }} placeholder='请填写手机号码' />
                    </div>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>身份证号:</p>
                        <Input style={{ width: 240 }} placeholder='请填写身份证号码' />
                    </div>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>身份证有效期:</p>
                        <Radio.Group onChange={onChange} value={1}>
                            <Radio value={1}>非长期</Radio>
                            <Radio value={2}>长期</Radio>
                        </Radio.Group>

                    </div>

                    <div className='flex'>
                        <p className='inputTitle'></p>
                        <RangePicker locale={locale} />
                    </div>

                    <div className='flex'>
                        <p className='inputTitle'></p>
                        <p className='warn'>请确保上方期限与您 身份证背面 的有效期限一致</p>
                    </div>

                    <div className='inputBox flex uploadsId'>
                        <p className='inputTitle'>身份证件图片:</p>

                        <div className='identity flex'>
                            <div className='identityPhoto font'>
                                {
                                    !fontImg ? (<p className='photoTips'>身份证正面照</p>) : null
                                }
                                <div className='uploads'>
                                    <Upload {...fontprops}>
                                        <Button icon={<UploadOutlined />}>点击上传</Button>
                                    </Upload>
                                </div>
                                {fontImg ? (
                                    <img src={fontImg} alt='' />
                                ) : null}

                            </div>
                            <div className='identityPhoto back'>
                                {!backImg ? (<p className='photoTips'>身份证反面照</p>) : null}

                                <div className='uploads'>
                                    <Upload {...backprops}>
                                        <Button icon={<UploadOutlined />}>点击上传</Button>
                                    </Upload>
                                </div>
                                {backImg ? (<img src={backImg} alt='' />) : null}

                            </div>
                        </div>
                    </div>

                    {/* 入驻商户证件信息 */}
                    <p className='title'>入驻商户证件信息</p>
                    <div className='inputBox flex'>
                        <p className='inputTitle'>商户/公司名称:</p>
                        <Input style={{ width: 240 }} placeholder='请填写商户/公司名称' />
                    </div>
                    <div className='inputBox flex'>
                        <p className='inputTitle'></p>

                        <div>
                            <p className='explain'>
                                1.个体户：个体户的商户名称不能以“公司”结尾；
                            </p>
                            <p className='explain'>
                                2.个体户：若营业执照上商户名称为空或为“无”，请填写【个体户+经营者姓名】，如“个体户小明”。
                            </p>
                        </div>
                    </div>

                    <div className='inputBox flex'>
                        <p className='inputTitle'>注册号/统一社会信用代码：</p>
                        <Input
                            style={{ width: 240 }}
                            placeholder='请填写注册号/统一社会信用代码' />
                    </div>


                    <div className='inputBox flex uploadsId'>
                        <p className='inputTitle'>营业执照：</p>
                        <div className='identity flex'>
                            <div className='identityPhoto font'>
                                {
                                    !fontImg ? (<p className='photoTips'>添加照片</p>) : null
                                }
                                <div className='uploads'>
                                    <Upload {...fontprops}>
                                        <Button icon={<UploadOutlined />}>点击上传</Button>
                                    </Upload>
                                </div>
                                {fontImg ? (
                                    <img src={fontImg} alt='' />
                                ) : null}

                            </div>
                        </div>
                    </div>

                    <div className='inputBox flex'>
                        <p className='inputTitle'></p>

                        <div>
                            <p className='explain'>
                                1.请上传证件的彩色扫描件或彩色数码拍摄件，黑白复印件需加盖公章（公章信息需完整）
                            </p>
                            <p className='explain'>
                                2.不得添加无关水印（非微信支付商户申请用途的其它水印）
                            </p>
                            <p className='explain'>
                                3.需提供证件的正面拍摄件，完整、照片信息清晰可见。信息不清晰、扭曲、压缩变形、反光、不完整均不接受
                            </p>
                            <p className='explain'>
                                4.不接受二次裁剪、翻拍、PS的证件照片
                            </p>

                        </div>
                    </div>



                    {/* 结算账户信息（选填） */}

                    <p className='title'>结算账户信息（选填）</p>
                    <div className='inputBox flex'>
                        <p className='inputTitle'>开户名称:</p>
                        <Input style={{ width: 240 }} placeholder='请填写开户名称' />
                    </div>
                    <div className='inputBox flex'>
                        <p className='inputTitle'>银行账号:</p>
                        <Input style={{ width: 240 }} placeholder='请填写银行账号' />
                    </div>
                    <div className='inputBox flex'>
                        <p className='inputTitle'>开户地:</p>
                        <Cascader
                            fieldNames={{ label: 'name', value: 'code' }}//自定义字段名
                            options={region}
                            onChange={(e) => { console.log(e, 1) }}
                            displayRender={(e) => {//选择后展示的渲染函数
                                return (
                                    e.join('/')
                                )
                            }}
                            showSearch={(val, arrs) => { return arrs.some(option => option.label.includes(val)); }}//筛选
                            placeholder="请填写开户名称"
                        />
                    </div>
                    <div className='inputBox flex'>
                        <p className='inputTitle'>开户银行:</p>
                        <Select defaultValue="lucy" style={{ width: 120 }} onChange={handleChange}>
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>
                                Disabled
                            </Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </div>

                    <div className='nextStep'>
                        <div className='stepCtrl flex'>
                            <Button className='themebtn prev' onClick={() => { this.stepCtrl(-1) }}>上一步</Button>
                            <Button className='themebtn' onClick={() => { this.stepCtrl(1) }}>下一步</Button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}