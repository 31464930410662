import { Request } from '@zoranwong/acc-engine.js';
export default class OrderExportAddRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        
    };
    _method = 'POST';
    _uri = '/order/group-order/merchant-order/order-export-task-add';
    constructor(params) {
        super(params);
        this._data['end_date'] = params['end_date'];
        this._data['search'] = params['search'];
        this._data['searchFields'] = params['searchFields'];
        this._data['group_fields'] = params['group_fields'];
        this._data['goods_fields'] = params['goods_fields'];
        this._data['order_fields'] = params['order_fields'];
        this._data['mailing_fields'] = params['mailing_fields'];
    }
    rules() {

    }

    messages() {

    }
}