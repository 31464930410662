import { Menu } from 'antd';
import React, { useState } from "react";
import { Layout } from 'antd';
import { Link } from 'react-router-dom'
import '../../style/leftMenus.sass'
// import '../../style/sider.sass'
const { Sider } = Layout;
// const {Item} = Menu;

//const path = 0
const Menus = ({ menus }) => {

    let beginPath = menus.length > 0 ? [menus[0].path] : '';//初始化leftActive

    let localActive = window.localStorage.getItem('leftActive');

    //console.log(localActive == null,)

    if (localActive !== null && localActive !== 'null') { beginPath = JSON.parse(localActive); }//如果存有 ，则取

    let [active, setCount] = useState(beginPath)// 数据

    //console.log(active, beginPath, menus, localActive)

    return (

        <Sider className='sider leftMenu'>
            <div className='logo'>
                <h2></h2>
            </div>
            <Menu
                theme="light"
                defaultSelectedKeys={active}
                mode="vertical"
                onSelect={(e) => {
                    //console.log(e, 'onSelect')
                    //设置 active
                    setCount(active = e.keyPath)
                    //储存active 刷新时还原
                    window.localStorage.setItem('leftActive', JSON.stringify(e.keyPath))
                }}
            > { /*<Item ></Item>*/}
                {

                    menus.map(item => {
                        return (
                            <Menu.Item key={item.path}>
                                <Link to={item.path}>
                                    {typeof (item.icon) == 'string' ? (<img className='left_icon' src={item.icon} width={16} alt='' />) : item.icon}

                                    <span style={{ minWidth: '80px', display: 'inline-block', textAlign: 'left' }}>{item.name}</span>
                                </Link>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        </Sider>
    )
};

export default Menus;