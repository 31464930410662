//import './index.css';
import 'core-js';
import reportWebVitals from './reportWebVitals';
import Application from "@zoranwong/acc-engine.js";
import ReactDOMServiceProvider from "./providers/ReactDOMServiceProvider";
import RouterServiceProvider from "./providers/RouterServiceProvider";
import ValidateServiceProvider from "./providers/ValidateServiceProvider";
import HttpServiceProvider from "./providers/HttpServiceProvider";

const app = Application.getInstance();
global.app = app;
app.register(ValidateServiceProvider);
app.register(RouterServiceProvider);
app.register(ReactDOMServiceProvider);
app.register(HttpServiceProvider);
app.run();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
