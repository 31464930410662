import Layout from '../components/layout';
import DatusIndex from '../pages/datum';
import datumIcon from '../assets/datumIcon.png'
const sider = [
    {
        name: '数据总览',
        path: '/datum',
        icon: datumIcon
    }
];
const datum = {
    path: "/datum",
    component: Layout,
    sider: sider,
    routes:[
        {
            path: "/datum",
            exact: false,
            name: 'datusIndex',
            component: DatusIndex
        }
    ]
};
export default datum;