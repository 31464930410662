/**
 * Created by qs on 2021/3/16.
 */
import {Request} from '@zoranwong/acc-engine.js';

export default class GroupListRequest extends Request{
    _data={
        page: 1,//分页页数
        template_id: null,//团模版Id
        search:'',//搜索条件 昵称或者手机号
        arrived_date:null//配送日期
    }//

    _method = 'GET';//
    _uri = '/goods/group-buy/group-template/alpha-group-list';//

    constructor(params){
        super(params);
        this._data['page'] = params['page'];
        this._data['template_id'] = params['template_id'];
        this._data['search'] = params['search']?params['search']:'';
        this._data['arrived_date'] = params['arrived_date']?params['arrived_date']:'';
    }

    rules(){
        return {

        };
    }

    messages(){
        return  {

        };
    }
}