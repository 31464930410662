import {Request} from '@zoranwong/acc-engine.js';

//let merchant_id = window.localStorage.getItem('merchant_id')
export default class ClassificationListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        //merchant_id: null,
    };
    _method = 'GET';
    _uri = '/goods/shop-goods/category/cate-tree';

    // _responseClass = ClassificationListRequest;
    constructor (params) {
        super(params);
        
    }

    // get middleware(){
    //   return [...super.middleware, PasswordHashMiddleware];
    // }
    rules () {
        return {
            // cate_name: 'size:20',
        };
    }

    messages () {
        return {
            // 'cate_name.size': '分类名称最多为20字'
        };
    }
}
