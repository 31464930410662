import { withRouter as router } from "react-router-dom";
import Base from "../../../components/base";
import React from "react";
import SpecificationListRequest from '../api/SpecificationMangagemen/SpecificationListRequest'
import DeleteSpecificationRequest from '../api/SpecificationMangagemen/DeleteSpecificationRequest'
import AddSpecificationValueRequest from '../api/SpecificationMangagemen/AddSpecificationValueRequest'
import { Button, Table, message, Popconfirm, Modal, Input, Spin, Pagination } from "antd";
import AddSpecifica from './addSpecifica'
import { SyncOutlined } from '@ant-design/icons';

class SpecificationMangagemen extends Base {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      data: [],
      speciId: '',
      standardVisible: false,
      standardValue: '',
      //merchantId: 'RXZgrV',//商户id
      syncoutlined: false,
      loading: true,
      SpinLoading: false,
      clean: false,
      page: 1,
      total: 0
    }

  }
  componentDidMount() {
    this.specificationlist();
  }
  specificationlist = async () => {
    let { page } = this.state
    let params = {
      //merchant_id: this.state.merchantId
      page,
      page_size: 15
    }
    let res = await SpecificationListRequest.send(params)
    //console.log(res)
    if (res.status && res.data.data) {
      this.setState({ data: [...res.data.data], syncoutlined: false, total: res.data.total })
    }

    this.setState({ loading: false })

  }

  // 删除
  delete = (record) => {
    this.setState({ speciId: record.id })
  }

  confirm = async (state) => {
    let arr = []
    let arr1 = arr.concat(this.state.speciId)
    let params = {
      spec_ids: arr1,
      //merchant_id: this.state.merchantId
    }

    //if(!state)return

    const hide = message.loading('删除中')
    let res = await DeleteSpecificationRequest.send(params)
    if (res.status == true) {
      this.specificationlist();
      message.success(res.message)
    } else {
      message.error(res.message)
    }

    hide()
  }

  cancel = (e) => {
    message.error('取消删除喽！');
  }
  // 新增规格值
  standardVal = (record) => {
    this.setState({ standardVisible: true, speciId: record.id, record: {} })
  }
  handleOk = async () => {
    let arr = []
    let arr1 = arr.concat(this.state.standardValue)
    let params = {
      spec_id: this.state.speciId,
      //merchant_id: this.state.merchantId,
      spec_value: arr1
    }
    const hide = message.loading('添加中...', 1)
    let res = await AddSpecificationValueRequest.send(params)
    if (res.status == true) {
      message.success(res.message)
      //window.location.reload()
      this.specificationlist();
    } else {
      message.error(res.message)
    }
    hide();
    this.handleCancel();
  };

  handleCancel = () => {
    this.setState({ standardVisible: false })
  };

  // 新增规格
  closeNew = () => {
    this.setState({ visible: false })
  };
  addSpeci = () => {
    this.setState({ visible: true, record: {}, clean: true })
  }
  edit = (record) => {
    this.setState({ visible: true, record: record, clean: false })
  }
  updateList = () => {
    this.setState({
      syncoutlined: true,
      loading: true
    })
    this.specificationlist()
  }

  closeAdd = (e) => {//关闭弹窗
    this.setState({
      visible: e
    })

    this.specificationlist();
  }


  render() {

    let { page, total } = this.state;

    const columns = [
      {
        title: '规格分类',
        dataIndex: 'spec_name'
      },
      {
        title: '规格值',
        render: (text, record) => {
          return <div>
            {
              record.spec_value.map((item, index) => {
                return (
                  <span key={index}>
                    {item.spec_value}
                    {index < record.spec_value.length - 1 ? (<b style={{ color: '#366FBB' }}> / </b>) : null}
                  </span>
                )
              })
            }
          </div>
        }
      },
      {
        title: "操作",
        render: (text, record) =>
          <div>
            <span onClick={() => this.standardVal(record)} style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px' }}>新增规格值</span>
            <span onClick={() => this.edit(record)} style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px' }}>编辑</span>
            <Popconfirm
              title="您确定要删除吗？"
              onConfirm={() => { this.confirm(false) }}
              onCancel={this.cancel}
              okText="确定"
              cancelText="取消"
            >
              <span onClick={() => this.delete(record, false)} style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px' }}>删除</span>
            </Popconfirm>
          </div>
      }
    ]

    return (
      <div className='need-margin'>
        <AddSpecifica
          visible={this.state.visible}
          onCancel={this.closeNew}
          record={this.state.record}
          close={this.closeAdd}
          clean={this.state.clean}
        />
        <Modal
          title="新增规格值"
          style={{ textAlign: 'center' }}
          visible={this.state.standardVisible}
          onCancel={this.handleCancel}
          footer={
            <div>
              <Spin spinning={this.state.SpinLoading} />
              {!this.state.SpinLoading ? (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    size="small"
                    className='button-bac'
                    onClick={this.handleCancel}
                    type="default">取消</Button>
                  <Button
                    size="small"
                    className='button-bac'
                    onClick={this.handleOk}
                    type="primary">保存</Button>
                </div>
              ) : null}

            </div>
          }
        >
          <ul>
            <li>
              <span>规格值：</span>
              <Input
                style={{ width: '300px', borderRadius: '4px' }}
                value={this.state.standardValue}
                onChange={(e) => { this.setState({ standardValue: e.target.value }) }}
                placeholder="请输入规格值"
              />
            </li>
          </ul>
        </Modal>


        <div className='flex' style={{ marginBottom: '10px' }}>
          <Button className='button-bac' onClick={this.addSpeci} >添加规格</Button>
          <p style={{ paddingLeft: 10, color: '#909090' }}>注： 最多可添加500条</p>
          {/* <div className='flex' style={{ marginLeft: '40px', fontSize: '16px', color: '#6F6F6F' }}> <div className='updateList' onClick={this.updateList}><SyncOutlined style={{ color: '#ffffff', fontSize: '20px' }} spin={this.state.syncoutlined} /></div> 更新</div> */}
        </div>

        <Table
          scroll={
            {
              scrollToFirstRowOnChange: false,
              x: false,
              y: 600
            }
          }
          columns={columns}
          dataSource={this.state.data}
          loading={this.state.loading}
          rowKey={record => record.id}
          pagination={false}
          rowClassName={(record, index) => {
            let className = '';
            if (index % 2) className = 'dark-row';
            return className;
          }}
        />

        <div className={'page_style'}>
          <Pagination
            onChange={async (e) => {
              await this.setState({ page: e })
              this.specificationlist()//////////////////////////////////
            }}
            hideOnSinglePage={true}
            defaultCurrent={1}
            showSizeChanger={false}
            pageSize={15}
            current={page}
            total={total} />
        </div>
      </div>
    );
  }
}

export default router(SpecificationMangagemen);