import React from 'react'
import { withRouter as router } from 'react-router-dom'
import Base from '../../../components/base'
import { Modal, Table, Input, message, Button, Spin } from 'antd'
import AddSpecificationRequest from '../api/SpecificationMangagemen/AddSpecificationRequest'
import EditSpecificationRequest from '../api/SpecificationMangagemen/EditSpecificationRequest'
import DeleteSpecificationRequest from '../api/SpecificationMangagemen/DeleteSpecificationRequest'
import DeleteSpecValueRequest from '../api/SpecificationMangagemen/DeleteSpecValueRequest'
import { PlusCircleOutlined } from '@ant-design/icons';
import _ from 'lodash'

class AddSpecifica extends Base {
  constructor(props) {
    super(props)
    this.state = {
      typeName: "",
      type: 'create',
      speciList: [],
      //merchantId: 'RXZgrV',//商户id
      specId: '',
      SpinLoading: false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.record && nextProps.record.id) {
      this.setState({ type: 'edit', typeName: nextProps.record.spec_name, specId: nextProps.record.id, speciList: nextProps.record.spec_value })
    } else {
      this.setState({ type: 'create', typeName: '', speciList: [] })
    }
  }

  handleCancel = () => {
    this.props.onCancel()
  };
  handleSubmit = async () => {

    let api = this.state.type === 'create' ? AddSpecificationRequest : EditSpecificationRequest;
    //let id = this.state.type === 'create' ? this.state.merchantId : this.props.record.merchant_id;
    if (!this.state.typeName) {
      message.error('请填写规格名称');
      return;
    }
    // if (this.state.speciList.length < 1) {
    //   message.error('请添加规格值')
    //   return;
    // }

    let list = this.state.speciList

    if (list.length >= 500) return message.error('规格不可超过500条！')

    let newlist = _.map(list, item => {
      return item.spec_value
    })
    let creatparam = {
      spec_name: this.state.typeName,
      //merchant_id: id,
      spec_value: newlist
    }

    let editparams = {
      //merchant_id: id,
      spec_name: this.state.typeName,
      spec_id: this.state.specId,
      spec_value: newlist
    }

    let params = this.state.type === 'create' ? creatparam : editparams

    this.setState({
      SpinLoading: true
    })

    let res = await api.send(params)
    if (res.status == true) {
      message.success(res.message)
      //window.location.reload()
      this.props.close(false)
    } else {
      message.error(res.message)
    }
    this.setState({
      SpinLoading: false
    })
  };

  delete = async (record, index) => {
    let speciList = this.state.speciList

    let params = {
      spec_ids: [record.id]
    }

    let list = _.filter(speciList, function (item) {
      return item.id !== record.id
    })
    this.setState({ speciList: list })

    // let res = await DeleteSpecValueRequest.send(params)
    // if (res.status) {
    //   message.success(res.message)
    //   let list = _.filter(specilist, function (item) {
    //     return item.id !== record.id
    //   })
    //   this.setState({ speciList: list })
    // } else {
    //   message.error(res.message)
    // }
  }
  // 新增规格值
  add = () => {
    let params = {
      id: Date.now(),
      spec_value: ''
    }
    let list = this.state.speciList.concat([params]);
    this.setState({ speciList: list })
  }
  // input输入值
  onTableInputChange = (e, proudctIndex, column, record) => {
    let list = this.state.speciList;
    //console.log(proudctIndex, column, e.target.value)
    // if(e.target.value && column === 'specilValue'){
    list[proudctIndex][column] = e.target.value
    // }
    this.setState({ speciList: list })
  }


  render() {
    let { speciList } = this.state
    const columns = [
      {
        title: '规格值',
        render: (text, record, index) => {
          //console.log(text, record, index)
          return (
            <Input value={speciList[index]['spec_value']} onChange={(e) => { this.onTableInputChange(e, index, 'spec_value', record) }} />
          )
        }

      },
      {
        title: '操作',
        render: (text, record, index) =>
          <div>
            <span onClick={() => this.delete(record, index)} style={{ 'color': '#909090', 'cursor': "pointer", "marginLeft": '5px' }}>删除</span>
          </div>
      }
    ]


    return (
      <div>
        <Modal
          title={this.state.type === 'create' ? '新增规格' : '编辑规格'}
          width={520}
          visible={this.props.visible}
          onCancel={this.handleCancel}
          maskClosable={false}
          footer={
            <div>
              <Spin spinning={this.state.SpinLoading} />
              {!this.state.SpinLoading ? (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    size="small"
                    className='button-bac'
                    onClick={this.handleCancel}
                    type="default">取消</Button>
                  <Button
                    size="small"
                    className='button-bac'
                    onClick={this.handleSubmit}
                    type="primary">保存</Button>
                </div>
              ) : null}

            </div>
          }
        >
          <ul>
            <li>
              <span style={{ width: '70px' }}>规格分类：</span>
              <Input style={{ width: '350px', borderRadius: '4px' }}
                maxLength={20}
                value={this.state.typeName}
                placeholder="请输入规格分类，例如：颜色，最多20字"
                onChange={(e) => { this.setState({ typeName: e.target.value }) }} />
            </li>
            <li style={{ display: 'flex', marginTop: '20px' }}>
              <span style={{ paddingTop: '16px', width: '70px' }}>规格值：</span>
              <div>
                <Table
                  style={{ width: '350px' }}
                  dataSource={speciList}
                  columns={columns}
                  rowKey={record => record.id}
                  pagination={false}
                />

                <p
                  onClick={() => { this.add() }}
                  style={{ 'color': '#366FBB', 'cursor': "pointer", "marginLeft": '5px', padding: '10px 0' }}>
                  <PlusCircleOutlined style={{ color: '366FBB', fontSize: '15px', marginRight: '5px', display: 'inline-block', verticalAlign: 'middle' }} />添加规格值</p>
              </div>
            </li>
          </ul>

        </Modal>

      </div>

    )
  }

}
export default router(AddSpecifica)