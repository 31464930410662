import React from 'react'
import { Image } from 'antd';

export default class Output extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            edmo: props['edmo']
        }
    }

    componentDidMount() {
        //console.log(this.props)
    }

    render() {

        const { edmo } = this.state
        const Show = () => {

            // image_b :0
            // image_s : 1
            // text: 2

            const { edmo } = this.state
            let components = []
            for (let i in edmo) {
                let type = Object.keys(edmo[i])[0];
                let val = Object.values(edmo[i])[0];

                switch (type) {
                    case 'image_b':
                        components.push({ type: 0, data: val });
                        break;
                    case 'image_s':
                        components.push({ type: 1, data: val });
                        break;
                    case 'text':
                        components.push({ type: 2, data: val });
                        break;
                    default:
                        break;
                }

            }

            return (
                <div className='output'>
                    {components && components.length ? (
                        components.map((v, i) => {
                            return (
                                <div key={i}>
                                    {!v.type ? (
                                        <div className='bigImg'>
                                            <Image fallback={v['data']} src={v['data']} />
                                        </div>
                                    ) : null}

                                    {v.type == 1 ? (
                                        <div className='smallImg flex'>

                                            {v['data'].map(j => {
                                                return (
                                                    <Image className='separate' fallback={j} src={j} />
                                                )
                                            })}

                                        </div>
                                    ) : null}

                                    {v.type == 2 ? (
                                        <div className='text'>
                                            <p className='explanation'>{v['data']}</p>
                                        </div>
                                    ) : null}
                                </div>
                            )
                        })
                    ) : null}
                </div>
            )

        }

        return (
            <>
                <Show></Show>
            </>
        )
    }

}