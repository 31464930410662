import React from "react";
import './list.sass'
import ListSon from "./ListSon";
import ListLi from "./ListLI";
import { message, Pagination } from 'antd'
import qs from '../../../utils/qs';
import ListRequest from '../api/listRequest';
import { FileSearchOutlined } from '@ant-design/icons';
//import GroupTemplateInfoRequest from '../../groups/api/groupsList/groupTemplateInfoRequest'

export default class List extends React.Component {
    constructor(props) {
        super(props)
        //console.log(props,)
        this.state = {
            channels: props.channels,
            askIds: '',
            page: 1,
            loading: true,
            listSonData: [],
            listLength: 0,
            total: 0,
            emptyArr: [],
            searchState: 101,
            dateParam: props.dateParam,
            lastRes: {}
        }
    }

    async componentDidMount(e) {
        let props = this.props
        //console.log(props, )
        if (props.channels) {
            let empty = [];
            for (let i in props.channels) {
                //empty = empty.concat(props.channels[i]['id'])
            }

            let state = props.searchState === -1 ? '' : props.searchState;
            //
            await this.setState({
                page: 1,
                emptyArr: empty.join(','),
                searchState: state,
                dateParam: props.dateParam,
                lastRes: props.lastRes
            })

            this.searchGoods(null, [], state)
        }
    }

    async componentWillReceiveProps(e) {
        //console.log(e, 'eeeeeeeeeeeeeeeeeee',this.props)
        if (e.channels) {
            let empty = [];
            for (let i in e.channels) {
                empty = empty.concat(e.channels[i]['id'])
            }

            let state = e.searchState === -1 ? '' : e.searchState;
            //
            await this.setState({
                channels: e.channels,
                page: 1,
                emptyArr: empty.join(','),
                searchState: state,
                dateParam: e.dateParam,
                lastRes: e.lastRes
            })

            this.searchGoods(null, empty.join(','), state)
        }
    }

    searchGoods = async (page, data, state = '') => {//搜索条件

        let { dateParam } = await this.state;
        //console.log(dateParam, data)

        let sqlarr = [
            { name: 'group_template_state', val: state },
            { name: dateParam['dateType'], val: dateParam['dateVal'] }
        ]

        if (data) {
            sqlarr.push(
                { name: 'channel_id', val: data }
            )
        }

        let sql = qs.sqlSearch(sqlarr)//sql 

        if (!page) {
            page = this.state.page
        }

        this.setState({ loading: true })
        this.getList(sql, page)
    }


    getList = async (sql, page) => {
        //console.log(sql, )
        let params = {
            page: page,
            page_size: 15,
            with_batch: true,
            searchFields: 'id::in;state::in'
        }
        if (sql.search) {
            params['search'] = sql['search']
        }

        //const hide = message.loading('数据加载中', 1)

        let res = await ListRequest.send(params);

        if (!res.data.data) return

        this.setState({
            listSonData: res.data.data,
            total: res.data.total
        })

        this.props.listLength(res.data.data.length)
        //hide()
    }

    changePage = async (e) => {
        let { emptyArr, searchState } = this.state;

        await this.setState({
            page: e
        })

        this.searchGoods(null, emptyArr, searchState)
    }

    render() {
        let { channels, listSonData, listLength, total, page, lastRes } = this.state
        //console.log(channels, lastRes)
        return (
            <div className='home_list'>
                {
                    channels && channels.map((v, index) => {
                        //console.log(v, listSonData)
                        return (
                            <div key={index + 'channel_list'} className='channel_list flex' ref={'channel_list' + v['id']}>

                                <div className='channel_name' ref={'channel_name' + v['id']}>
                                    <p>{v['name']}</p>
                                </div>

                                <ListSon
                                    
                                    key={index + 'ListSon'}
                                    channel_id={v['id']}
                                    listLength={(e) => {
                                        let height = e.val * 72 - 4;
                                        height = e.val > 0 ? height : 0
                                        //console.log(e,'ccccccccccccc', v['channel_id'] )
                                        this.refs['channel_name' + v['id']].style.height = height + 'px';
                                        this.refs['channel_list' + v['id']].style.display = 'flex'
                                        if (height === 0) {
                                            //this.refs['channel_name' + v['channel_id']].style.display = 'none'
                                            this.refs['channel_list' + v['id']].style.display = 'none'
                                        }
                                    }} templatesId={v['id']}
                                    listSonData={listSonData} />

                                <ListLi key={index + 'ListLi'} lastRes={lastRes} templatesId={v['id']} listSonData={listSonData} />

                            </div>
                        )
                    })
                }

                {
                    !listSonData.length ? (
                        <div className='emptyLi'>
                            <FileSearchOutlined style={{ fontSize: 34, color: '#366fbb' }} />
                            暂无数据，请切换团购状态
                        </div>
                    ) : null
                }

                <div className='pagination'>
                    <Pagination
                        hideOnSinglePage={true}
                        current={page}
                        defaultCurrent={1}
                        total={total}
                        pageSize={30}
                        showSizeChanger={false}
                        onChange={(e) => { this.changePage(e) }} />
                </div>
            </div>
        )
    }

}