import { Request } from '@zoranwong/acc-engine.js'
let merchant_id = window.localStorage.getItem('merchant_id')

export default class AlphaGroupRequest extends Request {//手动成团
  _headers = {
    'request-source': 'admin-web'
  }
  _data = {
    alpha_id: null,
    alpha_group_batch_id: null
  };
  _method = 'PUT';
  _uri = '/goods/alpha/alpha-group/alpha-group-batch-pass-rule';
  constructor(params) {
    super(params);
    this._data['alpha_id'] = params.alpha_id;//params.merchant_id;
    this._data['alpha_group_batch_id'] = params.alpha_group_batch_id;
  }
  // get middleware(){
  //   return [...super.middleware, PasswordHashMiddleware];
  // }
  rules() {
    return {
    };
  }

  messages() {
    return {
    };
  }
}