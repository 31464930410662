import {ServiceProvider} from "@zoranwong/acc-engine.js";

export default class ValidateServiceProvider extends ServiceProvider {
    register () {
        this.validateRule('phone_number', (value, attribute, messages, rules, params, rule) => {
            return true;
        });
    }

    validateRule (name, callback) {
        this.app.callMethodBinding('defineValidateRule', name, callback);
    }
}