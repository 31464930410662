import { withRouter as router } from "react-router-dom";
import Base from "../../components/base";
import React from "react";
import './home.sass';
import Calendar from './calendar';
import releaseUrl from '../../assets/release.png';
import { Select, Modal, message, Pagination } from 'antd';
import { LeftOutlined, RightOutlined, CloseOutlined } from '@ant-design/icons';
import UserInfoRequest from './api/userInfoRequest';
import emitter from '../../ev'
import qs from "../../utils/qs";
import GroupListRequest from './api/groupListRequest'
//import PurchaseListRequest from './api/purchaseListRequest'
//import GetGroupRequest from './api/GetGroupRequest'
import Header from './components/Header';
import List from './components/List';

const { Option } = Select;
class Home extends Base {
    constructor(args) {
        super(args)

        this.state = {
            channels: [{ name: '公开渠道', id: '' }],//渠道
            isModalVisible: false,
            searchState: '',
            searchOpen: true,
            loading: false,
            dateParam: {},
            lastRes: {},
            channelsSingle: []
        }
    }

    async componentDidMount() {
        let token = qs.getQueryVariable('token') || window.localStorage.getItem('token')
        if (!token) {
            return this.props.history.push({
                pathname: '/'
            })
        }
        await this.getUser(token);//用户
        await this.getGroup();//
        emitter.addListener('goGroupDetail', data => {
            if (data) {
                this.props.history.push({ pathname: `/groups/details`, state: { 'data': data } })
            }
        })

    }

    getUser = async (token) => {//获取信息
        let check_merchant_id = window.localStorage.getItem('merchant_id');
        let check_userInfo = window.localStorage.getItem('userInfo');
        let check_token = window.localStorage.getItem('token');

        let check_userInfo_length

        check_userInfo_length = check_userInfo ? check_userInfo.length > 4 : 0

        if (check_merchant_id && check_userInfo_length && check_token) return;

        let param = {
            token: token
        }

        let res = await UserInfoRequest.send(param)
        if (res['code'] === 200) {
            let merchant_id = res['data']['merchant_id'];
            if (merchant_id == undefined || merchant_id == 'undefined') return
            //message.success(res.message)
            window.localStorage.setItem('merchant_id', res['data']['merchant_id'])
            window.localStorage.setItem('main_merchant_id', res['data']['merchant_id'])
            window.localStorage.setItem('main_customer_id', res['data']['customer_id'])
            window.localStorage.setItem('userInfo', JSON.stringify(res.data))
            window.localStorage.setItem('token', token)

            emitter.emit('userInfo', JSON.stringify(res.data))
        } else {
            message.error(res.message)
        }
    }

    getGroup = async (sql) => {
        let { channels } = this.state;
        let params = {
            page_size: 500
        }
        if (sql) {
            params['search'] = sql['search']
        }
        let res = await GroupListRequest.send(params)
        if (res.code === 200) {
            this.setState({
                channels: channels.concat(res.data),
                loading: true
            })
        }
        //console.log(res, )
    }

    searchList = (e) => {

        this.setState({
            searchState: e
        })

    }

    hideModel = () => {
        this.setState({
            isModalVisible: false,
            searchOpen: false
        })
    }

    creatGroups = (name, type) => {
        //if(name=='gonghuo'){return message.error('暂未开放，敬请期待')}

        this.props.history.push({
            pathname: '/groups/newgroups', query: {
                name,
                type
            }
        })

    }

    render() {
        let { channels, isModalVisible, searchState, searchOpen, loading, dateParam, lastRes, channelsSingle } = this.state

        return (
            <div className='team_list'>
                {
                    loading ? (
                        <>
                            <Header
                                lastRes={e => { this.setState({ lastRes: e }) }}
                                dateParam={e => { this.setState({ dateParam: e }) }}
                                postChannels={e => { this.setState({ channels: e }) }}
                                channels={channels}
                                tabState={e => { this.searchList(e) }}
                                openRe={e => { if (e) { this.setState({ isModalVisible: e }) } }} />
                            <List lastRes={lastRes} listLength={(e) => { }} searchState={searchState} channels={channels} searchOpen={searchOpen} dateParam={dateParam} />
                        </>
                    ) : null
                }

                {/* Modal */}
                <Modal
                    style={{ padding: '10px 0' }}
                    visible={isModalVisible}
                    footer={false}
                    onCancel={this.hideModel}
                    centered>
                    <div className='Modeltitle'>请选择开团类型 <CloseOutlined onClick={this.hideModel} className='closeModal' /></div>
                    <ul className='flex open_menu'>
                        <li onClick={() => { this.creatGroups('pintuan', 1) }}>
                            <p className='title'>创建拼团合约</p>
                            <p>
                                统一供价、返佣，用户拼团付款，团长零库存
                            </p>
                        </li>
                        {/* 100为供货合约，暂时隐掉改为1 */}
                        <li onClick={() => { this.creatGroups('gonghuo', 100) }}>
                            <p className='title'>创建供货合约</p>
                            <p>
                                统一出厂价，团长订货
                                后，自行设置零售价格
                                后开团售卖
                            </p>
                        </li>
                        <li>
                            <p style={{ marginTop: '20px' }} className='title'>复制已有团购快速开团</p>
                        </li>
                    </ul>
                </Modal>
            </div>
        );
    }
}

export default router(Home);