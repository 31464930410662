import { withRouter as router } from "react-router-dom";
import Base from "../../components/base";
import {
    Row,
    Col,
    Input,
    DatePicker,
    Space,
    Select,
    Button,
    Tooltip,
    Table,
    Radio,
    Divider,
    Popconfirm,
    Modal
} from 'antd';
import React, { Component } from "react";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './css/index.sass'

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {
    LineChart
} from 'echarts/charts';
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent
} from 'echarts/components';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
    CanvasRenderer
} from 'echarts/renderers';
// 注册必须的组件
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]
);
const { RangePicker } = DatePicker;

class Datum extends Base {
    componentDidMount() {
        // 基于准备好的dom，初始化echarts实例
        let myChart1 = echarts.init(document.getElementById('forms1'));

        // 绘制图表
        myChart1.setOption({
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'line',
                smooth: true
            }]
        });

        let myChart2 = echarts.init(document.getElementById('forms2'));
        // 绘制图表
        myChart2.setOption({
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'line',
                smooth: true
            }]
        });
    }

    constructor(args) {
        super(args)

        const columns = [
            {
                title: '商品名称',
                dataIndex: 'goods_name'
            },
            {
                title: '销售数量',
                dataIndex: 'sell_num'
            },
            {
                title: '支付金额',
                dataIndex: 'get'
            }
        ]

        const dataSource = [
            {
                sell_num: '200',
                goods_name: '粉底霜',
                get: '200000'
            },
            {
                sell_num: '100',
                goods_name: '粉底霜1',
                get: '100000'
            },
            {
                sell_num: '50',
                goods_name: '粉底霜2',
                get: '50000'
            }
        ]

        this.state = {
            setDatesFlag: 0,
            columns: columns,
            dataSource: dataSource
        }
    }

    setDates = (flag) => {
        this.setState({
            setDatesFlag: flag
        })
    }

    render() {
        //console.log(this.props);
        return (
            <div className='datum'>
                <div className='box'>
                    <div className='top flex' style={{ justifyContent: 'space-between' }}>
                        <p className='gettime'>当前统计数据时间：2020-11-28 ~ 2020-12-04</p>
                        <div className='flex' >
                            <ul className='flex orderLi' >
                                <li className={this.state.setDatesFlag == 0 ? 'active' : ''}
                                    onClick={
                                        () => this.setDates(0)
                                    } > 最近3天 </li>
                                <li className={
                                    this.state.setDatesFlag == 1 ? 'active' : ''
                                }
                                    onClick={
                                        () => this.setDates(1)
                                    } > 最近7天
                              </li>
                                <li className={
                                    this.state.setDatesFlag == 2 ? 'active' : ''
                                }
                                    onClick={
                                        () => this.setDates(2)
                                    } > 最近1个月 </li>

                            </ul>
                            <RangePicker locale={locale} />
                        </div>
                    </div>

                    <div className='echarts'>
                        <Row>
                            <Col span={11}>
                                <div className='chartTitle'>
                                    <p className='data_title'>总收入（元）</p>
                                    <p className='data_num'>100.00</p>
                                </div>
                                <div className='echarts_son' id='forms1'></div>
                            </Col>
                            <Col span={11}>
                                <div className='chartTitle'>
                                    <p className='data_title'>下单总人数（人）</p>
                                    <p className='data_num'>23</p>
                                </div>
                                <div className='echarts_son' id='forms2'></div>
                            </Col>
                        </Row>
                    </div>
                    {/* 交易分析 */}
                    <div className='trade'>
                        <p className='title'>交易分析</p>
                        <Row>
                            <Col span={14}>
                                <ul className='flex wealth'>
                                    <li>
                                        <div>收入金额（元）</div>
                                        <div>+314.00</div>
                                    </li>
                                    <li>
                                        <div>收入笔数（笔）</div>
                                        <div>4</div>
                                    </li>
                                    <li>
                                        <div>支出金额（元）</div>
                                        <div>0.00</div>
                                    </li>
                                    <li>
                                        <div>支出笔数（笔）</div>
                                        <div>0</div>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>

                    {/* TOP5商品销售 */}

                    <div className='topSell'>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <div>
                                    <p className='title'>TOP5商品销售</p>
                                    <Table
                                        columns={this.state.columns}
                                        dataSource={this.state.dataSource}
                                        rowKey={(res) => res.goods_name}
                                        pagination={false}
                                    ></Table>
                                </div>
                            </Col>
                            <Col span={11}>
                                <div>
                                    <p className='title'>TOP5商品销售</p>
                                    <Table
                                        columns={this.state.columns}
                                        dataSource={this.state.dataSource}
                                        rowKey={(res) => res.goods_name}
                                        pagination={false}
                                    ></Table>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        );
    }
}

export default router(Datum);