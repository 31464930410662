import { Request } from '@zoranwong/acc-engine.js';
export default class getCommuneListRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        page: 1
    };
    _method = 'GET';
    _uri = '/commune-service/commune-api/get-commune-list';
    constructor(params) {
        super(params);
        this._data = {
            ...params
        }
    }
    rules() {

    }

    messages() {
    }
}
