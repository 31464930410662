import { Request } from '@zoranwong/acc-engine.js';
export default class SmsRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        mobile: null
    };
    _method = 'POST';
    _uri = '/member/user/send-sms';
    constructor(params) {
        super(params);
        this._data['mobile'] = params['mobile']
    }
    rules() {
        return {

        };
    }

    messages() {
        return {

        };
    }
}