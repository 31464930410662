import { Response } from "@zoranwong/acc-engine.js";

export default class BaseResponse extends Response {
    headers = {
        'request-source':'admin-web'
    };
    data = {};
    status = true;
    message = "";
    code = 200;
    constructor(status, httpStatus, body, headers = {}) {
        //console.log(headers,'headers...')
        super(status, httpStatus, body, headers);
        (async()=>{
            let res = await body;
            if(!res){
                return
            }
            this.headers = res['headers'];
            this.data =res['data'];
            this.status =res['status'];
            this.code =res['code'];
            this.message =res['message'];
        })()
    }
}