import React, { Component } from 'react';
import { message, Upload } from 'antd'
import config from '../../configs/upload'
import bigImgUrl from '../../assets/bigPic.png'

export default class BigImgBar extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    beforeUpload = (e) =>{
        let type = e.type.includes('image');

        return new Promise((resolve, reject) => {
            if (!type) {
                message.error('格式不正确！')
                reject(false)
            } else if (e.size > 2000000) {
                message.error('图片尺寸应小于2MB')
                reject(false)
            } else {
                message.loading('上传图片中...', 1)
                resolve(e)
            }
        })
    }

    upBigImgCg = async (e) => {//

        //const hide = message.loading('上传图片中...', 1)

        let res = await e.file.response;
        if (res && res.code == 200) {
            //hide();
            message.success('上传成功！')
            this.props.addComponent(res.data.url)
        }
    }

    render() {
        // return (
        //     <Upload
        //         className='editor_bar'
        //         progress={false}
        //         name='file'
        //         action={`${config.apiUrl}/admin/upload/upload-image`}
        //         beforeUpload={this.beforeUpload}
        //         onChange={this.upBigImgCg}
        //         accept='.png,.jpg,.jpeg,.gif'
        //     >
        //         <img width={20} src={bigImgUrl}></img>
        //         <p>{this.props.title}</p>
        //     </Upload>
        // )
        return (
            <div
                className='editor_bar'
                onClick={() => {this.props.addComponent([]);}}
            >
                <img width={20} src={bigImgUrl} alt='' />
                <p>{this.props.title}</p>
            </div>
        )
    }
}