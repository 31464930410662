import { Request } from '@zoranwong/acc-engine.js';
export default class GetAgentRequest extends Request {
    _headers = {
        'request-source': 'admin-web'
    }
    _data = {
        
    };
    _method = 'GET';
    _uri = '/user-service/group-leader-api/get-consignor-list';
    constructor(params) {
        super(params);
        this._data={
            ...params
        }
    }
    rules() {
        
    }

    messages() {
    }
}
