import React from "react";
import Caledar from "./caledar";
import './header.sass'
import click_img from '../../../assets/click.png'
import channels_img from '../../../assets/channels.png'
import band_img from '../../../assets/band.png'
import { Input, Modal, Select, DatePicker, Space, Button, message } from 'antd'
import { LeftOutlined, RightOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class Header extends React.Component {
    constructor(props) {
        super(props)

        //console.log(props,)

        this.state = {
            flag: -1,
            channels: props.channels,
            dateType: 'end_date',
            dateVal: '',
            setTime: ''
        }
    }

    tabs = (i) => {
        this.setState({
            flag: i
        })
        this.props.tabState(i)
    }

    componentDidMount(e) {
        //console.log(e, this.props)

    }

    componentWillReceiveProps() {
        //console.log(this.props,)
    }

    shouldComponentUpdate() {
        return true;
    }

    onChange = (value) => {
        //console.log(`${value}`);
        this.props.postChannels([JSON.parse(value)]);
    }

    getDateType = (e) => {
        this.setState({
            dateType: e
        })
    }

    getRangeVal = (data) => {
        this.setState({
            dateVal: data.length ? data.join('~') : ''
        })
    }

    searchGoods = () => {
        let { dateType, dateVal } = this.state;
        //console.log(, )
        message.loading('数据加载中...', 1)
        this.props.dateParam({
            dateType, dateVal
        })
    }

    render() {
        let { flag, channels, setTime, dateVal } = this.state;

        function onBlur() {
            //console.log('blur');
        }

        function onFocus() {
            //console.log('focus');
        }

        function onSearch(val) {
            //console.log(val);
        }
        return (
            <div className='home_header'>
                <div className='flex' style={{ alignItems: 'center', marginBottom: 26 }}>
                    <div className='release_team' onClick={() => { this.props.openRe(true) }}>
                        <img src={click_img} style={{ width: 36, height: 40, marginRight: 10 }} alt='' />
                        发布团购
                    </div>
                    <div className='searchBox flex'>
                        <div className='flex'>
                            渠道名称：
                            <Select
                                showSearch
                                style={{ width: 160 }}
                                placeholder="请选择"
                                optionFilterProp="children"
                                onChange={this.onChange}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={-1} value='{"id":"","name":"全部渠道","remark":"","tags":[],"created_at":"","updated_at":"","grouper_count":1}'>全部渠道</Option>
                                {
                                    channels && channels.map((v, i) => {
                                        return (
                                            <Option key={i} value={JSON.stringify(v)}>{v.name}</Option>
                                        )
                                    })
                                }

                            </Select>
                        </div>

                        <div className='flex' style={{ margin: '0 60px' }}>
                            <Select
                                onChange={e => { this.getDateType(e) }}
                                defaultValue="end_date"
                                style={{ width: 100 }}
                                allowClear>
                                <Option value="end_date">截团时间</Option>
                                <Option value="arrival_date">到货时间</Option>
                            </Select>
                            <RangePicker onChange={(e, data) => { this.getRangeVal(data) }} locale={locale} />
                        </div>

                        <div className='needMargin inline' style={{ marginRight: 0 }}>
                            <Button onClick={() => { this.searchGoods() }} className='search-btn'>
                                <SearchOutlined style={{ fontSize: '15px', fontWeight: 'bold' }} />搜索
                            </Button>
                            {/* <span onClick={() => { this.clearAll() }} className='clear'>清空所有搜索条件</span> */}
                        </div>

                    </div>
                </div>
                <div className='flex'>
                    <div className='channel_name'>
                        <img src={channels_img} style={{ width: 20, height: 18, marginRight: 10 }} alt='' />
                        渠道
                    </div>

                    <div className='group_list'>
                        <p className='title'>
                            <img src={band_img} style={{ width: 17, height: 18, marginRight: 10 }} alt='' />
                            团购合约
                        </p>
                        <ul className='tab flex'>
                            <li className={flag === -1 ? 'active' : ''} onClick={() => { this.tabs(-1) }}>全部</li>
                            <li className={flag === '101,102' ? 'active' : ''} onClick={() => { this.tabs('101,102') }}>进行中</li>
                            <li className={flag === '201,202' ? 'active' : ''} onClick={() => { this.tabs('201,202') }}>已结束</li>
                            {/* <li className={flag === 202 ? 'active' : ''} onClick={() => { this.tabs(202) }}>已停止</li> */}
                            <li className={flag === '0' ? 'active' : ''} onClick={() => { this.tabs('0') }}>未开始</li>
                        </ul>
                    </div>

                    <div className='caledar'>
                        <Caledar lastRes={e => { this.props.lastRes(e) }} setTime={dateVal} />
                    </div>
                </div>
            </div>
        )
    }
}