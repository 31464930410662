import React from "react";
import './listLi.sass'
import emitter from "../../../ev";
import qs from '../../../utils/qs';
import Line from "./line";
import DateArea from "./DateArea";
import { LeftCircleOutlined, RightOutlined, CloseOutlined, RightCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import arrival_img from '../../../assets/arrival.png'
import endTime_img from '../../../assets/endTime.png'

export default class ListLi extends React.Component {
    constructor(props) {
        super(props)
        //console.log(props, )
        this.state = {
            list: [],
            threeMonthDays: JSON.parse(window.localStorage.getItem('lastRes'))
        }
    }

    componentDidMount = () => {

    }

    componentWillReceiveProps=async(e)=> {
        let threeMonthDays = e.lastRes || this.props.lastRes;
        emitter.addListener('swipLeft', data => {
            //console.log(data,)
            threeMonthDays['beginLeft'] = data + 'px';
            this.setState({
                threeMonthDays
            })
        })
        //console.log(e, this.props)
        let empty = []

        empty = await e.listSonData
        empty.map(v => {
            if (v['batch'] && v['batch'].length) {
                v['arrival_date'] = [];
                v['end_datetime'] = [];
                v['batch'].map((data, index) => {
                    v['arrival_date'].push(qs.transDate(data['arrival_date']));
                    v['end_datetime'].push(qs.transDate(data['end_datetime']));
                })
            }
        })

        this.setState({
            list: empty
        })
    }

    render() {

        let { list } = this.state
        let threeMonthDays = this.props.lastRes
        //console.log(threeMonthDays,)
        return (
            <div className='list_li' style={{ height: this.props.height + 'px' }}>

                {list.length ? list.map((vli, ili) => {
                    //console.log(vli, )
                    return (
                        <div className='line_list' key={ili + 'line_list'}>
                            <div className='tips_text'>
                                <p>
                                    <img src={endTime_img} style={{ width: 12, height: 12, marginRight: 3 }} alt='' />
                                    截单</p>
                                <p>
                                    <img src={arrival_img} style={{ width: 12, height: 12, marginRight: 3 }} alt='' />
                                    到货</p>
                            </div>
                            {
                                threeMonthDays ? (

                                    <div ref=''
                                        key={ili + 'list_li_line'}
                                        className='list_li_line'
                                        style={{ width: threeMonthDays['swipWidth'], marginLeft: threeMonthDays['beginLeft'] }}>

                                        <div className='end_datetime'>
                                            {/* <DateArea threeMonthDays={threeMonthDays} item={vli} end={true} /> */}
                                            <ul className='home_ul' ref='home_ul'>
                                                {
                                                    threeMonthDays.looparr && threeMonthDays.looparr.length ? (
                                                        threeMonthDays.looparr.map((v, i) => {
                                                            //console.log(threeMonthDays,)
                                                            return (
                                                                <li
                                                                    key={i + 'end_datetime'}
                                                                    className={v.daynum === 0 || v.daynum === 6 ? 'lineLi week' : 'lineLi'}>
                                                                    {/* <div>{v}</div> */}

                                                                    {
                                                                        Array.isArray(vli['end_datetime']) && vli['end_datetime'].map((data, index) => {
                                                                            if (!data || !threeMonthDays) return
                                                                            let jsx

                                                                            if (v.year === data['year'] && v.month === data['month'] && v.date === data['day']) {
                                                                                //console.log(data, threeMonthDays.preMonth)
                                                                                jsx = <div title={'截单日期：' + data['month'] + '-' + data['day'] + ' ' + `${vli['end_time']}`} className='dayLi error'>
                                                                                    <Line
                                                                                        params={
                                                                                            {
                                                                                                endDay: vli['end_day'],
                                                                                                name: vli['group_name']
                                                                                            }
                                                                                        } />
                                                                                    <CheckCircleOutlined className='circleLine' style={{ background: '#FF6666', color: '#fff', borderRadius: '50%', zIndex: 10 }} />
                                                                                </div>
                                                                            }
                                                                            return jsx;
                                                                        })
                                                                    }
                                                                </li>
                                                            )
                                                        })
                                                    ) : null
                                                }
                                            </ul>

                                        </div>

                                        <div className='arrival_date'>
                                            {/* <DateArea threeMonthDays={threeMonthDays} item={vli} end={false} /> */}
                                            <ul className='home_ul' ref='home_ul'>
                                                {
                                                    threeMonthDays.looparr && threeMonthDays.looparr.length ? (
                                                        threeMonthDays.looparr.map((v, i) => {
                                                            return (

                                                                <li
                                                                    key={i + 'arrival_date'}
                                                                    className={v.daynum === 0 || v.daynum === 6 ? 'lineLi week' : 'lineLi'}>
                                                                    {/* <div>{v}</div> */}

                                                                    {
                                                                        Array.isArray(vli['arrival_date']) && vli['arrival_date'].map((data, index) => {
                                                                            if (!data || !threeMonthDays) return
                                                                            let jsx
                                                                            if (v.year === data['year'] && v.month === data['month'] && v.date === data['day']) {
                                                                                //console.log(data, threeMonthDays.preMonth)
                                                                                jsx = <div title={'到货日期：' + data['month'] + '-' + data['day']} className='dayLi success'>
                                                                                    <CheckCircleOutlined
                                                                                        className='circleLine'
                                                                                        style={{
                                                                                            background: '#366FBB',
                                                                                            color: '#fff',
                                                                                            borderRadius: '50%'
                                                                                        }} />
                                                                                </div>
                                                                            }
                                                                            return jsx;
                                                                        })
                                                                    }
                                                                </li>
                                                            )
                                                        })
                                                    ) : null
                                                }
                                            </ul>
                                        </div>

                                    </div>

                                ) : null
                            }
                        </div>
                    )
                }) : ''}

            </div>
        )
    }
}