import Header from './Header';
import React from "react";
import { Layout } from "antd";
import { renderRoutes } from "react-router-config";
import LeftMenus from "./LeftMenus";
import Base from '../base';
import { withRouter } from "react-router-dom";
import navs from "../../configs/nav.config";
import emitter from "../../ev"
const { Content } = Layout;
class BaseLayout extends Base {
    constructor(props) {
        super(props);
        this.state = {
            navs: navs,
            userInfo: null,
            pathname: ''
        }
    }

    componentWillMount() {
        emitter.addListener('userInfo', data => {
            if (data) {
                this.setState({
                    userInfo: data
                })
            }
        })

        this.setState({
            pathname: window.location.pathname
        })
    }


    render() {
        let { pathname } = this.state
        let route = this.props.route;
        return (
            <Layout style={{ background: "#F0F0F0", height: "100vh", boxSizing: 'border-box',overflowY:'auto' }}>
                <Header className="ttq-app-header" navs={this.state.navs} userInfodata={this.state.userInfo} />
                <Layout style={{
                    background: "#FFFFFF",
                    minHeight: '800px',
                    display: 'flex',
                    flex: 1,
                    margin: '0 auto',
                    minWidth: 1400,
                    marginTop: 15
                }}>
                    {
                        pathname != '/home' ? (
                            <LeftMenus menus={route.sider}></LeftMenus>
                        ) : null
                    }

                    {/* <LeftMenus menus={route.sider}></LeftMenus> */}

                    <Content >{renderRoutes(route.routes)}</Content>
                    {/* style={{width:'840px'}} */}
                </Layout>
            </Layout>
        );
    }
}

export default withRouter(BaseLayout);