import Layout from '../components/layout';
import OrderManagement from '../pages/orders/orderManagement/orderManagement';
import OrderReport from '../pages/orders/orderManagement/orderReport';
import OrderDetail from '../pages/orders/orderDetail/orderDetail';
import ordersIcon from '../assets/ordersIcon.png'
const sider = [
    {
        name: '订单',
        path: '/orders/orderManagement',
        icon: ordersIcon
    },
    {
        name: '订单报表',
        path: '/orders/orderReport',
        icon: ordersIcon
    }
];
const orders = {
    path: "/orders",
    component: Layout,
    sider: sider,
    routes:[
        {
            path: "/orders",
            exact: true,
            name: 'ordersIndex',
            component: OrderManagement
        },
        {
            path: "/orders/orderManagement",
            exact: true,
            name: 'orderManagement',
            component: OrderManagement
        },
        {
            path: "/orders/orderDetail",
            exact: true,
            name: 'orderDetail',
            component: OrderDetail
        },
        {
            path: "/orders/orderReport",
            exact: true,
            name: 'orderReport',
            component: OrderReport
        }
    ]
};
export default orders;