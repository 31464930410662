import {Request} from '@zoranwong/acc-engine.js'
let merchant_id = window.localStorage.getItem('merchant_id')
export default class AddPurchaseRequest extends Request{
  _headers = {
    'request-source':'admin-web'
   }
   _data  = {
    group_type:null,
    //merchant_id: null,
    group_name:'',
    end_day:null,
    end_time:'00:00',
    delivery_type:null,
    is_open:'',
    group_detail:'',
    group_rule:{},
    arrival_datetime:{},
    goods_list:{},
    commission_rate: null,
    visible_channel: [],
    open_audit: 0,
    promotions: []

  };
  _method = 'POST';
  _uri = '/goods/group-buy/group-template/group-template-add';
  constructor(params) {
   super(params);
   //this._data['merchant_id'] = merchant_id;//params.merchant_id;
   this._data['group_type'] = params.group_type;
   this._data['group_name'] = params.group_name;
   this._data['end_day'] = params.end_day;
   this._data['end_time'] = params.end_time;
   this._data['delivery_type'] = params.delivery_type;
   this._data['is_open'] = params.is_open;
   this._data['group_detail'] = params.group_detail;
   this._data['group_rule'] = params.group_rule;
   this._data['arrival_datetime'] = params.arrival_datetime;
   this._data['goods_list'] = params.goods_list;
   this._data['commission_rate'] = params.commission_rate;
   this._data['visible_channel'] = params.visible_channel;
   this._data['open_audit'] = params.open_audit;
   this._data['promotions'] = params.promotions;//
   this._data['group_notice'] = params.group_notice;
   this._data['refund_limit'] = params.refund_limit;
   this._data['need_pay'] = params.need_pay;
   this._data['commune_id'] = params.commune_id;
  }
  // get middleware(){
  //   return [...super.middleware, PasswordHashMiddleware];
  // }
  rules(){
    return {
    };
  }

  messages(){
    return  {   
   };
  }
}